import React from "react";
import { Court } from "../../../types";
import { useGlobalContext } from "../GlobalProvider";
import { CommonSelect } from "../CommonSelect";

/** Props for the disabled state of the CourtSelect component */
type DisabledProps = {
  /** When set to true, the component is read-only */
  disabled: true;
};

/** Props for the enabled state of the CourtSelect component */
type EnabledProps = {
  /** Callback function for updating the selected court */
  setSelectedCourt: (courtId: string) => void;
  /** When set to true, the component is read-only */
  disabled?: false;
};

/** Props for the CourtSelect component */
export type CourtSelectProps = (DisabledProps | EnabledProps) & {
  /** Selected court value */
  selectedCourt: Court;
  /** Array of available courts */
  courts: Court[];
};

/**
 * CourtSelect component allows the user to select a specific court from a list of available courts. It supports both
 * read-only and editable states.
 *
 * @param props - The props for the CourtSelect component.
 * @returns The CourtSelect component.
 */
export const CourtSelect: React.FC<CourtSelectProps> = (props) => {
  const { globalSelectedClub } = useGlobalContext();

  const isSelectedCourtInCurrentClub = globalSelectedClub?.courts.some((court) => court.id === props.selectedCourt.id);

  /**
   * Handles the change of selected court. If the component is not disabled and the setSelectedCourt callback is
   * defined, it calls setSelectedCourt with the new value.
   *
   * @param event - The event object
   */
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.disabled && "setSelectedCourt" in props) {
      props.setSelectedCourt(event.target.value);
    }
  };

  let options = props.courts.map((court) => ({ value: court.id || "", label: court.name }));
  if (!isSelectedCourtInCurrentClub) {
    options = [{ value: props.selectedCourt.id || "", label: "Other club's court" }, ...options];
  }
  if (props.courts.length === 0) {
    options = [{ value: "", label: "No courts defined" }, ...options];
  }

  return (
    <CommonSelect
      placeholder="Court"
      value={props.selectedCourt?.id || ""}
      onChange={handleChange}
      isDisabled={props.disabled}
      options={options}
      data-testid="court-select"
    />
  );
};
