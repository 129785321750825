import React from "react";
import { Coach, Venue, Athlete, Drill, type Session, type SkillSet } from "../../../../../types";
import { ViewEventModal } from "./components/ViewEventModal/ViewEventModal";
import { AddEventModal } from "./components/AddEventModal/AddEventModal";
import { Box } from "@chakra-ui/react";
import type { TrainingEventDetails, TrainingEventUpdateInput } from "../../../types";

/** Props for the CalendarModals component. */
export type CalendarModalsProps = {
  /** Indicates if the view event modal is open. */
  isViewModalOpen: boolean;
  /** Function to save the updated training event data. */
  saveTrainingEventUpdate: (
    trainingEventType: TrainingEventDetails["trainingEventType"],
    updatedData: TrainingEventUpdateInput,
  ) => void;
  /** Information of the selected event. */
  selectedEventInfo: TrainingEventDetails;
  /** Indicates if the add event modal is open. */
  isAddEventModalOpen: boolean;
  /** Function to save the new training event data. */
  saveNewSingleTrainingEvent: (singleTrainingEventInput: TrainingEventUpdateInput) => void;
  /** The selected date and time for the event. */
  selectedDateTime: string | undefined;
  /** The selected start time for the event. */
  selectedStartTime: string | null;
  /** The selected end time for the event. */
  selectedEndTime: string | null;
  /** The selected venue for the event in day view. */
  dayViewSelectedVenue: Venue | null;
  /** List of all coaches. */
  allCoaches: Coach[];
  /** List of all venues. */
  allVenues: Venue[];
  /** List of all athletes. */
  allAthletes: Athlete[];
  /** List of all drills. */
  allDrills: Drill[];
  /** List of all sessions. */
  allSessions: Session[];
  /** List of all skill sets. */
  allSkillSets: SkillSet[];
  /** Function to handle closing of the add event modal. */
  handleAddEventModalClose: () => void;
  /** Function to handle closing of the view event modal. */
  handleViewEventModalClose: () => void;
  /** Indicates if the user is using a mobile device. */
  isMobile: boolean;
};

/** A component that manages the modals for viewing and adding calendar events. */
export const CalendarModals: React.FC<CalendarModalsProps> = (props) => {
  return (
    <Box id="calendar-modals">
      <ViewEventModal
        key={JSON.stringify(props.selectedEventInfo)} // to make absolutely sure that event has up to date info (note id missing after initial creation)
        open={props.isViewModalOpen}
        onClose={props.handleViewEventModalClose}
        onSave={props.saveTrainingEventUpdate}
        event={props.selectedEventInfo}
        allCoaches={props.allCoaches}
        allVenues={props.allVenues}
        allAthletes={props.allAthletes}
        allDrills={props.allDrills}
        allSessions={props.allSessions}
        allSkillSets={props.allSkillSets}
        isMobile={props.isMobile}
      />
      <AddEventModal
        key={Math.random()}
        open={props.isAddEventModalOpen}
        onSave={props.saveNewSingleTrainingEvent}
        onClose={props.handleAddEventModalClose}
        initialDate={props.selectedDateTime}
        initialTime={props.selectedStartTime}
        initialEndTime={props.selectedEndTime}
        initialVenue={props.dayViewSelectedVenue || props.allVenues[0] || null}
        allCoaches={props.allCoaches}
        allVenues={props.allVenues}
        allAthletes={props.allAthletes}
        allDrills={props.allDrills}
        allSessions={props.allSessions}
        allSkillSets={props.allSkillSets}
        isMobile={props.isMobile}
      />
    </Box>
  );
};
