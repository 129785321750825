import { Coach, SingleTrainingEvent, type Organization, type TrainingGroupEvent } from "../../../types";
import { extractUtcTime } from "../../../common/utils/dateAndTime";

import type {
  TrainingEventDetails,
  SingleOrTrainingGroupData,
  TrainingEventUpdateInput,
  FullCalendarEvent,
  FullCalendarEventExtendedProps,
} from "../types";
import type { FetchResult, OperationVariables } from "@apollo/client";

/**
 * Creates events based on the provided training data.
 *
 * @param singleOrTrainingGroupData - Array of training event objects.
 * @returns An array of created events.
 */
export const createEvents = (
  singleOrTrainingGroupData: SingleOrTrainingGroupData[],
  eventType: FullCalendarEventExtendedProps["eventType"],
): FullCalendarEvent[] => {
  const createdEvents: FullCalendarEvent[] = singleOrTrainingGroupData.map((trainingEventData) => {
    const id = trainingEventData.id || "";
    const start = trainingEventData.startDateTime;
    const end = trainingEventData.endDateTime;
    const eventVenue = trainingEventData.venue;
    const eventCoaches = trainingEventData.coaches || [];
    const eventAthletes = trainingEventData.athletes || [];
    const eventIsLoading = false;

    if (eventType === "weekly" && "trainingGroup" in trainingEventData) {
      // Weekly training event
      const trainingGroupEvent: FullCalendarEvent = {
        id,
        start,
        end,
        eventCoaches,
        eventVenue,
        eventAthletes,
        eventIsLoading,
        eventType: "weekly",
        eventName: trainingEventData.trainingGroup?.name || "",
      };
      return trainingGroupEvent;
    } else if (eventType === "single") {
      // Single training event
      const singleTrainingEvent: FullCalendarEvent = {
        id,
        start,
        end,
        eventCoaches,
        eventVenue,
        eventAthletes,
        eventIsLoading,
        eventType: "single",
        eventName: "One-time training",
      };
      return singleTrainingEvent;
    } else {
      // Return empty object if the event type is not recognized
      return {} as FullCalendarEvent;
    }
  });

  return createdEvents;
};

/**
 * Creates training group events based on the provided trainingGroupEvents and athlete information.
 *
 * @param trainingGroupEvents - Array of all training group events
 * @returns An array of created events.
 */
export const createTrainingGroupEvents = (trainingGroupEvents: TrainingGroupEvent[]) => {
  const trainingGroupEventsToCreate: TrainingGroupEvent[] = trainingGroupEvents.map((trainingGroupEvent) => {
    const event: TrainingGroupEvent = {
      id: trainingGroupEvent.id,
      venue: trainingGroupEvent.venue,
      coaches: trainingGroupEvent.coaches || [],
      athletes: trainingGroupEvent.athletes || [],
      startDateTime: trainingGroupEvent.startDateTime,
      endDateTime: trainingGroupEvent.endDateTime,
      trainingGroup: trainingGroupEvent.trainingGroup,
    };
    return event;
  });

  return createEvents(trainingGroupEventsToCreate, "weekly");
};

/**
 * Creates single training events for use in the application.
 *
 * @param singleTrainingEvents - An array of database single training event objects.
 * @returns The formatted single training events.
 */
export const createSingleTrainingEvents = (singleTrainingEvents: SingleTrainingEvent[]) => {
  const singleTrainingEventsToCreate: SingleTrainingEvent[] = singleTrainingEvents.map((singleTrainingEvent) => {
    const event: SingleTrainingEvent = {
      id: singleTrainingEvent.id,
      venue: singleTrainingEvent.venue,
      coaches: singleTrainingEvent.coaches || [],
      athletes: singleTrainingEvent.athletes || [],
      startDateTime: singleTrainingEvent.startDateTime,
      endDateTime: singleTrainingEvent.endDateTime,
    };
    return event;
  });

  return createEvents(singleTrainingEventsToCreate, "single");
};

/**
 * Handles the date selection and updates the corresponding state.
 *
 * @param args - The information about the selected date, including start and end times.
 * @param setSelectedDateTime - Function to update the selected date and time.
 * @param setSelectedStartTime - Function to update the selected start time.
 * @param setSelectedEndTime - Function to update the selected end time.
 * @param setIsAddEventModalOpen - Function to open the add event modal.
 */
export const handleDateSelect = (
  args: {
    start: Date;
    end: Date;
  },
  setSelectedDateTime: (dateTime: string) => void,
  setSelectedStartTime: (time: string) => void,
  setSelectedEndTime: (time: string) => void,
  setIsAddEventModalOpen: (isOpen: boolean) => void,
): void => {
  setSelectedDateTime(new Date(args.start).toISOString());
  setSelectedStartTime(extractUtcTime(new Date(args.start).toISOString()));
  setSelectedEndTime(extractUtcTime(new Date(args.end).toISOString()));
  setIsAddEventModalOpen(true);
};

/**
 * Saves a new training event with the given data.
 *
 * @param singleTrainingEventInput - The data for the event to be saved.
 * @param setIsAddEventModalOpen - Function to set the state of the event modal.
 * @param saveSingleTrainingEvent - Function to save a single training event.
 * @param setFullCalendarKey - Function to update the calendar key.
 * @param showAlert - Function to show alerts.
 */
export const saveNewSingleTrainingEvent = async (
  singleTrainingEventInput: TrainingEventUpdateInput,
  setIsAddEventModalOpen: (isOpen: boolean) => void,
  saveSingleTrainingEvent: (variables?: OperationVariables | undefined) => Promise<FetchResult<any>>,
  showAlert: (message: string, type: "success" | "error", timeout?: number) => void,
) => {
  setIsAddEventModalOpen(false);

  const result = await saveSingleTrainingEvent({ data: singleTrainingEventInput });

  if (result) {
    showAlert("Created new training!", "success", 5000);
  }
};

/**
 * Saves an updated training event.
 *
 * @param updatedData - The data of the event to be updated.
 * @param setIsViewModalOpen - Function to set the view modal state.
 * @param saveWeeklyTrainingEvent - Function to save updated weekly training events.
 * @param saveSingleTrainingEvent - Function to save single training events.
 * @param setIsAddEventModalOpen - Function to set the add event modal state.
 * @param showAlert - Function to show alerts.
 */
export const saveTrainingEventUpdate = async (
  trainingEventType: TrainingEventDetails["trainingEventType"],
  updatedData: TrainingEventUpdateInput,
  setIsViewModalOpen: (isOpen: boolean) => void,
  saveWeeklyTrainingEvent: (variables?: OperationVariables | undefined) => Promise<FetchResult<any>>,
  saveSingleTrainingEvent: (variables?: OperationVariables | undefined) => Promise<FetchResult<any>>,
  setIsAddEventModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showAlert: (message: string, type: "success" | "error", duration?: number) => void,
) => {
  setIsViewModalOpen(false);
  setIsAddEventModalOpen(false);

  let result;
  if (trainingEventType === "single") {
    // Single training event
    result = await saveSingleTrainingEvent({ data: updatedData });
  } else {
    // Weekly training event
    result = await saveWeeklyTrainingEvent({ data: updatedData });
  }

  if (result) {
    if (updatedData.remove) {
      showAlert("Training deleted!", "success", 5000);
    } else {
      showAlert("Training updated!", "success", 5000);
    }
  }
};

/**
 * Filters events based on selected coaches, availability, and event types.
 *
 * @param unitedEventsRef - A reference to the list of all events.
 * @param selectedCoaches - A list of selected coaches to filter the events by.
 * @param showOnlyFreeSpots - A flag to show only events with free spots.
 * @param showOnlyTrainingGroups - A flag to show only Training Groups.
 * @param showOnlySingleTrainingEvents - A flag to show only single training events.
 * @returns A filtered list of events based on the provided criteria.
 */
export const filterEvents = (
  unitedEventsRef: React.MutableRefObject<FullCalendarEvent[] | null>,
  selectedCoaches: Coach[],
  showOnlyFreeSpots: boolean,
  showOnlyTrainingGroups: boolean,
  showOnlySingleTrainingEvents: boolean,
  organization?: Organization,
): FullCalendarEvent[] | undefined => {
  return unitedEventsRef?.current?.filter((event) => {
    const coachMatch =
      selectedCoaches.length === 0 ||
      selectedCoaches.some((selectedCoach) =>
        event?.eventCoaches.map((coach: Coach) => coach.id).includes(selectedCoach.id),
      );

    const expectedAthletesPerTrainingEvent = organization?.expectedAthletesPerTrainingEvent || 4;

    const freeSpotMatch = !showOnlyFreeSpots || event.eventAthletes.length < expectedAthletesPerTrainingEvent;

    const trainingGroupsMatch = !showOnlyTrainingGroups || event.eventType === "weekly";

    const singleTrainingEventsMatch = !showOnlySingleTrainingEvents || event.eventType === "single";

    return coachMatch && freeSpotMatch && trainingGroupsMatch && singleTrainingEventsMatch;
  });
};

/**
 * Updates the selected event information based on the given event type and data.
 *
 * @param eventType - The type of training event (single or weekly).
 * @param eventData - The event data object.
 * @param setSelectedEventInfo - Function to update the selected event information.
 */
export const setEventInfo = (
  eventType: "single" | "weekly",
  eventData: TrainingGroupEvent | SingleTrainingEvent,
  setSelectedEventInfo: (eventInfo: TrainingEventDetails) => void,
) => {
  const isTrainingGroupEvent = eventType === "weekly" && "trainingGroup" in eventData;
  if (!isTrainingGroupEvent && eventType !== "single") {
    // for type safety
    return;
  }

  setSelectedEventInfo({
    id: eventData.id || "",
    name: isTrainingGroupEvent ? eventData.trainingGroup.name : "One-time training",
    coach: eventData.coaches || [],
    venue: eventData.venue,
    date: eventData.startDateTime,
    start: eventData.startDateTime,
    end: eventData.endDateTime,
    athletes: eventData.athletes || [],
    groupNote: eventData.notes?.find((note) => note.isGroupNote),
    athleteNotes: eventData.notes?.filter((note) => !note.isGroupNote) || [],
    drills: eventData.drills,
    session: eventData.session,
    trainingEventType: eventType,
    seasonPlans: isTrainingGroupEvent ? eventData.trainingGroup.seasonPlans : undefined,
    trainingGroup: isTrainingGroupEvent ? eventData.trainingGroup : undefined,
    isLoading: false,
  });
};

/**
 * Checks if two dates are equal.
 *
 * @remarks
 *   This function compares the year, month, and date, but not the time.
 * @example
 *   ```typescript
 *   const same = areDatesEqual(new Date(2022, 1, 1), new Date(2022, 1, 1));
 *   console.log(same); // Output: true
 *   ```;
 *
 * @param date1 - The first date to check.
 * @param date2 - The second date to check.
 * @returns Returns `true` if the dates are the same, otherwise `false`.
 */
export const areDatesEqual = (date1: Date | string, date2: Date | string) => {
  date1 = new Date(date1);
  date2 = new Date(date2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
