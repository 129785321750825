import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { CalendarWeekViewButton } from "./CalendarWeekViewButton";
import { CommonIconButton } from "../../../../../../common/components/CommonIconButton";
import { CommonButton } from "../../../../../../common/components/CommonButton";

/** Props for the CalendarViewControls component. */
export type CalendarViewControlsProps = {
  /** The currently active view in the calendar. */
  activeView: string;
  /** The date to be displayed in the day view. */
  dayViewDate: Date;
  /** A function to set the new day view date. */
  setDayViewDate: (dateSetter: (prevDate: Date) => Date) => void;
  /** A function to set the active view in the calendar. */
  setActiveView: (view: string) => void;
  /** A function to set the key for the full calendar. */
  setFullCalendarKey: (keySetter: (prevKey: number) => number) => void;
  /** Reference to the calendar components. */
  calendarRefs: any;
  /** The global selected club state. */
  globalSelectedClub: boolean;
  /** Function to get a new date based on the current view and direction. */
  getNewDate: (prevDate: Date, isNext: boolean, activeView: string, calendarRefs: any) => Date;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
  onlyMonthView?: boolean;
};

/** A control component for navigating the calendar views and dates. */
export const CalendarViewControls: React.FC<CalendarViewControlsProps> = (props) => {
  return (
    <Stack
      justifyContent="center"
      spacing="4"
      alignItems="center"
      direction="row"
      display={!props.globalSelectedClub ? "none" : "flex"}
      marginBottom="4"
      data-testid="calendar-view-controls"
    >
      <CommonIconButton
        ariaLabel="previous"
        onClick={() =>
          props.setDayViewDate((prevDate) => props.getNewDate(prevDate, false, props.activeView, props.calendarRefs))
        }
        icon={<ChevronLeftIcon boxSize="6" color="blackAlpha.800" />}
      />
      {!props.isMobile &&
        !props.onlyMonthView &&
        (props.activeView === "timeGridDay" ? (
          CalendarWeekViewButton(props)
        ) : (
          <CommonButton
            variantType="outlineSecondary"
            aria-label="month-view"
            disabled={props.activeView === "dayGridMonth"}
            onClick={() => {
              props.setActiveView("dayGridMonth");
              props.setFullCalendarKey((prevKey) => prevKey + Math.random());
            }}
          >
            <Text fontSize="lg" paddingY="2" minWidth="55px" /* equivalent of "Month" text */>
              Month
            </Text>
          </CommonButton>
        ))}
      {
        <Text
          fontSize="lg"
          flex={{ mobile: 1, laptop: 0 }}
          minWidth={{ mobile: undefined, laptop: "60" }}
          textAlign="center"
        >
          {props.activeView === "timeGridDay" &&
            props.dayViewDate.toLocaleDateString("en-GB", {
              weekday: "long",
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })}
          {props.activeView === "timeGridWeek" && (
            <>
              {props.dayViewDate.toLocaleDateString("en-GB", {
                month: "long",
              })}{" "}
              {props.dayViewDate.toLocaleDateString("en-GB", {
                day: "numeric",
              })}
              {" – "}
              {new Date(props.dayViewDate.getTime() + 4 * 24 * 60 * 60 * 1000).toLocaleDateString("en-GB", {
                day: "numeric",
              })}
              {", "}
              {new Date(props.dayViewDate.getTime() + 4 * 24 * 60 * 60 * 1000).toLocaleDateString("en-GB", {
                year: "numeric",
              })}
            </>
          )}
          {props.activeView === "dayGridMonth" && (
            <>
              {props.dayViewDate.toLocaleDateString("en-GB", {
                month: "long",
              })}{" "}
              1 – {new Date(props.dayViewDate.getFullYear(), props.dayViewDate.getMonth() + 1, 0).getDate()},{" "}
              {props.dayViewDate.getFullYear()}
            </>
          )}
        </Text>
      }
      {!props.isMobile &&
        !props.onlyMonthView &&
        (props.activeView === "dayGridMonth" ? (
          CalendarWeekViewButton(props)
        ) : (
          <CommonButton
            variantType="outlineSecondary"
            aria-label="day-view"
            disabled={props.activeView === "timeGridDay"}
            onClick={() => {
              props.setActiveView("timeGridDay");
              props.setFullCalendarKey((prevKey) => prevKey + Math.random());
            }}
          >
            <Text fontSize="lg" paddingY="2" minWidth="55px" /* equivalent of "Month" text */>
              Day
            </Text>
          </CommonButton>
        ))}
      <CommonIconButton
        ariaLabel="next"
        onClick={() =>
          props.setDayViewDate((prevDate) => props.getNewDate(prevDate, true, props.activeView, props.calendarRefs))
        }
        icon={<ChevronRightIcon boxSize="6" color="blackAlpha.800" />}
      />
    </Stack>
  );
};
