import React from "react";
import { MultiTagSelectMenu } from "../../../../../../common/components/MultiTagSelectMenu";
import type { Player } from "../../../../../../types";

type PlayerInputProps = {
  /** Callback function for player selection */
  handlePlayerSelection: (value: Player[]) => void;
  /** Array of player names */
  players: Player[];
  /** Array of the selected players */
  selectedPlayers: Player[];
};

/** PlayerInput component allows the user to search for existing players by name, or create a new one from the spot. */
export const PlayerInput: React.FC<PlayerInputProps> = (props) => {
  return (
    <MultiTagSelectMenu
      options={props.players}
      selectedTags={props.selectedPlayers}
      handleTagSelection={props.handlePlayerSelection}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      label="Players"
      disableActive={true}
    />
  );
};
