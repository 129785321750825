import { Button, FormControl, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import React, { useState } from "react";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils/styles";

/** Props for the CommonInput component. */
export type CommonInputProps = {
  /** The current value of the input field. */
  value: string;
  /** Placeholder text for the input field. */
  placeholder: string;
  /** Callback function to handle changes in the input field. */
  onChange: (value: string) => void;
  /** Whether the input field is disabled. */
  disabled?: boolean;
  /** Whether the input field is required. */
  required?: boolean;
  /** The data-testid attribute to be added to the input field. */
  dataTestId?: string;
  /** The type of the input field. */
  type?: string;
};

/** A common input component that supports different customization options. */
export const CommonInput: React.FC<CommonInputProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  //  TODO: set id from prop

  return (
    <InputGroup>
      <FormControl id="common-input">
        <CommonFormLabel display={!!props.value}>{props.placeholder}</CommonFormLabel>
        <Input
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          required={props.required}
          height="42px"
          opacity={props.disabled ? 0.6 : 1}
          isDisabled={props.disabled}
          type={props.type === "password" && isPasswordVisible ? "text" : props.type}
          paddingRight={props.type === "password" ? "14" : undefined}
          {...commonStyles(!!props.value)}
          data-testid={props.dataTestId}
        />
        {props.type === "password" && (
          <InputRightElement marginRight="2">
            <Button variant="unstyled" size="sm" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        )}
      </FormControl>
    </InputGroup>
  );
};
