import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateAccount } from "./CreateAccount";
import { useAlert } from "../../../../common/components/AlertProvider";
import { GET_INVITE_CODE } from "../../graphql/get-invite-code.query";
import { useLazyQuery } from "@apollo/client";
import { useHandleQueryErrors } from "../../../../common/hooks";
import { Box } from "@chakra-ui/react";
import { LoadingContainer } from "../../../../common/components/LoadingContainer";

type CreateAccountRouteProps = {
  setIsAuthenticated: (value: boolean) => void;
};

/**
 * Ensures that the create-account page can only be accessed with a valid inviteCode. Redirects to /login if inviteCode
 * is missing, invalid, or already used.
 */
export const CreateAccountRoute: React.FC<CreateAccountRouteProps> = (props) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [getInviteCode, { data, loading, error }] = useLazyQuery(GET_INVITE_CODE, {
    fetchPolicy: "network-only",
  });

  useHandleQueryErrors([error]);

  const searchParams = new URLSearchParams(location.search);
  const inviteCode = searchParams.get("inviteCode");

  useEffect(() => {
    const validateInviteCode = async () => {
      if (!inviteCode || inviteCode.length !== 8) {
        navigate("/login");
        return;
      }

      await getInviteCode({ variables: { inviteCode } });
      setInitialLoading(false);
    };

    validateInviteCode();
  }, []);

  if (initialLoading || loading) {
    // still validating, show a loader
    return (
      <Box position="relative">
        <LoadingContainer display={true} />
      </Box>
    );
  }

  if (data?.getInviteCode) {
    if (data.getInviteCode.used) {
      // inviteCode query succeeded, but it is already used, redirect to login with a warning
      showAlert("Invite link is already used", "warning");
      navigate("/login");
    } else {
      // inviteCode query succeeded, pass it to CreateAccount
      return <CreateAccount setIsAuthenticated={props.setIsAuthenticated} invite={data.getInviteCode} />;
    }
  }

  if (!data?.getInviteCode) {
    // inviteCode query failed or returned no data, redirect to login with a warning
    showAlert("Invite link is invalid", "warning");
    navigate("/login");
  }
};
