import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem, Stack, Text, Tooltip } from "@chakra-ui/react";
import { CommonDayPicker } from "../../../../common/components/CommonDayPicker";
import { CommonButton } from "../../../../common/components/CommonButton";
import { useAlert } from "../../../../common/components/AlertProvider";
import { formatDateToUTC } from "../../../../common/utils";
import { ConfirmationDialog } from "../../../../common/components/ConfirmationDialog";
import type { MissingMetadata } from "../../../../types";
import { extractDateFromUTC } from "../../../../common/utils";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";

type SeasonProps = {
  seasonStart?: string;
  seasonEnd?: string;
  missingMetadata: MissingMetadata[];
  updateOrganizationLoading: boolean;
  createMetadatasLoading: boolean;
  updateOrganization: (data: any) => Promise<void>;
  createMissingMetadatas: () => Promise<void>;
};

export const Season: React.FC<SeasonProps> = (props) => {
  const [seasonStart, setSeasonStart] = useState(props.seasonStart);
  const [seasonEnd, setSeasonEnd] = useState(props.seasonEnd);
  const [seasonDatesModified, setIsSeasonDatesModified] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    setIsSeasonDatesModified(seasonStart !== props.seasonStart || seasonEnd !== props.seasonEnd);
  }, [seasonStart, seasonEnd]);

  const handleSaveSeason = async () => {
    showAlert("Updating season dates", "info", undefined, true);
    await props.updateOrganization({ seasonStart, seasonEnd });
    setIsSeasonDatesModified(false);
    showAlert("Season dates updated!", "success", 5000);
  };

  const handleConfirm = async () => {
    setIsConfirmationOpen(false);
    showAlert("Updating training events", "info", undefined, true);
    await props.createMissingMetadatas();
    showAlert("Training events updated!", "success", 5000);
  };

  return (
    <Box position="relative" id="season-settings">
      <LoadingOverlay
        display={props.updateOrganizationLoading || props.createMetadatasLoading}
        spinnerSize="xl"
        spinnerTopPosition="40px"
      />

      <Tooltip label="Training Season determines time period, for which recurring trainings will be created">
        <Text fontSize="x-large" marginBottom="6" textAlign="center">
          Training Season
        </Text>
      </Tooltip>

      <Grid templateColumns="repeat(3, 1fr)" gap="12">
        <GridItem colSpan={1}>
          <CommonDayPicker
            onDateChange={(date) => (date ? setSeasonStart(formatDateToUTC(date)) : setSeasonStart(undefined))}
            initialDate={seasonStart ? new Date(seasonStart) : undefined}
            label="Season start"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <CommonDayPicker
            onDateChange={(date) => (date ? setSeasonEnd(formatDateToUTC(date)) : setSeasonEnd(undefined))}
            initialDate={seasonEnd ? new Date(seasonEnd) : undefined}
            label="Season end"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stack direction="row" spacing="6">
            <CommonButton
              fullWidth
              variantType="solidPrimary"
              onClick={handleSaveSeason}
              disabled={!seasonDatesModified}
              tooltip={seasonDatesModified ? "" : "Update both Season Start and Season End dates to save"}
              dataTestId="save-season-dates"
            >
              Update season dates
            </CommonButton>
            <CommonButton
              fullWidth
              variantType="outlineSecondary"
              onClick={() => setIsConfirmationOpen(true)}
              disabled={props.missingMetadata.length === 0}
              tooltip={props.missingMetadata.length > 0 ? "" : "All recurring training events are up to date!"}
              dataTestId="update-training-events"
            >
              Update training events
            </CommonButton>
          </Stack>
        </GridItem>
      </Grid>

      <ConfirmationDialog
        title="Update training events"
        message={
          props.missingMetadata.length > 0 ? (
            <Stack direction="column" spacing="4">
              <Text>
                Below are the missing recurring training events that align with the updated season dates. These events
                will be created once you confirm:
              </Text>
              {props.missingMetadata.map((meta, index) => (
                <Box key={index} p={4} border="1px solid" borderColor="gray.200" borderRadius="md" bg="gray.50">
                  <Text>Date: {extractDateFromUTC(meta.startDateTime)}</Text>
                  <Text>Court: {meta.court.name}</Text>
                  <Text>Coaches: {meta.coaches?.map((coach) => coach.name).join(", ") || "None"}</Text>
                  <Text>Players: {meta.players?.map((player) => player.name).join(", ") || "None"}</Text>
                </Box>
              ))}
            </Stack>
          ) : (
            "No missing Recurring Training Events"
          )
        }
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={() => setIsConfirmationOpen(false)}
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onClose={() => setIsConfirmationOpen(false)}
      />
    </Box>
  );
};
