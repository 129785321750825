import React, { useEffect, useState } from "react";
import { Drills } from "../components/Drills/Drills";
import { Sessions } from "../components/Sessions/Sessions";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { GET_ALL_DRILLS } from "../../../common/graphql/get-all-drills.query";
import { GET_ALL_SESSIONS } from "../../../common/graphql/get-all-sessions.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { SAVE_DRILL } from "../graphql/save-drill.mutation";
import { SAVE_SESSION } from "../graphql/save-session.mutation";
import { useAlert } from "../../../common/components/AlertProvider";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { componentContainerShadowStyles } from "../../../common/utils/styles";

/** DrillInput type for saving drill */
export type DrillInput = {
  id?: string;
  name: string;
  implementation: string;
  goal: string;
  tags?: string[];
  skillSetIds?: string[];
  remove?: boolean;
};

type SessionDrillInput = {
  id?: string;
  drillId: string;
  duration: number;
  order: number;
  remove?: boolean;
};

/** SessionInput type for saving session */
export type SessionInput = {
  id?: string;
  sessionDrills?: SessionDrillInput[];
  skillSetIds?: string[];
  name: string;
  goal: string;
  totalDuration: number;
  tags?: string[];
  remove?: boolean;
};

export const TrainingLibraryContainer: React.FC = () => {
  const { data: drillData, loading: drillLoading, error: drillError } = useQuery(GET_ALL_DRILLS);
  const { data: sessionData, loading: sessionLoading, error: sessionError } = useQuery(GET_ALL_SESSIONS);
  const { data: skillSetData, loading: skillSetLoading, error: skillSetError } = useQuery(GET_ALL_SKILL_SETS);

  const { execute: saveDrillMutation, loading: saveDrillLoading } = useAsyncMutation(SAVE_DRILL, {
    refetchQueries: [{ query: GET_ALL_DRILLS }],
    awaitRefetchQueries: true,
  });

  const { execute: saveSessionMutation, loading: saveSessionLoading } = useAsyncMutation(SAVE_SESSION, {
    refetchQueries: [{ query: GET_ALL_SESSIONS }],
    awaitRefetchQueries: true,
  });

  const { showAlert } = useAlert();
  const isLoading = drillLoading || sessionLoading || skillSetLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setShowContent(true), 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([drillError, sessionError, skillSetError]);

  const saveDrill = async (drillInput: DrillInput) => {
    if (drillInput.remove) {
      showAlert("Deleting Drill", "info", undefined, true);
    } else if (drillInput.id) {
      showAlert("Updating Drill", "info", undefined, true);
    } else {
      showAlert("Creating new Drill", "info", undefined, true);
    }

    const result = await saveDrillMutation({ data: drillInput });

    if (result) {
      if (drillInput.remove) {
        showAlert("Drill deleted!", "success", 5000);
      } else if (drillInput.id) {
        showAlert("Drill updated!", "success", 5000);
      } else {
        showAlert("Drill created!", "success", 5000);
      }
    }
  };

  const saveSession = async (sessionInput: SessionInput) => {
    if (sessionInput.remove) {
      showAlert("Deleting Session", "info", undefined, true);
    } else if (sessionInput.id) {
      showAlert("Updating Session", "info", undefined, true);
    } else {
      showAlert("Creating new Session", "info", undefined, true);
    }

    const result = await saveSessionMutation({ data: sessionInput });

    if (result) {
      if (sessionInput.remove) {
        showAlert("Session deleted!", "success", 5000);
      } else if (sessionInput.id) {
        showAlert("Session updated!", "success", 5000);
      } else {
        showAlert("Session created!", "success", 5000);
      }
    }
  };

  return (
    <Box height="100%">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap="8"
          animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}
          height="inherit"
        >
          <GridItem colSpan={1} height="inherit" overflow="hidden" {...componentContainerShadowStyles()}>
            <ComponentContainer handlePaddingManually={true}>
              <Drills
                allDrills={drillData?.getAllDrills || []}
                allSkillSets={skillSetData?.getAllSkillSets || []}
                saveDrill={saveDrill}
                saveDrillLoading={saveDrillLoading}
              />
            </ComponentContainer>
          </GridItem>
          <GridItem colSpan={1} height="inherit" overflow="hidden" {...componentContainerShadowStyles()}>
            <ComponentContainer handlePaddingManually={true}>
              <Sessions
                allSessions={sessionData?.getAllSessions || []}
                allDrills={drillData?.getAllDrills || []}
                allSkillSets={skillSetData?.getAllSkillSets || []}
                saveSession={saveSession}
                saveSessionLoading={saveSessionLoading}
              />
            </ComponentContainer>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};
