import React, { useRef, useState } from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { CommonButton } from "../CommonButton";
import { CommonIconButton } from "../CommonIconButton";
import { DeleteIcon } from "@chakra-ui/icons";
import { ConfirmationDialog } from "../ConfirmationDialog";

/** Props for the FormModal component. */
export type FormModalProps = {
  /** Callback function to close the modal */
  onClose: () => void;
  /** Callback function to handle form submission */
  handleSubmit: () => void;
  /** Controls the visibility of the modal */
  open: boolean;
  /** Text for the submit button */
  submitButtonText: string;
  /** Children elements to be rendered within the form modal */
  children: React.ReactNode[] | React.ReactNode;
  /** The title of the modal */
  title?: string;
  /** Boolean to control the disabled state of the submit button */
  submitDisabled?: boolean;
  /** Callback function to handle secondary button click */
  handleSecondaryClick?: () => void;
  /** Text for the secondary button */
  secondaryButtonText?: string;
  /** Text to display on hover of submit button */
  submitButtonHoverText?: string;
  /** Tab titles if tabs are used */
  tabs?: string[];
  /** Callback function to handle tab change */
  handleTabChange?: (newValue: number) => void;
  /** Current tab index */
  tabIndex?: number;
  /** Set padding top, default 24px */
  paddingTop?: string | number;
  /** If given, Delete button will be rendered. Callback function to handle remove button click */
  handleRemove?: () => void;
  /** Boolean to control the disabled state of the remove button */
  removeDisabled?: boolean;
  /** Text to display on hover of disabled remove button */
  removeDisabledReason?: string;
  /** Use fixed height 85svh for the modal content, defaults to auto if not given */
  fixedHeight?: boolean;
  /** Title of the confirmation dialog */
  confirmationDialogTitle?: string;
  /** Description of the confirmation dialog */
  confirmationDialogMessage?: string | React.ReactNode;
  /** Text of the button of confirmation dialog, defaults to 'Delete' */
  confirmationDialogSubmitButtonText?: string;
  /**
   * Optional flag to indicate if ModalBody left & right padding should be handled manually. Paddings/margins should
   * still total to 24px for consistency
   */
  handlePaddingXManually?: boolean;
  /** Callback to be called when confirmation dialog will be opened */
  onConfirmationDialogOpen?: () => Promise<void>;
  /**
   * If true, scrolling will be unblocked and confirmation dialog scrollbehaviour is changed to outside, so user must
   * scroll to the bottom to confirm deletion
   */
  isConfirmingDeletion?: boolean;
};

/**
 * The FormModal component renders a modal with a form. The modal includes a title, the form elements, and action
 * buttons to submit the form or cancel.
 */
export const FormModal: React.FC<FormModalProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState(props.tabIndex || 0);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);
  const [isConfirmationDialogLoading, setIsConfirmationDialogLoading] = useState(false);
  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const tabPanelRefs = useRef<(HTMLDivElement | null)[]>([]);

  /**
   * Handle tab change and set the active tab index.
   *
   * @param index - The index of the new active tab.
   */
  const handleTabsChange = (index: number) => {
    if (props.handleTabChange) {
      props.handleTabChange(index);
    }
    setActiveIndex(index);
  };

  const handleConfirmationDialogOpen = async () => {
    setDisplayConfirmationDialog(true);
    if (props.onConfirmationDialogOpen) {
      setIsConfirmationDialogLoading(true);
      await props.onConfirmationDialogOpen();
      setIsConfirmationDialogLoading(false);
    }
  };

  const handleConfirmationDialogClose = () => {
    setDisplayConfirmationDialog(false);
  };

  const handleConfirmationDialogConfirm = () => {
    setDisplayConfirmationDialog(false);
    props.handleRemove?.();
  };

  return (
    <>
      <Modal
        isOpen={props.isConfirmingDeletion && displayConfirmationDialog ? false : props.open}
        onClose={props.onClose}
        closeOnOverlayClick={false}
        blockScrollOnMount={true}
        size={{ mobile: "full", laptop: "6xl" }}
        motionPreset="slideInTop"
        scrollBehavior="inside"
      >
        <ModalOverlay backdropFilter="auto" backdropBlur="5px" />
        <ModalContent height={props.fixedHeight ? "85svh" : "auto"} overflow={{ mobile: "auto", laptop: "hidden" }}>
          {props.title && <ModalHeader>{props.title}</ModalHeader>}
          <ModalBody
            paddingBottom="4"
            paddingX={props.handlePaddingXManually ? "0" : undefined}
            position="relative"
            height="100%"
            overflow={{ mobile: "auto", laptop: "hidden" }}
          >
            <Box height="100%">
              {props.tabs && props.handleTabChange && typeof props.tabIndex !== "undefined" && (
                <Tabs index={activeIndex} onChange={handleTabsChange} width="100%" isFitted={true} height="100%">
                  <TabList overflowY={{ mobile: "auto", laptop: "hidden" }}>
                    {props.tabs.map((tab, index) => (
                      <Tab
                        key={index}
                        transition="all 0.3s ease"
                        _selected={{ color: "orange.400" }}
                        _hover={{ color: "orange.500" }}
                        height={{ mobile: undefined, laptop: "66px" }}
                      >
                        {tab}
                      </Tab>
                    ))}
                  </TabList>
                  {Array.isArray(props.children) && props.children.length > 0 && (
                    <TabPanels height="calc(100% - 66px)">
                      {props.children.map((child, index) => (
                        <TabPanel
                          key={index}
                          ref={(el) => (tabPanelRefs.current[index] = el)}
                          paddingX="0"
                          paddingBottom="0"
                          width="100%"
                          display={activeIndex === index ? "block" : "none"}
                          height="100%"
                        >
                          {child}
                        </TabPanel>
                      ))}
                    </TabPanels>
                  )}
                </Tabs>
              )}
              {!props.tabs && (
                <Box display="flex" flexDirection="column" flexGrow={1} height="100%" data-testid="formModalContent">
                  {props.children}
                </Box>
              )}
            </Box>
          </ModalBody>
          <ModalFooter
            paddingTop={{
              mobile: "4",
              /* ModalBody paddingBottom instead of ModalFooter paddingTop, to prevent cut-off of elements */
              laptop: "0",
            }}
          >
            <Stack direction="row" spacing="4" justifyContent="space-between" flex={1}>
              {props.handleRemove ? (
                <Tooltip
                  label={(props.removeDisabled && props.removeDisabledReason) || undefined}
                  placement="top"
                  whiteSpace="pre-wrap"
                >
                  <Box>
                    <CommonIconButton
                      onClick={handleConfirmationDialogOpen}
                      disabled={props.removeDisabled}
                      icon={<DeleteIcon color="red" />}
                      hoverColor="red"
                      dataTestId="form-modal-delete-button"
                    />
                  </Box>
                </Tooltip>
              ) : (
                <Box flex={1} />
              )}
              <Stack direction="row" spacing="4">
                <CommonButton
                  variantType="outlineSecondary"
                  onClick={props.handleSecondaryClick ? props.handleSecondaryClick : props.onClose}
                  dataTestId="form-modal-secondary-button"
                >
                  {props.secondaryButtonText ? props.secondaryButtonText : "Cancel"}
                </CommonButton>
                <Tooltip label={props.submitButtonHoverText || ""} placement="top" whiteSpace="pre-wrap">
                  <Box>
                    <CommonButton
                      variantType="solidPrimary"
                      disabled={props.submitDisabled}
                      onClick={props.handleSubmit}
                      dataTestId="form-modal-submit-button"
                    >
                      {props.submitButtonText}
                    </CommonButton>
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {props.handleRemove && (
        <ConfirmationDialog
          isOpen={displayConfirmationDialog}
          isDeletion={props.isConfirmingDeletion}
          isLoading={isConfirmationDialogLoading}
          message={props.confirmationDialogMessage || "Please confirm the action. This cannot be undone."}
          onCancel={handleConfirmationDialogClose}
          onClose={handleConfirmationDialogClose}
          onConfirm={handleConfirmationDialogConfirm}
          title={props.confirmationDialogTitle || "Delete"}
          cancelButtonText="Cancel"
          confirmButtonText={props.confirmationDialogSubmitButtonText || "Delete"}
        />
      )}
    </>
  );
};
