import { gql } from "@apollo/client";

export const ADMIN_GET_ALL_INVITES = gql`
  query AdminGetAllInvites {
    adminGetAllInvites {
      id
      inviteCode
      receiver
      used
      usedAt
      createdAt
    }
  }
`;
