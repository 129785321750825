import React from "react";
import { DeletionInfoEntityType, DeletionNode } from "../../../../types";
import { Text } from "@chakra-ui/react";

type Messages = {
  loadingMessage: string;
  showEntityList: boolean;
  childEntityName?: string;
  relatedEntityCount?: number;
  subEntityCount?: number;
  noDeletionMessage?: string;
  allEntityInfo?: DeletionNode[];
};

/**
 * Generates messages and data based on the entity type and entities to be removed.
 *
 * @param entityType - The type of the entity to be deleted (e.g., TrainingSeason, SkillSet, Skill).
 * @param entityName - The name of the entity to be deleted.
 * @param entitiesToBeRemoved - An array of DeletionNode representing entities that will be removed.
 * @returns An object containing messages and data relevant to the deletion process.
 */
export const getMessages = (
  entityType: DeletionInfoEntityType,
  entityName: string,
  entitiesToBeRemoved: DeletionNode[],
): Messages => {
  const relatedEntityCount = entitiesToBeRemoved.length;
  const subEntityCount = entitiesToBeRemoved.reduce((acc, entity) => acc + entity.children.length, 0);

  switch (entityType) {
    case DeletionInfoEntityType.TrainingSeason:
      return {
        loadingMessage: "Loading data to determine affected Training Groups...",
        showEntityList: true,
        childEntityName: "training events",
        relatedEntityCount,
        subEntityCount,
        noDeletionMessage: `No Training Groups will be deleted along with the deletion of ${entityName}.`,
      };
    case DeletionInfoEntityType.SkillSet:
      return {
        loadingMessage: "Loading data to determine affected Skills and Evaluations...",
        showEntityList: true,
        childEntityName: "athlete evaluations",
        relatedEntityCount,
        subEntityCount,
        noDeletionMessage: `No skills or evaluations will be deleted along with the deletion of ${entityName}.`,
      };
    case DeletionInfoEntityType.Skill:
      return {
        loadingMessage: "Loading data to determine affected Evaluations...",
        showEntityList: false,
        relatedEntityCount,
        noDeletionMessage: `No evaluations will be deleted along with the deletion of ${entityName}.`,
      };
    case DeletionInfoEntityType.TrainingGroup:
      return {
        loadingMessage: "Loading data to determine affected training events...",
        showEntityList: false,
        relatedEntityCount,
        noDeletionMessage: `No calendar training events will be deleted along with the deletion of ${entityName}.`,
      };
    case DeletionInfoEntityType.Venue:
      return {
        loadingMessage: "Loading data to determine affected training events...",
        showEntityList: true,
        childEntityName: "training events",
        relatedEntityCount,
        subEntityCount,
        noDeletionMessage: `No training groups will be deleted along with the deletion of ${entityName}.`,
        allEntityInfo: entitiesToBeRemoved,
      };
    case DeletionInfoEntityType.Center:
      return {
        loadingMessage: "Loading data to determine affected venues...",
        showEntityList: true,
        childEntityName: "training groups",
        relatedEntityCount,
        subEntityCount,
        noDeletionMessage: `No venues will be deleted along with the deletion of ${entityName}.`,
        allEntityInfo: entitiesToBeRemoved,
      };
    default:
      return {
        loadingMessage: "",
        showEntityList: false,
      };
  }
};

/**
 * Renders the deletion message based on the entity type, entity name, and messages.
 *
 * @param entityType - The type of the entity being deleted.
 * @param entityName - The name of the entity being deleted.
 * @param messages - The messages and data generated by getMessages function.
 * @returns A JSX element containing the deletion message, with important parts highlighted.
 */
export const renderDeletionMessage = (
  entityType: DeletionInfoEntityType,
  entityName: string,
  messages: ReturnType<typeof getMessages>,
): JSX.Element | null => {
  switch (entityType) {
    case DeletionInfoEntityType.TrainingSeason:
      return (
        <Text>
          The deletion of the
          <Text as="span" color="red" fontWeight="bold">{` ${entityName} `}</Text>
          will also delete the following
          <Text as="span" color="red" fontWeight="bold">{` ${messages.relatedEntityCount} training groups `}</Text>
          and
          <Text as="span" color="red" fontWeight="bold">{` ${messages.subEntityCount} training events`}</Text>.
        </Text>
      );
    case DeletionInfoEntityType.SkillSet:
      return (
        <Text>
          The deletion of the
          <Text as="span" color="red" fontWeight="bold">{` ${entityName} `}</Text>
          will also delete the following
          <Text as="span" color="red" fontWeight="bold">{` ${messages.relatedEntityCount} skills `}</Text>
          and
          <Text as="span" color="red" fontWeight="bold">{` ${messages.subEntityCount} evaluations`}</Text>.
        </Text>
      );
    case DeletionInfoEntityType.Skill:
      return (
        <Text>
          The deletion of the
          <Text as="span" color="red" fontWeight="bold">{` ${entityName} `}</Text>
          will also delete related
          <Text
            as="span"
            color="red"
            fontWeight="bold"
          >{` evaluations for ${messages.relatedEntityCount} athletes`}</Text>
          .
        </Text>
      );
    case DeletionInfoEntityType.TrainingGroup:
      return (
        <Text>
          The deletion of the
          <Text as="span" color="red" fontWeight="bold">{` ${entityName} `}</Text>
          will also delete related
          <Text as="span" color="red" fontWeight="bold">{` ${messages.relatedEntityCount} training events `}</Text>
          from the calendar.
        </Text>
      );
    case DeletionInfoEntityType.Venue:
      const singleTrainingEvents = messages.allEntityInfo?.filter(
        (entity) => entity?.data?.type === "SingleTrainingEvent",
      );
      const venueTrainingGroups = messages.allEntityInfo?.filter((entity) => entity?.data?.type === "TrainingGroup");

      return (
        <Text>
          The deletion of the
          <Text as="span" color="red" fontWeight="bold">{` ${entityName} `}</Text>
          will also delete the following
          <Text as="span" color="red" fontWeight="bold">{` ${venueTrainingGroups?.length} training groups `}</Text>
          and
          <Text as="span" color="red" fontWeight="bold">{` ${messages.subEntityCount} training events`}</Text>.
          {singleTrainingEvents?.length && singleTrainingEvents.length > 0 ? (
            <>
              {" "}
              Also, all
              <Text
                as="span"
                color="red"
                fontWeight="bold"
              >{` ${singleTrainingEvents?.length} one-time trainings `}</Text>
              related to this venue will be deleted.
            </>
          ) : null}
        </Text>
      );
    case DeletionInfoEntityType.Center:
      const centerTrainingGroups = messages.allEntityInfo
        ?.flatMap((entity) => entity.children)
        ?.filter((child) => child?.data?.type === "TrainingGroup");
      const centerSingleTrainingEvents = messages.allEntityInfo
        ?.flatMap((entity) => entity.children)
        ?.filter((child) => child?.data?.type === "SingleTrainingEvent");
      return (
        <Text>
          The deletion of the
          <Text as="span" color="red" fontWeight="bold">{` ${entityName} `}</Text>
          will also delete the following
          <Text as="span" color="red" fontWeight="bold">{` ${messages.relatedEntityCount} venues `}</Text>
          and
          <Text as="span" color="red" fontWeight="bold">{` ${centerTrainingGroups?.length} training groups`}</Text>, and
          <Text as="span" color="red" fontWeight="bold">
            {" "}
            every calendar event of those {centerTrainingGroups?.length} training groups
          </Text>
          .
          {centerSingleTrainingEvents?.length && centerSingleTrainingEvents.length > 0 ? (
            <>
              {" "}
              Also, all
              <Text
                as="span"
                color="red"
                fontWeight="bold"
              >{` ${centerSingleTrainingEvents?.length} one-time trainings `}</Text>
              related to this center's venues will be deleted.
            </>
          ) : null}
        </Text>
      );
    default:
      return null;
  }
};

/**
 * Renders the message when no related entities will be deleted along with the main entity.
 *
 * @param entityType - The type of the entity being deleted.
 * @param entityName - The name of the entity being deleted.
 * @returns A JSX element containing the no-deletion message.
 */
export const renderNoDeletionMessage = (
  entityType: DeletionInfoEntityType,
  entityName: string,
  extraEntitesToBeRemoved: number | null,
): JSX.Element | null => {
  switch (entityType) {
    case DeletionInfoEntityType.TrainingSeason:
      return (
        <Text>
          No Training Groups will be deleted along with the deletion of
          <Text as="span" color="red" fontWeight="bold">{` ${entityName}`}</Text>. Please confirm the action. This
          cannot be undone.
        </Text>
      );
    case DeletionInfoEntityType.SkillSet:
      return (
        <Text>
          No skills or evaluations will be deleted along with the deletion of
          <Text as="span" color="red" fontWeight="bold">{` ${entityName}`}</Text>. Please confirm the action. This
          cannot be undone.
        </Text>
      );
    case DeletionInfoEntityType.Skill:
      return (
        <Text>
          No evaluations will be deleted along with the deletion of
          <Text as="span" color="red" fontWeight="bold">{` ${entityName}`}</Text>. Please confirm the action. This
          cannot be undone.
        </Text>
      );
    case DeletionInfoEntityType.TrainingGroup:
      return (
        <Text>
          No calendar training events will be deleted along with the deletion of
          <Text as="span" color="red" fontWeight="bold">{` ${entityName}`}</Text>. Please confirm the action. This
          cannot be undone.
        </Text>
      );
    case DeletionInfoEntityType.Venue:
      return (
        <Text>
          No training groups will be deleted along with the deletion of
          <Text as="span" color="red" fontWeight="bold">{` ${entityName}`}</Text>.
          {extraEntitesToBeRemoved ? (
            <>
              {" "}
              However, all related
              <Text
                as="span"
                color="red"
                fontWeight="bold"
              >{` ${extraEntitesToBeRemoved} one-time training events `}</Text>
              will be deleted.
            </>
          ) : null}{" "}
          Please confirm the action. This cannot be undone.
        </Text>
      );
    case DeletionInfoEntityType.Center:
      return (
        <Text>
          No venues will be deleted along with the deletion of
          <Text as="span" color="red" fontWeight="bold">{` ${entityName}`}</Text>. Please confirm the action. This
          cannot be undone.
        </Text>
      );
    default:
      return null;
  }
};
