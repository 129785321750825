import { FormControl, InputGroup, Textarea } from "@chakra-ui/react";
import React from "react";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils";

/** Props for the CommonTextArea component. */
export type CommonTextAreaProps = {
  /** The value of the text area. */
  value: string;
  /** The placeholder text for the text area. */
  placeholder: string;
  /** The function to call when the text area value changes. */
  onChange: (value: string) => void;
  /** Whether the text area is disabled. */
  disabled?: boolean;
  /** Whether the text area is required. */
  required?: boolean;
  /** The minimum height of the text area. */
  minHeight?: string;
  /** Whether the text area resizable should be disabled. */
  disableRezise?: boolean;
  /** The data-testid attribute to be added to the component */
  dataTestId?: string;
};

/** A common text area component that supports different customization options. */
export const CommonTextArea: React.FC<CommonTextAreaProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <InputGroup>
      <FormControl id="common-textarea">
        <CommonFormLabel display={!!props.value}>{props.placeholder}</CommonFormLabel>
        <Textarea
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          required={props.required}
          minHeight={props.minHeight}
          resize={props.disabled || props.disableRezise ? "none" : undefined}
          isDisabled={props.disabled}
          {...commonStyles(!!props.value)}
          data-testid={props.dataTestId}
        />
      </FormControl>
    </InputGroup>
  );
};
