import React from "react";
import { Box, Grid, Icon, Stack, Text } from "@chakra-ui/react";
import { SeasonPlanCard } from "../../../../../../common/components/SeasonPlanCard";
import { cardStyles, extractDateFromUTC } from "../../../../../../common/utils";
import { RootNode } from "./components/TreeNode";
import { SkillSetDisplayCardProps } from "./components/components/SkillSetDisplayCard";
import { CommonScrollbar } from "../../../../../../common/components/CommonScrollbar";
import { ArrowForward, ArrowBack, Groups, Group, Person } from "@mui/icons-material";

type StepReviewProps = {
  startDate: string | undefined;
  endDate: string | undefined;
  treeData: RootNode;
  allSkillSets: SkillSetDisplayCardProps["skillSet"][];
};

export const StepReview: React.FC<StepReviewProps> = ({ startDate, endDate, treeData, allSkillSets }) => {
  return (
    <CommonScrollbar
      maxHeight="100%"
      overflow="auto"
      invisibleBorderWidth="0px 8px 0px 0px"
      paddingRightWhenOverflowing="2"
      paddingRightWhenNotOverflowing="6"
      paddingLeft="6"
      paddingBottom="6"
    >
      <Stack direction="column" spacing="6">
        <Box>
          <Text fontSize="lg" marginBottom="2">
            Planned dates
          </Text>
          <Stack direction="column" spacing="4" padding="4" {...cardStyles({ selected: true, disableClick: true })}>
            <Grid templateColumns="auto 1fr" alignItems="center" gap={4}>
              <Icon
                alignSelf={{ mobile: "start", laptop: "center" }}
                as={ArrowForward}
                boxSize={8}
                color="orange.400"
              />
              <Box>
                <Text fontSize="lg" fontWeight="semibold" color="orange.400">
                  Start Date
                </Text>
                <Text fontSize="md" color="blackAlpha.800">
                  {startDate ? extractDateFromUTC(startDate, ".", true) : "Could not display start date"}
                </Text>
              </Box>
            </Grid>
            <Grid templateColumns="auto 1fr" alignItems="center" gap={4}>
              <Icon alignSelf={{ mobile: "start", laptop: "center" }} as={ArrowBack} boxSize={8} color="orange.400" />
              <Box>
                <Text fontSize="lg" fontWeight="semibold" color="orange.400">
                  End Date
                </Text>
                <Text fontSize="md" color="blackAlpha.800">
                  {endDate ? extractDateFromUTC(endDate, ".", true) : "Could not display end date"}
                </Text>
              </Box>
            </Grid>
          </Stack>
        </Box>

        <Box>
          <Text fontSize="lg" marginBottom="2">
            Targeted groups and players
          </Text>
          <Stack direction="column" spacing="4" padding="4" {...cardStyles({ selected: true, disableClick: true })}>
            {treeData.checked && (
              <Grid templateColumns="auto 1fr" alignItems="center" gap={4}>
                <Icon alignSelf={{ mobile: "start", laptop: "center" }} as={Groups} boxSize={8} color="orange.400" />
                <Box>
                  <Text fontSize="lg" fontWeight="semibold" color="orange.400">
                    Everyone
                  </Text>
                  <Text fontSize="md" color="blackAlpha.800">
                    All groups and players
                  </Text>
                </Box>
              </Grid>
            )}
            {!treeData.checked && treeData.children.filter((group) => group.checked).length > 0 && (
              <Grid templateColumns="auto 1fr" alignItems="center" gap={4}>
                <Icon alignSelf={{ mobile: "start", laptop: "center" }} as={Group} boxSize={8} color="orange.400" />
                <Box>
                  <Text fontSize="lg" fontWeight="semibold" color="orange.400">
                    Training groups
                  </Text>
                  <Text fontSize="md" color="blackAlpha.800">
                    {treeData.children
                      .filter((group) => group.checked)
                      .map((group) => group.label)
                      .join(", ")}
                  </Text>
                </Box>
              </Grid>
            )}
            {!treeData.checked &&
              treeData.children.flatMap((group) => group.children.filter((player) => player.checked && !group.checked))
                .length > 0 && (
                <Grid templateColumns="auto 1fr" alignItems="center" gap={4}>
                  <Icon alignSelf={{ mobile: "start", laptop: "center" }} as={Person} boxSize={8} color="orange.400" />
                  <Box>
                    <Text fontSize="lg" fontWeight="semibold" color="orange.400">
                      Players
                    </Text>
                    <Text fontSize="md" color="blackAlpha.800">
                      {[
                        ...new Set(
                          treeData.children.flatMap((group) =>
                            group.children
                              .filter((player) => player.checked && !group.checked)
                              .map((player) => player.label),
                          ),
                        ),
                      ].join(", ")}
                    </Text>
                  </Box>
                </Grid>
              )}
          </Stack>
        </Box>

        <Box>
          <Text fontSize="lg" marginBottom="2">
            Targeted training
          </Text>
          <Stack direction="column" spacing="2">
            {allSkillSets.filter((skillSet) => skillSet.selected).length > 0 ? (
              allSkillSets
                .filter((skillSet) => skillSet.selected)
                .map((skillSet) => (
                  <SeasonPlanCard
                    key={skillSet.id}
                    skillSets={[{ skillSet: skillSet, skills: skillSet.skills.filter((skill) => skill.selected) }]}
                  />
                ))
            ) : (
              <SeasonPlanCard skillSets={undefined} />
            )}
          </Stack>
        </Box>
      </Stack>
    </CommonScrollbar>
  );
};
