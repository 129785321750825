import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { useAlert } from "../../../common/components/AlertProvider";
import { GET_ALL_ATHLETES } from "../graphql/get-all-athletes.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { SAVE_ATHLETE } from "../graphql/save-athlete.mutation";
import { SAVE_TRAINING_GROUP } from "../../../common/graphql/save-training-group.mutation";
import { SAVE_NOTE } from "../graphql/save-note.mutation";
import { SAVE_EVALUATION } from "../graphql/save-evaluation.mutation";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Athletes } from "../components/Athletes";
import type { Center, NoteInput, Athlete, EvaluationCriteria } from "../../../types";
import { GET_ALL_TRAINING_GROUP_EVENTS } from "../../../common/graphql/get-all-training-group-events.query";
import { GET_ALL_TRAINING_GROUPS } from "../../../common/graphql/get-all-training-groups.query";
import { GET_GENERIC_EVALUATION_CRITERIA } from "../../SkillSets/graphql/get-generic-evaluation-criteria.query";
import { GET_ALL_TRAINING_SEASONS } from "../../Management/graphql/get-all-training-seasons.query";
import { GET_ATHLETE_EVALUATION } from "../graphql/get-athlete-evaluation.query";
import { GET_ATHLETE_NOTES } from "../graphql/get-athlete-notes.query";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { GET_SKILL_SET_EVALUATION_CRITERIA } from "../../SkillSets/graphql/get-skill-set-evaluation-criteria.query";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";

export type AthleteInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type TrainingGroupInput = {
  /** Weekday for event, 1 = monday */
  recurrenceWeekday: number;
  /** Start time for event, e.g. "09.00" */
  recurrenceStartTime: string;
  /** End time for event, e.g. "10.30" */
  recurrenceEndTime: string;
  /** Unique identifier */
  id?: string;
  /** Array of coach IDs */
  coachIds?: string[];
  /** Array of Athlete IDs */
  athleteIds?: string[];
  /** Venue ID */
  venueId?: string;
  /** Indicates if the event should be removed */
  remove?: boolean;
  /** When the first possible date is for Training Group */
  recurrenceStartDate?: string;
  /** When the last possible date is for Training Group */
  recurrenceEndDate?: string;
  /** Training group name */
  name: string;
  /** Training Season ID */
  trainingSeasonId: string;
};

export type SkillEvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Skill ID */
  skillId: string;
  /** Rating */
  rating: number;
};

export type SkillSetEvaluationInput = {
  id?: string;
  skillSetId: string;
  skillEvaluations: SkillEvaluationInput[];
  comment?: string;
};

export type EvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Athlete ID */
  athleteId: string;
  /** Array of skill set evaluations */
  skillSetEvaluations: SkillSetEvaluationInput[];
  /** Indicates if the evaluation should be removed */
  remove?: boolean;
};

export const AthleteContainer: React.FC = () => {
  const { showAlert } = useAlert();

  // Queries
  const { data: athleteData, loading: athleteDataLoading, error: athleteDataError } = useQuery(GET_ALL_ATHLETES);
  const { data: coachData, loading: coachDataLoading, error: coachDataError } = useQuery(GET_ALL_COACHES);
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);
  const {
    data: evaluationCriteriaData,
    loading: evaluationCriteriaLoading,
    error: evaluationCriteriaError,
  } = useQuery(GET_GENERIC_EVALUATION_CRITERIA);
  const {
    data: allTrainingSeasonsData,
    loading: allTrainingSeasonsLoading,
    error: allTrainingSeasonsError,
  } = useQuery(GET_ALL_TRAINING_SEASONS);
  const {
    data: allTrainingGroupsData,
    loading: allTrainingGroupsDataLoading,
    error: allTrainingGroupsDataError,
  } = useQuery(GET_ALL_TRAINING_GROUPS);
  const { data: centerData, loading: centerLoading, error: centerError } = useQuery(GET_ALL_CENTERS);
  const {
    data: organizationData,
    loading: organizationDataLoading,
    error: organizationDataError,
  } = useQuery(GET_USER_ORGANIZATION);

  const [
    getAthleteEvaluation,
    { data: athleteEvaluationData, loading: athleteEvaluationLoading, error: athleteEvaluationError },
  ] = useLazyQuery(GET_ATHLETE_EVALUATION, { fetchPolicy: "network-only" });
  const [getAthleteNotes, { data: athleteNotesData, loading: athleteNotesLoading, error: athleteNotesError }] =
    useLazyQuery(GET_ATHLETE_NOTES, { fetchPolicy: "network-only" });

  const [getSkillSetEvaluationCriteriaQuery, { error: skillSetEvaluationCriteriaDataError }] = useLazyQuery(
    GET_SKILL_SET_EVALUATION_CRITERIA,
    { fetchPolicy: "network-only" },
  );

  // Mutations
  const { execute: saveAthleteMutation, loading: saveAthleteLoading } = useAsyncMutation(SAVE_ATHLETE, {
    refetchQueries: [
      { query: GET_ALL_ATHLETES },
      { query: GET_ALL_TRAINING_GROUP_EVENTS }, // to trigger update in Calendar
      { query: GET_ALL_TRAINING_GROUPS }, // to trigger update in Management
    ],
    awaitRefetchQueries: true,
  });

  const { execute: saveTrainingGroupMutation, loading: saveTrainingGroupLoading } = useAsyncMutation(
    SAVE_TRAINING_GROUP,
    {
      refetchQueries: [
        { query: GET_ALL_ATHLETES },
        { query: GET_ALL_TRAINING_GROUP_EVENTS },
        { query: GET_ALL_TRAINING_GROUPS },
      ],
      awaitRefetchQueries: true,
    },
  );

  const { execute: saveNoteMutation, loading: saveNoteLoading } = useAsyncMutation(SAVE_NOTE, {
    refetchQueries: [{ query: GET_ALL_ATHLETES }],
    awaitRefetchQueries: true,
  });

  const { execute: saveEvaluationMutation, loading: saveEvaluationLoading } = useAsyncMutation(SAVE_EVALUATION, {
    awaitRefetchQueries: true,
  });

  // Loading and error states
  const isLoading =
    athleteDataLoading ||
    coachDataLoading ||
    skillSetDataLoading ||
    evaluationCriteriaLoading ||
    allTrainingSeasonsLoading ||
    allTrainingGroupsDataLoading ||
    centerLoading ||
    organizationDataLoading;
  const [skillSetEvaluationCriteriaData, setSkillSetEvaluationCriteriaData] = useState<{
    [key: string]: EvaluationCriteria[];
  }>({});
  const [skillSetEvaluationCriteriaLoading, setSkillSetEvaluationCriteriaLoading] = useState<{
    [key: string]: boolean;
  }>({});

  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    athleteDataError,
    coachDataError,
    skillSetDataError,
    evaluationCriteriaError,
    allTrainingSeasonsError,
    allTrainingGroupsDataError,
    athleteEvaluationError,
    athleteNotesError,
    centerError,
    skillSetEvaluationCriteriaDataError,
    organizationDataError,
  ]);

  const handleSaveAthlete = async (athleteInput: AthleteInput) => {
    if (athleteInput.remove) {
      showAlert("Removing Athlete", "info", undefined, true);
    } else if (athleteInput.id) {
      showAlert("Updating Athlete", "info", undefined, true);
    } else {
      showAlert("Creating new Athlete", "info", undefined, true);
    }

    const result = await saveAthleteMutation({ data: athleteInput });

    if (result) {
      if (athleteInput.remove) {
        showAlert("Athlete removed!", "success", 5000);
      } else if (athleteInput.id) {
        showAlert("Athlete updated!", "success", 5000);
      } else {
        showAlert("Athlete created!", "success", 5000);
      }
    }
  };

  const handleSaveTrainingGroup = async (eventInput: TrainingGroupInput) => {
    showAlert("Updating athlete training groups", "info", undefined, true);

    const result = await saveTrainingGroupMutation({ data: eventInput });

    if (result) {
      showAlert("Updated athlete training groups!", "success", 5000);
    }
  };

  const handleSaveNote = async (noteInput: NoteInput) => {
    const result = await saveNoteMutation({
      data: noteInput,
    });
    await getAthleteNotes({ variables: { id: noteInput.athleteIds[0] } });

    if (result) {
      if (noteInput.remove) {
        showAlert("Note removed!", "success", 5000);
      } else if (noteInput.id) {
        showAlert("Note updated!", "success", 5000);
      } else {
        showAlert("Note saved!", "success", 5000);
      }
    }
  };

  const handleSaveEvaluation = async (evaluationInput: EvaluationInput) => {
    showAlert("Updating Evaluation", "info", undefined, true);

    const result = await saveEvaluationMutation({ data: evaluationInput });
    await getAthleteEvaluation({ variables: { id: evaluationInput.athleteId } });

    if (result) {
      showAlert("Evaluation saved!", "success", 5000);
    }
  };

  const handleAthleteChange = async (athlete: Athlete) => {
    await Promise.all([
      getAthleteEvaluation({ variables: { id: athlete.id } }),
      getAthleteNotes({ variables: { id: athlete.id } }),
    ]);
  };

  const getSkillSetEvaluationCriteria = async (skillSetId: string) => {
    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: true,
    }));
    const { data } = await getSkillSetEvaluationCriteriaQuery({
      variables: { skillSetId },
    });
    setSkillSetEvaluationCriteriaData((prev) => ({
      ...prev,
      [skillSetId]: data.getSkillSetEvaluationCriteria,
    }));

    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: false,
    }));
  };

  return (
    <Box id="athletes-container">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <Athletes
            allVenues={
              centerData?.getAllCenters?.flatMap((center: Center) =>
                center.venues.map((venue) => ({ ...venue, center })),
              ) || []
            }
            athletes={athleteData?.getAllAthletes || []}
            coaches={coachData?.getAllCoaches || []}
            skillSets={skillSetData?.getAllSkillSets || []}
            evaluationCriteria={evaluationCriteriaData?.getGenericEvaluationCriteria || []}
            trainingSeasons={allTrainingSeasonsData?.getAllTrainingSeasons || []}
            trainingGroups={allTrainingGroupsData?.getAllTrainingGroups || []}
            organizationData={organizationData?.getUserOrganization || null}
            onSaveAthlete={handleSaveAthlete}
            onSaveTrainingGroup={handleSaveTrainingGroup}
            onSaveNote={handleSaveNote}
            onSaveEvaluation={handleSaveEvaluation}
            isLoading={isLoading}
            isTrainingGroupLoading={saveTrainingGroupLoading}
            isAthleteLoading={saveAthleteLoading}
            onAthleteChange={handleAthleteChange}
            evaluation={athleteEvaluationData?.getAthleteEvaluation || null}
            evaluationLoading={athleteEvaluationLoading || saveEvaluationLoading}
            notes={athleteNotesData?.getAthleteNotes || []}
            notesLoading={athleteNotesLoading || saveNoteLoading}
            getSkillSetEvaluationCriteria={getSkillSetEvaluationCriteria}
            skillSetEvaluationCriteriaData={skillSetEvaluationCriteriaData}
            skillSetEvaluationCriteriaLoading={skillSetEvaluationCriteriaLoading}
          />
        </Box>
      )}
    </Box>
  );
};
