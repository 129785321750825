import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, keyframes } from "@chakra-ui/react";

/** Props for the OverflowIndicator component */
export type OverflowIndicatorProps = {
  /** The scrollable container element */
  containerRef: React.RefObject<HTMLElement>;
  /** The child element inside the container, optional */
  childRef?: HTMLElement;
  /** Offset from the bottom to show the icon, default is 75px */
  offset?: number;
};

/** OverflowIndicator component displays a chevron down icon when the content inside the container overflows. */
export const OverflowIndicator: React.FC<OverflowIndicatorProps> = (props) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  /** Handle the scroll event to determine if the content is overflowing. */
  const handleScroll = () => {
    if (props.containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = props.containerRef.current;
      setIsOverflowing(scrollTop < scrollHeight - clientHeight - (props.offset || 75));
    }
  };

  useEffect(() => {
    // Initial check for overflow
    handleScroll();

    // Add scroll and mouseenter event listeners
    props.containerRef.current?.addEventListener("scroll", handleScroll);
    props.childRef?.addEventListener("mouseenter", handleScroll);

    // Clean up event listeners on unmount
    return () => {
      props.containerRef.current?.removeEventListener("scroll", handleScroll);
      props.childRef?.removeEventListener("mouseenter", handleScroll);
    };
  }, [props.containerRef, props.childRef]);

  const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-10px);
    }
  `;

  return (
    <Box
      width="fit-content"
      position="sticky"
      bottom="4"
      left="calc(50% - 12px)"
      display={isOverflowing ? "flex" : "none"}
      data-testid="overflow-indicator"
    >
      <ChevronDownIcon
        className="overflowIcon"
        boxSize="14"
        opacity={0}
        transition="opacity 0.3s ease"
        animation={`${bounce} 1.5s infinite`}
      />
    </Box>
  );
};
