import { gql } from "@apollo/client";

export const GET_ALL_TRAINING_SEASONS = gql`
  query getAllTrainingSeasons {
    getAllTrainingSeasons {
      id
      name
      seasonStart
      seasonEnd
      status
    }
  }
`;
