import React from "react";
import { CommonScrollbar } from "../../../../../../common/components/CommonScrollbar";
import { TreeNode, type TreeNodeData } from "./components/TreeNode";

interface StepTrainingGroupsProps {
  treeData: TreeNodeData;
  onCheck: (currentNode: TreeNodeData, targetNodeId: string, checked: boolean) => void;
}

// TODO: add athletes without training groups as a separate group

export const StepTrainingGroups: React.FC<StepTrainingGroupsProps> = ({ treeData, onCheck }) => {
  return (
    <CommonScrollbar
      maxHeight="100%"
      overflow="auto"
      invisibleBorderWidth="0px 8px 0px 0px"
      paddingRight="0"
      paddingLeft="4"
    >
      <TreeNode node={treeData} onCheck={(node, checked) => onCheck(treeData, node.id, checked)} />
    </CommonScrollbar>
  );
};
