import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

/** Props for the LoadingOverlay component. */
export type LoadingOverlayProps = {
  /** Flag to indicate if the overlay should be displayed */
  display: boolean;
  /** Size of the spinner, defaults to "lg" */
  spinnerSize?: "md" | "lg" | "xl";
  /** Hov far from the top spinner is rendered, defaults to "8px" */
  spinnerTopPosition?: string;
};

/**
 * Overlay component to display a background and a loading spinner on top of it's parent. Parent needs to have
 * "position=relative"
 */
export const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => (
  <Box
    position="absolute"
    top="0"
    left="0"
    width="100%"
    borderRadius="md"
    height="100%"
    backgroundColor="whiteAlpha.700"
    display={props.display ? "flex" : "none"}
    justifyContent="center"
    zIndex={1}
    data-testid="loading-overlay"
  >
    <Spinner
      position="absolute"
      thickness="4px"
      color="orange.400"
      emptyColor="blackAlpha.300"
      size={props.spinnerSize || "lg"}
      top={props.spinnerTopPosition || "8px"}
    />
  </Box>
);
