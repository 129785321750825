/** Represents the structure of a GraphQL error object. */
type GraphQLErrorType = {
  networkError?: any;
  graphQLErrors?: { message: string; path?: string[] }[];
};

/**
 * Processes a GraphQL error and extracts user-friendly error messages.
 *
 * @param error - The GraphQL error object to process.
 * @returns An array of error messages extracted from the error object.
 */
const handleGraphQLError = (error: GraphQLErrorType): string[] => {
  const errorMessages: string[] = [];

  if (error.networkError) {
    // Check if networkError has a result with errors
    if (error.networkError.result && error.networkError.result.errors) {
      const errors = error.networkError.result.errors;
      errors.forEach((errorItem: any) => {
        if (errorItem.extensions?.code === "BAD_USER_INPUT") {
          // Extract missing field information if available
          const missingFieldMatch = errorItem.message.match(/Field "(.+)" of required type/);
          if (missingFieldMatch && missingFieldMatch[1]) {
            const missingField = missingFieldMatch[1];
            errorMessages.push(`Please provide the "${missingField}" field.`);
          } else {
            errorMessages.push("Some required information is missing or incorrect. Please check your input.");
          }
        } else {
          errorMessages.push("A network error occurred. Please try again.");
        }
      });
    } else {
      // Handle network errors without result.errors (e.g., TypeError: Failed to fetch)
      if (error.networkError.message === "Failed to fetch") {
        errorMessages.push("Unable to connect to the server. Please check your internet connection and try again.");
      } else {
        errorMessages.push("A network error occurred: " + error.networkError.message);
      }
    }
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    error.graphQLErrors.forEach((gqlError) => {
      // Map known GraphQL errors to user-friendly messages
      switch (gqlError.message) {
        case "UNAUTHORIZED":
          errorMessages.push("You do not have permission to perform this action.");
          break;
        default:
          errorMessages.push("An error occurred while processing your request. Please try again.");
      }
    });
  }

  // If no error messages were added, provide a generic message
  if (errorMessages.length === 0) {
    errorMessages.push("An unexpected error occurred. Please try again later.");
  }

  return errorMessages;
};

/**
 * Handles errors by extracting user-friendly messages and avoiding duplicate messages within the same call.
 *
 * @param errors - An array of errors to process.
 * @returns A string containing the combined error messages.
 */
export const handleError = (errors: (GraphQLErrorType | any)[]): string => {
  const allErrorMessages: string[] = [];
  const handledErrorsSet = new Set<string>(); // Local set to avoid duplicates within the same call

  errors.forEach((error) => {
    let errorMessages: string[] = [];

    if (error?.message === "Request timeout") {
      errorMessages.push("Request timed out. Please check your network connection.");
    } else if (error?.graphQLErrors || error?.networkError) {
      errorMessages = handleGraphQLError(error);
    } else if (error?.message) {
      errorMessages.push(error.message);
    } else {
      errorMessages.push("An unexpected error occurred. Please try again later.");
    }

    // Filter out duplicate error messages within this call
    errorMessages.forEach((msg) => {
      if (!handledErrorsSet.has(msg)) {
        handledErrorsSet.add(msg);
        allErrorMessages.push(msg);
      }
    });
  });

  // Combine error messages into a single string
  const errorMessage = allErrorMessages.join("\n");

  return errorMessage;
};
