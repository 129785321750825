import React from "react";
import { Stack, Box, Text, Divider } from "@chakra-ui/react";
import { MultiTagSelectMenu } from "../../../../../common/components/MultiTagSelectMenu";
import { SwitchWithLabel } from "../../../../../common/components/SwitchWithLabel/SwitchWithLabel";
import { CalendarViewControls, type CalendarViewControlsProps } from "./components/CalendarViewControls";
import { CommonSelect } from "../../../../../common/components/CommonSelect";
import type { Center } from "../../../../../types";

/** Props for the CalendarHeader component. */
type CalendarHeaderProps = CalendarViewControlsProps & {
  /** All available centers. */
  allCenters: Center[];
  /** All available coaches. */
  allCoaches: Array<{ name: string }>;
  /** Selected coaches. */
  selectedCoaches: Array<{ name: string }>;
  /** Filter to show only free spots. */
  showOnlyFreeSpots: boolean;
  /** Filter to show only Training Groups. */
  showOnlyTrainingGroups: boolean;
  /** Filter to show only single training events. */
  showOnlySingleTrainingEvents: boolean;
  /** State to indicate if the draggable To-do is open. */
  isDraggableTodoOpen: boolean;
  /** Function to set selected coaches. */
  setSelectedCoaches: (coaches: Array<{ name: string }>) => void;
  /** Function to toggle showing only free spots. */
  setShowOnlyFreeSpots: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only Training Groups. */
  setShowOnlyTrainingGroups: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only single training events. */
  setShowOnlySingleTrainingEvents: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle the draggable To-do. */
  setIsDraggableTodoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** The selected center. */
  selectedCenter: Center | null;
  /** Function to set the selected center. */
  setSelectedCenter: (center: Center) => void;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
};

/** CalendarHeader component to control and filter calendar view settings. */
export const CalendarHeader: React.FC<CalendarHeaderProps> = (props) => {
  const handleCenterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const center = props.allCenters.find((center) => center.id === event.target.value);
    if (center) {
      props.setSelectedCenter(center);
    }
  };

  return (
    <Box id="calendar-header" data-testid="calendar-header">
      <Stack
        direction={{ mobile: "column", laptop: "row" }}
        spacing={{ mobile: "3", laptop: "4" }}
        justifyContent="center"
        alignItems="center"
        data-testid="calendar-filter-controls"
      >
        <CommonSelect
          width={props.isMobile ? "100%" : "60"}
          placeholder={
            props.selectedCenter?.id ? "Center" : props.allCenters.length === 0 ? "No Center found" : "Select a Center"
          }
          value={props.selectedCenter?.id}
          onChange={handleCenterChange}
          options={props.allCenters.map((center) => ({ label: center.name, value: center.id || "" }))}
          disableSelectedStyle={true}
        />
        <MultiTagSelectMenu
          selectedTags={props.selectedCoaches}
          handleTagSelection={props.setSelectedCoaches}
          options={props.allCoaches}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          label="Filter by coach"
          width={props.isMobile ? "100%" : "80"}
          dataTestId="coach-filter"
        />
        <SwitchWithLabel
          checked={props.showOnlyFreeSpots}
          label="Free spots only"
          onChange={() => props.setShowOnlyFreeSpots((prev) => !prev)}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="free-spots-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlyTrainingGroups}
          label="Weekly trainings only"
          onChange={() => {
            props.setShowOnlyTrainingGroups((prev) => !prev);
            if (props.showOnlySingleTrainingEvents) {
              props.setShowOnlySingleTrainingEvents(false);
            }
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="weekly-events-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlySingleTrainingEvents}
          label="One-time trainings only"
          onChange={() => {
            props.setShowOnlySingleTrainingEvents((prev) => !prev);
            if (props.showOnlyTrainingGroups) {
              props.setShowOnlyTrainingGroups(false);
            }
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="single-events-switch"
        />
      </Stack>

      {!props.display ? (
        <Text textAlign="center" marginTop="12" fontSize="large" color="blackAlpha.600" data-testid="no-center-message">
          {props.allCenters.length === 0
            ? "No Center found. Create a Center with at least one Venue in the 'Management' section to use the Calendar."
            : "Select a Center to use the Calendar"}
        </Text>
      ) : (
        <Divider marginY="6" />
      )}

      <CalendarViewControls
        activeView={props.isMobile ? "timeGridDay" : props.activeView}
        calendarRefs={props.calendarRefs}
        dayViewDate={props.dayViewDate}
        getNewDate={props.getNewDate}
        display={props.display}
        setActiveView={props.setActiveView}
        setDayViewDate={props.setDayViewDate}
        setFullCalendarKey={props.setFullCalendarKey}
        isMobile={props.isMobile}
        data-testid="calendar-view-controls"
      />
    </Box>
  );
};
