import React from "react";
import { FormLabel } from "@chakra-ui/react";

export const CommonFormLabel: React.FC<{ children: React.ReactNode; display: boolean }> = ({ children, display }) => {
  return (
    <FormLabel
      zIndex={2}
      top="-8px"
      marginLeft="10px"
      marginBottom="0px"
      position="absolute"
      backgroundColor="white"
      paddingLeft="4px"
      paddingRight="4px"
      maxWidth="90%"
      fontSize="small"
      color="blackAlpha.600"
      display={display ? "flex" : "none"}
      overflow="hidden"
      textOverflow="ellipsis"
      noOfLines={display ? 1 : undefined}
      wordBreak="break-all"
      lineHeight="normal"
    >
      {children}
    </FormLabel>
  );
};
