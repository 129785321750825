import { gql } from "@apollo/client";

export const GET_ENTITIES_TO_REMOVE = gql`
  query GetEntitiesToRemove($data: DeletionInfoInput!) {
    getEntitiesToRemove(data: $data) {
      data {
        type
        name
        id
      }
      children {
        data {
          type
          name
          id
        }
        children {
          data {
            type
            name
            id
          }
        }
      }
    }
  }
`;
