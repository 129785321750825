import jwt_decode from "jwt-decode";

/**
 * Decodes a JWT token and determines authentication and admin status.
 *
 * @param token - The JWT token to decode.
 * @returns An object containing `authStatus` and `adminStatus` flags.
 */
export const parseToken = (token?: string): { authStatus: boolean; adminStatus: boolean } => {
  if (!token) {
    return { authStatus: false, adminStatus: false };
  }

  const decodedToken = jwt_decode(token) as {
    userId?: string; // old/manual
    organizationId?: string; // old/manual
    role?: string; // old/manual
    "custom:userId"?: string; // cognito
    "custom:organizationId"?: string; // cognito
    "custom:role"?: string; // cognito
  };

  const isAuthenticated =
    (!!decodedToken["custom:userId"] && !!decodedToken["custom:organizationId"]) ||
    (!!decodedToken.userId && !!decodedToken.organizationId);

  const isAdmin = decodedToken["custom:role"] === "admin" || decodedToken.role === "admin";

  return { authStatus: isAuthenticated, adminStatus: isAdmin };
};
