import { gql } from "@apollo/client";

export const GET_ALL_SINGLE_TRAINING_EVENTS = gql`
  query GetAllSingleTrainingEvents {
    getAllSingleTrainingEvents {
      id
      startDateTime
      endDateTime
      coaches {
        id
        name
      }
      court {
        id
        name
      }
      drills {
        goal
        id
        implementation
        name
        tags
      }
      players {
        id
        name
      }
      notes {
        id
        isGroupNote
        content
        players {
          id
        }
      }
      session {
        id
        name
        goal
        totalDuration
        tags
        sessionDrills {
          id
          duration
          order
          drill {
            id
            name
            implementation
            goal
            tags
          }
        }
      }
    }
  }
`;
