import React from "react";
import { Player } from "../../../../../../../../types";
import { SeasonPlanCard } from "../../../../../../../../common/components/SeasonPlanCard";
import { Box, Stack, Text } from "@chakra-ui/react";
import { NotesInput } from "../../NotesInput";

/** Props for the PlayerTab component. */
type PlayerTabProps = {
  /** Player object containing player details. */
  player: Player;
  /** Training note of the player. */
  trainingNote: string;
  /** Callback function to handle note changes. */
  onNoteChange: (newNote: string) => void;
};

/** A tab component displaying player specific details */
export const PlayerTab: React.FC<PlayerTabProps> = (props) => {
  return (
    <Box paddingX="6">
      <Box marginBottom={{ mobile: "6", laptop: "8" }}>
        <Text fontSize="lg" marginBottom="2">
          Training notes
        </Text>
        <NotesInput
          notes={props.trainingNote}
          label={`Add notes for ${props.player.name} only`}
          setNotes={props.onNoteChange}
          minRows={4}
        />
      </Box>

      <Text fontSize="lg" marginBottom="2">
        Training targets for {props.player.name} only
      </Text>
      {props.player.seasonPlans?.length ? (
        <Stack direction="column" spacing={{ mobile: "3", laptop: "6" }}>
          {props.player.seasonPlans.map((seasonPlan) => (
            <SeasonPlanCard key={seasonPlan.id} skillSets={seasonPlan.skillSets} disableHover={true} />
          ))}
        </Stack>
      ) : (
        <SeasonPlanCard skillSets={undefined} disableHover={true} />
      )}
    </Box>
  );
};
