import React, { useState } from "react";
import { Box, Text, Table, Tbody, Tr, Td, Thead, TableContainer, Tooltip, Stack, Button } from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { CommonInput } from "../../../../common/components/CommonInput";
import { CommonButton } from "../../../../common/components/CommonButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import type { Invite } from "../../../../types";

type InvitesProps = {
  allInvites: Invite[];
  createInvite: (receiver?: string) => Promise<void>;
  createInviteLoading: boolean;
};

/** Invites handles invite creation and displays existing invites with sorting and visual consistency. */
export const Invites: React.FC<InvitesProps> = (props) => {
  const [receiver, setReceiver] = useState("");
  const [sortConfig, setSortConfig] = useState<{ key: keyof Invite; direction: "ascending" | "descending" } | null>(
    null,
  );
  const [copySuccess, setCopySuccess] = useState<string | null>(null);

  const handleCreateInvite = async () => {
    await props.createInvite(receiver);
    setReceiver("");
  };

  const handleSort = (key: keyof Invite) => {
    if (sortConfig?.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "ascending" ? "descending" : "ascending",
      });
    } else {
      setSortConfig({ key, direction: "ascending" });
    }
  };

  const sortedInvites = React.useMemo(() => {
    let sorted = [...props.allInvites];
    if (sortConfig) {
      sorted.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "receiver") {
          aValue = aValue || "N/A";
          bValue = bValue || "N/A";
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sorted;
  }, [props.allInvites, sortConfig]);

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    setCopySuccess(link);
    setTimeout(() => setCopySuccess(null), 2000);
  };

  const columns = [
    { label: "Invite Link", key: "inviteCode" as keyof Invite, width: "25%" },
    { label: "Receiver", key: "receiver" as keyof Invite, width: "15%" },
    { label: "Status", key: "used" as keyof Invite, width: "10%" },
    { label: "Used At", key: "usedAt" as keyof Invite, width: "15%" },
    { label: "Created At", key: "createdAt" as keyof Invite, width: "20%" },
  ];

  return (
    <Box position="relative">
      <LoadingOverlay display={props.createInviteLoading} />

      <Box paddingBottom="6" display="flex" justifyContent="center">
        <Text fontSize="x-large" fontWeight="bold">
          Invites ({props.allInvites.length})
        </Text>
      </Box>

      <Box paddingBottom="6">
        <Text fontSize="large">Create a new invite</Text>
        <Stack direction="row" spacing="4" marginTop="2">
          <CommonInput placeholder="Receiver (optional)" value={receiver} onChange={(value) => setReceiver(value)} />
          <CommonButton variantType="outlineSecondary" onClick={handleCreateInvite}>
            Create Invite
          </CommonButton>
        </Stack>
      </Box>

      <TableContainer overflow="hidden" paddingBottom="6">
        <Table size="lg" variant="unstyled" layout="fixed">
          <Thead>
            <Tr>
              {columns.map((column) => {
                const isSorted = sortConfig?.key === column.key;
                return (
                  <Td
                    key={column.key as string}
                    width={column.width}
                    fontSize="md"
                    textTransform="uppercase"
                    paddingY="4"
                    paddingX="8"
                    onClick={() => handleSort(column.key)}
                    transition="all 0.3s ease"
                    _hover={{
                      color: "orange.400",
                      cursor: "pointer",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      {column.label}
                      {
                        <Box marginLeft="1" display="flex" alignItems="center" color="inherit">
                          {isSorted ? (
                            sortConfig.direction === "ascending" ? (
                              <TriangleUpIcon color="orange.400" opacity={1} />
                            ) : (
                              <TriangleDownIcon color="orange.400" opacity={1} />
                            )
                          ) : (
                            <TriangleDownIcon color="inherit" opacity={0.3} />
                          )}
                        </Box>
                      }
                    </Box>
                  </Td>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {sortedInvites.map((invite) => {
              const inviteLink = `${window.location.origin}/create-account/?inviteCode=${invite.inviteCode}`;

              return (
                <Tr
                  key={invite.id}
                  borderTop="1px solid"
                  borderColor="blackAlpha.50"
                  opacity={invite.used ? 0.5 : 1}
                  _hover={{
                    color: "orange.400",
                    boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Td>
                    <Stack direction="row" alignItems="center">
                      <Tooltip label={inviteLink}>
                        <Text isTruncated>{inviteLink}</Text>
                      </Tooltip>
                      <CommonButton variantType="outlineSecondary" onClick={() => handleCopyLink(inviteLink)}>
                        {copySuccess === inviteLink ? "Copied!" : "Copy"}
                      </CommonButton>
                    </Stack>
                  </Td>
                  <Td>{invite.receiver || "N/A"}</Td>
                  <Td>{invite.used ? "Used" : "Unused"}</Td>
                  <Td>{invite.usedAt ? new Date(invite.usedAt).toLocaleString().slice(0, -3) : "N/A"}</Td>
                  <Td>{new Date(invite.createdAt).toLocaleString().slice(0, -3)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {props.allInvites.length === 0 && (
        <Text fontSize="large" paddingY="6" textColor="blackAlpha.600" textAlign="center">
          No invites available. Create a new invite to get started.
        </Text>
      )}
    </Box>
  );
};
