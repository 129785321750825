import React, { useState } from "react";
import { Box, Text, Table, Thead, Tbody, Tr, Td, TableContainer, Tooltip, Stack } from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { Coach, Court, RecurringTrainingEvent, Player } from "../../../../types";
import { FormModal } from "../../../../common/components/FormModal";
import type { RecurringTrainingEventInput } from "../../../Players/containers/PlayerContainer";
import { useAlert } from "../../../../common/components/AlertProvider";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { TrainingGroupEditor } from "../../../../common/components/TrainingGroupEditor";

type TrainingGroupsProps = {
  events: RecurringTrainingEvent[];
  coaches: Coach[];
  courts: Court[];
  players: Player[];
  onSave: (eventToSave: RecurringTrainingEventInput) => Promise<void>;
  isLoading: boolean;
};

// TODO: what happens when trying to save training group with identical time & place
// TODO: deletion when required inputs are empty
// TODO: filters: weekday, court, coaches, players
// TODO: tooltips as a vertical list for better readability
// TODO: disable form modal save if no changes

export const TrainingGroups: React.FC<TrainingGroupsProps> = (props) => {
  const [selectedEvent, setSelectedEvent] = useState<RecurringTrainingEvent | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof RecurringTrainingEvent;
    direction: "ascending" | "descending";
  }>({ key: "recurrenceWeekday", direction: "ascending" });
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const { showAlert } = useAlert();

  const weekdayNames = [
    "", // 0 index is not used
    "Monday", // 1 is Monday
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const columns = [
    { label: "Group", key: "name" as keyof RecurringTrainingEvent, width: "15%" },
    {
      label: "Weekday",
      key: "recurrenceWeekday" as keyof RecurringTrainingEvent,
      width: "10%",
    },
    {
      label: "Start",
      key: "recurrenceStartTime" as keyof RecurringTrainingEvent,
      width: "10%",
    },
    { label: "End", key: "recurrenceEndTime" as keyof RecurringTrainingEvent, width: "10%" },
    { label: "Court", key: "court" as keyof RecurringTrainingEvent, width: "10%" },
    { label: "Coaches", key: "coaches" as keyof RecurringTrainingEvent, width: "15%" },
    { label: "Players", key: "players" as keyof RecurringTrainingEvent, width: "30%" },
  ];

  const sortableColumns: (keyof RecurringTrainingEvent)[] = [
    "name",
    "recurrenceWeekday",
    "recurrenceStartTime",
    "recurrenceEndTime",
    "court",
  ];

  const handleSort = (key: keyof RecurringTrainingEvent) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "ascending" ? "descending" : "ascending",
      });
    } else {
      setSortConfig({ key, direction: "ascending" });
    }
  };

  const sortedEvents = React.useMemo(() => {
    let sortableEvents = [...props.events];
    if (sortConfig !== null) {
      sortableEvents.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "court") {
          aValue = a.court.name;
          bValue = b.court.name;
        }
        if (sortConfig.key === "recurrenceWeekday") {
          aValue = a.recurrenceWeekday;
          bValue = b.recurrenceWeekday;
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sortableEvents;
  }, [props.events, sortConfig]);

  const handleRowClick = (event: RecurringTrainingEvent) => {
    setIsCreatingNewGroup(false);
    setSelectedEvent(event);
    setIsOpen(true);
  };

  const handleSave = async (remove: boolean) => {
    setIsOpen(false);
    if (selectedEvent) {
      const eventToSave: RecurringTrainingEventInput = {
        id: selectedEvent.id,
        name: selectedEvent.name,
        recurrenceWeekday: selectedEvent.recurrenceWeekday,
        recurrenceStartTime: selectedEvent.recurrenceStartTime,
        recurrenceEndTime: selectedEvent.recurrenceEndTime,
        courtId: selectedEvent.court.id,
        coachIds: selectedEvent.coaches?.map((coach) => coach.id || "") || [],
        playerIds: selectedEvent.players?.map((player) => player.id || "") || [],
        remove: remove,
      };

      if (eventToSave.remove) {
        showAlert("Removing Training Group", "info", undefined, true);
      } else if (eventToSave.id) {
        showAlert("Updating Training Group", "info", undefined, true);
      } else {
        showAlert("Crewating new Training Group", "info", undefined, true);
      }

      await props.onSave(eventToSave);

      if (eventToSave.remove) {
        showAlert("Training Group removed!", "success", 5000);
      } else {
        if (eventToSave.id) {
          showAlert("Training Group updated!", "success", 5000);
        } else {
          showAlert("Training Group created!", "success", 5000);
        }
      }
    }
  };

  const handleCreateNewGroup = () => {
    setIsCreatingNewGroup(true);
    setSelectedEvent({
      name: "",
      recurrenceWeekday: 1,
      recurrenceStartTime: "17:00",
      recurrenceEndTime: "18:30",
      court: props.courts[0],
      coaches: [],
      players: [],
    });
    setIsOpen(true);
  };

  /**
   * Generates a list of reasons why the submit button is disabled.
   *
   * @returns An object containing the submit disabled reasons if any condition is not met, otherwise undefined.
   */
  const getSubmitDisabledReasons = (): string | undefined => {
    const reasons: string[] = [];

    if (!selectedEvent?.name || selectedEvent?.name.trim() === "") {
      reasons.push("Group name is entered");
    }
    if (!selectedEvent?.recurrenceWeekday) {
      reasons.push("Weekday is selected");
    }
    if (!selectedEvent?.recurrenceStartTime || selectedEvent?.recurrenceStartTime === "24:00") {
      reasons.push("Start time is selected");
    }
    if (!selectedEvent?.recurrenceEndTime || selectedEvent?.recurrenceEndTime === "24:00") {
      reasons.push("End time is selected");
    }
    if (!selectedEvent?.court) {
      reasons.push("Court is selected");
    }

    return reasons.length > 0 ? `Saving is enabled once\n- ${reasons.join("\n- ")}` : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  return (
    <Box position="relative" data-testid="organization-training-events">
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" padding="6" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Text fontSize="x-large">Training Groups ({props.events.length})</Text>
        <CommonIconButton height="36px" onClick={handleCreateNewGroup} dataTestId="add-training-group" />
      </Stack>

      <TableContainer overflow="hidden" paddingBottom="6" paddingX="6">
        <Table size="lg" variant="unstyled" layout="fixed">
          <Thead>
            <Tr>
              {columns.map((column) => {
                const isSortable = sortableColumns.includes(column.key);
                const isSorted = sortConfig.key === column.key;
                return (
                  <Text
                    key={column.key as string}
                    as="th"
                    width={column.width}
                    fontSize="md"
                    textTransform="uppercase"
                    paddingY="4"
                    paddingX="8"
                    onClick={() => {
                      if (isSortable) {
                        handleSort(column.key);
                      }
                    }}
                    transition="all 0.3s ease"
                    _hover={
                      isSortable
                        ? {
                            color: "orange.400",
                            cursor: "pointer",
                          }
                        : undefined
                    }
                  >
                    <Box display="flex" alignItems="center" color={isSorted ? "orange.400" : "inherit"}>
                      {column.label}
                      {isSortable && (
                        <Box marginLeft="1" display="flex" alignItems="center" color="inherit">
                          {isSorted ? (
                            sortConfig.direction === "ascending" ? (
                              <TriangleUpIcon color="orange.400" opacity={1} />
                            ) : (
                              <TriangleDownIcon color="orange.400" opacity={1} />
                            )
                          ) : (
                            <TriangleDownIcon color="inherit" opacity={0.3} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Text>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {sortedEvents.map((event) => (
              <Tr
                key={event.id}
                onClick={() => handleRowClick(event)}
                cursor="pointer"
                borderTop="1px solid"
                borderColor="blackAlpha.50"
                borderRadius="md"
                transition="all 0.3s ease"
                _hover={{
                  color: "orange.400",
                  borderColor: "transparent",
                  boxShadow: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C", // lg blackAlpha.500
                }}
              >
                <Td width={columns[0].width} overflow="hidden" textOverflow="ellipsis">
                  <Tooltip placement="top" label={event.name}>
                    {event.name}
                  </Tooltip>
                </Td>
                <Td width={columns[1].width}>{weekdayNames[event.recurrenceWeekday]}</Td>
                <Td width={columns[2].width}>{event.recurrenceStartTime}</Td>
                <Td width={columns[3].width}>{event.recurrenceEndTime}</Td>
                <Td width={columns[4].width} overflow="hidden" textOverflow="ellipsis">
                  <Tooltip placement="top" label={event.court.name}>
                    {event.court.name}
                  </Tooltip>
                </Td>
                <Td width={columns[5].width} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  <Tooltip placement="top" label={event.coaches?.map((coach) => coach.name).join(", ")}>
                    {event.coaches && event.coaches.length > 0 ? (
                      <span>{event.coaches.map((coach) => coach.name).join(", ")}</span>
                    ) : (
                      <Text color="blackAlpha.600">No coaches</Text>
                    )}
                  </Tooltip>
                </Td>
                <Td width={columns[6].width} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  <Tooltip placement="top" label={event.players?.map((player) => player.name).join(", ")}>
                    {event.players && event.players.length > 0 ? (
                      <span>{event.players.map((player) => player.name).join(", ")}</span>
                    ) : (
                      <Text color="blackAlpha.600">No players</Text>
                    )}
                  </Tooltip>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {sortedEvents.length === 0 && (
        <Text
          fontSize="large"
          paddingY="6"
          textColor="blackAlpha.600"
          textAlign="center"
          data-testid="no-training-groups"
        >
          No Training Groups
        </Text>
      )}

      <FormModal
        open={isOpen}
        title={isCreatingNewGroup ? "Create new Training Group" : "Edit Training Group"}
        submitButtonText={isCreatingNewGroup ? "Create" : "Save"}
        handleSubmit={() => handleSave(false)}
        onClose={() => setIsOpen(false)}
        handleRemove={() => handleSave(true)}
        removeDisabled={!selectedEvent?.id}
        removeDisabledReason="Group is not created"
        confirmationDialogTitle="Delete Training Group"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
      >
        {selectedEvent && (
          <TrainingGroupEditor
            allCoaches={props.coaches}
            allCourts={props.courts}
            allPlayers={props.players}
            trainingGroup={selectedEvent}
            displayPlayers={true}
            onChange={(editedEvent) => setSelectedEvent(editedEvent)}
          />
        )}
      </FormModal>
    </Box>
  );
};
