import React from "react";
import { Stack, Text, Button } from "@chakra-ui/react";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { useNavigate } from "react-router-dom";

type Step_0_AccountProps = {
  email: string;
  password: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  loading: boolean;
};

/** Step_0_Account renders the UI for the first step (Account creation) */
export const Step_0_Account: React.FC<Step_0_AccountProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Stack direction="column" spacing="6">
      <Text fontSize="large" fontWeight="semibold">
        Enter your email and password
      </Text>

      <Stack direction="column" spacing="4">
        <CommonInput
          placeholder="Email"
          type="email"
          value={props.email}
          onChange={(value) => props.setEmail(value)}
          disabled={props.loading}
        />
        <CommonInput
          placeholder="Password (min 8 characters)"
          type="password"
          value={props.password}
          onChange={(value) => props.setPassword(value)}
          disabled={props.loading}
        />
      </Stack>

      <Text fontSize="sm" color="blackAlpha.600">
        Already have an account?{" "}
        <Button
          variant="link"
          color="blackAlpha.600"
          textDecoration="underline"
          fontSize="sm"
          onClick={() => navigate("/login")}
        >
          Go to Login Page
        </Button>
      </Text>
    </Stack>
  );
};
