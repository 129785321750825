import React, { createRef, useEffect, useRef, useState, type RefObject } from "react";
import { Drill } from "../../../../../../types";
import { Box, Grid, GridItem, Stack, Text, IconButton } from "@chakra-ui/react";
import Draggable from "react-draggable";
import { DeleteIcon, DragHandleIcon } from "@chakra-ui/icons";
import { CommonIconButton } from "../../../../../../common/components/CommonIconButton";
import { CommonNumberInput } from "../../../../../../common/components/CommonNumberInput";
import { CommonScrollbar } from "../../../../../../common/components/CommonScrollbar";
import { cardStyles } from "../../../../../../common/utils/styles";

/** Props for the OrderAndDurationStep component */
type OrderAndDurationStepProps = {
  /** Function to set selected drills */
  setSelectedDrills: React.Dispatch<React.SetStateAction<Drill[]>>;
  /** Function to set selected durations */
  setSelectedDurations: React.Dispatch<React.SetStateAction<number[]>>;
  /** Array of selected drills */
  selectedDrills: Drill[];
  /** Array of selected durations for the drills */
  selectedDurations: number[];
};

/** Component for the order and duration step of the session builder */
export const OrderAndDurationStep: React.FC<OrderAndDurationStepProps> = (props) => {
  const [drillOrder, setDrillOrder] = useState(props.selectedDrills);
  const [componentKey, setComponentKey] = useState(0);
  const [dragIndex, setDragIndex] = useState(-1);
  const [isDragging, setIsDragging] = useState(false);
  const drillCardHeight = 75; // height (px) of each DrillCard & how much to move them to change order
  const drillRefs = useRef<Array<RefObject<HTMLDivElement>>>([]);

  if (drillRefs.current.length !== props.selectedDrills.length) {
    drillRefs.current = Array(props.selectedDrills.length)
      .fill(null)
      .map((_, i) => drillRefs.current[i] || createRef());
  }

  useEffect(() => {
    // Reset the component key to force re-render when drillOrder changes, ensuring that drills snap to the correct position
    setComponentKey(Math.random());
  }, [drillOrder]);

  useEffect(() => {
    if (props.selectedDurations.length === 0 || props.selectedDurations[0] === undefined) {
      const initialDurations = new Array(props.selectedDrills.length).fill(0);
      props.setSelectedDurations(initialDurations);
    } else if (props.selectedDurations.length > props.selectedDrills.length) {
      // remove any extra durations if the number of drills has decreased
      const updatedDurations = props.selectedDurations.slice(0, props.selectedDrills.length);
      props.setSelectedDurations(updatedDurations);
    }
    setDrillOrder(props.selectedDrills);
  }, []);

  const handleDurationChange = (value: string, index: number) => {
    const newDuration = Number(value);
    const updatedDurations = [...props.selectedDurations];
    updatedDurations[index] = newDuration;
    props.setSelectedDurations(updatedDurations);
  };

  const handleDelete = (index: number) => {
    const updatedSelectedDrills = props.selectedDrills.filter((_, i) => i !== index);
    const updatedDurations = props.selectedDurations.filter((_, i) => i !== index);
    setDrillOrder(updatedSelectedDrills);
    props.setSelectedDrills(updatedSelectedDrills);
    props.setSelectedDurations(updatedDurations);
  };

  const handleDragStop = (index: number, position: { y: number }) => {
    setIsDragging(false);
    setDragIndex(-1);
    const slotHeight = drillCardHeight + drillCardHeight / 2;
    const newIndex = Math.min(
      Math.max(Math.round((index * slotHeight + position.y) / slotHeight), 0),
      props.selectedDrills.length - 1,
    );

    // Reorder the drills and durations
    const reorderedDrills = [...drillOrder];
    const [movedDrill] = reorderedDrills.splice(index, 1);
    reorderedDrills.splice(newIndex, 0, movedDrill);

    const reorderedDurations = [...props.selectedDurations];
    const [movedDuration] = reorderedDurations.splice(index, 1);
    reorderedDurations.splice(newIndex, 0, movedDuration);

    // Update state
    setDrillOrder(reorderedDrills);
    props.setSelectedDrills(reorderedDrills);
    props.setSelectedDurations(reorderedDurations);
  };

  const handleDragStart = (index: number) => {
    setIsDragging(true);
    setDragIndex(index);
  };

  const totalDuration = props.selectedDurations.reduce((total, duration) => total + duration, 0);

  const calculateRunningDuration = (index: number) => {
    const duration = props.selectedDurations.slice(0, index + 1).reduce((total, duration) => total + duration, 0);
    return isNaN(duration) ? 0 : duration;
  };

  const getPreviousDuration = (index: number) => {
    const duration = index === 0 ? 0 : calculateRunningDuration(index - 1);
    return isNaN(duration) ? 0 : duration;
  };

  return (
    <Stack spacing="4" key={componentKey} height="100%" data-testid="session-modal-step-order">
      {drillOrder.length > 0 ? (
        <Box display="flex" flexDirection="column" height="100%">
          <CommonScrollbar
            height="auto"
            overflow="auto"
            invisibleBorderWidth="0px 8px 0px 0px"
            paddingRight="2"
            paddingLeft="6"
            paddingBottom="6"
          >
            <Stack spacing="4" position="relative">
              {drillOrder.map((drill, index) => (
                <Draggable
                  key={index}
                  nodeRef={drillRefs.current[index]}
                  axis="y"
                  bounds="parent"
                  onStop={(_, data) => handleDragStop(index, data)}
                  onDrag={() => handleDragStart(index)}
                  handle="#drag-handle"
                >
                  <Box
                    zIndex={isDragging && dragIndex === index ? 1 : 0}
                    ref={drillRefs.current[index]}
                    backgroundColor="white"
                    alignContent="center"
                    paddingX="4"
                    height={drillCardHeight}
                    overflow="hidden"
                    opacity={isDragging && dragIndex !== index ? 0.6 : 1}
                    {...cardStyles({ selected: isDragging && dragIndex === index, disableClick: true })}
                    // override cardStyles attributes after spread
                    borderColor="blackAlpha.300"
                    transition="border-color 0.3s ease, box-shadow 0.3s ease"
                  >
                    <Grid templateColumns="repeat(6, 1fr)" alignItems="center" gap="4">
                      <GridItem colSpan={1}>
                        <CommonIconButton
                          hoverColor="red.500"
                          onClick={() => handleDelete(index)}
                          icon={<DeleteIcon color="red.500" />}
                        />
                      </GridItem>
                      <GridItem colSpan={2}>
                        <Text fontSize="medium" noOfLines={2}>
                          {drill.name}
                        </Text>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Text fontSize="medium">
                          {getPreviousDuration(index)} - {calculateRunningDuration(index)}min
                        </Text>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <CommonNumberInput
                          placeholder="Duration (min)"
                          value={props.selectedDurations[index]}
                          onChange={(value) => handleDurationChange(value, index)}
                        />
                      </GridItem>
                      <GridItem colSpan={1} justifySelf="end">
                        <IconButton
                          id="drag-handle"
                          aria-label="drag"
                          variant="unstyled"
                          transition="background-color 0.3s ease"
                          _hover={{ cursor: "grab", backgroundColor: "blackAlpha.50" }}
                          _active={{ cursor: "grabbing" }}
                          icon={<DragHandleIcon />}
                        />
                      </GridItem>
                    </Grid>
                  </Box>
                </Draggable>
              ))}
            </Stack>
          </CommonScrollbar>
          <Text textAlign="center" fontSize="medium" padding="4">
            {`Total planned duration for this session: ${totalDuration} minutes.`}
          </Text>
        </Box>
      ) : (
        <Text textAlign="center" fontSize="medium">
          No drills planned for this session. If you want to add drills, go back to the previous step.
        </Text>
      )}
    </Stack>
  );
};
