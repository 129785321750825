import React from "react";

import { Grid, GridItem, Stack } from "@chakra-ui/react";
import type { RecurringTrainingEvent } from "../../../../../types";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { CommonSelect } from "../../../../../common/components/CommonSelect";
import { TimePicker } from "../../../../../common/components/TimePicker";
import { CourtSelect } from "../../../../../common/components/CourtSelect";
import { CoachSelect } from "../../../../../common/components/CoachSelect";

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

type SingleTrainingGroupProps = {
  event: RecurringTrainingEvent;
};

/** Component for displaying a single training group in a read-only state */
export const SingleTrainingGroup: React.FC<SingleTrainingGroupProps> = (props) => {
  return (
    <Stack direction="row" spacing="4" width="100%">
      <Grid templateColumns="repeat(10, 1fr)" gap="4" alignItems="center" width="100%">
        <GridItem colSpan={2}>
          <CommonInput placeholder="Group name" value={props.event.name || ""} onChange={() => {}} disabled={true} />
        </GridItem>
        <GridItem colSpan={2}>
          <CommonSelect
            placeholder="Weekday"
            value={props.event.recurrenceWeekday}
            options={weekdays.map((weekday, i) => ({
              value: i + 1,
              label: weekday,
            }))}
            isDisabled={true}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <TimePicker label="Start" selectedTime={props.event.recurrenceStartTime} disabled={true} />
        </GridItem>
        <GridItem colSpan={1}>
          <TimePicker label="End" selectedTime={props.event.recurrenceEndTime} disabled={true} />
        </GridItem>

        <GridItem colSpan={2}>
          <CourtSelect courts={[props.event.court]} selectedCourt={props.event.court} disabled={true} />
        </GridItem>
        <GridItem colSpan={2}>
          <CoachSelect selectedCoaches={props.event.coaches?.map((coach) => coach) || []} disabled={true} />
        </GridItem>
      </Grid>
    </Stack>
  );
};
