import React, { useState } from "react";
import { Box, Stack, Text, Tooltip } from "@chakra-ui/react";
import { Session, Drill, SkillSet } from "../../../../types";
import { SessionLibrary } from "../../../../common/components/SessionLibrary";
import { SessionBuilder } from "./components/SessionBuilder";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import type { SessionInput } from "../../containers/TrainingLibraryContainer";
import { filterSessions } from "../../../../common/utils/dataProcessing";

type SessionsProps = {
  allSessions: Session[];
  allDrills: Drill[];
  allSkillSets: SkillSet[];
  saveSession: (sessionInput: SessionInput) => Promise<void>;
  saveSessionLoading: boolean;
};

export const Sessions: React.FC<SessionsProps> = (props) => {
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const [isSessionBuilderOpen, setIsSessionBuilderOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>([]);
  const allUniqueTags = Array.from(new Set(props.allSessions.flatMap((session) => (session.tags ? session.tags : []))));

  /**
   * Select a session
   *
   * @param {Session} session - The session to select
   */
  const handleSelectSession = (session: Session) => {
    setSelectedSession(session);
    setIsSessionBuilderOpen(true);
  };

  /**
   * Change the search query
   *
   * @param {string} newSearchQuery - The new search query
   * @param {string[]} newSearchTags - The new search tags
   * @param {SkillSet[]} newSearchSkillSets - The new search skill sets
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  const handleSessionBuilderClose = () => {
    setSelectedSession(null);
    setIsSessionBuilderOpen(false);
  };

  const filteredSessions = filterSessions(props.allSessions, searchQuery, searchTags, searchSkillSets);

  const featurePurpose =
    "Sessions are structured collections of Drills. Add Sessions to organize complete training events";

  return (
    <Box data-testid="session-manager" height="inherit" display="flex" flexDirection="column">
      <Stack direction="row" padding="6" spacing="4" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Sessions ({filteredSessions.length})</Text>
        </Tooltip>
        <CommonIconButton height="36px" onClick={() => setIsSessionBuilderOpen(true)} dataTestId="add-session" />
      </Stack>
      <Box flex={1} overflow="hidden">
        <SessionLibrary
          allSkillSets={props.allSkillSets}
          allTags={allUniqueTags}
          sessions={filteredSessions}
          searchSkillSets={searchSkillSets}
          searchQuery={searchQuery}
          searchTags={searchTags}
          displayMetadata={true}
          scrollbarHeight="100%"
          isLoading={props.saveSessionLoading}
          onSelectSession={handleSelectSession}
          onSearchChange={handleSearchChange}
        />
      </Box>
      {isSessionBuilderOpen && (
        <SessionBuilder
          allTags={allUniqueTags}
          allSessionNames={props.allSessions.map((session) => session.name)}
          allSkillSets={props.allSkillSets}
          allDrills={props.allDrills}
          open={isSessionBuilderOpen}
          selectedSession={selectedSession}
          onSave={props.saveSession}
          onClose={handleSessionBuilderClose}
        />
      )}
    </Box>
  );
};
