import { gql } from "@apollo/client";

export const GET_ALL_ATHLETES_FOR_MANAGEMENT = gql`
  query GetAllAthletesForManagement {
    getAllAthletes {
      id
      name
      trainingGroups {
        id
        name
        coaches {
          id
          name
        }
        recurrenceWeekday
        recurrenceStartTime
        recurrenceEndTime
        venue {
          id
          name
        }
        athletes {
          id
          name
        }
        trainingSeason {
          id
          name
        }
      }
    }
  }
`;
