import React, { useState, useEffect } from "react";
import { Box, useBreakpoint } from "@chakra-ui/react";
import { useAlert } from "../../../common/components/AlertProvider";
import { useQuery } from "@apollo/client";
import { GET_ALL_SEASON_PLANS } from "../graphql/get-all-season-plans.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { GET_ALL_TRAINING_GROUPS_FOR_SEASON_PLAN } from "../graphql/get-all-training-groups-for-season-plan.query";
import { GET_ALL_ATHLETES_FOR_SEASON_PLAN } from "../graphql/get-all-athletes-for-season-plan.query";
import { SAVE_SEASON_PLAN } from "../graphql/save-season-plan.mutation";
import { SeasonPlans } from "../components/SeasonPlans";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN } from "../graphql/get-all-training-group-events-for-season-plan.query";

export type SkillSetSelectionInput = {
  skillSetId: string;
  selectedSkillIds?: string[];
};

export type SeasonPlanInput = {
  id?: string;
  skillSetSelections: SkillSetSelectionInput[];
  trainingGroupIds?: string[];
  athleteIds?: string[];
  startDateTime: string;
  endDateTime: string;
  isOrganizationWide?: boolean;
  remove?: boolean;
};

// TODO: monthly view

export const SeasonPlanContainer: React.FC = () => {
  const currentBreakPoint = useBreakpoint({ ssr: false });
  const { showAlert } = useAlert();

  const {
    data: seasonPlanData,
    loading: seasonPlanDataLoading,
    error: seasonPlanDataError,
  } = useQuery(GET_ALL_SEASON_PLANS);

  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);

  const {
    data: trainingGroupsData,
    loading: trainingGroupsDataLoading,
    error: trainingGroupsDataError,
  } = useQuery(GET_ALL_TRAINING_GROUPS_FOR_SEASON_PLAN);

  const {
    data: athleteData,
    loading: athleteDataLoading,
    error: athleteDataError,
  } = useQuery(GET_ALL_ATHLETES_FOR_SEASON_PLAN);

  const { execute: saveSeasonPlanMutation, loading: saveSeasonPlanLoading } = useAsyncMutation(SAVE_SEASON_PLAN, {
    refetchQueries: [{ query: GET_ALL_SEASON_PLANS }, { query: GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN }],
    awaitRefetchQueries: true,
  });

  const isLoading =
    seasonPlanDataLoading ||
    skillSetDataLoading ||
    trainingGroupsDataLoading ||
    saveSeasonPlanLoading ||
    athleteDataLoading;

  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([skillSetDataError, seasonPlanDataError, trainingGroupsDataError, athleteDataError]);

  const handleSaveSeasonPlan = async (seasonPlanInput: SeasonPlanInput) => {
    if (seasonPlanInput.remove) {
      showAlert("Deleting Season Plan", "info", undefined, true);
    } else if (seasonPlanInput.id) {
      showAlert("Updating Season Plan", "info", undefined, true);
    } else {
      showAlert("Creating new Season Plan", "info", undefined, true);
    }

    const result = await saveSeasonPlanMutation({
      data: seasonPlanInput,
    });

    if (result) {
      if (seasonPlanInput.remove) {
        showAlert("Season Plan deleted!", "success", 5000);
      } else if (seasonPlanInput.id) {
        showAlert("Season Plan updated!", "success", 5000);
      } else {
        showAlert("Season Plan created!", "success", 5000);
      }
    }
  };

  return (
    <Box id="season-plan">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <SeasonPlans
            seasonPlans={seasonPlanData?.getAllSeasonPlans}
            allTrainingGroups={trainingGroupsData?.getAllTrainingGroups || []}
            allSkillSets={skillSetData?.getAllSkillSets || []}
            allAthletes={athleteData?.getAllAthletes || []}
            onSaveSeasonPlan={handleSaveSeasonPlan}
            showAlert={showAlert}
            currentBreakPoint={currentBreakPoint}
            isLoading={isLoading}
          />
        </Box>
      )}
    </Box>
  );
};
