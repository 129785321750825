import React from "react";
import Striveon from "../../assets/StriveonCroppedPadding.png";
import {
  AdminPanelSettings,
  Assignment,
  Event,
  LocalLibrary,
  Logout,
  Person,
  Dashboard,
  Timeline,
} from "@mui/icons-material";
import { NavigationLink, type NavigationLinkProps } from "./components";
import { Card, Divider, Icon, IconButton, Stack, Text } from "@chakra-ui/react";

/** Array of links to display */
export const links: (NavigationLinkProps & { restricted?: boolean })[] = [
  {
    to: "/",
    icon: <Icon as={Event} />,
    primaryText: "Calendar",
  },
  {
    to: "/season-plan",
    icon: <Icon as={Timeline} />,
    primaryText: "Season Plan",
    isDisabledOnMobile: true,
  },
  {
    to: "/skill-sets",
    icon: <Icon as={Assignment} />,
    primaryText: "Skill Sets",
    isDisabledOnMobile: true,
  },
  {
    to: "/training-library",
    icon: <Icon as={LocalLibrary} />,
    primaryText: "Training Library",
    isDisabledOnMobile: true,
  },
  {
    to: "/athletes",
    icon: <Icon as={Person} />,
    primaryText: "Athletes",
    isDisabledOnMobile: true,
  },
  {
    to: "/management",
    icon: <Icon as={Dashboard} />,
    primaryText: "Management",
    isDisabledOnMobile: true,
  },
  {
    to: "/admin",
    icon: <Icon as={AdminPanelSettings} color="red" />,
    primaryText: "Admin",
    isDisabledOnMobile: true,
    restricted: true,
  },
];

export type HeaderProps = {
  isAdmin: boolean;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
};

export const Header: React.FC<HeaderProps> = (props) => {
  return (
    <Card
      zIndex={11}
      position="fixed"
      width="100%"
      height="56px"
      direction="row"
      alignContent="center"
      justifyContent={props.isAuthenticated ? "center" : "start"}
      bgColor="teal.800"
      paddingY="2"
      paddingX="8"
      variant="outline"
      border={0}
      borderRadius={0}
      data-testid="header"
    >
      <img src={Striveon} width="40px" height="40px" style={{ borderRadius: "8px" }} />
      <Text fontSize="2xl" fontWeight="bold" marginLeft="2" color="whiteAlpha.900">
        Striveon
      </Text>
      {props.isAuthenticated && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          flex={1}
          spacing="6"
          data-testid="header-links"
        >
          {links.map((link, index) => (
            <React.Fragment key={link.to}>
              {(!link.restricted || (link.restricted && props.isAdmin)) && (
                <NavigationLink to={link.to} icon={link.icon} primaryText={link.primaryText} />
              )}
              {index < links.length - (props.isAdmin ? 1 : 2) && <Divider orientation="vertical" />}
            </React.Fragment>
          ))}
        </Stack>
      )}
      {props.isAuthenticated && (
        <IconButton
          aria-label="logout"
          icon={<Logout />}
          variant="ghost"
          color="whiteAlpha.900"
          marginLeft="6"
          onClick={() => {
            props.setIsAuthenticated(false);
            localStorage.clear();
          }}
          transition="all 0.3s ease"
          _hover={{ color: "orange.500" }}
          data-testid="header-logout"
        />
      )}
    </Card>
  );
};
