import { gql } from "@apollo/client";

export const REGISTER = gql`
  mutation Register(
    $username: String!
    $password: String!
    $organizationId: String!
  ) {
    register(
      username: $username
      password: $password
      organizationId: $organizationId
    )
  }
`;
