import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Box, useBreakpoint } from "@chakra-ui/react";
import {
  Header,
  CalendarContainer,
  TrainingLibraryContainer,
  AthleteContainer,
  ManagementContainer,
  Login,
  AdminContainer,
  SkillSetsContainer,
  SeasonPlanContainer,
  CreateAccountRoute,
} from "./features";
import { MobileHeader } from "./features/Header/MobileHeader";
import { CommonScrollbar } from "./common/components/CommonScrollbar";
import { parseToken } from "./features/Auth/utils";

/**
 * Main App component:
 *
 * - Checks localStorage for an auth token
 * - Conditionally renders public or authenticated routes based on the token
 */
const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const currentBreakPoint = useBreakpoint({ ssr: false });

  /** UseEffect hook to parse token from localStorage and set authentication state. */
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const { authStatus, adminStatus } = parseToken(token);
      setIsAuthenticated(authStatus);
      setIsAdmin(adminStatus);
    }

    setIsCheckingToken(false);
  }, []);

  return (
    <Router>
      <Box>
        {currentBreakPoint === "mobile" ? (
          <MobileHeader isAdmin={isAdmin} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
        ) : (
          <Header isAdmin={isAdmin} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
        )}

        {isCheckingToken ? null : (
          <CommonScrollbar
            isMainContainer
            as="main"
            position="relative"
            top="56px"
            width="100%"
            height="calc(100svh - 56px)"
            paddingTop="8"
            paddingLeft="16"
            paddingBottom="12"
            paddingRight="12"
            bgColor="white"
            overflowY="visible"
            overflowX="hidden"
            data-testid="mainBox"
          >
            {isAuthenticated ? (
              // Authenticated routes
              <Routes>
                <Route path="/" element={<CalendarContainer />} />
                <Route path="/season-plan" element={<SeasonPlanContainer />} />
                <Route path="/athletes" element={<AthleteContainer />} />
                <Route path="/skill-sets" element={<SkillSetsContainer />} />
                <Route path="/training-library" element={<TrainingLibraryContainer />} />
                <Route path="/management" element={<ManagementContainer />} />
                {isAdmin && <Route path="/admin" element={<AdminContainer />} />}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            ) : (
              // Public routes
              <Routes>
                <Route
                  path="/login"
                  element={<Login setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} />}
                />
                <Route
                  path="/create-account"
                  element={<CreateAccountRoute setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            )}
          </CommonScrollbar>
        )}
      </Box>
    </Router>
  );
};

export default App;
