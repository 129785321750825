import React, { useEffect, useState } from "react";
import { Box, Input, InputGroup, InputRightElement, List, ListItem } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { commonStyles } from "../../utils";

/** Props for the Autocomplete component. */
export type AutocompleteProps<T> = {
  /** All available options for the autocomplete */
  allOptions: T[];
  /** Callback function to handle when an option is selected */
  handleOptionChange: (option: T) => void;
  /** Function to get the display label of an option */
  getOptionLabel: (option: T) => string;
  /** Initially selected option */
  initialOption?: T;
  /** When set to true, clears the input */
  clearInput?: boolean;
  /** Data test id for the autocomplete component */
  dataTestId?: string;
};

/** Autocomplete component for selecting an option from a list of suggestions. */
export const Autocomplete: React.FC<AutocompleteProps<any>> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.initialOption ? props.getOptionLabel(props.initialOption) : "");
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);

  const filteredOptions = props.allOptions.filter((option) =>
    props.getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase()),
  );

  useEffect(() => {
    if (!isActive) {
      setActiveOptionIndex(0);
    }
  }, [isActive]);

  useEffect(() => {
    if (props.clearInput) {
      setInputValue("");
    }
  }, [props.clearInput]);

  /**
   * Selects an option.
   *
   * @param option - The selected option
   */
  const selectOption = (option: any) => {
    props.handleOptionChange(option);
    setIsActive(false);
    setActiveOptionIndex(-1);
  };

  /** Handles keyboard navigation and selection in the autocomplete list. */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && filteredOptions.length > 0 && activeOptionIndex >= 0) {
      selectOption(filteredOptions[activeOptionIndex]);
    } else if (e.key === "ArrowDown") {
      setActiveOptionIndex((prevIndex) => (prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === "ArrowUp") {
      setActiveOptionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1));
    }
  };

  /** Handles focus event on the input, setting the autocomplete to active. */
  const handleInputFocus = () => {
    setIsActive(true);
  };

  /** Handles blur event on the input, setting the autocomplete to inactive. */
  const handleInputBlur = () => {
    setIsActive(false);
  };

  /** Handles change event on the input, updating the input value and setting the autocomplete to active. */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(true);
    setInputValue(e.target.value);
  };

  return (
    <Box minWidth="200px" position="relative" data-testid={props.dataTestId}>
      <InputGroup transition="all 0.3s ease" _hover={{ color: "orange.500" }}>
        <Input
          value={inputValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={`Search (${filteredOptions.length})`}
          {...commonStyles(isActive)}
        />
        <InputRightElement color="inherit">
          <SearchIcon />
        </InputRightElement>
      </InputGroup>
      <List
        paddingY="2"
        display={isActive ? "block" : "none"}
        position="absolute"
        width="100%"
        maxHeight="600px"
        overflowY="auto"
        zIndex={5}
        backgroundColor="white"
        {...commonStyles(isActive)}
        data-testid="autocomplete-list"
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <ListItem
              key={index}
              padding="2"
              cursor="pointer"
              backgroundColor={activeOptionIndex === index ? "gray.100" : undefined}
              _hover={{ backgroundColor: "gray.100" }}
              onMouseDown={() => selectOption(option)}
              data-testid={`autocomplete-option-${index}`}
            >
              {props.getOptionLabel(option)}
            </ListItem>
          ))
        ) : (
          <ListItem padding="2" color="gray.500" data-testid="autocomplete-option-no-result">
            No results
          </ListItem>
        )}
      </List>
    </Box>
  );
};
