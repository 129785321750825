import React from "react";
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormControl,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils";

/** Props for the CommonSelect component */
type CommonSelectProps = ChakraSelectProps & {
  /** Array of options to be displayed in the Select component */
  options: { value: string | number; label: string }[];
};

/** CommonSelect component is a reusable Select component that uses Chakra UI's Select and extends its functionality. */
export const CommonSelect: React.FC<CommonSelectProps> = (props) => {
  const { options, ...selectProps } = props;

  return (
    <InputGroup width={props.width}>
      <FormControl id="common-select">
        <CommonFormLabel display={!!props.value}>{props.placeholder}</CommonFormLabel>
        <ChakraSelect
          {...selectProps}
          placeholder={!props.value ? props.placeholder : undefined}
          isInvalid={!props.value && options.length === 0}
          height="42px"
          {...commonStyles(!!props.value)}
          sx={{ ...selectProps.sx }}
        >
          {options.map((option, index) => (
            <Text as={"option"} key={index} value={option.value} color="black">
              {option.label}
            </Text>
          ))}
        </ChakraSelect>
      </FormControl>
    </InputGroup>
  );
};
