import React, { useEffect, useState } from "react";
import { Box, Text, Table, Thead, Tbody, Tr, Td, TableContainer, Tooltip, Stack } from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  DeletionInfoEntityType,
  TrainingSeason,
  TrainingSeasonStatus,
  MissingTrainingGroupEvent,
  type TrainingGroup,
  DeletionNode,
} from "../../../../types";
import { FormModal } from "../../../../common/components/FormModal";
import { useAlert } from "../../../../common/components/AlertProvider";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { extractDateFromUTC } from "../../../../common/utils/dateAndTime";
import type { CreateMissingTrainingGroupEventInput, TrainingSeasonInput } from "../../containers/ManagementContainer";
import { CommonButton } from "../../../../common/components/CommonButton";
import { TrainingSeasonEditor, type TrainingSeasonWithTrainingGroups } from "./components/TrainingSeasonEditor";
import { DeletionInfo } from "../../../../common/components/DeletionInfo";
import { TrainingGroupEventSelectionList } from "./components/TrainingGroupEventSelectionList";

type TrainingSeasonsProps = {
  allTrainingGroups: TrainingGroup[];
  trainingSeasons: TrainingSeason[];
  missingTrainingGroupEvents: MissingTrainingGroupEvent[];
  updateTrainingSeasonLoading: boolean;
  createTrainingGroupEventsLoading: boolean;
  saveTrainingSeason: (data: TrainingSeasonInput) => Promise<any>;
  createMissingTrainingGroupEvents: (
    missingTrainingGroupEventInput: CreateMissingTrainingGroupEventInput[],
  ) => Promise<void>;
  entitiesToBeRemoved: DeletionNode[];
  entitiesToBeRemovedLoading: boolean;
  getEntitiesToBeRemoved: (trainingSeasonId: string) => Promise<void>;
  onGetMissingTrainingGroupEvents: (trainingSeasonId: string) => Promise<void>;
  missingTrainingGroupEventsLoading: boolean;
};

export const TrainingSeasons: React.FC<TrainingSeasonsProps> = (props) => {
  const [selectedSeason, setSelectedSeason] = useState<TrainingSeasonWithTrainingGroups | null>(null);
  const [initialSeason, setInitialSeason] = useState<TrainingSeason | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof TrainingSeason;
    direction: "ascending" | "descending";
  }>({ key: "status", direction: "ascending" });
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNewSeason, setIsCreatingNewSeason] = useState(false);
  const { showAlert } = useAlert();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedSeasonForUpdate, setSelectedSeasonForUpdate] = useState<TrainingSeason | null>(null);
  const [selectedEvents, setSelectedEvents] = useState(props.missingTrainingGroupEvents);

  const stageActiveTooltip = (
    <Stack direction="column" spacing="0">
      <Text color="white" fontSize="medium">
        Active
      </Text>
      <Text color="white">- Currently ongoing Training Season</Text>
      <Text color="white">- Calendar events are added automatically</Text>
      <Text color="white">- Only one Training Season can be "Active" at a time</Text>
    </Stack>
  );

  const stageUpcomingTooltip = (
    <Stack direction="column" spacing="0">
      <Text color="white" fontSize="medium">
        Upcoming
      </Text>
      <Text color="white">- Training Season planned for the future</Text>
      <Text color="white">- Calendar events won’t be added automatically</Text>
      <Text color="white">- Activating this will make the current "Active" season "Completed"</Text>
    </Stack>
  );

  const stageCompletedTooltip = (
    <Stack direction="column" spacing="0">
      <Text color="white" fontSize="medium">
        Completed
      </Text>
      <Text color="white">- Finished Training Season</Text>
      <Text color="white">- No new groups or events can be added</Text>
      <Text color="white">- For viewing past information only</Text>
    </Stack>
  );

  const columns = [
    {
      label: "Stage",
      key: "status" as keyof TrainingSeason,
      width: "15%",
      tooltip: (
        <Stack direction="column" spacing="4" padding="2">
          {stageActiveTooltip}
          {stageUpcomingTooltip}
          {stageCompletedTooltip}
        </Stack>
      ),
    },
    { label: "Name", key: "name" as keyof TrainingSeason, width: "25%" },
    { label: "Start Date", key: "seasonStart" as keyof TrainingSeason, width: "20%" },
    { label: "End Date", key: "seasonEnd" as keyof TrainingSeason, width: "20%" },
    { label: "Actions", key: "actions" as keyof TrainingSeason, width: "20%" },
  ];

  const sortableColumns: (keyof TrainingSeason)[] = ["name", "seasonStart", "seasonEnd", "status"];

  const handleSort = (key: keyof TrainingSeason) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "ascending" ? "descending" : "ascending",
      });
    } else {
      setSortConfig({ key, direction: "ascending" });
    }
  };

  const sortedSeasons = React.useMemo(() => {
    let sortableSeasons = [...props.trainingSeasons];
    if (sortConfig !== null) {
      sortableSeasons.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "name") {
          aValue = a.name || "";
          bValue = b.name || "";
        }

        if (sortConfig.key === "seasonStart" || sortConfig.key === "seasonEnd") {
          aValue = new Date(aValue as string).getTime().toString();
          bValue = new Date(bValue as string).getTime().toString();
        }

        if (sortConfig.key === "status") {
          const getOrder = (status: TrainingSeasonStatus): number => {
            if (status === TrainingSeasonStatus.Completed) return 3; // Completed always last
            if (sortConfig.direction === "ascending") {
              return status === TrainingSeasonStatus.Active ? 1 : 2; // Active before Upcoming
            } else {
              return status === TrainingSeasonStatus.Upcoming ? 1 : 2; // Upcoming before Active
            }
          };

          const aOrder = getOrder(aValue as TrainingSeasonStatus);
          const bOrder = getOrder(bValue as TrainingSeasonStatus);

          return aOrder - bOrder;
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sortableSeasons;
  }, [props.trainingSeasons, sortConfig]);

  const handleRowClick = (season: TrainingSeason) => {
    setIsCreatingNewSeason(false);
    setSelectedSeason({ ...season, trainingGroups: [] });
    setInitialSeason(season);
    setIsOpen(true);
  };

  const handleSave = async (remove: boolean) => {
    setIsOpen(false);
    if (selectedSeason) {
      const seasonToSave: TrainingSeasonInput = {
        id: selectedSeason.id,
        name: selectedSeason.name || initialSeason?.name || "unnamed",
        seasonStart: selectedSeason.seasonStart || initialSeason?.seasonStart || "",
        seasonEnd: selectedSeason.seasonEnd || initialSeason?.seasonEnd || "",
        status: selectedSeason.status,
        trainingGroupsToCopy: selectedSeason.trainingGroups.map((group) => ({ id: group.id || "", name: group.name })),
        remove: remove,
      };

      if (seasonToSave.remove) {
        showAlert("Removing Training Season", "info", undefined, true);
      } else if (seasonToSave.id) {
        showAlert("Updating Training Season", "info", undefined, true);
      } else {
        showAlert("Creating new Training Season", "info", undefined, true);
      }

      const result = await props.saveTrainingSeason(seasonToSave);

      if (result) {
        if (seasonToSave.remove) {
          showAlert("Training Season removed!", "success", 5000);
        } else {
          if (seasonToSave.id) {
            showAlert("Training Season updated!", "success", 5000);
          } else {
            showAlert("Training Season created!", "success", 5000);
          }
        }
      }
    }
  };

  const handleCreateNewSeason = () => {
    setIsCreatingNewSeason(true);
    setSelectedSeason({
      name: "",
      seasonStart: "",
      seasonEnd: "",
      status: props.trainingSeasons.some((season) => season.status === TrainingSeasonStatus.Active)
        ? TrainingSeasonStatus.Upcoming
        : TrainingSeasonStatus.Active,
      trainingGroups: [],
    });
    setIsOpen(true);
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const reasons: string[] = [];

    if (!selectedSeason?.name || selectedSeason?.name.trim() === "") {
      reasons.push("Season name is entered");
    }
    if (!selectedSeason?.seasonStart) {
      reasons.push("Season start is selected");
    }
    if (!selectedSeason?.seasonEnd) {
      reasons.push("Season end is selected");
    }
    if (!selectedSeason?.status) {
      reasons.push("Season stage is selected");
    }

    return reasons.length > 0 ? `Saving is enabled once\n- ${reasons.join("\n- ")}` : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const handleUpdateTrainingEvents = (season: TrainingSeason) => {
    setSelectedSeasonForUpdate(season);
    if (season.id) {
      props.onGetMissingTrainingGroupEvents(season.id);
    }
    setIsConfirmationOpen(true);
  };

  const handleConfirmUpdateTrainingEvents = async () => {
    setIsConfirmationOpen(false);
    if (selectedSeasonForUpdate && selectedSeasonForUpdate.id) {
      showAlert("Updating training events", "info", undefined, true);

      const inputData: CreateMissingTrainingGroupEventInput[] = selectedEvents.map((trainingGroupEvent) => ({
        trainingSeasonId: trainingGroupEvent.trainingGroup.trainingSeason.id || "",
        trainingGroupId: trainingGroupEvent.trainingGroup.id || "",
        startDateTime: trainingGroupEvent.startDateTime,
      }));

      await props.createMissingTrainingGroupEvents(inputData);
      showAlert("Training events updated!", "success", 5000);
      setSelectedEvents([]);
    }
  };

  const featurePurpose =
    "Training Seasons are defined periods that organize Training Groups and schedules. Add Training Seasons to structure coaching across different time frames";

  return (
    <Box position="relative" data-testid="organization-training-seasons">
      <LoadingOverlay
        display={props.updateTrainingSeasonLoading || props.createTrainingGroupEventsLoading}
        spinnerSize="xl"
        spinnerTopPosition="40px"
      />
      <Stack direction="row" padding="6" spacing="4" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Training Seasons ({props.trainingSeasons.length})</Text>
        </Tooltip>
        <CommonIconButton height="36px" onClick={handleCreateNewSeason} dataTestId="add-training-season" />
      </Stack>

      <TableContainer overflow="hidden" paddingBottom="6" paddingX="6">
        <Table size="lg" variant="unstyled" layout="fixed">
          <Thead>
            <Tr>
              {columns.map((column) => {
                const isSortable = sortableColumns.includes(column.key);
                const isSorted = sortConfig.key === column.key;
                return (
                  <Tooltip key={column.key as string} label={column.tooltip} placement="bottom" whiteSpace="pre-wrap">
                    <Text
                      as="th"
                      width={column.width}
                      fontSize="md"
                      textTransform="uppercase"
                      paddingY="4"
                      paddingX="8"
                      onClick={() => {
                        if (isSortable) {
                          handleSort(column.key);
                        }
                      }}
                      transition="all 0.3s ease"
                      _hover={
                        isSortable
                          ? {
                              color: "orange.400",
                              cursor: "pointer",
                            }
                          : undefined
                      }
                    >
                      <Box display="flex" alignItems="center" color={isSorted ? "orange.400" : "inherit"}>
                        {column.label}
                        {isSortable && (
                          <Box marginLeft="1" display="flex" alignItems="center" color="inherit">
                            {isSorted ? (
                              sortConfig.direction === "ascending" ? (
                                <TriangleUpIcon color="orange.400" opacity={1} />
                              ) : (
                                <TriangleDownIcon color="orange.400" opacity={1} />
                              )
                            ) : (
                              <TriangleDownIcon color="inherit" opacity={0.3} />
                            )}
                          </Box>
                        )}
                      </Box>
                    </Text>
                  </Tooltip>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {sortedSeasons.map((season) => (
              <Tr
                key={season.id}
                onClick={() => handleRowClick(season)}
                cursor="pointer"
                borderTop="1px solid"
                borderColor="blackAlpha.50"
                borderRadius="md"
                color={
                  season.status === TrainingSeasonStatus.Active
                    ? "orange.400"
                    : season.status === TrainingSeasonStatus.Completed
                      ? "blackAlpha.600"
                      : undefined
                }
                transition="all 0.3s ease"
                _hover={{
                  color: "orange.400",
                  borderColor: "transparent",
                  boxShadow: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C", // lg blackAlpha.500
                }}
              >
                <Td width={columns[0].width} overflow="hidden" textOverflow="ellipsis">
                  <Tooltip
                    placement="right-start"
                    label={
                      (season.status === TrainingSeasonStatus.Active && stageActiveTooltip) ||
                      (season.status === TrainingSeasonStatus.Upcoming ? stageUpcomingTooltip : stageCompletedTooltip)
                    }
                  >
                    {season.status}
                  </Tooltip>
                </Td>
                <Td width={columns[1].width} overflow="hidden" textOverflow="ellipsis">
                  <Tooltip placement="top" label={season.name}>
                    {season.name}
                  </Tooltip>
                </Td>
                <Td width={columns[2].width} overflow="hidden" textOverflow="ellipsis">
                  {extractDateFromUTC(season.seasonStart, ".", true)}
                </Td>
                <Td width={columns[3].width} overflow="hidden" textOverflow="ellipsis">
                  {extractDateFromUTC(season.seasonEnd, ".", true)}
                </Td>
                <Td width={columns[4].width} overflow="hidden" textOverflow="ellipsis">
                  <CommonButton
                    fullWidth
                    variantType="outlineSecondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUpdateTrainingEvents(season);
                    }}
                    disabled={season.status === TrainingSeasonStatus.Completed}
                    tooltip={season.status === TrainingSeasonStatus.Completed ? "No actions for completed seasons" : ""}
                    dataTestId={`update-training-groups-${season.id}`}
                  >
                    {season.status === TrainingSeasonStatus.Completed ? "No action" : "Manage training events"}
                  </CommonButton>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {sortedSeasons.length === 0 && (
        <Text
          fontSize="large"
          paddingY="6"
          textColor="blackAlpha.600"
          textAlign="center"
          data-testid="no-training-seasons"
        >
          {featurePurpose}
        </Text>
      )}

      <FormModal
        fixedHeight
        open={isOpen}
        title={isCreatingNewSeason ? "Create new Training Season" : "Edit Training Season"}
        submitButtonText={isCreatingNewSeason ? "Create" : "Save"}
        handleSubmit={() => handleSave(false)}
        onClose={() => setIsOpen(false)}
        handleRemove={() => handleSave(true)}
        removeDisabled={!selectedSeason?.id || selectedSeason.status === TrainingSeasonStatus.Active}
        removeDisabledReason={
          !selectedSeason?.id
            ? "Season is not created"
            : selectedSeason?.status === TrainingSeasonStatus.Active
              ? "Active season cannot be removed"
              : undefined
        }
        confirmationDialogTitle="Delete Training Season"
        confirmationDialogMessage={
          <DeletionInfo
            entityName={initialSeason?.name || ""}
            entitiesToBeRemoved={props.entitiesToBeRemoved}
            entityType={DeletionInfoEntityType.TrainingSeason}
            loading={props.entitiesToBeRemovedLoading}
          />
        }
        onConfirmationDialogOpen={async () => await props.getEntitiesToBeRemoved(selectedSeason?.id || "")}
        isConfirmingDeletion={true}
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
        handlePaddingXManually
      >
        {selectedSeason && (
          <TrainingSeasonEditor
            trainingSeason={selectedSeason}
            allTrainingGroups={props.allTrainingGroups}
            onChange={(seasonWithGroups) => setSelectedSeason(seasonWithGroups)}
          />
        )}
      </FormModal>

      <FormModal
        fixedHeight
        open={isConfirmationOpen}
        title="Select calendar training events to create"
        submitButtonText="Create"
        handleSubmit={handleConfirmUpdateTrainingEvents}
        onClose={() => setIsConfirmationOpen(false)}
        submitDisabled={props.missingTrainingGroupEvents.length === 0 || selectedEvents.length === 0}
        submitButtonHoverText={selectedEvents.length === 0 ? "No training events selected" : undefined}
        handlePaddingXManually
      >
        <TrainingGroupEventSelectionList
          trainingGroupEvents={props.missingTrainingGroupEvents}
          onSelectionChange={setSelectedEvents}
          loading={props.missingTrainingGroupEventsLoading}
        />
      </FormModal>
    </Box>
  );
};
