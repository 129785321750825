import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Box, Text, VStack, Heading } from "@chakra-ui/react";
import { FallbackProps } from "react-error-boundary";
import { CommonButton } from "../../CommonButton";

function FallbackComponent({ error, resetErrorBoundary }: FallbackProps) {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Box role="alert" minHeight="100vh" display="flex" alignItems="center" justifyContent="center" padding="4">
      <VStack spacing="4" textAlign="center">
        <Heading size="lg">Something went wrong</Heading>
        <Text>Sorry, an unexpected error occurred. Please refresh the page to continue.</Text>
        <CommonButton variantType="solidPrimary" onClick={handleRefresh}>
          Refresh Page
        </CommonButton>
        {process.env.NODE_ENV === "development" && (
          <Box marginTop="4">
            <Text color="red.500">{error.message}</Text>
          </Box>
        )}
      </VStack>
    </Box>
  );
}

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return <ReactErrorBoundary FallbackComponent={FallbackComponent}>{children}</ReactErrorBoundary>;
};
