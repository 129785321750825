import { gql } from "@apollo/client";

export const GET_ALL_RECURRING_TRAINING_EVENT_METADATAS = gql`
  query GetAllRecurringTrainingEventMetadatas {
    getAllRecurringTrainingEventMetadatas {
      id
      startDateTime
      endDateTime
      coaches {
        id
        name
      }
      court {
        id
        name
      }
      players {
        id
        name
        seasonPlans {
          id
          skillSets {
            id
            skillSet {
              id
              name
            }
            skills {
              id
              name
            }
          }
        }
      }
      recurringTrainingEvent {
        id
        name
        seasonPlans {
          id
          skillSets {
            id
            skillSet {
              id
              name
            }
            skills {
              id
              name
            }
          }
        }
      }
      notes {
        id
        isGroupNote
        content
        players {
          id
        }
      }
      drills {
        id
        name
        implementation
        goal
        tags
      }
      session {
        id
        name
        goal
        totalDuration
        tags
        sessionDrills {
          id
          duration
          order
          drill {
            id
            name
            implementation
            goal
            tags
          }
        }
      }
    }
  }
`;
