import { gql } from "@apollo/client";

export const GET_CENTER = gql`
  query GetCenter($id: String!) {
    getCenter(id: $id) {
      id
      name
      venues {
        id
        name
      }
    }
  }
`;
