import React from "react";
import { Box, Divider, Stack, Text, Tooltip } from "@chakra-ui/react";
import type { EventContentArg } from "fullcalendar";
import type { FullCalendarSeasonPlanEvent } from "../../../../types";
import { extractDateFromUTC } from "../../../../../../common/utils";
import "./seasonPlanEventContent.css";

/** Renders the content of an event in the calendar with improved visuals and information clarity. */
export const SeasonPlanEventContent: React.FC<EventContentArg> = (args: EventContentArg) => {
  const { event } = args;

  const isMultiWeekEvent =
    event.end && event.start && event.end.getTime() - event.start.getTime() > 7 * 24 * 60 * 60 * 1000;
  const isEventFirstWeek =
    (args.isStart === true && args.isEnd === false) || (args.isStart === true && args.isEnd === true);
  const isEventMiddleWeek = args.isStart === false && args.isEnd === false;
  const isEventLastWeek = args.isStart === false && args.isEnd === true;
  const eventSeasonPlan = event.extendedProps.seasonPlan as FullCalendarSeasonPlanEvent["seasonPlan"];
  const seasonPlanStartDate = extractDateFromUTC(eventSeasonPlan.startDateTime, ".", true);
  const seasonPlanEndDate = extractDateFromUTC(eventSeasonPlan.endDateTime, ".", true);
  const isSingleDayEvent = seasonPlanStartDate === seasonPlanEndDate;

  const trainingSkillSetTarget = eventSeasonPlan.skillSets.map((spss) => spss.skillSet.name).join(", ");

  const trainingSkillTarget =
    eventSeasonPlan.skillSets.some((spss) => spss.skills.length > 0) &&
    eventSeasonPlan.skillSets.map((spss) => spss.skills.map((skill) => skill.name).join(", "));

  const allGroups = eventSeasonPlan.isOrganizationWide ? "Everyone" : undefined;
  const specificGroups = eventSeasonPlan.recurringTrainingEvents?.map((group) => group.name).join(", ");
  const players = eventSeasonPlan.players?.map((player) => player.name).join(", ");
  let displayGroups;
  if (allGroups) {
    displayGroups = allGroups;
  } else if (specificGroups && players) {
    displayGroups = `Groups: ${specificGroups}, Players: ${players}`;
  } else if (specificGroups) {
    displayGroups = `Groups: ${specificGroups}`;
  } else {
    displayGroups = `Players: ${players}`;
  }

  const handleMouseEnter = () => {
    const elements = document.querySelectorAll(`.season-plan-event-${eventSeasonPlan.id}`);
    elements.forEach((element) => {
      isMultiWeekEvent
        ? element.classList.add("isHovering-multi-week-event")
        : element.classList.add("isHovering-single-week-event");
    });
  };

  const handleMouseLeave = () => {
    const elements = document.querySelectorAll(`.season-plan-event-${eventSeasonPlan.id}`);
    elements.forEach((element) => {
      isMultiWeekEvent
        ? element.classList.remove("isHovering-multi-week-event")
        : element.classList.remove("isHovering-single-week-event");
    });
  };

  return (
    <Tooltip
      width="100%"
      label={
        <Stack direction="column" spacing="2" padding="1" borderRadius="md">
          <Text fontSize="medium" color="whiteAlpha.900">
            Who - {displayGroups}
          </Text>
          <Text fontSize="medium" color="whiteAlpha.900">
            Skill Sets to train - {trainingSkillSetTarget || "none"}
          </Text>
          <Text fontSize="medium" color="whiteAlpha.900">
            Skills to train - {trainingSkillTarget || "none"}
          </Text>
          <Text fontSize="medium" color="whiteAlpha.900">
            Training timeperiod - {`${seasonPlanStartDate} - ${seasonPlanEndDate}`}
          </Text>
        </Stack>
      }
      hasArrow
      placement="top-end"
    >
      <Box
        className={`season-plan-event-${eventSeasonPlan.id}`}
        padding="2"
        marginY="1"
        shadow="md"
        overflow="hidden"
        borderRadius="md"
        border="1px solid"
        borderColor="whiteAlpha.700"
        color="white"
        backgroundColor={isMultiWeekEvent ? "teal.800" : "orange.400"}
        transition="all 0.3s ease"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid="season-plan-event-content"
      >
        {isEventFirstWeek && (
          <Stack direction="column" spacing="1" color="inherit">
            <Stack
              direction="row"
              spacing="4"
              color="inherit"
              justifyContent={!isMultiWeekEvent ? "space-between" : undefined}
            >
              <Text
                fontSize="large"
                color="inherit"
                maxWidth={!isSingleDayEvent ? (isMultiWeekEvent ? "80%" : "50%") : undefined}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {displayGroups}
              </Text>
              {!isSingleDayEvent && (
                <>
                  <Text fontSize="large" color="inherit" overflow="hidden" textOverflow="ellipsis">
                    {`(${seasonPlanStartDate}`}
                  </Text>
                  <Divider alignSelf="center" flex={1} variant="dashed" />
                  {!isMultiWeekEvent && (
                    <Text fontSize="large" color="inherit" overflow="hidden" textOverflow="ellipsis">
                      {`${seasonPlanEndDate})`}
                    </Text>
                  )}
                </>
              )}
            </Stack>
            {trainingSkillSetTarget && (
              <Text fontSize="medium" color="inherit" overflow="hidden" textOverflow="ellipsis">
                {trainingSkillSetTarget}
              </Text>
            )}
            {trainingSkillTarget && (
              <Text fontSize="medium" color="inherit" overflow="hidden" textOverflow="ellipsis">
                {trainingSkillSetTarget ? `(${trainingSkillTarget})` : trainingSkillTarget}
              </Text>
            )}
          </Stack>
        )}

        {isEventMiddleWeek && (
          <Stack direction="column" spacing="1">
            <Stack direction="row" spacing="0">
              {/* Display whitespaces so event height stays the same from week to week */}
              <Text fontSize="large">&nbsp;</Text>
              <Divider alignSelf="center" flex={1} variant="dashed" />
            </Stack>
            {trainingSkillSetTarget && <Text fontSize="medium">&nbsp;</Text>}
            {trainingSkillTarget && <Text fontSize="medium">&nbsp;</Text>}
          </Stack>
        )}

        {isEventLastWeek && (
          <Stack direction="column" spacing="1" color="inherit">
            <Stack direction="row" spacing="4" color="inherit">
              <Divider alignSelf="center" flex={1} variant="dashed" />
              <Text fontSize="large" color="inherit">
                {`${seasonPlanEndDate})`}
              </Text>
            </Stack>
            {trainingSkillSetTarget && <Text fontSize="medium">&nbsp;</Text>}
            {trainingSkillTarget && <Text fontSize="medium">&nbsp;</Text>}
          </Stack>
        )}
      </Box>
    </Tooltip>
  );
};
