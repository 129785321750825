import React from "react";

import { cardStyles, extractDateFromUTC } from "../../../../../common/utils";
import { extractFormattedTime } from "../../../../../common/utils";
import { format, getISOWeek } from "date-fns";
import { Box, Grid, Stack, Text } from "@chakra-ui/react";
import { Log, LogType } from "../../../../../types";

type LogItemProps = {
  log: Log;
};

export const LogItem: React.FC<LogItemProps> = (props) => {
  const formatWeekday = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "EEEE");
  };

  const formatWeekNumber = (dateString: string) => {
    const date = new Date(dateString);
    return getISOWeek(date);
  };

  const formatLogType = (logType: LogType) => {
    switch (logType) {
      case LogType.ParticipationWithdrawn:
        return "Withdrawn from training";
      case LogType.JoinedReplacementEvent:
        return "Replacement training";
      case LogType.EventCancelled:
        return "Training cancelled";
      default:
        return "Unknown";
    }
  };

  const isRecurringTrainingEventMetadata = !!props.log.recurringTrainingEventMetadata;

  return (
    <Box padding="4" {...cardStyles({ disableClick: true })}>
      <Box fontWeight="medium" whiteSpace="pre-wrap" marginBottom="3">
        <Box as="dl">
          <Grid templateColumns="max-content 1fr" columnGap="4">
            <Text as="dt" color="blackAlpha.600">
              Event
            </Text>
            <Text as="dd" marginBottom="2">
              {formatLogType(props.log.type)}
            </Text>
            <Text as="dt" color="blackAlpha.600">
              Training date
            </Text>
            <Text as="dd">
              {props.log.type === LogType.EventCancelled && props.log.description
                ? `${formatWeekday(props.log.description || "")}, ${format(props.log.description || "", "dd.MM.yyyy")}, (week ${formatWeekNumber(props.log.description || "")})`
                : props.log.recurringTrainingEventMetadata &&
                  `${formatWeekday(props.log.recurringTrainingEventMetadata?.startDateTime || "")}, ${format(extractDateFromUTC(props.log.recurringTrainingEventMetadata?.startDateTime || ""), "dd.MM.yyyy")} (week ${formatWeekNumber(props.log.recurringTrainingEventMetadata?.startDateTime || "")})`}
            </Text>
            {props.log.type !== LogType.EventCancelled && (
              <>
                <Text as="dt" color="blackAlpha.600">
                  Training time
                </Text>
                {isRecurringTrainingEventMetadata ? (
                  <Text as="dd">
                    {extractFormattedTime(props.log.recurringTrainingEventMetadata?.startDateTime || "")} -{" "}
                    {extractFormattedTime(props.log.recurringTrainingEventMetadata?.endDateTime || "")}
                  </Text>
                ) : (
                  <Text as="dd">Unknown</Text>
                )}
                <Text as="dt" color="blackAlpha.600">
                  Training place
                </Text>
                {isRecurringTrainingEventMetadata ? (
                  <Text as="dd">{props.log.recurringTrainingEventMetadata?.court.name}</Text>
                ) : (
                  <Text as="dd">Unknown</Text>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Text fontSize="xs" color="blackAlpha.600">
          Automated message
        </Text>
        <Stack direction="row" spacing="4">
          <Text fontSize="xs" color="blackAlpha.600">
            {format(extractDateFromUTC(props.log.createdAt), "dd.MM.yyyy")}
          </Text>
          <Text fontSize="xs" color="blackAlpha.600">
            {extractFormattedTime(props.log.createdAt)}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
