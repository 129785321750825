import React, { useState } from "react";
import { Coach } from "../../../../types";
import { useAlert } from "../../../../common/components/AlertProvider";
import { sortNames } from "../../../../common/utils/dataProcessing";
import { Box, Grid, GridItem, Stack, Text, Tooltip } from "@chakra-ui/react";
import { FormModal } from "../../../../common/components/FormModal";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { CommonInput } from "../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";

type CoachesProps = {
  allCoaches: Coach[];
  saveCoachLoading: boolean;
  saveCoach: (data: any) => Promise<any>;
};

export const Coaches: React.FC<CoachesProps> = (props) => {
  const [selectedCoach, setSelectedCoach] = useState<Coach | null>(null);
  const [isNewCoach, setIsNewCoach] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showAlert } = useAlert();

  const handleOpenDialog = (coach: Coach) => {
    setSelectedCoach(coach);
    setIsDialogOpen(true);
  };

  const handleSave = async (remove: boolean) => {
    if (remove) {
      showAlert("Deleting Coach", "info", undefined, true);
    } else if (selectedCoach?.id) {
      showAlert("Updating Coach", "info", undefined, true);
    } else {
      showAlert("Creating new Coach", "info", undefined, true);
    }

    setIsNewCoach(false);
    setIsDialogOpen(false);

    const { __typename, ...dataToSave } = selectedCoach || {};
    const result = await props.saveCoach({ ...dataToSave, remove });
    if (result) {
      showAlert(remove ? "Coach deleted!" : selectedCoach?.id ? "Coach updated!" : "Coach created!", "success", 5000);
    }
  };

  const allCoachNames = props.allCoaches.map((coach) => coach.name.toLowerCase());
  const submitDisabledReasons = allCoachNames.includes(selectedCoach?.name.toLowerCase() || "")
    ? `Coach "${selectedCoach?.name}" already exists`
    : selectedCoach?.name.trim() === ""
      ? "Coach name cannot be empty"
      : undefined;

  const featurePurpose =
    "Coaches are essential for managing Training Groups and organizing events. Add Coaches for effective training management";

  return (
    <Box position="relative" paddingY="6" data-testid="coaches">
      <LoadingOverlay display={props.saveCoachLoading} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Coaches ({props.allCoaches.length})</Text>
        </Tooltip>
        <CommonIconButton
          height="36px"
          onClick={() => {
            setIsNewCoach(true);
            handleOpenDialog({ name: "" });
          }}
          dataTestId="add-coach"
        />
      </Stack>
      <CommonScrollbar
        maxHeight="30svh"
        overflow="auto"
        paddingLeft="6"
        paddingRight="2"
        invisibleBorderWidth="0px 8px 0px 0px"
      >
        <Grid
          templateColumns="repeat(auto-fit, minmax(calc(25% - 12px), calc(25% - 12px)))"
          gap="4"
          justifyContent="center"
        >
          {props.allCoaches.length === 0 ? (
            <GridItem colSpan={4}>
              <Text fontSize="large" textAlign="center" color="blackAlpha.600" data-testid="no-coaches">
                {featurePurpose}
              </Text>
            </GridItem>
          ) : (
            props.allCoaches
              .slice()
              .sort((a, b) => sortNames(a.name, b.name))
              .map((coach, index) => (
                <GridItem key={index} colSpan={1} data-testid="coach-grid">
                  <CommonButton
                    variantType="outlineSecondary"
                    fullWidth
                    onClick={() => handleOpenDialog(coach)}
                    dataTestId={`coach-${index}`}
                  >
                    <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {coach.name}
                    </Text>
                  </CommonButton>
                </GridItem>
              ))
          )}
        </Grid>
      </CommonScrollbar>
      <FormModal
        open={isDialogOpen}
        title={isNewCoach ? "Create new Coach" : "Edit Coach"}
        submitButtonText={isNewCoach ? "Create" : "Confirm"}
        handleSubmit={() => handleSave(false)}
        onClose={() => setIsDialogOpen(false)}
        handleRemove={() => handleSave(true)}
        confirmationDialogTitle="Delete Coach"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
      >
        <Grid templateColumns="repeat(2, 1fr)" data-testid="coach-modal">
          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Coach name"
              value={selectedCoach?.name || ""}
              onChange={(value) => setSelectedCoach({ ...selectedCoach, name: value })}
              dataTestId="coach-name"
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
