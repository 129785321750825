import { gql } from "@apollo/client";

export const GET_ALL_DRILLS = gql`
  query GetAllDrills {
    getAllDrills {
      goal
      id
      implementation
      name
      tags
      createdAt
      updatedAt
      createdBy {
        username
      }
      skillSets {
        id
        name
      }
    }
  }
`;
