import { gql } from "@apollo/client";

export const GET_ALL_ATHLETES = gql`
  query GetAllAthletes {
    getAllAthletes {
      id
      name
      trainingGroups {
        id
        name
        coaches {
          id
          name
        }
        recurrenceWeekday
        recurrenceStartTime
        recurrenceEndTime
        venue {
          id
          name
        }
        athletes {
          id
          name
        }
        trainingSeason {
          id
          name
        }
      }
      logs {
        id
        type
        description
        createdAt
        eventId
        eventStartDateTime
        eventEndDateTime
        eventVenue
        eventCenter
        eventTrainingGroupName
        eventCoaches
      }
    }
  }
`;
