import { gql } from "@apollo/client";

export const GET_ALL_ATHLETES_FOR_CALENDAR = gql`
  query GetAllAthletesForCalendar {
    getAllAthletes {
      id
      name
    }
  }
`;
