import React, { useState } from "react";
import { Stack, Text, useBreakpoint } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COGNITO_LOGIN } from "../graphql/cognito-login.mutation";
import { CommonButton } from "../../../common/components/CommonButton";
import { CommonInput } from "../../../common/components/CommonInput";
import { useAlert } from "../../../common/components/AlertProvider";
import { useAsyncMutation } from "../../../common/hooks";
import { LoadingOverlay } from "../../../common/components/LoadingOverlay";
import { AlertComponent } from "../../../common/components/AlertProvider/components/AlertComponent";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { OLD_LOGIN } from "../graphql/old-login.mutation";
import { parseToken } from "../utils";

type LoginProps = {
  setIsAuthenticated: (value: boolean) => void;
  setIsAdmin: (value: boolean) => void;
};

export const Login: React.FC<LoginProps> = (props) => {
  const [isAlertOpen, setIsAlertOpen] = useState(useBreakpoint({ ssr: false }) === "mobile");
  const { showAlert, hideAlert } = useAlert();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [getAllCenters] = useLazyQuery(GET_ALL_CENTERS, {
    fetchPolicy: "network-only",
  });

  const { execute: cognitoLogin, loading: cognitoLoginLoading } = useAsyncMutation(COGNITO_LOGIN, {
    onCustomError: (error) => {
      // If incorrect credentials for Cognito
      if (error.message === "Incorrect username or password.") {
        return "Log in failed, please check your credentials and try again";
      }
      return undefined;
    },
  });

  // Old login mutation
  const { execute: oldLogin, loading: oldLoginLoading } = useAsyncMutation(OLD_LOGIN, {
    onCustomError: (error) => {
      // If incorrect credentials for old login
      if (error.name === "ApolloError" && error.message !== "Failed to fetch") {
        return "Log in failed, please check your credentials and try again";
      }
      return undefined;
    },
  });

  const navigate = useNavigate();
  const { globalSelectedCenter, globalSetSelectedCenter, globalSetAllCenters } = useGlobalContext();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    showAlert("Logging in", "info", undefined, true);

    let result;
    let token = "";
    // Check if email looks like an actual email address
    if (email.includes("@")) {
      // Use Cognito login
      result = await cognitoLogin({ email, password });
      token = result?.cognitoLogin;
    } else {
      // Use old login
      result = await oldLogin({ username: email, password });
      token = result?.login;
    }

    if (result) {
      const { authStatus, adminStatus } = parseToken(token);
      // If login was successful
      if (authStatus) {
        localStorage.setItem("token", token);
        props.setIsAuthenticated(authStatus);
        props.setIsAdmin(adminStatus);
        if (!globalSelectedCenter) {
          try {
            const centerResponse = await getAllCenters();
            globalSetSelectedCenter(centerResponse.data.getAllCenters[0]);
            globalSetAllCenters?.(centerResponse.data.getAllCenters);
          } catch {
            // Fail silently if initial center fetch fails
          }
        }
        navigate("/");
      }

      hideAlert();
    }
  };

  return (
    <Stack
      position="relative"
      maxWidth="400px"
      margin={{ mobile: undefined, laptop: "auto" }}
      spacing="6"
      data-testid="login"
    >
      <LoadingOverlay display={cognitoLoginLoading || oldLoginLoading} spinnerSize="xl" spinnerTopPosition="50%" />
      <Text fontSize="x-large" align="center">
        Log In
      </Text>
      <form onSubmit={handleLogin}>
        <Stack spacing="4">
          <CommonInput
            placeholder="Username" // TODO: change to email once cognito is fully implemented
            type="text"
            value={email}
            onChange={(value) => setEmail(value)}
            disabled={cognitoLoginLoading || oldLoginLoading}
          />
          <CommonInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            disabled={cognitoLoginLoading || oldLoginLoading}
          />
          <CommonButton
            variantType="solidPrimary"
            fullWidth
            disabled={!email || !password || cognitoLoginLoading || oldLoginLoading}
            buttonType="submit"
          >
            Log In
          </CommonButton>
        </Stack>
      </form>
      {/* enable once ready for public launch <CommonButton
        variantType="outlineSecondary"
        onClick={() => navigate("/create-account")}
        disabled={cognitoLoginLoading || oldLoginLoading}
      >
        Register
      </CommonButton> */}

      {isAlertOpen && (
        <AlertComponent
          severity="info"
          message="To access all features, use Striveon on a laptop or larger screen"
          hideAlert={() => setIsAlertOpen(false)}
        />
      )}
    </Stack>
  );
};
