import React from "react";
import { Button, Icon, type ComponentWithAs, type IconProps } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

/** Props for the NavigationLink component */
export type NavigationLinkProps = {
  /** Path to link to */
  to: string;
  /** Icon for the link */
  icon: React.ReactElement<IconProps>;
  /** Primary text for the link */
  primaryText: string;
  /** Function to be called when the link is clicked */
  onClick?: () => void;
  /** Whether the link is disabled on mobile */
  isDisabledOnMobile?: boolean;
};

/** Component for a Navigation link */
export const NavigationLink: React.FC<NavigationLinkProps> = (props) => {
  const location = useLocation();

  return (
    <Button
      as={RouterLink}
      to={props.isDisabledOnMobile ? undefined : props.to}
      onClick={props.isDisabledOnMobile ? undefined : props.onClick}
      isDisabled={props.isDisabledOnMobile}
      leftIcon={props.icon}
      color={location.pathname === props.to ? "orange.400" : "white"}
      variant="link"
      size={{ mobile: "lg", laptop: "md" }}
      transition="color 0.2s ease"
      _hover={{ color: "orange.500" }}
      pointerEvents={props.isDisabledOnMobile ? "none" : "auto"}
      padding="0 !important"
    >
      {props.primaryText}
    </Button>
  );
};
