import { gql } from "@apollo/client";

export const GET_ALL_SKILL_SETS = gql`
  query GetAllSkillSets {
    getAllSkillSets {
      id
      name
      skills {
        id
        name
      }
    }
  }
`;
