import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { Box, Stack, Text } from "@chakra-ui/react";
import type { Club, Court } from "../../../../../types";
import { CommonEventContent } from "./components/CommonEventContent";
import { areDatesEqual } from "../../../utils";
import { getCommonCalendarProps } from "./utils";
import type { FullCalendarEvent } from "../../../types";
import { extractTimeFromUTC, sortNames } from "../../../../../common/utils";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import type { EventClickArg } from "fullcalendar";

/** Props for the CalendarBody component. */
export type CalendarBodyProps = {
  /** The active view of the calendar. */
  activeView: string;
  /** The date to display in the day view. */
  dayViewDate: Date;
  /** The events to be displayed in the calendar. */
  eventsToDisplay: FullCalendarEvent[];
  /** All courts available in the club. */
  allCourts: Court[];
  /** The globally selected club. */
  globalSelectedClub: Club | null;
  /** References to FullCalendar instances. */
  calendarRefs: React.MutableRefObject<(FullCalendar | null)[]>;
  /** Unique key for FullCalendar instances. */
  fullCalendarKey: number;
  /** Handler for when an event is clicked. */
  handleEventClick: (info: EventClickArg) => void;
  /** Handler for when a date is selected. */
  dateSelectHandler: (args: any, court: Court | null) => void;
  /** Function to show alerts. */
  showAlert: (message: string, type: "error" | "success" | "info" | "warning") => void;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
};

/**
 * CalendarBody component for displaying a calendar with events and courts, using pagination for performance
 * improvement.
 */
export const CalendarBody: React.FC<CalendarBodyProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;

  const paginatedCourts = props.allCourts
    .slice(1)
    .sort((a, b) => sortNames(a.name, b.name))
    .reverse()
    .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const totalPages = Math.ceil(props.allCourts.length / itemsPerPage);

  return (
    <Box>
      <Stack
        id="calendar-body"
        direction="row"
        spacing={0}
        display={!props.globalSelectedClub ? "none" : "flex"}
        overflowX="auto"
        __css={{ "& .fc": { minWidth: "300px !important" } }}
        data-testid="calendar-body"
      >
        <FullCalendar
          key={props.fullCalendarKey}
          initialView={props.isMobile ? "timeGridDay" : props.activeView}
          eventContent={(args) => <CommonEventContent {...args} />}
          views={{
            timeGridWeek: {
              dayHeaderContent: (args) => (
                <Box marginY={2}>
                  {args.date.toLocaleString("en-US", { weekday: "long" })}{" "}
                  {args.date.toLocaleString("fi-FI", { day: "numeric", month: "numeric" })}
                </Box>
              ),
              slotLabelContent: (args) => extractTimeFromUTC(args.date.toISOString()),
            },
            timeGridDay: {
              weekends: false,
              dayHeaders: true,
              weekNumbers: props.isMobile ? true : false,
              dayHeaderContent: () =>
                props.isMobile ? (
                  <Text marginY="1">All courts</Text>
                ) : (
                  props.globalSelectedClub?.courts[0]?.name ||
                  (props.globalSelectedClub
                    ? `No courts defined for ${props.globalSelectedClub.name}!`
                    : "No club selected!")
                ),
              slotLabelContent: (args) => extractTimeFromUTC(args.date.toISOString()),
            },
          }}
          {...getCommonCalendarProps(
            props.activeView === "timeGridDay" && !props.isMobile
              ? props.eventsToDisplay.filter(
                  (event) => event.court.id === props.allCourts[0]?.id && areDatesEqual(event.start, props.dayViewDate),
                )
              : props.eventsToDisplay.filter((event) =>
                  props.globalSelectedClub?.courts.some((court: Court) => court.id === event.court.id),
                ),
            0,
            props.allCourts[0]?.id ? props.allCourts[0] : null,
            props.dayViewDate,
            props.calendarRefs,
            props.handleEventClick,
            props.globalSelectedClub,
            props.showAlert,
            props.dateSelectHandler,
          )}
        />

        <Box display={{ mobile: "none", laptop: props.activeView === "timeGridDay" ? "flex" : "none" }}>
          {paginatedCourts.map((court, index) => (
            <FullCalendar
              key={props.fullCalendarKey + index}
              initialView="timeGridDay"
              eventContent={(args) => <CommonEventContent {...args} />}
              viewClassNames={["daily-view-fc-instance"]}
              views={{
                timeGridDay: {
                  weekends: false,
                  dayHeaders: true,
                  weekNumbers: false,
                  dayHeaderContent: () => <>{court.name}</>,
                },
              }}
              {...getCommonCalendarProps(
                props.eventsToDisplay.filter((event) => event.court.id === court.id),
                index + 1,
                court,
                props.dayViewDate,
                props.calendarRefs,
                props.handleEventClick,
                props.globalSelectedClub,
                props.showAlert,
                props.dateSelectHandler,
              )}
            />
          ))}
        </Box>
      </Stack>
      <Stack
        display={
          props.activeView === "timeGridDay" && !props.isMobile && props.allCourts.length > itemsPerPage + 1
            ? "flex"
            : "none"
        }
        direction="row"
        justify="center"
        spacing="4"
        marginTop="4"
      >
        <CommonButton
          variantType="outlineSecondary"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          disabled={currentPage === 0}
        >
          <ChevronLeftIcon boxSize="6" color="blackAlpha.800" />
          Previous {itemsPerPage + 1} Courts
        </CommonButton>
        <CommonButton
          variantType="outlineSecondary"
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
          disabled={currentPage >= totalPages - 1}
        >
          Next {itemsPerPage + 1} Courts
          <ChevronRightIcon boxSize="6" color="blackAlpha.800" />
        </CommonButton>
      </Stack>
    </Box>
  );
};
