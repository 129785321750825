import React, { useState } from "react";
import { useAlert } from "../../../../common/components/AlertProvider";
import { Divider, Grid, GridItem, Stack, Text, Tooltip } from "@chakra-ui/react";
import { FormModal } from "../../../../common/components/FormModal";
import { CommonInput } from "../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { CommonButton } from "../../../../common/components/CommonButton";
import type { Organization as OrganizationType, OrganizationInput } from "../../../../types";
import { CommonNumberInput } from "../../../../common/components/CommonNumberInput";
import { validateAndCheckChanges } from "../../../../common/utils/dataProcessing";

/** Props for the Organization component. */
type OrganizationProps = {
  organization: OrganizationType | null;
  updateOrganizationLoading: boolean;
  updateUserOrganizationSettings: (data: OrganizationInput) => Promise<any>;
};

/** A component for displaying and editing the organization's settings */
export const Organization: React.FC<OrganizationProps> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editField, setEditField] = useState<"name" | "expectedAthletes" | "evaluationScale" | null>(null);
  const [tempData, setTempData] = useState<OrganizationInput>({
    name: props.organization?.name,
    expectedAthletesPerTrainingEvent: props.organization?.expectedAthletesPerTrainingEvent,
    evaluationScaleMin: props.organization?.evaluationScaleMin,
    evaluationScaleMax: props.organization?.evaluationScaleMax,
  });
  const { showAlert } = useAlert();

  // Initial data to compare against when editing
  const initialData: OrganizationInput = {
    name: props.organization?.name,
    expectedAthletesPerTrainingEvent: props.organization?.expectedAthletesPerTrainingEvent,
    evaluationScaleMin: props.organization?.evaluationScaleMin,
    evaluationScaleMax: props.organization?.evaluationScaleMax,
  };

  /** Validation logic for organization settings. */
  const validationLogic = (current: OrganizationInput): string[] => {
    const reasons: string[] = [];

    if (editField === "name" && (!current.name || current.name.trim() === "")) {
      reasons.push("Organization name cannot be empty");
    }

    if (editField === "expectedAthletes" && (current.expectedAthletesPerTrainingEvent ?? 0) < 1) {
      reasons.push("Expected athletes per training event must be at least 1");
    }

    if (editField === "evaluationScale") {
      const min = current.evaluationScaleMin ?? 1;
      const max = current.evaluationScaleMax ?? 1;
      if (min < 1) {
        reasons.push("Evaluation scale min must be at least 1");
      }
      if (max > 1000) {
        reasons.push("Evaluation scale max cannot exceed 1000");
      }
      if (min >= max) {
        reasons.push("Evaluation scale min must be less than evaluation scale max");
      }
    }

    return reasons;
  };

  /** Compare logic to detect changes in Organization data. */
  const compareLogic = (current: OrganizationInput, initial: OrganizationInput): boolean => {
    return (
      current.name !== initial.name ||
      current.expectedAthletesPerTrainingEvent !== initial.expectedAthletesPerTrainingEvent ||
      current.evaluationScaleMin !== initial.evaluationScaleMin ||
      current.evaluationScaleMax !== initial.evaluationScaleMax
    );
  };

  const { submitDisabledReasons, hasChanges } = validateAndCheckChanges(
    tempData,
    validationLogic,
    initialData,
    compareLogic,
  );

  const handleSave = async (): Promise<void> => {
    showAlert("Updating organization settings", "info", undefined, true);
    setIsDialogOpen(false);

    const result = await props.updateUserOrganizationSettings(tempData);
    if (result) {
      showAlert("Organization settings updated!", "success", 5000);
    }
  };

  const handleOpenEdit = (field: "name" | "expectedAthletes" | "evaluationScale") => {
    setEditField(field);
    setTempData({
      name: props.organization?.name,
      expectedAthletesPerTrainingEvent: props.organization?.expectedAthletesPerTrainingEvent,
      evaluationScaleMin: props.organization?.evaluationScaleMin,
      evaluationScaleMax: props.organization?.evaluationScaleMax,
    });
    setIsDialogOpen(true);
  };

  const featurePurpose = "Manage and edit your organization's settings";

  const submitDisabledTooltip = submitDisabledReasons
    ? submitDisabledReasons
    : !hasChanges
      ? "No changes to save"
      : undefined;

  return (
    <Stack direction="column" spacing="6" position="relative" data-testid="organization-component">
      <LoadingOverlay display={props.updateOrganizationLoading} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" spacing="4" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Organization</Text>
        </Tooltip>
      </Stack>

      {!props.organization ? (
        <Text fontSize="large" color="blackAlpha.600" alignSelf="center">
          Couldn't load organization settings, try again by refreshing the page
        </Text>
      ) : (
        <Grid templateColumns="repeat(7, 1fr)" rowGap="4" alignItems="center">
          <GridItem colSpan={2} justifySelf="start">
            <Text fontSize="large" color="blackAlpha.600">
              Organization Name
            </Text>
          </GridItem>
          <GridItem colSpan={3} justifySelf="center">
            <Text fontSize="large">{props.organization?.name}</Text>
          </GridItem>
          <GridItem colSpan={2} justifySelf="end">
            <CommonButton
              onClick={() => handleOpenEdit("name")}
              variantType="outlineSecondary"
              dataTestId="edit-organization-name-button"
            >
              Edit
            </CommonButton>
          </GridItem>

          <GridItem colSpan={7}>
            <Divider />
          </GridItem>

          <GridItem colSpan={3} justifySelf="start">
            <Text fontSize="large" color="blackAlpha.600">
              Expected Athletes per training event
            </Text>
          </GridItem>
          <GridItem colSpan={1} justifySelf="center">
            <Text fontSize="large">{props.organization.expectedAthletesPerTrainingEvent}</Text>
          </GridItem>
          <GridItem colSpan={3} justifySelf="end">
            <CommonButton onClick={() => handleOpenEdit("expectedAthletes")} variantType="outlineSecondary">
              Edit
            </CommonButton>
          </GridItem>

          <GridItem colSpan={7}>
            <Divider />
          </GridItem>

          <GridItem colSpan={3} justifySelf="start">
            <Text fontSize="large" color="blackAlpha.600">
              Evaluation Scale
            </Text>
          </GridItem>
          <GridItem colSpan={1} justifySelf="center">
            <Text fontSize="large">{`${props.organization.evaluationScaleMin} - ${props.organization.evaluationScaleMax}`}</Text>
          </GridItem>
          <GridItem colSpan={3} justifySelf="end">
            <CommonButton onClick={() => handleOpenEdit("evaluationScale")} variantType="outlineSecondary">
              Edit
            </CommonButton>
          </GridItem>
        </Grid>
      )}

      <FormModal
        open={isDialogOpen}
        title={
          editField === "name"
            ? "Edit Organization Name"
            : editField === "expectedAthletes"
              ? "Edit Expected Athletes"
              : "Edit Evaluation Scale"
        }
        submitButtonText="Confirm"
        handleSubmit={handleSave}
        onClose={() => setIsDialogOpen(false)}
        submitDisabled={!!submitDisabledReasons || !hasChanges}
        submitButtonHoverText={submitDisabledTooltip}
      >
        <Stack direction="column" spacing="6">
          {editField === "name" && (
            <>
              <CommonInput
                placeholder="Organization name"
                value={tempData.name || ""}
                onChange={(value) => setTempData((prev) => ({ ...prev, name: value }))}
              />
              <Text color="blackAlpha.600">
                Enter the name of your organization (sports club, academy, team, training organization)
              </Text>
            </>
          )}

          {editField === "expectedAthletes" && (
            <>
              <CommonNumberInput
                placeholder="Expected athletes per training event"
                value={tempData.expectedAthletesPerTrainingEvent?.toString() ?? ""}
                min={1}
                onChange={(value) =>
                  setTempData((prev) => ({
                    ...prev,
                    expectedAthletesPerTrainingEvent: Number(value),
                  }))
                }
              />
              <Text color="blackAlpha.600">
                Define the ideal number of athletes per training event to mark it as full. This value helps filter
                events with available spots in the Calendar
              </Text>
            </>
          )}

          {editField === "evaluationScale" && (
            <>
              <Stack direction="row" spacing="4">
                <CommonNumberInput
                  placeholder="Evaluation range min"
                  min={1}
                  max={(tempData.evaluationScaleMax || 10) - 1}
                  value={tempData.evaluationScaleMin?.toString() ?? ""}
                  onChange={(value) => setTempData((prev) => ({ ...prev, evaluationScaleMin: Number(value) }))}
                />
                <CommonNumberInput
                  placeholder="Evaluation range max"
                  min={(tempData.evaluationScaleMin || 1) + 1}
                  max={1000}
                  value={tempData.evaluationScaleMax?.toString() ?? ""}
                  onChange={(value) => setTempData((prev) => ({ ...prev, evaluationScaleMax: Number(value) }))}
                />
              </Stack>
              <Text color="blackAlpha.600">Set the numerical range for evaluating Skills and Athlete performance</Text>
            </>
          )}
        </Stack>
      </FormModal>
    </Stack>
  );
};
