import { CheckIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { ButtonGroup, IconButton, useEditableControls } from "@chakra-ui/react";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../ConfirmationDialog";

/** Props for the EditableControls component. */
type EditableControlsProps = {
  onRemove: () => void;
  /** Determines whether the remove button should be displayed. */
  displayRemove: boolean;
  /** The title of the confirmation dialog. */
  confirmationTitle: string;
  /** The message of the confirmation dialog. */
  confirmationMessage: string;
  /** Determines whether the submit button should be disabled. */
  disableSubmit?: boolean;
};

/**
 * A component that provides editable controls, including a submit button, a cancel button, and optionally a remove
 * button.
 */
export const EditableControls: React.FC<EditableControlsProps> = (props) => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps } = useEditableControls();
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Handle the click event on the remove button. This function will confirm the remove operation from the user. If the
   * user confirms, it will call the submit button's onClick function. If the user cancels, it will call the cancel
   * button's onClick function.
   *
   * @param e - The click event.
   */
  const handleRemoveClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(true);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    props.onRemove();
  };

  return (
    <>
      {isEditing ? (
        <ButtonGroup alignSelf="center" size="sm" marginX="4">
          <IconButton
            aria-label="Cancel"
            icon={<CloseIcon />}
            {...getCancelButtonProps()}
            bgColor="whiteAlpha.900"
            border="1px solid"
            borderColor="blackAlpha.300"
            borderRadius="md"
            boxShadow="sm"
            transition="all 0.3s ease"
            _hover={{
              boxShadow: "none",
              borderColor: "orange.500",
              color: "orange.500",
            }}
          />
          {props.displayRemove ? (
            <IconButton
              isDisabled={props.disableSubmit}
              aria-label="Delete"
              icon={<DeleteIcon />}
              color="red"
              {...getSubmitButtonProps()}
              onClick={handleRemoveClick}
              bgColor="whiteAlpha.900"
              border="1px solid"
              borderColor="blackAlpha.300"
              borderRadius="md"
              boxShadow="sm"
              transition="all 0.3s ease"
              _hover={{
                boxShadow: "none",
                borderColor: "red",
                color: "red",
              }}
              data-testid="delete"
            />
          ) : (
            <IconButton
              isDisabled={props.disableSubmit}
              aria-label="Submit"
              icon={<CheckIcon />}
              {...getSubmitButtonProps()}
              bgColor="whiteAlpha.900"
              border="1px solid"
              borderColor="blackAlpha.300"
              borderRadius="md"
              boxShadow="sm"
              transition="all 0.3s ease"
              _hover={{
                boxShadow: "none",
                borderColor: "orange.500",
                color: "orange.500",
              }}
              data-testid="submit"
            />
          )}
        </ButtonGroup>
      ) : null}
      <ConfirmationDialog
        isOpen={isOpen}
        message={props.confirmationMessage}
        onCancel={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        onConfirm={handleConfirm}
        title={props.confirmationTitle}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
      />
    </>
  );
};
