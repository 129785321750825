import React from "react";
import { toUTCString } from "../../utils/dateAndTime";
import { timeStringToDate, dateToTimeString, generateTimeOptions } from "./utils";
import { CommonSelect } from "../CommonSelect";

/** Props for the disabled state of the TimePicker component */
type DisabledProps = {
  /** When set to true, the component is read-only */
  disabled: true;
  /** Selected time value */
  selectedTime: string | Date;
};

/** Props for the enabled state of the TimePicker component */
type EnabledProps = {
  /** Callback function for updating the selected time */
  setSelectedTime: (value: string | Date) => void;
  /** Selected time value */
  selectedTime: string | Date;
  /** When set to true, the component is read-only */
  disabled?: false;
};

/** Props for the TimePicker component */
type TimePickerProps = (DisabledProps | EnabledProps) & {
  /** Label of the component, e.g. "Start time" */
  label: string;
  /** Minimum selectable time */
  minTime?: string | Date;
  /** Maximum selectable time */
  maxTime?: string | Date;
};

/** TimePicker component allows the user to select a specific time from a list of predefined time options. */
export const TimePicker: React.FC<TimePickerProps> = (props) => {
  /**
   * Handles the change of selected time. Calls setSelectedTime with the new time if the component is not disabled.
   *
   * @param event - The event object
   */
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.disabled && props.setSelectedTime) {
      if (props.selectedTime instanceof Date) {
        const newDate = timeStringToDate(event.target.value, new Date(toUTCString(props.selectedTime)));
        props.setSelectedTime(newDate);
      } else {
        props.setSelectedTime(event.target.value);
      }
    }
  };

  const displayTime = props.selectedTime instanceof Date ? dateToTimeString(props.selectedTime) : props.selectedTime;

  const options = generateTimeOptions().map((time) => {
    let isDisabled = false;

    const timeValue = timeStringToDate(time, new Date(toUTCString(props.selectedTime)));
    const minTimeValue = props.minTime
      ? props.minTime instanceof Date
        ? props.minTime
        : timeStringToDate(props.minTime, new Date(toUTCString(props.selectedTime)))
      : null;
    const maxTimeValue = props.maxTime
      ? props.maxTime instanceof Date
        ? props.maxTime
        : timeStringToDate(props.maxTime, new Date(toUTCString(props.selectedTime)))
      : null;

    if (minTimeValue && timeValue < minTimeValue) {
      isDisabled = true;
    }
    if (maxTimeValue && timeValue > maxTimeValue) {
      isDisabled = true;
    }

    return { value: time, label: time, disabled: isDisabled };
  });

  return (
    <CommonSelect
      placeholder={props.label}
      value={displayTime}
      onChange={handleChange}
      isDisabled={props.disabled}
      options={options}
    />
  );
};
