import React, { useEffect, useRef, useState } from "react";
import { Box, BoxProps, useBreakpoint } from "@chakra-ui/react";
import { OverflowIndicator } from "./components/OverflowIndicator";

type CommonScrollbarProps = BoxProps & {
  /** Child elements to display inside the scrollable container */
  children: React.ReactNode;
  /** Color of the scrollbar border, defaults to white */
  scrollbarBorderColor?: string;
  /** Customize the invisible border of thumb, instead of default left 8px */
  invisibleBorderWidth?: string;
  /** If the component is used as a main (root) component */
  isMainContainer?: boolean;
};

/**
 * CustomScrollbar component adds a stylized scrollbar to its children, and displays OverflowIndicator on hover. It
 * extends BoxProps, allowing Chakra UI Box styling properties.
 */
export const CommonScrollbar: React.FC<CommonScrollbarProps> = ({
  children,
  scrollbarBorderColor,
  invisibleBorderWidth,
  isMainContainer,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const updateOverflow = () => {
      const hasOverflow = container.scrollHeight > container.clientHeight;
      setIsOverflowing(hasOverflow);
    };

    updateOverflow(); // Initial check on mount

    const resizeObserver = new ResizeObserver(() => {
      updateOverflow();
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const currentBreakPoint = useBreakpoint({ ssr: false });

  return currentBreakPoint === "mobile" ? (
    isMainContainer ? (
      // mobile main container
      <Box
        as="main"
        display="flex"
        flexDirection="column"
        position="fixed"
        top="56px"
        width="100%"
        height="calc(100% - 56px)"
        overflow="auto"
        paddingX="4"
        paddingY="6"
      >
        {children}
      </Box>
    ) : (
      // mobile component container
      children
    )
  ) : (
    // laptop
    <Box
      ref={containerRef}
      _hover={
        isMainContainer
          ? undefined
          : {
              ".overflowIcon": {
                opacity: 0.25,
              },
            }
      }
      css={{
        scrollbarGutter: "stable",
        "&::-webkit-scrollbar": {
          width: "16px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#00000029", // blackAlpha.300
          borderStyle: "solid",
          borderWidth: invisibleBorderWidth || "0px",
          borderColor: scrollbarBorderColor || "white",
          backgroundClip: "padding-box",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#0000007A", // blackAlpha.600
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "inherit",
        },
      }}
      {...props}
    >
      {children}
      {!isMainContainer && <OverflowIndicator containerRef={containerRef} />}
    </Box>
  );
};
