import { gql } from "@apollo/client";

export const GET_ALL_SINGLE_TRAINING_EVENTS = gql`
  query GetAllSingleTrainingEvents {
    getAllSingleTrainingEvents {
      id
      startDateTime
      endDateTime
      coaches {
        id
        name
      }
      venue {
        id
        name
      }
      athletes {
        id
        name
      }
    }
  }
`;
