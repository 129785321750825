import { gql } from "@apollo/client";

export const GET_ALL_PLAYERS = gql`
  query GetAllPlayers {
    getAllPlayers {
      id
      name
      recurringTrainingEvents {
        id
        name
        coaches {
          id
          name
        }
        recurrenceWeekday
        recurrenceStartTime
        recurrenceEndTime
        court {
          id
          name
        }
        players {
          id
          name
        }
      }
      notes {
        id
        content
        createdAt
        isGroupNote
        createdBy {
          id
          username
        }
        recurringTrainingEventMetadata {
          id
          startDateTime
          endDateTime
          court {
            id
            name
          }
          coaches {
            id
            name
          }
          players {
            id
            name
          }
        }
        singleTrainingEvent {
          id
          startDateTime
          endDateTime
          court {
            id
            name
          }
          coaches {
            id
            name
          }
          players {
            id
            name
          }
        }
      }
      logs {
        id
        type
        description
        createdAt
        recurringTrainingEventMetadata {
          startDateTime
          endDateTime
          court {
            name
          }
        }
      }
      evaluation {
        id
        skillSetEvaluations {
          id
          rating
          comment
          skillSet {
            id
            name
          }
          skillEvaluations {
            id
            rating
            skill {
              id
              name
            }
          }
        }
      }
    }
  }
`;
