import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LOGIN } from "./login.mutation";
import { GET_ALL_CLUBS } from "../../common/graphql/get-all-clubs.query";
import { useGlobalContext } from "../../common/components/GlobalProvider";
import { CircularProgress, Input, Stack, Text } from "@chakra-ui/react";
import { CommonButton } from "../../common/components/CommonButton";
import { useAlert } from "../../common/components/AlertProvider";
import { CommonInput } from "../../common/components/CommonInput";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [login, { loading: loginLoading, error: loginError }] = useMutation(LOGIN);
  const [getAllClubs] = useLazyQuery(GET_ALL_CLUBS, {
    fetchPolicy: "network-only",
  });
  const { globalSelectedClub, globalSetAllClubs, globalSetSelectedClub } = useGlobalContext();
  const { showAlert, hideAlert } = useAlert();

  useEffect(() => {
    if (loginError) {
      const errors = [loginError].filter(Boolean);
      if (errors.length) {
        showAlert("Login failed, please check your credentials and try again", "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [loginError]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await login({ variables: { username, password } });
      const token = response.data.login;
      if (token) {
        localStorage.setItem("token", token);
        if (!globalSelectedClub) {
          try {
            const clubResponse = await getAllClubs();
            globalSetAllClubs(clubResponse.data.getAllClubs);
            globalSetSelectedClub(clubResponse.data.getAllClubs[0]);
          } catch {
            // fail silently potential error of initial club fetch
          }
        }
        window.location.href = "/";
      }
    } catch (err) {
      // catch silently and let the error handling in the useEffect above handle it
    }
  };

  return (
    <Stack padding="0" spacing="6" maxWidth="400px" margin="auto" marginTop="16" data-testid="login">
      <Text fontSize="x-large" align="center">
        Striveon
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing="4" marginBottom="6">
          <CommonInput
            placeholder="Username"
            value={username}
            onChange={(value) => setUsername(value)}
            disabled={loginLoading}
          />
          <CommonInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            disabled={loginLoading}
          />
        </Stack>
        {loginLoading ? (
          <Stack direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <CommonButton
            variantType="solidPrimary"
            fullWidth
            disabled={!username || !password || loginLoading}
            buttonType="submit"
          >
            Login
          </CommonButton>
        )}
      </form>
    </Stack>
  );
};
