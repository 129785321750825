import { gql } from "@apollo/client";

export const GET_ALL_CLUBS = gql`
  query GetAllClubs {
    getAllClubs {
      id
      name
      courts {
        id
        name
      }
    }
  }
`;
