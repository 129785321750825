import React, { useState } from "react";
import { OrganizationUser } from "../../../../types";
import { Stack, Grid, GridItem } from "@chakra-ui/react";
import { Autocomplete } from "../../../../common/components/AutoComplete";
import { CommonButton } from "../../../../common/components/CommonButton";
import { FormModal } from "../../../../common/components/FormModal";
import { CommonInput } from "../../../../common/components/CommonInput";

type OrganizationUsersProps = {
  organizationUsers: OrganizationUser[];
  onUserAndPasswordChange: (username: string, password: string) => void;
};

export const OrganizationUsers: React.FC<OrganizationUsersProps> = (props) => {
  const [selectedUser, setSelectedUser] = useState<OrganizationUser | null>(null);
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleUserChange = (newUser: OrganizationUser) => {
    setSelectedUser(newUser);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    setNewUsername("");
    setNewPassword("");
  };

  const handleCreateNewUser = () => {
    if (newUsername.trim() && newPassword.trim()) {
      props.onUserAndPasswordChange(newUsername, newPassword);
      setSelectedUser({ username: newUsername, id: undefined });
      setIsDialogOpen(false);
    } else {
      alert("Both username and password are required.");
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Autocomplete
        initialOption={selectedUser}
        allOptions={props.organizationUsers}
        handleOptionChange={(option: OrganizationUser) => handleUserChange(option)}
        getOptionLabel={(option: OrganizationUser) => option.username}
      />
      <CommonButton variantType="outlineSecondary" onClick={handleOpenDialog}>
        Create new User for Organization
      </CommonButton>

      <FormModal
        open={isDialogOpen}
        title="Create new User"
        submitButtonText="Create"
        handleSubmit={handleCreateNewUser}
        onClose={handleCloseDialog}
        submitDisabled={!newUsername.trim() || !newPassword.trim()}
      >
        <Grid templateColumns="repeat(2, 1fr)" gap="4">
          <GridItem colSpan={2}>
            <CommonInput placeholder="Username" value={newUsername} onChange={(value) => setNewUsername(value)} />
          </GridItem>
          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Password"
              type="password"
              value={newPassword}
              onChange={(value) => setNewPassword(value)}
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Stack>
  );
};
