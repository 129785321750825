import React from "react";
import { Text } from "@chakra-ui/react";
import { CommonButton } from "../../../../../../common/components/CommonButton";

type CalendarWeekViewButtonProps = {
  activeView: string;
  setActiveView: (view: string) => void;
  setFullCalendarKey: (keySetter: (prevKey: number) => number) => void;
};

export const CalendarWeekViewButton: React.FC<CalendarWeekViewButtonProps> = (props) => {
  return (
    <CommonButton
      variantType="outlineSecondary"
      aria-label="week-view"
      onClick={() => {
        props.setActiveView("timeGridWeek");
        props.setFullCalendarKey((prevKey) => prevKey + Math.random());
      }}
    >
      <Text fontSize="lg" paddingY="2" minWidth="55px" /* equivalent of "Month" text */>
        Week
      </Text>
    </CommonButton>
  );
};
