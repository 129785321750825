import React, { useEffect, useState } from "react";
import { Club } from "../../../../types";
import { Courts } from "./components/Courts";
import { useAlert } from "../../../../common/components/AlertProvider";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { FormModal } from "../../../../common/components/FormModal";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { CommonInput } from "../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { useGlobalContext } from "../../../../common/components/GlobalProvider";
import type { ClubInput, CourtInput } from "../../containers/SettingsContainer";
import { ComponentContainer } from "../../../../common/components/ComponentContainer";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";

type ClubsProps = {
  allClubs: Club[];
  saveClubLoading: boolean;
  saveCourtLoading: boolean;
  saveClub: (data: ClubInput) => Promise<void>;
  saveCourt: (data: CourtInput, clubId: string) => Promise<void>;
};

export const Clubs: React.FC<ClubsProps> = (props) => {
  const { globalAllClubs, globalSelectedClub, globalSetAllClubs, globalSetSelectedClub } = useGlobalContext();
  const [selectedClub, setSelectedClub] = useState<Club | null>(globalSelectedClub);
  const [isNewClub, setIsNewClub] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeClub, setActiveClub] = useState<Club | null>(selectedClub);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (props.allClubs.length > 0) {
      globalSetAllClubs(props.allClubs);
    }
  }, [props.allClubs, globalSetAllClubs]);

  useEffect(() => {
    setSelectedClub(globalSelectedClub);
    setActiveClub(globalSelectedClub);
  }, [globalSelectedClub]);

  const handleOpenDialog = (club: Club) => {
    setActiveClub(club);
    setIsDialogOpen(true);
  };

  const handleSaveClub = async (remove: boolean) => {
    if (!activeClub) return;
    if (remove) {
      showAlert("Deleting Club", "info", undefined, true);
    } else if (activeClub?.id) {
      showAlert("Updating Club", "info", undefined, true);
    } else {
      showAlert("Creating new Club", "info", undefined, true);
    }
    setIsNewClub(false);
    setIsDialogOpen(false);

    const { __typename, ...clubToSave } = activeClub;
    const courtsToSave = activeClub.courts?.map((court) => {
      const { __typename, ...rest } = court;
      return rest;
    });

    const dataToSave: ClubInput = {
      ...clubToSave,
      courts: courtsToSave,
      remove: remove,
    };
    await props.saveClub(dataToSave);

    if (remove) {
      if (activeClub?.id === globalSelectedClub?.id) {
        globalSetSelectedClub(null);
      }
      showAlert("Club deleted!", "success", 5000);
    } else {
      if (!activeClub?.id && !globalSelectedClub) {
        globalSetSelectedClub(activeClub);
      }
      showAlert(activeClub?.id ? "Club updated!" : "Club created!", "success", 5000);
    }
  };

  const handleSetActiveClub = () => {
    if (activeClub) {
      globalSetSelectedClub(activeClub);
    }
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    handleSaveClub(false);
  };

  const handleClose = () => {
    setIsNewClub(false);
    setIsDialogOpen(false);
  };

  const handleAddNew = () => {
    setIsNewClub(true);
    const newClub: Club = { name: "", courts: [] };
    setActiveClub(newClub);
    handleOpenDialog(newClub);
  };

  const getActivateClubTooltip = () => {
    if (activeClub?.id === globalSelectedClub?.id) {
      return "This club is already active";
    } else if (isNewClub) {
      return "This club needs to be created first before it can be set as active";
    }
    return "Setting this club as active will display its courts and related information";
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const clubNames = props.allClubs.map((club) => club.name.toLowerCase());
    if (clubNames.includes(activeClub?.name.toLowerCase() || "")) {
      return `Club "${activeClub?.name}" already exists`;
    } else if (activeClub?.name.trim() === "") {
      return "Club name cannot be empty";
    }
    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  return (
    <Box id="clubs">
      <Grid templateColumns="repeat(2, 1fr)" gap="8">
        {/* Clubs */}
        <GridItem position="relative" colSpan={1} data-testid="clubs">
          <ComponentContainer>
            <CommonScrollbar height="50svh" overflow="auto">
              <LoadingOverlay display={props.saveClubLoading} spinnerSize="xl" spinnerTopPosition="40px" />
              <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
                <Text fontSize="x-large">Clubs ({props.allClubs.length})</Text>
                <CommonIconButton height="36px" onClick={handleAddNew} dataTestId="add-club" />
              </Stack>
              <Grid
                templateColumns="repeat(auto-fit, minmax(calc(25% - 12px), calc(25% - 12px)))"
                gap="4"
                justifyContent="center"
              >
                {props.allClubs.length === 0 ? (
                  <Text fontSize="large" textAlign="center" data-testid="no-clubs">
                    No clubs
                  </Text>
                ) : (
                  props.allClubs.map((club, index) => (
                    <GridItem key={index} colSpan={1} data-testid="club-grid">
                      <CommonButton
                        fullWidth
                        variantType={club.id === selectedClub?.id ? "solidPrimary" : "outlineSecondary"}
                        disableHover={club.id === selectedClub?.id}
                        onClick={() => handleOpenDialog(club)}
                        dataTestId={`club-${index}`}
                      >
                        <Text
                          fontSize="medium"
                          color={club.id === selectedClub?.id ? "white" : "black"}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {club.name}
                        </Text>
                      </CommonButton>
                    </GridItem>
                  ))
                )}
              </Grid>
            </CommonScrollbar>
          </ComponentContainer>
        </GridItem>

        {/* Courts */}
        <GridItem colSpan={1}>
          <ComponentContainer>
            <CommonScrollbar height="50svh" overflow="auto">
              {selectedClub && selectedClub.id ? (
                <Courts
                  key={selectedClub.id}
                  title={`${selectedClub.name} courts (${selectedClub.courts?.length || 0})`}
                  allCourts={selectedClub.courts || []}
                  clubId={selectedClub.id}
                  isLoading={props.saveCourtLoading}
                  saveCourt={props.saveCourt}
                />
              ) : (
                <Text fontSize="x-large" textAlign="center" data-testid="courts-fallback">
                  {globalAllClubs.length === 0 ? "Create club to add courts" : "Select club to view courts"}
                </Text>
              )}
            </CommonScrollbar>
          </ComponentContainer>
        </GridItem>

        <FormModal
          handleSubmit={handleSubmit}
          open={isDialogOpen}
          submitButtonText={isNewClub ? "Create" : "Confirm"}
          title={isNewClub ? "Create new Club" : "Edit Club"}
          onClose={handleClose}
          handleRemove={() => handleSaveClub(true)}
          submitDisabled={!!submitDisabledReasons}
          submitButtonHoverText={submitDisabledReasons}
          removeDisabled={!activeClub?.id}
          removeDisabledReason="Club isn't created yet"
        >
          <Grid templateColumns="repeat(2, 1fr)" gap="4" marginTop="4" data-testid="club-modal">
            <GridItem colSpan={2}>
              <CommonButton
                variantType="outlineSecondary"
                fullWidth
                onClick={handleSetActiveClub}
                tooltip={getActivateClubTooltip()}
                disabled={activeClub?.id === globalSelectedClub?.id || isNewClub}
                dataTestId="set-active-club-button"
              >
                Set as the active club
              </CommonButton>
            </GridItem>
            <GridItem colSpan={2}>
              <CommonInput
                placeholder="Club name"
                value={activeClub?.name || ""}
                onChange={(value) => {
                  setActiveClub({ ...activeClub!, name: value });
                }}
                dataTestId="club-name"
              />
            </GridItem>
          </Grid>
        </FormModal>
      </Grid>
    </Box>
  );
};
