import { gql } from "@apollo/client";

export const GET_ALL_RECURRING_TRAINING_EVENTS_FOR_SEASON_PLAN = gql`
  query GetAllRecurringTrainingEventsForSeasonPlan {
    getAllRecurringTrainingEvents {
      id
      name
      players {
        id
        name
      }
    }
  }
`;
