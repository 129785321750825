import React from "react";
import Striveon from "../../assets/StriveonCroppedPadding.png";
import { useGlobalContext } from "../../common/components/GlobalProvider";
import {
  AdminPanelSettings,
  Assignment,
  Event,
  LocalLibrary,
  Logout,
  Person,
  Settings,
  Timeline,
} from "@mui/icons-material";
import { NavigationLink, type NavigationLinkProps } from "./components";
import { Card, Divider, Icon, IconButton, Stack, Text } from "@chakra-ui/react";
import { CommonSelect } from "../../common/components/CommonSelect";

/** Array of links to display */
export const links: (NavigationLinkProps & { restricted?: boolean })[] = [
  {
    to: "/",
    icon: <Icon as={Event} />,
    primaryText: "Calendar",
  },
  {
    to: "/season-plan",
    icon: <Icon as={Timeline} />,
    primaryText: "Season Plan",
    isDisabledOnMobile: true,
  },
  {
    to: "/skill-sets",
    icon: <Icon as={Assignment} />,
    primaryText: "Skill Sets",
    isDisabledOnMobile: true,
  },
  {
    to: "/training-library",
    icon: <Icon as={LocalLibrary} />,
    primaryText: "Training Library",
    isDisabledOnMobile: true,
  },
  {
    to: "/players",
    icon: <Icon as={Person} />,
    primaryText: "Players",
    isDisabledOnMobile: true,
  },
  {
    to: "/settings",
    icon: <Icon as={Settings} />,
    primaryText: "Settings",
    isDisabledOnMobile: true,
  },
  {
    to: "/admin",
    icon: <Icon as={AdminPanelSettings} color="red" />,
    primaryText: "Admin",
    isDisabledOnMobile: true,
    restricted: true,
  },
];

export type HeaderProps = {
  isAdmin: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { globalAllClubs, globalSelectedClub, globalSetSelectedClub } = useGlobalContext();

  const isAuthenticated = localStorage.getItem("token") !== null;

  const handleClubChange = (event: any) => {
    const selectedClub = globalAllClubs.find((club) => club.id === event.target.value);
    if (selectedClub) {
      globalSetSelectedClub(selectedClub);
    } else {
      globalSetSelectedClub(null);
    }
  };

  return (
    <Card
      zIndex={11}
      position="fixed"
      width="100%"
      direction="row"
      alignContent="center"
      justifyContent="center"
      bgColor="teal.800"
      paddingY="2"
      paddingX="8"
      variant="outline"
      border={0}
      borderRadius={0}
      data-testid="header"
    >
      <img src={Striveon} width="40px" height="40px" style={{ borderRadius: "8px" }} />
      <Text fontSize="2xl" fontWeight="bold" marginLeft="2" color="whiteAlpha.900">
        Striveon
      </Text>
      <Stack direction="row" alignItems="center" justifyContent="center" flex={1} spacing="6">
        {links.map((link, index) => (
          <React.Fragment key={link.to}>
            {(!link.restricted || (link.restricted && props.isAdmin)) && (
              <NavigationLink to={link.to} icon={link.icon} primaryText={link.primaryText} />
            )}
            {index < links.length - (props.isAdmin ? 1 : 2) && <Divider orientation="vertical" />}
          </React.Fragment>
        ))}
      </Stack>
      {globalSelectedClub ? (
        <CommonSelect
          value={globalSelectedClub?.id || ""}
          options={globalAllClubs.map((club) => ({ value: club.id || "", label: club.name }))}
          onChange={handleClubChange}
          width="auto"
          maxWidth="60"
          color="whiteAlpha.900"
          sx={{ borderColor: "whiteAlpha.900" }}
        />
      ) : (
        isAuthenticated && (
          <Text fontSize="md" color="whiteAlpha.900" alignContent="center">
            Select club from Settings
          </Text>
        )
      )}
      <IconButton
        aria-label="logout"
        icon={<Logout />}
        variant="ghost"
        color="whiteAlpha.900"
        marginLeft="6"
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
        transition="all 0.3s ease"
        _hover={{ color: "orange.500" }}
      />
    </Card>
  );
};
