import { gql } from "@apollo/client";

export const SAVE_ORGANIZATION = gql`
  mutation SaveOrganization($data: OrganizationInput!) {
    saveOrganization(data: $data) {
      id
      name
    }
  }
`;
