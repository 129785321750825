import { gql } from "@apollo/client";

export const GET_ALL_RECURRING_TRAINING_EVENTS = gql`
  query GetAllRecurringTrainingEvents {
    getAllRecurringTrainingEvents {
      id
      name
      recurrenceWeekday
      recurrenceStartTime
      recurrenceEndTime
      court {
        id
        name
        club {
          id
          name
        }
      }
      players {
        id
        name
      }
      coaches {
        id
        name
      }
    }
  }
`;
