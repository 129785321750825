import React, { useState } from "react";
import { Court } from "../../../../../types";
import { useAlert } from "../../../../../common/components/AlertProvider";
import { sortNames } from "../../../../../common/utils";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { FormModal } from "../../../../../common/components/FormModal";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { CommonIconButton } from "../../../../../common/components/CommonIconButton";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../../common/components/LoadingOverlay";
import type { CourtInput } from "../../../containers/SettingsContainer";

type CourtsProps = {
  title: string;
  allCourts: Court[];
  clubId: string;
  isLoading: boolean;
  saveCourt: (data: CourtInput, clubId: string) => Promise<void>;
};

// TODO: court deletion also deletes training groups, their events, and single training events
// make a list which shows all training groups and single training events that will be deleted

export const Courts: React.FC<CourtsProps> = (props) => {
  const [selectedCourt, setSelectedCourt] = useState<Court | null>(null);
  const [isNewCourt, setIsNewCourt] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { showAlert } = useAlert();

  const handleOpenDialog = (court: Court) => {
    setSelectedCourt(court);
    setIsDialogOpen(true);
  };

  const handleSaveCourt = async (remove: boolean) => {
    if (!selectedCourt) return;
    if (remove) {
      showAlert("Deleting Court", "info", undefined, true);
    } else if (selectedCourt?.id) {
      showAlert("Updating Court", "info", undefined, true);
    } else {
      showAlert("Creating new Court", "info", undefined, true);
    }

    setIsNewCourt(false);
    setIsDialogOpen(false);

    const { __typename, ...dataToSave } = selectedCourt;
    await props.saveCourt({ ...dataToSave, remove }, props.clubId);
    showAlert(remove ? "Court deleted!" : selectedCourt?.id ? "Court updated!" : "Court created!", "success", 5000);
  };

  const handleSubmit = () => {
    handleSaveCourt(false);
  };

  const handleRemove = () => {
    handleSaveCourt(true);
  };

  const handleClose = () => {
    setIsNewCourt(false);
    setIsDialogOpen(false);
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const courtNames = props.allCourts.map((court) => court.name.toLowerCase());
    if (courtNames.includes(selectedCourt?.name.toLowerCase() || "")) {
      return `Court "${selectedCourt?.name}" already exists`;
    } else if (selectedCourt?.name.trim() === "") {
      return "Court name cannot be empty";
    }
    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  return (
    <Box position="relative" data-testid="courts">
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Text fontSize="x-large">{props.title}</Text>
        <CommonIconButton
          height="36px"
          onClick={() => {
            setIsNewCourt(true);
            const newCourt: Court = { name: "" };
            handleOpenDialog(newCourt);
          }}
          dataTestId="add-court"
        />
      </Stack>
      <Grid
        templateColumns="repeat(auto-fit, minmax(calc(25% - 12px), calc(25% - 12px)))"
        gap="4"
        justifyContent="center"
      >
        {props.allCourts.length === 0 ? (
          <Text fontSize="large" textAlign="center" data-testid="no-courts">
            No courts
          </Text>
        ) : (
          props.allCourts
            .slice()
            .sort((a, b) => sortNames(a.name, b.name))
            .map((court, index) => (
              <GridItem key={index} colSpan={1} data-testid="court-grid">
                <CommonButton
                  variantType="outlineSecondary"
                  fullWidth
                  onClick={() => handleOpenDialog(court)}
                  dataTestId={`court-${index}`}
                >
                  <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {court.name}
                  </Text>
                </CommonButton>
              </GridItem>
            ))
        )}
      </Grid>
      <FormModal
        key={selectedCourt?.id}
        handleSubmit={handleSubmit}
        open={isDialogOpen}
        submitButtonText={isNewCourt ? "Create" : "Confirm"}
        title={isNewCourt ? "Create new Court" : "Edit Court"}
        onClose={handleClose}
        handleRemove={handleRemove}
        confirmationDialogTitle="Delete Court"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
      >
        <Grid templateColumns="repeat(2, 1fr)" data-testid="court-modal">
          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Court name"
              value={selectedCourt?.name || ""}
              onChange={(value) => {
                setSelectedCourt({ ...selectedCourt!, name: value });
              }}
              dataTestId="court-name"
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
