import { gql } from "@apollo/client";

export const GET_ALL_TRAINING_GROUPS_FOR_SEASON_PLAN = gql`
  query GetAllTrainingGroupsForSeasonPlan {
    getAllTrainingGroups {
      id
      name
      athletes {
        id
        name
      }
    }
  }
`;
