import { gql } from "@apollo/client";

export const GET_CLUB = gql`
  query GetClub($id: String!) {
    getClub(id: $id) {
      id
      name
      courts {
        id
        name
      }
    }
  }
`;
