import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { MultiTagSelectMenu } from "../../../../../common/components/MultiTagSelectMenu";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { Coach, Venue, Athlete, TrainingSeason } from "../../../../../types";
import { weekdayOptions } from "../../../../../common/utils/dataProcessing";

type TrainingGroupsFilterOptionsProps = {
  allTrainingSeasons: TrainingSeason[];
  selectedTrainingSeasons: TrainingSeason[];
  setSelectedTrainingSeasons: (seasons: TrainingSeason[]) => void;

  allCoaches: Coach[];
  selectedCoaches: Coach[];
  setSelectedCoaches: (coaches: Coach[]) => void;

  allAthletes: Athlete[];
  selectedAthletes: Athlete[];
  setSelectedAthletes: (athletes: Athlete[]) => void;

  allVenues: Venue[];
  selectedVenues: Venue[];
  setSelectedVenues: (venues: Venue[]) => void;

  selectedWeekdays: number[];
  setSelectedWeekdays: (weekdays: number[]) => void;

  searchQuery: string;
  setSearchQuery: (query: string) => void;
};

export const TrainingGroupsFilterOptions: React.FC<TrainingGroupsFilterOptionsProps> = (props) => {
  const handleSearchChange = (value: string) => {
    props.setSearchQuery(value);
  };

  return (
    <Box id="training-groups-filter-options" data-testid="training-groups-filter-options" paddingX="6" marginBottom="6">
      <Grid templateColumns="repeat(6, 1fr)" gap={4}>
        <GridItem colSpan={1}>
          <MultiTagSelectMenu
            selectedTags={props.selectedTrainingSeasons}
            handleTagSelection={props.setSelectedTrainingSeasons}
            label="Filter by Training Seasons"
            options={props.allTrainingSeasons}
            getOptionLabel={(option) => `${option.name}  (${option.status})`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            dataTestId="training-season-filter"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <CommonInput
            placeholder="Filter by Group Name"
            value={props.searchQuery}
            onChange={handleSearchChange}
            dataTestId="search-training-groups"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <MultiTagSelectMenu
            selectedTags={weekdayOptions.filter((weekday) => props.selectedWeekdays.includes(weekday.value))}
            handleTagSelection={(selectedWeekdays) =>
              props.setSelectedWeekdays(selectedWeekdays.map((weekday) => weekday.value))
            }
            label="Filter by Weekdays"
            options={weekdayOptions.map((weekday) => ({
              value: weekday.value,
              label: weekday.label,
            }))}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            dataTestId="weekday-filter"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <MultiTagSelectMenu
            selectedTags={props.selectedVenues}
            handleTagSelection={props.setSelectedVenues}
            label="Filter by Venues"
            options={props.allVenues}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            dataTestId="venue-filter"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <MultiTagSelectMenu
            selectedTags={props.selectedCoaches}
            handleTagSelection={props.setSelectedCoaches}
            label="Filter by Coaches"
            options={props.allCoaches}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            dataTestId="coach-filter"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <MultiTagSelectMenu
            selectedTags={props.selectedAthletes}
            handleTagSelection={props.setSelectedAthletes}
            label="Filter by Athletes"
            options={props.allAthletes}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            dataTestId="athlete-filter"
          />
        </GridItem>
      </Grid>
    </Box>
  );
};
