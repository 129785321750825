import { gql } from "@apollo/client";

export const GET_SINGLE_TRAINING_EVENT = gql`
  query GetSingleTrainingEvent($id: String!) {
    getSingleTrainingEvent(id: $id) {
      id
      startDateTime
      endDateTime
      coaches {
        id
        name
      }
      venue {
        id
        name
      }
      drills {
        goal
        id
        implementation
        name
        tags
      }
      athletes {
        id
        name
      }
      notes {
        id
        isGroupNote
        content
        athletes {
          id
        }
      }
      session {
        id
        name
        goal
        totalDuration
        tags
        sessionDrills {
          id
          duration
          order
          drill {
            id
            name
            implementation
            goal
            tags
          }
        }
      }
    }
  }
`;
