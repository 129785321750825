import React, { createContext, useContext, useEffect, useState } from "react";
import { Club } from "../../../types";
import { sortNames } from "../../utils";

/** Type definition for the global context state */
type GlobalContextType = {
  /** List of all clubs */
  globalAllClubs: Club[];
  /** The selected club */
  globalSelectedClub: Club | null;
  /** Function to set the list of all clubs */
  globalSetAllClubs: React.Dispatch<React.SetStateAction<Club[]>>;
  /** Function to set the selected club */
  globalSetSelectedClub: React.Dispatch<React.SetStateAction<Club | null>>;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

/** GlobalProvider component that provides global state for clubs. */
export const GlobalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [globalAllClubs, globalSetAllClubs] = useState<Club[]>([]);
  const [globalSelectedClub, globalSetSelectedClub] = useState<Club | null>(null);

  /** Load clubs from localStorage on mount. */
  useEffect(() => {
    const storedAllClubs = localStorage.getItem("allClubs");
    const storedClub = localStorage.getItem("selectedClub");
    if (storedClub) {
      globalSetSelectedClub(JSON.parse(storedClub));
    }
    if (storedAllClubs) {
      globalSetAllClubs(JSON.parse(storedAllClubs));
    }
  }, []);

  /** Store the selected club in localStorage whenever it changes. */
  useEffect(() => {
    if (globalSelectedClub) {
      const sortedCourts = [...globalSelectedClub.courts].sort((a, b) => sortNames(a.name, b.name));
      const sortedClub = { ...globalSelectedClub, courts: sortedCourts };
      localStorage.setItem("selectedClub", JSON.stringify(sortedClub));
    } else {
      localStorage.setItem("selectedClub", JSON.stringify(null));
    }
  }, [globalSelectedClub]);

  /** Store all clubs in localStorage whenever they change. */
  useEffect(() => {
    if (globalAllClubs.length > 0) {
      const sortedClubs = globalAllClubs.map((club) => {
        const sortedCourts = [...club.courts].sort((a, b) => sortNames(a.name, b.name));
        return { ...club, courts: sortedCourts };
      });
      localStorage.setItem("allClubs", JSON.stringify(sortedClubs));
    } else {
      localStorage.setItem("allClubs", JSON.stringify(globalAllClubs));
    }
  }, [globalAllClubs]);

  return (
    <GlobalContext.Provider
      value={{
        globalAllClubs,
        globalSelectedClub,
        globalSetAllClubs,
        globalSetSelectedClub,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

/**
 * Custom hook to use the global context.
 *
 * @returns The global context.
 * @throws Will throw an error if used outside of a GlobalProvider.
 */
export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
