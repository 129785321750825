import { useMutation, type DocumentNode, type MutationHookOptions, type OperationVariables } from "@apollo/client";
import { useAlert } from "../components/AlertProvider";
import { handleError } from "./utils/handleErrors";

/** Options for the useAsyncMutation hook. */
type UseAsyncMutationOptions<TData, TVariables> = MutationHookOptions<TData, TVariables> & {
  /** Timeout duration in milliseconds. Defaults to 10,000 ms (10 seconds). */
  timeoutMs?: number;
  /**
   * Optional custom error handling function.
   *
   * - Return a string to replace the error message.
   * - Return `null` to skip showing an error message.
   * - Return `undefined` to use the default error message.
   */
  onCustomError?: (error: any, defaultErrorMessage?: string) => string | null | undefined;
};

/**
 * A custom hook for handling asynchronous mutations with timeout and error handling.
 *
 * @param mutation - The GraphQL mutation document.
 * @param options - Optional settings for the mutation execution, including Apollo mutation options, timeoutMs, and
 *   onCustomError.
 * @returns An object containing the execute function and loading state.
 */
export const useAsyncMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: UseAsyncMutationOptions<TData, TVariables>,
): { execute: (variables?: TVariables) => Promise<TData | null>; loading: boolean } => {
  const { showAlert } = useAlert();
  const [mutationFunction, { loading }] = useMutation<TData, TVariables>(mutation, options);

  /**
   * Executes the asynchronous mutation with error handling and timeout.
   *
   * @param variables - Variables to pass to the mutation function.
   * @returns The result of the mutation or null if an error occurred.
   */
  const execute = async (variables?: TVariables): Promise<TData | null> => {
    try {
      const timeoutMs = options?.timeoutMs || 10000;
      // Create a timeout promise that rejects after timeoutMs milliseconds
      const timeout = new Promise<never>((_, reject) =>
        setTimeout(() => reject(new Error("Request timeout")), timeoutMs),
      );

      // Execute the mutation and race it against the timeout
      const result = await Promise.race([mutationFunction({ variables }), timeout]);

      return result.data || null;
    } catch (error) {
      const defaultErrorMessage = handleError([error]);
      let finalErrorMessage: string | null | undefined = defaultErrorMessage;

      if (options?.onCustomError) {
        const customErrorMessage = options.onCustomError(error, defaultErrorMessage);
        if (customErrorMessage !== undefined) {
          finalErrorMessage = customErrorMessage;
        }
      }

      if (finalErrorMessage) {
        showAlert(finalErrorMessage, "error");
      }

      return null;
    }
  };

  return { execute, loading };
};
