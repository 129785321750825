import React from "react";
import { Box, Stack, Text, Tooltip } from "@chakra-ui/react";
import type { Log } from "../../../../types";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { LogItem } from "./components/LogItem";

type LogsProps = {
  logs: Log[];
  /** Whether the component is loading */
  isLoading?: boolean;
};

export const Logs: React.FC<LogsProps> = (props) => {
  const withdrawns = props.logs.filter((log) => log.type === "ParticipationWithdrawn").length;
  const cancellations = props.logs.filter((log) => log.type === "EventCancelled").length;
  const replacements = props.logs.filter((log) => log.type === "JoinedReplacementEvent").length;
  const eventsToRecover = withdrawns + cancellations - replacements;

  return (
    <Box position="relative" width="100%" minHeight="400px" maxHeight="75svh" display="flex" flexDirection="column">
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />
      <Stack padding="6" direction="row" justifyContent="space-between">
        <Text fontSize="large" color="blackAlpha.900">
          Logs ({props.logs.length})
        </Text>
        <Tooltip
          label={
            <Stack direction="column" spacing="1">
              <Text color="white">{`${withdrawns} times withdrawn from weekly trainings`}</Text>
              <Text color="white">{`${cancellations} weekly trainings cancelled`}</Text>
              <Text color="white">{`${replacements} participations in extra weekly trainings`}</Text>
            </Stack>
          }
        >
          <Text fontSize="medium" color="blackAlpha.900">
            {eventsToRecover < 0
              ? `${Math.abs(eventsToRecover)} extra trainings attended`
              : `${eventsToRecover} training to recover`}
          </Text>
        </Tooltip>
      </Stack>
      <CommonScrollbar
        height="auto"
        overflow="auto"
        invisibleBorderWidth="0px 8px 0px 0px"
        paddingRight="2"
        paddingLeft="6"
        paddingBottom="6"
        marginBottom="6"
      >
        {props.logs.length > 0 ? (
          <Stack direction="column" spacing="6" align="stretch">
            {props.logs.map((log, index) => (
              <LogItem key={index} log={log} />
            ))}
          </Stack>
        ) : (
          <Text color="blackAlpha.600">No logs</Text>
        )}
      </CommonScrollbar>
    </Box>
  );
};
