import {
  FormControl,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import React from "react";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils/styles";

/** Props for the CommonNumberInput component. */
export type CommonNumberInputProps = {
  /** The current value of the input field. */
  value: string | number;
  /** Placeholder text for the input field. */
  placeholder: string;
  /** Callback function to handle changes in the input field. */
  onChange: (valueAsString: string, valueAsNumber: number) => void;
  /** Whether the input field is disabled. */
  disabled?: boolean;
  /** Whether the input field is required. */
  required?: boolean;
  /** The minimum value allowed for the input field, defaults to 0 */
  min?: number;
  /** The maximum value allowed for the input field */
  max?: number;
};

/** A common number input component that supports different customization options. */
export const CommonNumberInput: React.FC<CommonNumberInputProps> = (props) => {
  const handleChange = (valueAsString: string, valueAsNumber: number) => {
    props.onChange(valueAsString, valueAsNumber);
  };

  return (
    <InputGroup>
      <FormControl id="common-input">
        <CommonFormLabel display={!!props.value}>{props.placeholder}</CommonFormLabel>
        <NumberInput
          variant="outline"
          min={props.min || 0}
          max={props.max}
          value={props.value || 0}
          onChange={handleChange}
          isRequired={props.required}
          height="42px"
          opacity={props.disabled ? 0.6 : 1}
          focusBorderColor="orange.400"
          isDisabled={props.disabled}
          {...commonStyles(!!props.value)}
          data-testid="common-number-input"
        >
          <NumberInputField border="none" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </InputGroup>
  );
};
