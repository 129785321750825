import React, { useState, useEffect, useMemo } from "react";
import { Box, CircularProgress, CircularProgressLabel, Stack, Text, Tooltip, keyframes } from "@chakra-ui/react";
import { Autocomplete } from "../../../common/components/AutoComplete";
import { FormModal } from "../../../common/components/FormModal";
import { CommonButton } from "../../../common/components/CommonButton";
import { CommonInput } from "../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../common/components/LoadingOverlay";
import { Evaluation } from "./Evaluation/Evaluation";
import { Notes } from "./Notes/Notes";
import { Logs } from "./Logs/Logs";
import { TrainingGroups } from "./TrainingGroups/TrainingGroups";
import type {
  Athlete,
  TrainingGroup,
  Evaluation as EvaluationType,
  NoteInput,
  SkillSet,
  Coach,
  EvaluationCriteria,
  TrainingSeason,
  Note,
  Venue,
} from "../../../types";
import type {
  EvaluationInput,
  AthleteInput,
  TrainingGroupInput,
  SkillEvaluationInput,
  SkillSetEvaluationInput,
} from "../containers/AthleteContainer";
import { ComponentContainer } from "../../../common/components/ComponentContainer";

type AthletesProps = {
  allVenues: Venue[];
  athletes: Athlete[];
  coaches: Coach[];
  skillSets: SkillSet[];
  evaluationCriteria: EvaluationCriteria[];
  trainingSeasons: TrainingSeason[];
  trainingGroups: TrainingGroup[];
  organizationData: any;
  onSaveAthlete: (athleteInput: AthleteInput) => Promise<void>;
  onSaveTrainingGroup: (eventInput: TrainingGroupInput) => Promise<void>;
  onSaveNote: (noteInput: NoteInput) => Promise<void>;
  onSaveEvaluation: (evaluationInput: EvaluationInput) => Promise<void>;
  isLoading: boolean;
  isTrainingGroupLoading: boolean;
  isAthleteLoading: boolean;
  onAthleteChange: (athlete: Athlete) => Promise<void>;
  evaluation: EvaluationType;
  evaluationLoading: boolean;
  notes: Note[];
  notesLoading: boolean;
  getSkillSetEvaluationCriteria: (skillSetId: string) => Promise<void>;
  skillSetEvaluationCriteriaData: { [key: string]: EvaluationCriteria[] };
  skillSetEvaluationCriteriaLoading: { [key: string]: boolean };
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
`;

export const Athletes: React.FC<AthletesProps> = (props) => {
  const [selectedAthlete, setSelectedAthlete] = useState<Athlete | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [clearAutocomplete, setClearAutocomplete] = useState(false);
  const [changeAthleteAnimation, setChangeAthleteAnimation] = useState("");
  const [isHeaderDisabled, setIsHeaderDisabled] = useState(false);
  const [athleteRating, setAthleteRating] = useState<number>(0);

  useEffect(() => {
    // TODO: shouldn't be needed once all component data are loaded separately, for now it updates components and is needed
    const newSelectedAthlete =
      props.athletes.find((athlete) => athlete.id === selectedAthlete?.id || athlete.name === selectedAthlete?.name) ||
      null;

    if (
      newSelectedAthlete &&
      (newSelectedAthlete.id === selectedAthlete?.id || newSelectedAthlete.name === selectedAthlete?.name)
    ) {
      handleAthleteChange(newSelectedAthlete);
    } else {
      setSelectedAthlete(null);
    }
  }, [props.athletes]);

  const handleAthleteChange = (newAthlete: Athlete) => {
    setIsHeaderDisabled(true);
    setClearAutocomplete(true);
    setChangeAthleteAnimation(`${fadeOut} 0.5s ease`);

    setTimeout(() => {
      setClearAutocomplete(false);
      setChangeAthleteAnimation(`${fadeIn} 0.5s ease`);
      setSelectedAthlete(newAthlete);
      setIsHeaderDisabled(false);
      // start loading evaluation for the new athlete only after the animation is done for smoother transition
      props.onAthleteChange(newAthlete);
    }, 400);
  };

  const handleAddAthlete = () => {
    setSelectedAthlete({
      id: undefined,
      name: "",
      trainingGroups: [],
    });
    setIsDialogOpen(true);
  };

  const handleSaveAthlete = async (remove: boolean) => {
    if (!selectedAthlete) {
      return;
    }

    const athleteInput: AthleteInput = {
      name: selectedAthlete.name,
      id: selectedAthlete.id,
      remove,
    };

    await props.onSaveAthlete(athleteInput);

    setIsDialogOpen(false);
  };

  const handleSaveTrainingGroup = async (eventToSave: TrainingGroup) => {
    const existingAthleteIds = eventToSave.athletes?.map((athlete) => athlete.id || "") || [];
    const athleteIdsToSave = eventToSave.remove
      ? existingAthleteIds.filter((athleteId) => athleteId !== selectedAthlete?.id || "")
      : [...existingAthleteIds, selectedAthlete?.id || ""];

    const dataToSave: TrainingGroupInput = {
      name: eventToSave.name,
      id: eventToSave.id,
      recurrenceEndTime: eventToSave.recurrenceEndTime,
      recurrenceStartTime: eventToSave.recurrenceStartTime,
      recurrenceWeekday: eventToSave.recurrenceWeekday,
      coachIds: eventToSave.coaches?.map((coach) => coach.id || "") || [],
      venueId: eventToSave.venue?.id,
      athleteIds: athleteIdsToSave,
      trainingSeasonId: eventToSave.trainingSeason.id || "",
      // always false under athletes, if whole training group should be removed, it needs to be done through management
      remove: false,
    };
    await props.onSaveTrainingGroup(dataToSave);
  };

  const handleSaveNote = async (note: Note, remove?: boolean) => {
    if (!selectedAthlete) {
      return;
    }
    const noteToSave: NoteInput = {
      id: note.id,
      content: note.content,
      isGroupNote: note.isGroupNote,
      athleteIds: [selectedAthlete.id || ""],
      remove: remove,
    };
    await props.onSaveNote(noteToSave);
  };

  const handleSaveEvaluation = async (evaluation: EvaluationType) => {
    if (!selectedAthlete) {
      return;
    }

    // Map over each SkillSetEvaluation to create SkillSetEvaluationInput
    const skillSetEvaluationsInput: SkillSetEvaluationInput[] = evaluation.skillSetEvaluations.map(
      (skillSetEvaluation) => {
        // Map over each SkillEvaluation to create SkillEvaluationInput
        const skillEvaluationsInput: SkillEvaluationInput[] = skillSetEvaluation.skillEvaluations.map(
          (skillEvaluation) => {
            return {
              id: skillEvaluation.id,
              skillId: skillEvaluation.skill.id || "",
              rating: skillEvaluation.rating,
            };
          },
        );

        // Create SkillSetEvaluationInput
        const skillSetEvaluationInput: SkillSetEvaluationInput = {
          id: skillSetEvaluation.id,
          skillSetId: skillSetEvaluation.skillSet.id || "",
          skillEvaluations: skillEvaluationsInput,
          comment: skillSetEvaluation.comment,
        };

        return skillSetEvaluationInput;
      },
    );

    const evaluationInput: EvaluationInput = {
      id: evaluation.id,
      athleteId: selectedAthlete.id || "",
      skillSetEvaluations: skillSetEvaluationsInput,
      remove: evaluation.remove,
    };

    await props.onSaveEvaluation(evaluationInput);
  };

  const handleCloseModal = () => {
    if (!selectedAthlete?.id) {
      setSelectedAthlete(null);
    }
    setIsDialogOpen(false);
  };

  const handleAthleteNameChange = (name: string) => {
    if (selectedAthlete) {
      setSelectedAthlete({
        ...selectedAthlete,
        name,
      });
    }
  };

  const handleSave = () => {
    setIsDialogOpen(false);
    handleSaveAthlete(false);
  };

  const handleRemove = () => {
    setIsDialogOpen(false);
    handleSaveAthlete(true);
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    if (
      props.athletes.map((athlete) => athlete.name.toLowerCase()).includes(selectedAthlete?.name.toLowerCase() || "")
    ) {
      return `Athlete "${selectedAthlete?.name}" already exists`;
    } else if (selectedAthlete?.name.trim() === "") {
      return "Athlete name cannot be empty";
    }
    return undefined;
  };

  /** Calculate athlete rating based on evaluation. */
  const handleEvaluationChange = (evaluation: EvaluationType) => {
    // TODO: improve this by saving rating to db
    if (!evaluation || !evaluation.skillSetEvaluations) {
      setAthleteRating(0);
      return;
    }

    const skillSetEvaluations = evaluation.skillSetEvaluations;
    const skillSetRatings = skillSetEvaluations
      .filter((skillSetEvaluation) => skillSetEvaluation.skillEvaluations.length > 0)
      .map((skillSetEvaluation) => {
        const skillRatings = skillSetEvaluation.skillEvaluations.map((skillEvaluation) => skillEvaluation.rating);
        const skillSetRating = skillRatings.reduce((acc, current) => acc + current, 0) / skillRatings.length;
        return skillSetRating;
      });

    const overallRating = skillSetRatings.reduce((acc, current) => acc + current, 0) / skillSetRatings.length || 0;
    setAthleteRating(overallRating);
  };

  const submitDisabledReasons = useMemo(() => getSubmitDisabledReasons(), [props.athletes, selectedAthlete?.name]);

  const filteredUniqueTrainingGroups = props.trainingGroups.filter(
    (event) => !selectedAthlete?.trainingGroups?.some((athleteEvent) => athleteEvent.id === event.id),
  );

  const percentage = (athleteRating / (props.organizationData?.evaluationScaleMax || 10)) * 100;

  return (
    <Box id="athletes" data-testid="athletes">
      <LoadingOverlay display={props.isAthleteLoading} spinnerSize="xl" spinnerTopPosition="60px" />

      {/* Header */}
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" marginBottom="12">
        <Stack direction="row" spacing="4" flex={1}>
          <Autocomplete
            initialOption={selectedAthlete}
            allOptions={props.athletes}
            getOptionLabel={(athlete: Athlete) => athlete.name}
            handleOptionChange={handleAthleteChange}
            clearInput={clearAutocomplete}
            dataTestId="athlete-autocomplete"
          />
        </Stack>
        <Text fontSize="x-large" flex={1} textAlign="center" animation={changeAthleteAnimation}>
          {selectedAthlete?.name || "Select Athlete"}
        </Text>
        <Stack direction="row" spacing="12" flex={1} justifyContent="end">
          <CommonButton
            variantType="outlineSecondary"
            onClick={handleAddAthlete}
            disabled={isHeaderDisabled}
            dataTestId="add-athlete"
          >
            New Athlete
          </CommonButton>
          <CommonButton
            variantType="outlineSecondary"
            disabled={!selectedAthlete || isHeaderDisabled}
            onClick={() => setIsDialogOpen(true)}
            dataTestId="edit-athlete"
          >
            Edit
          </CommonButton>
        </Stack>
      </Stack>

      {/* Athlete content */}
      {selectedAthlete && (
        <Stack animation={changeAthleteAnimation} direction="column" spacing="8">
          {/* Athlete overall rating */}
          <Box
            // lift overall rating with negative margin to reduce whitespace
            marginTop="-8"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <Tooltip label="Athlete overall rating is the average of Skill Set ratings">
              <CircularProgress
                value={percentage}
                color="orange.400"
                size="55px"
                capIsRound={true}
                trackColor="gray.200"
              >
                <CircularProgressLabel fontSize="large" cursor="default">
                  {athleteRating > 0 ? athleteRating.toFixed(1) : "-"}
                </CircularProgressLabel>
              </CircularProgress>
            </Tooltip>
          </Box>

          <ComponentContainer handlePaddingManually={true}>
            <Evaluation
              key={props.evaluation?.id}
              evaluation={props.evaluation}
              allSkillSets={props.skillSets}
              allCriteria={props.evaluationCriteria}
              organization={props.organizationData || null}
              disabled={false}
              onSaveEvaluation={handleSaveEvaluation}
              onEvaluationChange={handleEvaluationChange}
              getSkillSetEvaluationCriteria={props.getSkillSetEvaluationCriteria}
              skillSetEvaluationCriteriaData={props.skillSetEvaluationCriteriaData}
              skillSetEvaluationCriteriaLoading={props.skillSetEvaluationCriteriaLoading}
              isLoading={props.evaluationLoading}
            />
          </ComponentContainer>

          <Stack direction="row" spacing="8" width="100%">
            <ComponentContainer handlePaddingManually={true}>
              <Notes notes={props.notes} onSave={handleSaveNote} isLoading={props.notesLoading} />
            </ComponentContainer>
            <ComponentContainer handlePaddingManually={true}>
              <Logs logs={selectedAthlete.logs || []} isLoading={props.isTrainingGroupLoading} />
            </ComponentContainer>
          </Stack>

          <ComponentContainer>
            <TrainingGroups
              athleteName={selectedAthlete?.name || ""}
              coaches={props.coaches}
              venues={props.allVenues}
              nonParticipatedTrainingGroups={filteredUniqueTrainingGroups}
              allTrainingGroups={props.trainingGroups}
              trainingGroups={selectedAthlete?.trainingGroups || []}
              allTrainingSeasons={props.trainingSeasons}
              onSave={handleSaveTrainingGroup}
              onRemove={(eventToRemove) => handleSaveTrainingGroup({ ...eventToRemove, remove: true })}
              isLoading={props.isTrainingGroupLoading}
            />
          </ComponentContainer>
        </Stack>
      )}

      <FormModal
        handleSubmit={handleSave}
        open={isDialogOpen}
        submitButtonText={!selectedAthlete?.id ? "Create" : "Confirm"}
        title={!selectedAthlete?.id ? "Create new athlete" : `Edit ${selectedAthlete.name}`}
        handleRemove={handleRemove}
        removeDisabled={!selectedAthlete?.id}
        removeDisabledReason={!selectedAthlete?.id ? "Athlete isn't saved yet" : undefined}
        onClose={handleCloseModal}
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
      >
        <Box data-testid="athlete-modal">
          <CommonInput
            placeholder="Athlete name"
            value={selectedAthlete?.name || ""}
            onChange={handleAthleteNameChange}
            dataTestId="athlete-name"
          />
        </Box>
      </FormModal>
    </Box>
  );
};
