import { gql } from "@apollo/client";

export const GET_ALL_TRAINING_GROUP_EVENTS = gql`
  query GetAllTrainingGroupEvents {
    getAllTrainingGroupEvents {
      id
      athletes {
        id
      }
    }
  }
`;
