import React from "react";
import { createRoot, type Root } from "react-dom/client";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AlertProvider } from "./common/components/AlertProvider";
import { GlobalProvider } from "./common/components/GlobalProvider";
import { theme } from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { ErrorBoundary } from "./common/components/AlertProvider/components/ErrorBoundary";

const httpLink = new HttpLink({
  uri: process.env.NODE_ENV === "production" ? "https://api.striveon.cloud/" : "http://localhost:4000/",
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      RecurringTrainingEvent: {
        // caching is disabled for this entity due to the custom season plan returning for metadatas on resolver
        // (null | season plan is returned for metadatas depending on dates, when cached it will return the same season plan for all metadatas)
        // TODO: this can be fixed by implementing custom keyfield for the recurring training event
        keyFields: false,
      },
      Player: {
        // same as above
        keyFields: false,
      },
      Skill: {
        // TODO: e2e this & remove cache disablion: create new season plan, add new skill to it, go to player evaluation & try to evaluate the new skill
        keyFields: false,
      },
    },
  }),
  connectToDevTools: false,
});

let root: Root;
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}

if (!window.__root__) {
  root = createRoot(rootElement);
  window.__root__ = root;
} else {
  root = window.__root__;
}

root.render(
  <ApolloProvider client={client}>
    <GlobalProvider>
      <ChakraProvider theme={theme}>
        <AlertProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </AlertProvider>
      </ChakraProvider>
    </GlobalProvider>
  </ApolloProvider>,
);

if ((module as any).hot) {
  (module as any).hot.accept();
}
