import React, { useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { Box, Divider } from "@chakra-ui/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarViewControls } from "../../../../Calendar/components/components/CalendarHeader/components/CalendarViewControls";
import { getNewDate } from "../../../../../common/utils";
import { SeasonPlanEventContent } from "./components/SeasonPlanEventContent";
import type { FullCalendarSeasonPlanEvent } from "../../../types";
import "./fullCalendar.css";
import { SeasonPlanCalendarHeader } from "./components/SeasonPlanCalendarHeader";

export type SeasonPlanCalendarProps = {
  /** The events to be displayed in the calendar. */
  eventsToDisplay: FullCalendarSeasonPlanEvent[];
  /** Handler for when an event is clicked. */
  handleEventClick: (info: any) => void;
  /** Handler for when a date is selected. */
  dateSelectHandler: (args: any) => void;
  /** Function to show alerts. */
  showAlert: (message: string, type: "error" | "success" | "info" | "warning") => void;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
  /** Loading state indicator. */
  isLoading: boolean;
  /** All available players. */
  allPlayers: Array<{ name: string }>;
  /** Selected players. */
  selectedPlayers: Array<{ name: string }>;
  /** Function to set selected players. */
  setSelectedPlayers: (players: Array<{ name: string }>) => void;
  /** All available groups. */
  allGroups: Array<{ name: string }>;
  /** Selected groups. */
  selectedGroups: Array<{ name: string }>;
  /** Function to set selected groups. */
  setSelectedGroups: (groups: Array<{ name: string }>) => void;
  /** Filter to show only organization wide season plans */
  showOnlyOrganizationWide: boolean;
  /** Function to toggle showing only organization wide season plans */
  setShowOnlyOrganizationWide: React.Dispatch<React.SetStateAction<boolean>>;
  /** Filter to show only recurring training event specific season plans */
  showOnlyRecurringTrainingEvents: boolean;
  /** Function to toggle showing only recurring training event specific season plans */
  setShowOnlyRecurringTrainingEvents: React.Dispatch<React.SetStateAction<boolean>>;
  /** Filter to show only individual player season plans */
  showOnlyPlayerPlans: boolean;
  /** Function to toggle showing only individual player season plans */
  setShowOnlyPlayerPlans: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SeasonPlanCalendar: React.FC<SeasonPlanCalendarProps> = (props) => {
  const calendarRefs = useRef<(FullCalendar | null)[]>([]);
  const [fullCalendarKey, setFullCalendarKey] = useState(1);
  const [activeView, setActiveView] = useState("dayGridMonth");
  const [dayViewDate, setDayViewDate] = useState(new Date());

  return (
    <Box>
      <SeasonPlanCalendarHeader
        allPlayers={props.allPlayers}
        selectedPlayers={props.selectedPlayers}
        setSelectedPlayers={props.setSelectedPlayers}
        allGroups={props.allGroups}
        selectedGroups={props.selectedGroups}
        setSelectedGroups={props.setSelectedGroups}
        showOnlyOrganizationWide={props.showOnlyOrganizationWide}
        setShowOnlyOrganizationWide={props.setShowOnlyOrganizationWide}
        showOnlyRecurringTrainingEvents={props.showOnlyRecurringTrainingEvents}
        setShowOnlyRecurringTrainingEvents={props.setShowOnlyRecurringTrainingEvents}
        showOnlyPlayerPlans={props.showOnlyPlayerPlans}
        setShowOnlyPlayerPlans={props.setShowOnlyPlayerPlans}
        isMobile={props.isMobile}
        isLoading={props.isLoading}
      />
      <Divider marginY="6" />
      <CalendarViewControls
        activeView={activeView}
        calendarRefs={calendarRefs}
        dayViewDate={dayViewDate}
        getNewDate={getNewDate}
        globalSelectedClub={true}
        setActiveView={setActiveView}
        setDayViewDate={setDayViewDate}
        setFullCalendarKey={setFullCalendarKey}
        isMobile={props.isMobile}
        onlyMonthView={true}
        data-testid="calendar-view-controls"
      />
      <Box
        id="season-plan-calendar-body"
        __css={{ "& .fc": { minWidth: "300px !important" } }}
        data-testid="season-plan-calendar-body"
      >
        <FullCalendar
          key={fullCalendarKey}
          ref={(ref: any) => (calendarRefs.current[0] = ref)}
          initialDate={dayViewDate}
          initialView="dayGridMonth"
          eventContent={(args) => <SeasonPlanEventContent {...args} />}
          timeZone="UTC"
          plugins={[dayGridPlugin, interactionPlugin]}
          firstDay={1}
          events={props.eventsToDisplay}
          weekends={true}
          weekNumbers={true}
          selectable={true}
          selectMirror={true}
          unselectAuto={true}
          fixedWeekCount={false}
          headerToolbar={false as false}
          eventDisplay="block"
          contentHeight="auto"
          eventClick={props.handleEventClick}
          select={(args: any) => {
            props.dateSelectHandler(args);
          }}
        />
      </Box>
    </Box>
  );
};
