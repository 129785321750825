import React, { useState } from "react";
import { FmdBadTwoTone, TimesOneMobiledata, Repeat, Timeline } from "@mui/icons-material";
import { EventContentArg } from "fullcalendar";
import { Box, Grid, GridItem, Icon, Stack, Text, Tooltip } from "@chakra-ui/react";
import type { Coach, SkillSet } from "../../../../../../types";
import { extractTimeFromUTC } from "../../../../../../common/utils";
import { LoadingOverlay } from "../../../../../../common/components/LoadingOverlay";

/** Renders the content of an event in the calendar. */
export const CommonEventContent = (args: EventContentArg) => {
  const [openTooltip, setOpenTooltip] = useState<string | null>(null);

  const isLoading = args.event.extendedProps.isLoading;
  const trainingEventType = args.event.extendedProps.trainingEventType;
  const freeSpots = args.event.extendedProps.players ? 4 - args.event.extendedProps.players.length : undefined;
  const durationInMinutes = ((args.event.end?.valueOf() || 0) - (args.event.start?.valueOf() || 0)) / 1000 / 60;
  const startHour = extractTimeFromUTC(new Date(args.event.start || "").toISOString());
  const endHour = extractTimeFromUTC(new Date(args.event.end || "").toISOString());
  const hasSeasonPlan = args.event.extendedProps.seasonPlans && args.event.extendedProps.seasonPlans.length > 0;
  const trainingSkillSetTarget = hasSeasonPlan
    ? args.event.extendedProps.seasonPlans[0].skillSets?.map((skillSet: SkillSet) => skillSet.name).join(", ")
    : undefined;
  const name = args.event.extendedProps.name;

  const handleTooltipOpen = (id: string) => {
    setOpenTooltip(id);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  return (
    <Box height="100%">
      <LoadingOverlay display={isLoading} />

      <Tooltip
        isOpen={openTooltip === "main"}
        onOpen={() => handleTooltipOpen("main")}
        onClose={handleTooltipClose}
        label={
          <Stack direction="column" spacing="1">
            <Text color="white">{`Training Group: ${name || "No group name"}`}</Text>
            <Text color="white">{`Time: ${startHour} - ${endHour}`}</Text>
            <Text color="white">{`Place: ${args.event.extendedProps.court?.name || "No court"}`}</Text>
            <Text color="white">{`Coach: ${args.event.extendedProps.coach?.map((coach: Coach) => coach.name).join(", ") || "No coach"}`}</Text>
            <Text color="white">{`Players: ${args.event.extendedProps.players?.map((player: any) => player.name).join(", ") || "No players"}`}</Text>
            {hasSeasonPlan ? (
              <Text color="white">{`Training targets: ${trainingSkillSetTarget}`}</Text>
            ) : (
              <Text color="white">{`Training targets: Not planned`}</Text>
            )}
          </Stack>
        }
        hasArrow
        placement="top"
      >
        <Grid
          templateColumns={{ mobile: "1fr auto", laptop: "auto 1fr auto" }}
          width="100%"
          templateRows={durationInMinutes >= 90 ? "auto 1fr" : "auto"}
          alignContent="start"
          columnGap="4"
          rowGap="2"
          padding="2"
          height="100%"
          overflow="hidden"
          shadow="md"
          cursor="pointer"
          borderRadius="md"
          border="1px solid"
          borderColor="whiteAlpha.700"
          backgroundColor="teal.800"
          transition="all 0.3s ease"
          _hover={{
            shadow: "base",
            backgroundColor: "teal.700",
            borderColor: "teal.700",
          }}
          data-testid="common-event-content"
        >
          {/* Always display group name first */}
          <GridItem
            colSpan={{ mobile: 1, laptop: 2 }}
            alignSelf={durationInMinutes < 60 ? "center" : undefined}
            overflow="hidden"
          >
            <Text
              fontSize="medium"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              color="white"
              overflow="hidden"
              textAlign="left"
            >
              {name || "No group name"}
            </Text>
          </GridItem>

          {/* Display icons on third column if event is 60 minutes or over */}
          {durationInMinutes >= 60 && (
            <GridItem colSpan={1} overflow="hidden">
              <Stack direction="row" spacing="1">
                {freeSpots !== undefined && freeSpots > 0 && (
                  <Tooltip
                    isOpen={openTooltip === "freeSpots"}
                    onOpen={() => handleTooltipOpen("freeSpots")}
                    onClose={handleTooltipClose}
                    label={`${freeSpots} free spot${freeSpots >= 2 ? "s" : ""}`}
                    hasArrow
                    placement="top"
                  >
                    <Icon as={FmdBadTwoTone} color="orange.400" />
                  </Tooltip>
                )}
                {hasSeasonPlan && (
                  <Tooltip
                    isOpen={openTooltip === "seasonPlan"}
                    onOpen={() => handleTooltipOpen("seasonPlan")}
                    onClose={handleTooltipClose}
                    label="Group has a training target"
                    hasArrow
                    placement="top"
                  >
                    <Icon as={Timeline} color="orange.400" />
                  </Tooltip>
                )}
                {trainingEventType === "recurring" ? (
                  <Tooltip
                    isOpen={openTooltip === "recurring"}
                    onOpen={() => handleTooltipOpen("recurring")}
                    onClose={handleTooltipClose}
                    label="Recurring training"
                    hasArrow
                    placement="top"
                  >
                    <Icon as={Repeat} color="orange.400" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    isOpen={openTooltip === "single"}
                    onOpen={() => handleTooltipOpen("single")}
                    onClose={handleTooltipClose}
                    label="Single training"
                    hasArrow
                    placement="top"
                  >
                    <Icon as={TimesOneMobiledata} color="orange.400" />
                  </Tooltip>
                )}
              </Stack>
            </GridItem>
          )}

          {/* Display court name on second row if event is 90 minutes or over */}
          {durationInMinutes >= 90 && (
            <GridItem colSpan={1} overflow="hidden">
              <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="white">
                {args.event.extendedProps.court?.name || "No court"}
              </Text>
            </GridItem>
          )}

          {/* Display coach names on second row if event is 90 minutes or over, unless mobile */}
          {durationInMinutes >= 90 && (
            <GridItem colSpan={2} display={{ mobile: "none", laptop: "block" }} overflow="hidden">
              <Text
                fontSize="medium"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                color="white"
                textAlign="left"
              >
                {args.event.extendedProps.coach?.map((coach: Coach) => coach.name).join(", ") || "No coach"}
              </Text>
            </GridItem>
          )}
        </Grid>
      </Tooltip>
    </Box>
  );
};
