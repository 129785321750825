// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FullCalendar customization */

/* ------------------------------------------------------------------ */
/* Daily view customization */

/* ------------------------------------------------------------------ */
/* Daily & Weekly view customization */

/* ------------------------------------------------------------------ */
/* Monthly view only customization */

.fc-daygrid-event-harness .fc-daygrid-event {
  background-color: transparent !important;
  border-color: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/Calendar/components/components/CalendarBody/calendar.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B,uEAAuE;AACvE,6BAA6B;;AAE7B,uEAAuE;AACvE,sCAAsC;;AAEtC,uEAAuE;AACvE,oCAAoC;;AAEpC;EACE,wCAAwC;EACxC,8BAA8B;AAChC","sourcesContent":["/* FullCalendar customization */\n\n/* ------------------------------------------------------------------ */\n/* Daily view customization */\n\n/* ------------------------------------------------------------------ */\n/* Daily & Weekly view customization */\n\n/* ------------------------------------------------------------------ */\n/* Monthly view only customization */\n\n.fc-daygrid-event-harness .fc-daygrid-event {\n  background-color: transparent !important;\n  border-color: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
