import { gql } from "@apollo/client";

export const GET_ALL_TRAINING_GROUPS = gql`
  query GetAllTrainingGroups {
    getAllTrainingGroups {
      id
      name
      recurrenceWeekday
      recurrenceStartTime
      recurrenceEndTime
      venue {
        id
        name
      }
      athletes {
        id
        name
      }
      coaches {
        id
        name
      }
      trainingSeason {
        id
        name
        status
      }
    }
  }
`;
