import React, { useEffect, useRef, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { CalendarHeader, CalendarBody, CalendarModals } from "./components";
import { useCalendarState } from "../hooks";
import {
  createTrainingGroupEvents,
  createSingleTrainingEvents,
  filterEvents,
  handleDateSelect,
  setEventInfo,
  saveNewSingleTrainingEvent,
  saveTrainingEventUpdate,
} from "../utils";
import type { FullCalendarEvent, TrainingEventDetails, TrainingEventUpdateInput } from "../types";
import type { Center, Venue, TrainingGroupEvent, SingleTrainingEvent } from "../../../types";
import type FullCalendar from "@fullcalendar/react";
import type { AlertContextType } from "../../../common/components/AlertProvider";
import type { MutationFunction } from "@apollo/client";
import { getNewDate } from "../../../common/utils/dateAndTime";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import { sortNames } from "../../../common/utils/dataProcessing";

export type CalendarProps = {
  currentBreakPoint: string;
  trainingGroupEvents: any;
  drillData: any;
  sessionData: any;
  athleteData: any;
  coachData: any;
  singleTrainingEventData: any;
  skillSetsData: any;
  centerData: any;
  organizationData: any;
  showAlert: AlertContextType["showAlert"];
  saveSingleTrainingEvent: MutationFunction;
  updateTrainingGroupEvents: MutationFunction;
  getTrainingGroupEventData: (trainingGroupEventId: string) => Promise<TrainingGroupEvent | null>;
  getSingleTrainingEventData: (singleTrainingEventId: string) => Promise<SingleTrainingEvent | null>;
};

export const Calendar: React.FC<CalendarProps> = (props) => {
  const calendarRefs = useRef<(FullCalendar | null)[]>([]);
  const unitedEventsRef = useRef<FullCalendarEvent[] | null>(null);
  const { globalSelectedCenter, globalSetSelectedCenter } = useGlobalContext();

  // State management using custom hooks
  const {
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyTrainingGroups,
    showOnlySingleTrainingEvents,
    isAddEventModalOpen,
    isDraggableTodoOpen,
    selectedDateTime,
    selectedStartTime,
    selectedEndTime,
    isViewModalOpen,
    selectedEventInfo,
    eventsToDisplay,
    fullCalendarKey,
    activeView,
    dayViewDate,
    dayViewSelectedVenue,
    selectedCenter,
    setSelectedCoaches,
    setShowOnlyFreeSpots,
    setShowOnlyTrainingGroups,
    setShowOnlySingleTrainingEvents,
    setIsAddEventModalOpen,
    setIsDraggableTodoOpen,
    setSelectedDateTime,
    setSelectedStartTime,
    setSelectedEndTime,
    setIsViewModalOpen,
    setSelectedEventInfo,
    setFullCalendarKey,
    setActiveView,
    setDayViewDate,
    setDayViewSelectedVenue,
    setEventsToDisplay,
    setSelectedCenter,
  } = useCalendarState();

  const allVenues =
    props.centerData?.getAllCenters?.flatMap((center: Center) =>
      center.venues.map((venue) => ({ ...venue, center })),
    ) || [];

  const trainingGroupEvents = useMemo(() => {
    if (
      props.trainingGroupEvents &&
      props.trainingGroupEvents.getAllTrainingGroupEvents &&
      props.athleteData?.getAllAthletes?.length > 0
    ) {
      return createTrainingGroupEvents(props.trainingGroupEvents.getAllTrainingGroupEvents);
    }
  }, [props.trainingGroupEvents, props.athleteData]);

  const singleTrainingEvents = useMemo(() => {
    if (props.singleTrainingEventData?.getAllSingleTrainingEvents?.length > 0) {
      return createSingleTrainingEvents(props.singleTrainingEventData.getAllSingleTrainingEvents);
    }
  }, [props.singleTrainingEventData, props.trainingGroupEvents]);

  useEffect(() => {
    if (trainingGroupEvents || singleTrainingEvents) {
      const allEvents = [...(trainingGroupEvents || []), ...(singleTrainingEvents || [])];
      unitedEventsRef.current = allEvents;
    }
  }, [trainingGroupEvents, singleTrainingEvents]);

  useEffect(() => {
    if (props.centerData?.getAllCenters?.length) {
      // sort venues by names
      const centersWithSortedVenues: Center[] = props.centerData.getAllCenters.slice().map((center: Center) => ({
        ...center,
        venues: center.venues.slice().sort((a, b) => sortNames(a.name, b.name)),
      }));

      if (globalSelectedCenter) {
        // check localstorage and use that as the selected center
        const selectedCenter = centersWithSortedVenues.find((center) => center.id === globalSelectedCenter.id);
        setSelectedCenter(selectedCenter || centersWithSortedVenues[0]);
      } else if (centersWithSortedVenues?.[0].id) {
        // fallback to the first available center
        setSelectedCenter(centersWithSortedVenues[0]);
        globalSetSelectedCenter(centersWithSortedVenues[0]);
      } else {
        setSelectedCenter(null);
        globalSetSelectedCenter(null);
      }
    }
  }, [props.centerData]);

  useEffect(() => {
    const events = filterEvents(
      unitedEventsRef,
      selectedCoaches,
      showOnlyFreeSpots,
      showOnlyTrainingGroups,
      showOnlySingleTrainingEvents,
      props.organizationData.getUserOrganization || null,
    );
    if (events) {
      setEventsToDisplay(events);
    }
  }, [
    trainingGroupEvents,
    singleTrainingEvents,
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyTrainingGroups,
    showOnlySingleTrainingEvents,
  ]);

  // Event handlers
  const handleAddEventModalClose = () => {
    setSelectedDateTime(undefined);
    setSelectedStartTime(null);
    setIsAddEventModalOpen(false);
    calendarRefs.current.forEach((calendarRef) => {
      calendarRef?.getApi().unselect();
    });
  };

  const handleViewEventModalClose = () => {
    setIsViewModalOpen(false);
  };

  const setEventLoadingState = (trainingEventId: string, loadingState: boolean) => {
    const event = eventsToDisplay.find((event) => event.id === trainingEventId);
    if (event) {
      event.eventIsLoading = loadingState;
      setFullCalendarKey((prevKey) => prevKey + 1);
    }
  };

  const handleSaveNewSingleTrainingEvent = async (singleTrainingEventInput: TrainingEventUpdateInput) => {
    props.showAlert("Creating new training", "info", undefined, true);
    setFullCalendarKey((prevKey) => prevKey + 1);

    await saveNewSingleTrainingEvent(
      singleTrainingEventInput,
      setIsAddEventModalOpen,
      props.saveSingleTrainingEvent,
      props.showAlert,
    );
  };

  const handleUpdateEvent = async (
    trainingEventType: TrainingEventDetails["trainingEventType"],
    updatedData: TrainingEventUpdateInput,
  ) => {
    if (updatedData.remove) {
      props.showAlert("Deleting training", "info", undefined, true);
    } else {
      props.showAlert("Updating training", "info", undefined, true);
    }
    setEventLoadingState(updatedData.id || "", true);

    await saveTrainingEventUpdate(
      trainingEventType,
      updatedData,
      setIsViewModalOpen,
      props.updateTrainingGroupEvents,
      props.saveSingleTrainingEvent,
      setIsAddEventModalOpen,
      props.showAlert,
    );

    setEventLoadingState(updatedData.id || "", false);
  };

  /**
   * Handles the click event for a calendar event and updates the selected event information and view modal state.
   *
   * @param info - The event click information.
   * @param fetchEventData - A function to fetch the event data based on its type.
   */
  const handleCalendarEventClick = async (info: any) => {
    const trainingEventId = info.event.id;
    if (!trainingEventId) {
      // For type safety
      return;
    }
    setEventLoadingState(trainingEventId, true);

    const eventType = info.event?.extendedProps?.eventType;
    if (!eventType) {
      props.showAlert("Something went wrong. Please try again", "error");
      setEventLoadingState(trainingEventId, false);
      return;
    }

    const fetchEventData = eventType === "weekly" ? props.getTrainingGroupEventData : props.getSingleTrainingEventData;

    const eventData = await fetchEventData(trainingEventId);

    if (eventData) {
      setEventInfo(eventType, eventData, setSelectedEventInfo);
      setIsViewModalOpen(true);
    } else {
      props.showAlert("Unable to load Training Event details. Please try again", "error");
    }

    setEventLoadingState(trainingEventId, false);
  };

  const handleCalendarDateSelect = (args: any, venue: Venue | null) => {
    setDayViewSelectedVenue(venue);
    handleDateSelect(args, setSelectedDateTime, setSelectedStartTime, setSelectedEndTime, setIsAddEventModalOpen);
  };

  const handleCenterChange = (center: Center) => {
    const sortedCenter = {
      ...center,
      venues: center.venues.slice().sort((a, b) => sortNames(a.name, b.name)),
    };
    setSelectedCenter(sortedCenter);
    globalSetSelectedCenter(sortedCenter);
  };

  return (
    <Box>
      <CalendarHeader
        allCenters={props.centerData?.getAllCenters || []}
        allCoaches={props.coachData?.getAllCoaches || []}
        isDraggableTodoOpen={isDraggableTodoOpen}
        selectedCoaches={selectedCoaches}
        setIsDraggableTodoOpen={setIsDraggableTodoOpen}
        setSelectedCoaches={setSelectedCoaches}
        setShowOnlyFreeSpots={setShowOnlyFreeSpots}
        setShowOnlyTrainingGroups={setShowOnlyTrainingGroups}
        setShowOnlySingleTrainingEvents={setShowOnlySingleTrainingEvents}
        showOnlyFreeSpots={showOnlyFreeSpots}
        showOnlyTrainingGroups={showOnlyTrainingGroups}
        showOnlySingleTrainingEvents={showOnlySingleTrainingEvents}
        activeView={activeView}
        calendarRefs={calendarRefs}
        dayViewDate={dayViewDate}
        getNewDate={getNewDate}
        display={!!selectedCenter}
        setActiveView={setActiveView}
        setDayViewDate={setDayViewDate}
        setFullCalendarKey={setFullCalendarKey}
        isMobile={props.currentBreakPoint === "mobile"}
        selectedCenter={selectedCenter}
        setSelectedCenter={handleCenterChange}
      />
      <CalendarBody
        activeView={props.currentBreakPoint === "mobile" ? "timeGridDay" : activeView}
        dayViewDate={dayViewDate}
        eventsToDisplay={eventsToDisplay}
        selectedCenter={selectedCenter}
        calendarRefs={calendarRefs}
        handleEventClick={handleCalendarEventClick}
        dateSelectHandler={handleCalendarDateSelect}
        fullCalendarKey={fullCalendarKey}
        organization={props.organizationData.getUserOrganization || null}
        showAlert={props.showAlert}
        isMobile={props.currentBreakPoint === "mobile"}
      />
      <CalendarModals
        isViewModalOpen={isViewModalOpen}
        saveTrainingEventUpdate={handleUpdateEvent}
        selectedEventInfo={selectedEventInfo}
        isAddEventModalOpen={isAddEventModalOpen}
        saveNewSingleTrainingEvent={handleSaveNewSingleTrainingEvent}
        selectedDateTime={selectedDateTime}
        selectedStartTime={selectedStartTime}
        selectedEndTime={selectedEndTime}
        dayViewSelectedVenue={dayViewSelectedVenue}
        allCoaches={props.coachData?.getAllCoaches || []}
        allVenues={allVenues}
        allAthletes={props.athleteData?.getAllAthletes || []}
        allDrills={props.drillData?.getAllDrills || []}
        allSessions={props.sessionData?.getAllSessions || []}
        allSkillSets={props.skillSetsData?.getAllSkillSets || []}
        handleAddEventModalClose={handleAddEventModalClose}
        handleViewEventModalClose={handleViewEventModalClose}
        isMobile={props.currentBreakPoint === "mobile"}
      />
    </Box>
  );
};
