import { gql } from "@apollo/client";

export const GET_ALL_ORGANIZATIONS = gql`
  query GetAllOrganizations {
    getAllOrganizations {
      id
      name
      users {
        id
        username
      }
    }
  }
`;
