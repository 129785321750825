import React, { useState } from "react";

import { Grid, GridItem, Stack } from "@chakra-ui/react";
import type { Coach, Court, Player, RecurringTrainingEvent } from "../../../types";
import { CommonInput } from "../CommonInput";
import { CommonSelect } from "../CommonSelect";
import { TimePicker } from "../TimePicker";
import { CourtSelect } from "../CourtSelect";
import { CoachSelect } from "../CoachSelect";
import { sortNames } from "../../utils";
import { MultiTagSelectMenu } from "../MultiTagSelectMenu";

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

type TrainingGroupEditorProps = {
  trainingGroup: RecurringTrainingEvent;
  allCoaches: Coach[];
  allCourts: Court[];
  onChange: (editedEvent: RecurringTrainingEvent) => void;
  displayPlayers: boolean;
  allPlayers?: Player[];
  dataTestId?: string;
};

/** Component for editing a single Training Group */
export const TrainingGroupEditor: React.FC<TrainingGroupEditorProps> = (props) => {
  const [trainingGroupToEdit, setTrainingGroupToEdit] = useState(props.trainingGroup);

  const handleChange = (field: keyof RecurringTrainingEvent, value: any) => {
    setTrainingGroupToEdit((prev) => {
      const updatedTrainingGroup = { ...prev };
      switch (field) {
        case "recurrenceWeekday":
          updatedTrainingGroup.recurrenceWeekday = value as number;
          break;
        case "recurrenceStartTime":
          updatedTrainingGroup.recurrenceStartTime = value as string;
          break;
        case "recurrenceEndTime":
          updatedTrainingGroup.recurrenceEndTime = value as string;
          break;
        case "court":
          updatedTrainingGroup.court = props.allCourts.find((court) => court.id === value) as Court;
          break;
        case "coaches":
          updatedTrainingGroup.coaches = props.allCoaches.filter((coach) =>
            (value as string[]).includes(coach.id || ""),
          );
          break;
        case "name":
          updatedTrainingGroup.name = value as string;
          break;
        case "players":
          updatedTrainingGroup.players = props.allPlayers?.filter((player) =>
            (value as Player[]).some((selectedPlayer) => selectedPlayer.id === player.id),
          );
      }

      props.onChange(updatedTrainingGroup);
      return updatedTrainingGroup;
    });
  };

  return (
    <Stack direction="row" spacing="4" width="100%" data-testid={props.dataTestId}>
      <Grid
        templateColumns={props.displayPlayers ? "repeat(4, 1fr)" : "repeat(10, 1fr)"}
        gap="4"
        alignItems="center"
        width="100%"
      >
        <GridItem colSpan={props.displayPlayers ? 2 : 2} data-testid="input-group">
          <CommonInput
            placeholder="Group name"
            value={trainingGroupToEdit.name || ""}
            onChange={(value) => handleChange("name", value)}
            dataTestId="input-group"
          />
        </GridItem>
        <GridItem colSpan={props.displayPlayers ? 2 : 2} data-testid="select-weekday">
          <CommonSelect
            placeholder="Weekday"
            value={trainingGroupToEdit.recurrenceWeekday}
            options={weekdays.map((weekday, i) => ({
              value: i + 1,
              label: weekday,
            }))}
            onChange={(e) => handleChange("recurrenceWeekday", parseInt(e.target.value))}
          />
        </GridItem>

        <GridItem colSpan={props.displayPlayers ? 2 : 1} data-testid="select-start">
          <TimePicker
            label="Start"
            selectedTime={trainingGroupToEdit.recurrenceStartTime}
            setSelectedTime={(value: string | Date) => handleChange("recurrenceStartTime", value)}
          />
        </GridItem>
        <GridItem colSpan={props.displayPlayers ? 2 : 1} data-testid="select-end">
          <TimePicker
            label="End"
            selectedTime={trainingGroupToEdit.recurrenceEndTime}
            setSelectedTime={(value: string | Date) => handleChange("recurrenceEndTime", value)}
          />
        </GridItem>

        <GridItem colSpan={props.displayPlayers ? 2 : 2} data-testid="select-court">
          <CourtSelect
            courts={props.allCourts.slice().sort((a, b) => sortNames(a.name, b.name))}
            selectedCourt={trainingGroupToEdit.court}
            setSelectedCourt={(courtId: string) => handleChange("court", courtId)}
          />
        </GridItem>
        <GridItem colSpan={props.displayPlayers ? 2 : 2} data-testid="select-coach">
          <CoachSelect
            availableCoaches={props.allCoaches}
            selectedCoaches={trainingGroupToEdit.coaches?.map((coach) => coach) || []}
            setSelectedCoach={(selectedCoaches) =>
              handleChange(
                "coaches",
                selectedCoaches.map((coach) => coach.id || ""),
              )
            }
          />
        </GridItem>

        {props.displayPlayers && (
          <GridItem colSpan={4} data-testid="select-players">
            <MultiTagSelectMenu
              options={props.allPlayers || []}
              getOptionLabel={(option) => option.name}
              selectedTags={trainingGroupToEdit.players || []}
              handleTagSelection={(newPlayers: Player[]) => handleChange("players", newPlayers)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              label="Players"
              disableActive={true}
              dataTestId="player-select"
            />
          </GridItem>
        )}
      </Grid>
    </Stack>
  );
};
