import { gql } from "@apollo/client";

export const GET_USER_ORGANIZATION = gql`
  query GetUserOrganization {
    getUserOrganization {
      id
      name
      seasonStart
      seasonEnd
    }
  }
`;
