import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { SeasonPlanCalendar } from "./components/SeasonPlanCalendar/SeasonPlanCalendar";
import { SeasonPlanModal } from "./components/SeasonPlanModal/SeasonPlanModal";
import type { FullCalendarSeasonPlanEvent } from "../types";
import { EventClickArg, DateSelectArg } from "@fullcalendar/core";
import type { RecurringTrainingEvent, SeasonPlan, Player, SkillSet } from "../../../types";
import { formatDateToUTC, updateUTCTime } from "../../../common/utils";
import type { AlertContextType } from "../../../common/components/AlertProvider";

type SkillSetSelectionInput = {
  skillSetId: string;
  selectedSkillIds?: string[];
};

export type SeasonPlanInput = {
  id?: string;
  skillSetSelections: SkillSetSelectionInput[];
  recurringTrainingEventIds?: string[];
  playerIds?: string[];
  startDateTime: string;
  endDateTime: string;
  isOrganizationWide?: boolean;
  remove?: boolean;
};

type SeasonPlansProps = {
  seasonPlans: SeasonPlan[];
  allTrainingGroups: RecurringTrainingEvent[];
  allSkillSets: SkillSet[];
  allPlayers: Player[];
  onSaveSeasonPlan: (seasonPlanInput: SeasonPlanInput) => Promise<void>;
  showAlert: AlertContextType["showAlert"];
  currentBreakPoint: string;
  isLoading: boolean;
};

export const SeasonPlans: React.FC<SeasonPlansProps> = (props) => {
  const [fullCalendarEvents, setFullCalendarEvents] = useState<FullCalendarSeasonPlanEvent[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeFullCalendarEvent, setActiveFullCalendarEvent] = useState<FullCalendarSeasonPlanEvent | null>(null);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [selectedPlayers, setSelectedPlayers] = useState<Array<{ name: string }>>([]);
  const [selectedGroups, setSelectedGroups] = useState<Array<{ name: string }>>([]);
  const [showOnlyOrganizationWide, setShowOnlyOrganizationWide] = useState<boolean>(false);
  const [showOnlyRecurringTrainingEvents, setShowOnlyRecurringTrainingEvents] = useState<boolean>(false);
  const [showOnlyPlayerPlans, setShowOnlyPlayerPlans] = useState<boolean>(false);

  useEffect(() => {
    if (props.seasonPlans) {
      setFullCalendarEvents(
        props.seasonPlans.map((seasonPlan: SeasonPlan) => {
          const { id, startDateTime, endDateTime } = seasonPlan;
          const seasonPlanFCEvent: FullCalendarSeasonPlanEvent = {
            id: id || "",
            title: "Season Plan",
            start: new Date(startDateTime),
            end: new Date(endDateTime),
            seasonPlan,
          };
          return seasonPlanFCEvent;
        }),
      );
    }
  }, [props.seasonPlans]);

  const handleSaveSeasonPlan = async (seasonPlanInput: SeasonPlanInput) => {
    await props.onSaveSeasonPlan(seasonPlanInput);
    handleModalClose();
  };

  /** Handle clicking on an existing event (season plan). */
  const handleEventClick = (clickInfo: EventClickArg) => {
    const event = clickInfo.event;
    const seasonPlan: FullCalendarSeasonPlanEvent = {
      id: event.id,
      title: event.title,
      start: event.start!,
      end: event.end!,
      seasonPlan: event.extendedProps.seasonPlan,
    };
    setActiveFullCalendarEvent(seasonPlan);
    setStartDate(undefined);
    setEndDate(undefined);
    setIsModalOpen(true);
  };

  /** Handle selecting a date range to create a new season plan. */
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const startDate = selectInfo.start;
    const endDate = new Date(selectInfo.end);
    endDate.setDate(endDate.getDate() - 1); // Adjust for FullCalendar's exclusive end date

    setStartDate(formatDateToUTC(startDate));
    setEndDate(updateUTCTime(formatDateToUTC(endDate), "23:59"));
    setActiveFullCalendarEvent(null);
    setIsModalOpen(true);
  };

  /** Close the modal and reset state. */
  const handleModalClose = () => {
    setIsModalOpen(false);
    setActiveFullCalendarEvent(null);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  // Function to filter season plans based on selected filters
  const getFilteredSeasonPlans = () => {
    return fullCalendarEvents.filter((plan) => {
      // Filter based on selected players
      let playerMatch = true;
      if (selectedPlayers.length > 0) {
        const planPlayerNames = plan.seasonPlan.players?.map((player) => player.name) || [];
        playerMatch = selectedPlayers.some((selectedPlayer) => planPlayerNames.includes(selectedPlayer.name));
      }

      // Filter based on selected groups
      let groupMatch = true;
      if (selectedGroups.length > 0) {
        const planGroupNames =
          (!plan.seasonPlan.isOrganizationWide &&
            plan.seasonPlan.recurringTrainingEvents?.map((event) => event.name)) ||
          [];
        groupMatch = selectedGroups.some((selectedGroup) => planGroupNames.includes(selectedGroup.name));
      }

      // Filter based on showOnlyOrganizationWide, showOnlyRecurringTrainingEvents, showOnlyPlayerPlans
      // If none are selected, include all plans
      let typeMatch = true;
      if (showOnlyOrganizationWide) {
        typeMatch = plan.seasonPlan.isOrganizationWide === true;
      } else if (showOnlyRecurringTrainingEvents) {
        typeMatch =
          (plan.seasonPlan.recurringTrainingEvents?.length &&
            plan.seasonPlan.recurringTrainingEvents?.length > 0 &&
            plan.seasonPlan.isOrganizationWide === false) ||
          false;
      } else if (showOnlyPlayerPlans) {
        typeMatch = (plan.seasonPlan.players?.length && plan.seasonPlan.players?.length > 0) || false;
      }

      return playerMatch && groupMatch && typeMatch;
    });
  };

  return (
    <Box>
      <SeasonPlanCalendar
        eventsToDisplay={getFilteredSeasonPlans()}
        handleEventClick={handleEventClick}
        dateSelectHandler={handleDateSelect}
        showAlert={props.showAlert}
        isMobile={props.currentBreakPoint === "mobile"}
        isLoading={props.isLoading}
        allPlayers={props.allPlayers.map((player) => ({ name: player.name }))}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        allGroups={props.allTrainingGroups.map((group) => ({ name: group.name || "" }))}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        showOnlyOrganizationWide={showOnlyOrganizationWide}
        setShowOnlyOrganizationWide={setShowOnlyOrganizationWide}
        showOnlyRecurringTrainingEvents={showOnlyRecurringTrainingEvents}
        setShowOnlyRecurringTrainingEvents={setShowOnlyRecurringTrainingEvents}
        showOnlyPlayerPlans={showOnlyPlayerPlans}
        setShowOnlyPlayerPlans={setShowOnlyPlayerPlans}
      />

      {isModalOpen && (
        <SeasonPlanModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSave={handleSaveSeasonPlan}
          allSkillSets={props.allSkillSets}
          allTrainingGroups={props.allTrainingGroups}
          fullCalendarEvent={activeFullCalendarEvent}
          initialStartDate={activeFullCalendarEvent?.seasonPlan?.startDateTime || startDate}
          initialEndDate={activeFullCalendarEvent?.seasonPlan?.endDateTime || endDate}
          isMobile={props.currentBreakPoint === "mobile"}
        />
      )}
    </Box>
  );
};
