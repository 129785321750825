import { keyframes } from "@chakra-ui/react";

export const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideOutToLeft = keyframes`
  0% {
    opacity: 0.1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

export const slideOutToRight = keyframes`
  0% {
    opacity: 0.1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

/**
 * Helper function to check for duplicates in a string array
 *
 * @param {string[]} arr - The array to check
 * @returns {boolean} Returns true if duplicates are found
 */
const hasDuplicates = (arr: string[]): boolean => {
  const set = new Set(arr.map((item) => item.trim().toLowerCase()));
  return set.size !== arr.length;
};

/**
 * Helper function to ensure all strings in array are non-empty
 *
 * @param {string[]} arr - The array to check
 * @returns {boolean} Returns true if all are non-empty
 */
const allNonEmpty = (arr: string[]): boolean => {
  return arr.every((item) => item.trim().length > 0);
};

/**
 * Validates the data at the current step and returns a list of reasons if invalid.
 *
 * @param {number} activeStep Active step to validate
 * @param {object} data The data to validate
 * @param {string} data.email Email input
 * @param {string} data.password Password input
 * @param {string} data.organizationName Organization name
 * @param {object[]} data.centers List of centers, each with name and venues
 * @param {string[]} data.coachNames List of coach names
 * @param {string[]} data.athleteNames List of athlete names
 * @returns {string[]} Array of reasons why submit is disabled
 */
export const validationLogic = (
  activeStep: number,
  data: {
    email: string;
    password: string;
    organizationName: string;
    centers: { name: string; venues: string[] }[];
    coachNames: string[];
    athleteNames: string[];
  },
) => {
  const reasons: string[] = [];

  if (activeStep === 0) {
    // Step Account validations
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.email) {
      reasons.push("Email can't be empty");
    } else {
      if (!data.email.includes("@")) {
        reasons.push("Email must contain '@'");
      }
      if (!data.email.split("@")[1]?.includes(".")) {
        reasons.push("Email must contain a domain (e.g. '.com')");
      }
      if (/\s/.test(data.email)) {
        reasons.push("Email must not contain spaces");
      }
      if (!emailRegex.test(data.email)) {
        reasons.push("Email must be a valid format (e.g. 'example@domain.com')");
      }
    }

    if (!data.password) {
      reasons.push("Password can't be empty");
    } else if (data.password.length < 8) {
      reasons.push("Password must be at least 8 characters long");
    }
  }

  if (activeStep === 1) {
    // Step Organization validations
    if (!data.organizationName) reasons.push("Organization name can't be empty");

    // At least one center with a name
    if (data.centers.length === 0) {
      reasons.push("You must add at least one Center");
    } else {
      const centerNames = data.centers.map((c) => c.name.trim());
      if (centerNames.some((n) => n.length === 0)) {
        reasons.push("All centers must have a name");
      }
      if (hasDuplicates(centerNames)) {
        reasons.push("Center names must be unique");
      }

      // Check venues in each center
      data.centers.forEach((center, cIndex) => {
        const venueNames = center.venues.map((v) => v.trim());
        if (venueNames.length === 0 || venueNames.every((v) => v.length === 0)) {
          reasons.push(`Center ${cIndex + 1} must have at least one venue`);
        }
        if (venueNames.some((v) => v.length === 0)) {
          reasons.push(`All venues in center ${cIndex + 1} must have a name`);
        }
        if (hasDuplicates(venueNames)) {
          reasons.push(`Venues in center ${cIndex + 1} must be unique`);
        }
      });
    }
  }

  if (activeStep === 2) {
    // Step Team validations
    // It's optional data, but if user provided entries, they must not be empty or duplicates
    if (data.coachNames.length > 0) {
      if (!allNonEmpty(data.coachNames)) {
        reasons.push("All coach names must be non-empty");
      }
      if (hasDuplicates(data.coachNames)) {
        reasons.push("Coach names must be unique");
      }
    }

    if (data.athleteNames.length > 0) {
      if (!allNonEmpty(data.athleteNames)) {
        reasons.push("All athlete names must be non-empty");
      }
      if (hasDuplicates(data.athleteNames)) {
        reasons.push("Athlete names must be unique");
      }
    }
  }

  return reasons;
};
