/**
 * Format the input value to DD/MM/YYYY.
 *
 * @param value - The input value to be formatted.
 * @returns The formatted value.
 */
export const formatInputValue = (value: string): string => {
  const cleaned = value.replace(/[^\d]/g, "");
  let formattedValue = cleaned;
  if (cleaned.length >= 2) {
    formattedValue = `${cleaned.substring(0, 2)}/${cleaned.substring(2)}`;
  }
  if (cleaned.length >= 4) {
    formattedValue = `${cleaned.substring(0, 2)}/${cleaned.substring(2, 4)}/${cleaned.substring(4, 8)}`;
  }
  return formattedValue;
};
