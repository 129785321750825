import React from "react";
import { Stack, Box, Text, Divider } from "@chakra-ui/react";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { CommonIconButton } from "../../../../../common/components/CommonIconButton";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { MinusIcon } from "@chakra-ui/icons";

type Step_1_OrganizationProps = {
  organizationName: string;
  setOrganizationName: (value: string) => void;
  centers: { name: string; venues: string[] }[];
  setCenters: React.Dispatch<React.SetStateAction<{ name: string; venues: string[] }[]>>;
  loading: boolean;
};

/** Step_1_Organization renders the UI for the second step (Organization details) */
export const Step_1_Organization: React.FC<Step_1_OrganizationProps> = (props) => {
  const handleCenterChange = (index: number, value: string) => {
    const updatedCenters = [...props.centers];
    updatedCenters[index].name = value;
    props.setCenters(updatedCenters);
  };

  const handleVenueChange = (centerIndex: number, venueIndex: number, value: string) => {
    const updatedCenters = [...props.centers];
    updatedCenters[centerIndex].venues[venueIndex] = value;
    props.setCenters(updatedCenters);
  };

  const addCenter = () => {
    props.setCenters([...props.centers, { name: "", venues: [""] }]);
  };

  const addVenue = (centerIndex: number) => {
    const updatedCenters = [...props.centers];
    updatedCenters[centerIndex].venues.push("");
    props.setCenters(updatedCenters);
  };

  return (
    <Stack direction="column" spacing="12" marginBottom={{ mobile: "8", laptop: "0" }}>
      <Stack direction="column" spacing="4">
        <Box>
          <Text fontSize="large">Let's start with your organization's name. What should we call it?</Text>{" "}
          <Text as="span" color="blackAlpha.600" fontSize="medium">
            (For example, "Striveon Academy")
          </Text>
        </Box>
        <CommonInput
          placeholder="Organization Name"
          type="text"
          value={props.organizationName}
          onChange={(value) => props.setOrganizationName(value)}
          disabled={props.loading}
        />
      </Stack>

      <Stack direction="column" spacing="4">
        <Text fontSize="large">
          Where does your training take place? Add at least one Center and Venue{" "}
          <Text as="span" color="blackAlpha.600" fontSize="medium">
            (For example, "Club Striveon" containing "Court 1" and "Court 2")
          </Text>
        </Text>
        <Stack direction="column" spacing="4" divider={<Divider />}>
          {props.centers.map((center, centerIndex) => (
            <Stack key={centerIndex} direction="column" spacing="4">
              <Stack direction="row" spacing="2">
                <CommonInput
                  placeholder={`Center Name`}
                  value={center.name}
                  onChange={(value) => handleCenterChange(centerIndex, value)}
                  disabled={props.loading}
                />
                {centerIndex > 0 && (
                  <CommonIconButton
                    icon={<MinusIcon />}
                    onClick={() => {
                      const updatedCenters = [...props.centers];
                      updatedCenters.splice(centerIndex, 1);
                      props.setCenters(updatedCenters);
                    }}
                  />
                )}
              </Stack>

              {center.venues.map((venue, venueIndex) => (
                <Stack key={venueIndex} direction="row" spacing="2">
                  <CommonInput
                    placeholder={`Venue Name`}
                    value={venue}
                    onChange={(value) => handleVenueChange(centerIndex, venueIndex, value)}
                    disabled={props.loading}
                  />
                  {(venueIndex > 0 || centerIndex > 0) && (
                    <CommonIconButton
                      icon={<MinusIcon />}
                      onClick={() => {
                        const updatedCenters = [...props.centers];
                        updatedCenters[centerIndex].venues.splice(venueIndex, 1);
                        props.setCenters(updatedCenters);
                      }}
                    />
                  )}
                </Stack>
              ))}
              <CommonButton variantType="outlineSecondary" onClick={() => addVenue(centerIndex)}>
                Add another Venue
              </CommonButton>
            </Stack>
          ))}

          <CommonButton variantType="outlineSecondary" onClick={addCenter}>
            Add another Center
          </CommonButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
