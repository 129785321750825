import React from "react";
import { Athlete } from "../../../../../../../../types";
import { SeasonPlanCard } from "../../../../../../../../common/components/SeasonPlanCard";
import { Box, Stack, Text } from "@chakra-ui/react";
import { NotesInput } from "../../NotesInput";

/** Props for the AthleteTab component. */
type AthleteTabProps = {
  /** Athlete object containing athlete details. */
  athlete: Athlete;
  /** Training note of the athlete. */
  trainingNote: string;
  /** Callback function to handle note changes. */
  onNoteChange: (newNote: string) => void;
};

/** A tab component displaying athlete specific details */
export const AthleteTab: React.FC<AthleteTabProps> = (props) => {
  return (
    <Box paddingX="6">
      <Box marginBottom={{ mobile: "6", laptop: "8" }}>
        <Text fontSize="lg" marginBottom="2">
          Training Notes
        </Text>
        <NotesInput
          notes={props.trainingNote}
          label={`Add notes for ${props.athlete.name} only`}
          setNotes={props.onNoteChange}
          minRows={4}
        />
      </Box>

      <Text fontSize="lg" marginBottom="2">
        Training Targets for {props.athlete.name} only
      </Text>
      {props.athlete.seasonPlans?.length ? (
        <Stack direction="column" spacing={{ mobile: "3", laptop: "6" }}>
          {props.athlete.seasonPlans.map((seasonPlan) => (
            <SeasonPlanCard key={seasonPlan.id} skillSets={seasonPlan.skillSets} disableHover={true} />
          ))}
        </Stack>
      ) : (
        <SeasonPlanCard skillSets={undefined} disableHover={true} />
      )}
    </Box>
  );
};
