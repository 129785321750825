import React from "react";

import { Grid, GridItem, Stack } from "@chakra-ui/react";
import type { Venue, TrainingGroup } from "../../../../../types";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { CommonSelect } from "../../../../../common/components/CommonSelect";
import { TimePicker } from "../../../../../common/components/TimePicker";
import { VenueSelect } from "../../../../../common/components/VenueSelect";
import { CoachSelect } from "../../../../../common/components/CoachSelect";
import { weekdayOptions } from "../../../../../common/utils/dataProcessing";

type SingleTrainingGroupProps = {
  event: TrainingGroup;
  allVenues: Venue[];
};

/** Component for displaying a single training group in a read-only state */
export const SingleTrainingGroup: React.FC<SingleTrainingGroupProps> = (props) => {
  return (
    <Stack direction="row" spacing="4" width="100%">
      <Grid templateColumns="repeat(16, 1fr)" gap="4" alignItems="center" width="100%">
        <GridItem colSpan={3}>
          <CommonSelect
            placeholder="Training Season"
            value={props.event.trainingSeason.id || ""}
            options={[
              {
                value: props.event.trainingSeason.id || "",
                label: props.event.trainingSeason.name || "",
              },
            ]}
            onChange={() => {}}
            isDisabled={true}
          />
        </GridItem>
        <GridItem colSpan={3}>
          <CommonInput placeholder="Group name" value={props.event.name || ""} onChange={() => {}} disabled={true} />
        </GridItem>
        <GridItem colSpan={2}>
          <CommonSelect
            placeholder="Weekday"
            value={props.event.recurrenceWeekday}
            options={weekdayOptions.map((weekday) => ({
              value: weekday.value,
              label: weekday.label,
            }))}
            isDisabled={true}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <TimePicker label="Start" selectedTime={props.event.recurrenceStartTime} disabled={true} />
        </GridItem>
        <GridItem colSpan={2}>
          <TimePicker label="End" selectedTime={props.event.recurrenceEndTime} disabled={true} />
        </GridItem>

        <GridItem colSpan={2}>
          <VenueSelect venues={props.allVenues} selectedVenue={props.event.venue} disabled={true} />
        </GridItem>
        <GridItem colSpan={2}>
          <CoachSelect selectedCoaches={props.event.coaches?.map((coach) => coach) || []} disabled={true} />
        </GridItem>
      </Grid>
    </Stack>
  );
};
