import React from "react";
import { Stack, Box } from "@chakra-ui/react";
import { MultiTagSelectMenu } from "../../../../../../common/components/MultiTagSelectMenu";
import { SwitchWithLabel } from "../../../../../../common/components/SwitchWithLabel/SwitchWithLabel";

/** Props for the CalendarHeader component. */
type SeasonPlanCalendarHeaderProps = {
  /** All available players. */
  allPlayers: Array<{ name: string }>;
  /** Selected players. */
  selectedPlayers: Array<{ name: string }>;
  /** All available groups. */
  allGroups: Array<{ name: string }>;
  /** Selected groups. */
  selectedGroups: Array<{ name: string }>;
  /** Loading state indicator. */
  isLoading: boolean;
  /** Filter to show only organization wide season plans */
  showOnlyOrganizationWide: boolean;
  /** Filter to show only recurring training event specific season plans */
  showOnlyRecurringTrainingEvents: boolean;
  /** Filter to show only individual player season plans */
  showOnlyPlayerPlans: boolean;
  /** Function to set selected players. */
  setSelectedPlayers: (players: Array<{ name: string }>) => void;
  /** Function to set selected groups. */
  setSelectedGroups: (groups: Array<{ name: string }>) => void;
  /** Function to toggle showing only organization wide season plans */
  setShowOnlyOrganizationWide: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only recurring training event specific season plans */
  setShowOnlyRecurringTrainingEvents: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only individual player season plans */
  setShowOnlyPlayerPlans: React.Dispatch<React.SetStateAction<boolean>>;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
};

/** SeasonPlanCalendarHeader component to control and filter calendar view settings. */
export const SeasonPlanCalendarHeader: React.FC<SeasonPlanCalendarHeaderProps> = (props) => {
  return (
    <Box id="season-plan-calendar-header" data-testid="season-plan-calendar-header">
      <Stack
        direction={{ mobile: "column", laptop: "row" }}
        spacing={{ mobile: "2", laptop: "4" }}
        justifyContent="center"
        alignItems="center"
        data-testid="calendar-filter-controls"
      >
        <MultiTagSelectMenu
          selectedTags={props.selectedPlayers}
          handleTagSelection={(selectedValues) => {
            props.setSelectedPlayers(selectedValues);
            props.setShowOnlyOrganizationWide(false);
            props.setShowOnlyPlayerPlans(false);
            props.setShowOnlyRecurringTrainingEvents(false);
            props.setSelectedGroups([]);
          }}
          options={props.allPlayers}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          label="Filter by players"
          width={props.isMobile ? "100%" : "80"}
          dataTestId="player-filter"
        />
        <MultiTagSelectMenu
          selectedTags={props.selectedGroups}
          handleTagSelection={(selectedValues) => {
            props.setSelectedGroups(selectedValues);
            props.setShowOnlyOrganizationWide(false);
            props.setShowOnlyPlayerPlans(false);
            props.setShowOnlyRecurringTrainingEvents(false);
            props.setSelectedPlayers([]);
          }}
          options={props.allGroups}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          label="Filter by groups"
          width={props.isMobile ? "100%" : "80"}
          dataTestId="group-filter"
        />
        <SwitchWithLabel
          checked={props.showOnlyOrganizationWide}
          label="Everyone only"
          onChange={() => {
            props.setShowOnlyOrganizationWide((prev) => !prev);
            props.setShowOnlyPlayerPlans(false);
            props.setShowOnlyRecurringTrainingEvents(false);
            props.setSelectedPlayers([]);
            props.setSelectedGroups([]);
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="free-spots-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlyRecurringTrainingEvents}
          label="Individual Groups only"
          onChange={() => {
            props.setShowOnlyRecurringTrainingEvents((prev) => !prev);
            props.setShowOnlyPlayerPlans(false);
            props.setShowOnlyOrganizationWide(false);
            props.setSelectedPlayers([]);
            props.setSelectedGroups([]);
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="recurring-events-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlyPlayerPlans}
          label="Individual Players only"
          onChange={() => {
            props.setShowOnlyPlayerPlans((prev) => !prev);
            props.setShowOnlyRecurringTrainingEvents(false);
            props.setShowOnlyOrganizationWide(false);
            props.setSelectedPlayers([]);
            props.setSelectedGroups([]);
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="single-events-switch"
        />
      </Stack>
    </Box>
  );
};
