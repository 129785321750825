import React, { useRef, useState } from "react";
import { Session } from "../../../types";
import {
  Box,
  Grid,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { tagStyles, cardStyles } from "../../utils/styles";
import { extractLocalTime, extractDateFromUTC } from "../../utils/dateAndTime";
import { CommonCheckbox } from "../CommonCheckbox";

/** SessionCard properties */
type SessionCardProps = {
  /** The session to display */
  session: Session;
  /** Callback function for selecting a session */
  onSelectSession?: (session: Session) => void;
  /** Whether the card is selected */
  selected?: boolean;
  /** Whether to show the checkbox */
  showCheckbox?: boolean;
  /** Whether to disable hover */
  disableHover?: boolean;
  /** Whether the card is read-only */
  readOnly?: boolean;
  /** Whether the user is using a mobile device */
  isMobile?: boolean;
  /** Whether to display created by & created at */
  displayMetadata?: boolean;
  /** The data-testid attribute to be added to the component */
  dataTestId?: string;
};

/** SessionCard component */
export const SessionCard: React.FC<SessionCardProps> = (props) => {
  const [isHoveringAccordion, setIsHoveringAccordion] = useState(false);
  const accordionRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const calculateRunningDuration = (index: number) => {
    const duration = props.session.sessionDrills
      .slice(0, index + 1)
      .reduce((total, sessionDrill) => total + sessionDrill.duration, 0);
    return isNaN(duration) ? 0 : duration;
  };

  const getPreviousDuration = (index: number) => {
    const duration = index === 0 ? 0 : calculateRunningDuration(index - 1);
    return isNaN(duration) ? 0 : duration;
  };

  const handleCardClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const target = e.target as HTMLElement;
    const isAccordionElement =
      target.getAttribute("role") === "button" ||
      target.getAttribute("role") === "region" ||
      target.closest(".chakra-accordion__button") ||
      target.closest(".chakra-accordion__panel");

    if (isAccordionElement) {
      return;
    }

    props.onSelectSession?.(props.session);
  };

  const SessionCardContent = (
    <Box
      paddingY="4"
      paddingX={{ mobile: "2", laptop: "4" }}
      {...cardStyles({ disableHover: props.disableHover, selected: props.selected })}
      data-testid={props.dataTestId}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="6">
        <Box width="100%">
          <Box>
            <Stack direction="row" justifyContent="space-between" marginBottom="2">
              <Stack direction="row" alignItems="center">
                <Text fontSize="large" color="blackAlpha.800">
                  {props.session.name}
                </Text>
                <Text fontSize="medium" color="blackAlpha.800">
                  ({props.session.totalDuration} min)
                </Text>
              </Stack>
              {!props.showCheckbox && (
                <Stack direction="row" spacing={1}>
                  {props.session.tags?.map((tag, index) => (
                    <Tag key={index} height={{ mobile: "fit-content", laptop: undefined }} {...tagStyles()}>
                      <Tooltip hasArrow label={tag} aria-label="Tag" placement="top">
                        <TagLabel>{tag}</TagLabel>
                      </Tooltip>
                    </Tag>
                  ))}
                </Stack>
              )}
              {props.showCheckbox && props.isMobile && <CommonCheckbox checked={!!props.selected} />}
            </Stack>

            {props.isMobile ? (
              <Stack direction="column" width="100%" spacing="3" marginBottom="3">
                <Text fontSize="medium" color="blackAlpha.800">
                  {props.session.goal}
                </Text>
                <Stack direction="row" spacing="1">
                  {props.session.skillSets?.map((skillSet) => (
                    <Tag key={skillSet.id} height="fit-content" {...tagStyles()}>
                      <TagLabel>{skillSet.name}</TagLabel>
                    </Tag>
                  ))}
                </Stack>
              </Stack>
            ) : (
              <Grid templateColumns="min-content 1fr" columnGap="4" rowGap="4" marginBottom="6">
                <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                  Goal
                </Text>
                <Text as="dd" fontSize="medium" color="blackAlpha.800">
                  {props.session.goal}
                </Text>
                <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                  Skill Sets
                </Text>
                <Stack as="dd" direction="row" spacing="1">
                  {props.session.skillSets?.map((skillSet) => (
                    <Tag key={skillSet.id} {...tagStyles()}>
                      <Tooltip hasArrow label={skillSet.name} aria-label="Tag" placement="top">
                        <TagLabel>{skillSet.name}</TagLabel>
                      </Tooltip>
                    </Tag>
                  )) || "-"}
                </Stack>
              </Grid>
            )}

            <Accordion
              allowMultiple
              className="accordion"
              display="flex"
              flexDirection="column"
              gap="2"
              onMouseEnter={() => setIsHoveringAccordion(true)}
              onMouseLeave={() => setIsHoveringAccordion(false)}
            >
              {props.session.sessionDrills.map((sessionDrill, index) => (
                <AccordionItem
                  key={index}
                  /* don't use commonStyles due to the focus within border color */
                  border="1px solid"
                  borderRadius="md"
                  boxShadow={props.selected ? "none" : "sm"}
                  borderColor={props.selected ? "orange.400" : "blackAlpha.300"}
                  transition="all 0.3s ease"
                  _hover={{ borderColor: "orange.500", boxShadow: "none" }}
                >
                  <AccordionButton
                    ref={(el) => (accordionRefs.current[index] = el)}
                    paddingY={{ mobile: "4", laptop: "2" }}
                    paddingX={{ mobile: "2", laptop: "4" }}
                    width="100%"
                    borderTopRadius="md"
                    borderBottomRadius="md"
                    transition="all 0.3s ease"
                    _expanded={{ borderBottomRadius: "none" }}
                    _hover={{ backgroundColor: "transparent" }}
                  >
                    <Grid
                      display={{ mobile: "flex", laptop: "grid" }}
                      flexDirection={{ mobile: "column", laptop: undefined }}
                      templateColumns="min-content 1fr"
                      columnGap="4"
                      width="100%"
                    >
                      {!props.isMobile && (
                        <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                          Drill {index + 1}
                        </Text>
                      )}
                      <Stack as="dd" direction="row" justifyContent="space-between">
                        <Text fontSize="medium" color="blackAlpha.800" textAlign="start">
                          {sessionDrill.drill.name}
                        </Text>
                        <Stack direction="row" spacing="6" alignItems="center">
                          <Text fontSize="medium" color="blackAlpha.800" whiteSpace="nowrap">
                            {getPreviousDuration(index)} - {calculateRunningDuration(index)} min
                          </Text>
                          <AccordionIcon />
                        </Stack>
                      </Stack>
                    </Grid>
                  </AccordionButton>
                  <AccordionPanel
                    paddingY="4"
                    paddingX={{ mobile: "2", laptop: "4" }}
                    borderBottomRadius="md"
                    cursor="pointer"
                    onClick={() => {
                      const button = accordionRefs.current[index];
                      if (button) {
                        button.click();
                      }
                    }}
                  >
                    <Grid templateColumns={{ mobile: "1", laptop: "min-content 1fr" }} columnGap="4" rowGap="4">
                      {!props.isMobile && (
                        <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                          Goal
                        </Text>
                      )}
                      <Text as="dd" fontSize="medium" color="blackAlpha.800">
                        {sessionDrill.drill.goal}
                      </Text>
                      {!props.isMobile && (
                        <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                          Implementation
                        </Text>
                      )}
                      <Text as="dd" fontSize="medium" color="blackAlpha.800">
                        {sessionDrill.drill.implementation}
                      </Text>
                    </Grid>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
          {props.displayMetadata && !props.isMobile && props.session?.createdAt && (
            <Stack direction="row" justifyContent="space-between" marginTop="4">
              {props.session.createdBy?.username ? (
                <Text fontSize="xs" color="blackAlpha.600">
                  {props.session.createdBy.username}
                </Text>
              ) : (
                <Box flex={1} />
              )}
              <Stack direction="row" spacing="4">
                <Text fontSize="xs" color="blackAlpha.600">
                  {props.session?.updatedAt
                    ? extractDateFromUTC(props.session.updatedAt)
                    : extractDateFromUTC(props.session.createdAt)}
                </Text>
                <Text fontSize="xs" color="blackAlpha.600">
                  {props.session?.updatedAt
                    ? extractLocalTime(props.session.updatedAt)
                    : extractLocalTime(props.session.createdAt)}
                </Text>
              </Stack>
            </Stack>
          )}
        </Box>
        {props.showCheckbox && !props.isMobile && <CommonCheckbox checked={!!props.selected} />}
      </Stack>
    </Box>
  );

  return props.onSelectSession && !props.readOnly ? (
    <Box onClick={handleCardClick}>{SessionCardContent}</Box>
  ) : (
    <Box>{SessionCardContent}</Box>
  );
};
