import React, { useState } from "react";
import type { MissingTrainingGroupEvent } from "../../../../../types";
import { CommonScrollbar } from "../../../../../common/components/CommonScrollbar";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { commonStyles } from "../../../../../common/utils/styles";
import { extractDateFromUTC } from "../../../../../common/utils/dateAndTime";
import { CommonCheckbox } from "../../../../../common/components/CommonCheckbox";
import { SwitchWithLabel } from "../../../../../common/components/SwitchWithLabel/SwitchWithLabel";
import { LoadingOverlay } from "../../../../../common/components/LoadingOverlay";
import { getWeek } from "date-fns";

type TrainingGroupEventSelectionListProps = {
  trainingGroupEvents: MissingTrainingGroupEvent[];
  onSelectionChange: (newSelection: MissingTrainingGroupEvent[]) => void;
  loading: boolean;
};

export const TrainingGroupEventSelectionList: React.FC<TrainingGroupEventSelectionListProps> = (props) => {
  const [selectedEvents, setSelectedEvents] = useState<MissingTrainingGroupEvent[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleEventClick = (event: React.MouseEvent, trainingGroupEvent: MissingTrainingGroupEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const isSelected = selectedEvents.some(
      (m) =>
        m.startDateTime === trainingGroupEvent.startDateTime &&
        m.trainingGroup.id === trainingGroupEvent.trainingGroup.id,
    );

    let newSelectedEvents: MissingTrainingGroupEvent[];
    if (isSelected) {
      newSelectedEvents = selectedEvents.filter(
        (m) =>
          !(
            m.startDateTime === trainingGroupEvent.startDateTime &&
            m.trainingGroup.id === trainingGroupEvent.trainingGroup.id
          ),
      );
    } else {
      newSelectedEvents = [...selectedEvents, trainingGroupEvent];
    }

    setSelectedEvents(newSelectedEvents);
    props.onSelectionChange(newSelectedEvents);
    setIsAllSelected(newSelectedEvents.length === props.trainingGroupEvents.length);
  };

  const handleSelectAllToggle = () => {
    if (selectedEvents.length === props.trainingGroupEvents.length) {
      setSelectedEvents([]);
      setIsAllSelected(false);
      props.onSelectionChange([]);
    } else {
      setSelectedEvents(props.trainingGroupEvents);
      setIsAllSelected(true);
      props.onSelectionChange(props.trainingGroupEvents);
    }
  };

  // TODO: currently it's possible to pick identical groups from different seasons, resulting to error, fix this

  return (
    <Box
      height="100%"
      overflow="hidden"
      alignContent={props.trainingGroupEvents.length === 0 ? "center" : undefined}
      marginTop="4"
    >
      <LoadingOverlay display={props.loading} />
      {props.trainingGroupEvents.length === 0 ? (
        <Text textAlign="center" fontSize="large" color="blackAlpha.600">
          There are no training events available to create for this season
        </Text>
      ) : (
        <Stack direction="column" spacing="4" alignItems="center" height="100%">
          <Stack direction="column" spacing="1" alignItems="center">
            <SwitchWithLabel
              width="240px"
              checked={isAllSelected}
              label={isAllSelected ? "Clear all selections" : "Select all training events"}
              onChange={handleSelectAllToggle}
              dataTestId="select-all-switch"
            />
            <Text fontSize="medium" color="blackAlpha.600">
              {selectedEvents.length} selected
            </Text>
          </Stack>
          <CommonScrollbar
            height="100%"
            overflow="auto"
            paddingLeft="6"
            paddingRight="2"
            invisibleBorderWidth="0px 8px 0px 0px"
          >
            <Stack direction="column" spacing="2" width="100%">
              {props.trainingGroupEvents.map((trainingGroupEvent) => {
                const isSelected = selectedEvents.some(
                  (m) =>
                    m.startDateTime === trainingGroupEvent.startDateTime &&
                    m.trainingGroup.id === trainingGroupEvent.trainingGroup.id,
                );
                return (
                  <Box
                    key={`${trainingGroupEvent.trainingGroup.id}-${trainingGroupEvent.startDateTime}`}
                    onClick={(event) => handleEventClick(event, trainingGroupEvent)}
                    paddingY="1"
                    paddingX="2"
                    cursor="pointer"
                    {...commonStyles(isSelected)}
                  >
                    <Grid
                      templateColumns="repeat(16, 1fr)"
                      gap="4"
                      width="100%"
                      justifyItems="start"
                      alignItems="center"
                    >
                      <GridItem colSpan={3} maxWidth="100%">
                        <Stack direction="row" spacing="2" maxWidth="inherit">
                          <CommonCheckbox checked={isSelected} />
                          <Text maxWidth="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                            {trainingGroupEvent.trainingGroup.name}
                          </Text>
                        </Stack>
                      </GridItem>
                      <GridItem colSpan={3}>
                        <Text maxWidth="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {extractDateFromUTC(trainingGroupEvent.startDateTime, ".", true)} - Week{" "}
                          {getWeek(trainingGroupEvent.startDateTime)}
                        </Text>
                      </GridItem>
                      <GridItem colSpan={2} maxWidth="100%">
                        <Text maxWidth="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {trainingGroupEvent.venue.name}
                        </Text>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <Text>{trainingGroupEvent.coaches?.map((coach) => coach.name).join(", ") || "No coaches"}</Text>
                      </GridItem>
                      <GridItem colSpan={6} maxWidth="100%">
                        <Text maxWidth="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {trainingGroupEvent.athletes?.map((athlete) => athlete.name).join(", ") || "No athletes"}
                        </Text>
                      </GridItem>
                    </Grid>
                  </Box>
                );
              })}
            </Stack>
          </CommonScrollbar>
        </Stack>
      )}
    </Box>
  );
};
