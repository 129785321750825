import React, { useState, useEffect } from "react";
import { Box, useBreakpoint } from "@chakra-ui/react";
import { useAlert } from "../../../common/components/AlertProvider";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_SEASON_PLANS } from "../graphql/get-all-season-plans.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { GET_ALL_RECURRING_TRAINING_EVENTS_FOR_SEASON_PLAN } from "../graphql/get-all-recurring-training-events-for-season-plan.query";
import { GET_ALL_PLAYERS_FOR_SEASON_PLAN } from "../graphql/get-all-players-for-season-plan.query";
import { SAVE_SEASON_PLAN } from "../graphql/save-season-plan.mutation";
import { SeasonPlans } from "../components/SeasonPlans";
import { handleError } from "../../../common/utils";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_SEASON_PLAN } from "../graphql/get-all-recurring-training-event-metadatas-for-season-plan.query";

export type SkillSetSelectionInput = {
  skillSetId: string;
  selectedSkillIds?: string[];
};

export type SeasonPlanInput = {
  id?: string;
  skillSetSelections: SkillSetSelectionInput[];
  recurringTrainingEventIds?: string[];
  playerIds?: string[];
  startDateTime: string;
  endDateTime: string;
  isOrganizationWide?: boolean;
  remove?: boolean;
};

export const SeasonPlanContainer: React.FC = () => {
  const currentBreakPoint = useBreakpoint({ ssr: false });
  const { showAlert, hideAlert } = useAlert();

  const {
    data: seasonPlanData,
    loading: seasonPlanDataLoading,
    error: seasonPlanDataError,
  } = useQuery(GET_ALL_SEASON_PLANS);

  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);

  const {
    data: recurringTrainingEventsData,
    loading: recurringTrainingEventsDataLoading,
    error: recurringTrainingEventsDataError,
  } = useQuery(GET_ALL_RECURRING_TRAINING_EVENTS_FOR_SEASON_PLAN);

  const {
    data: playerData,
    loading: playerDataLoading,
    error: playerDataError,
  } = useQuery(GET_ALL_PLAYERS_FOR_SEASON_PLAN);

  const [saveSeasonPlanMutation, { loading: saveSeasonPlanLoading, error: saveSeasonPlanError }] = useMutation(
    SAVE_SEASON_PLAN,
    {
      refetchQueries: [
        { query: GET_ALL_SEASON_PLANS },
        { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_SEASON_PLAN },
      ],
      awaitRefetchQueries: true,
    },
  );

  const isLoading =
    seasonPlanDataLoading ||
    skillSetDataLoading ||
    recurringTrainingEventsDataLoading ||
    saveSeasonPlanLoading ||
    playerDataLoading;

  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      skillSetDataError ||
      seasonPlanDataError ||
      recurringTrainingEventsDataError ||
      saveSeasonPlanError ||
      playerDataError
    ) {
      const errors = [
        skillSetDataError,
        seasonPlanDataError,
        recurringTrainingEventsDataError,
        saveSeasonPlanError,
        playerDataError,
      ].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [skillSetDataError, seasonPlanDataError, recurringTrainingEventsDataError, saveSeasonPlanError, playerDataError]);

  const handleSaveSeasonPlan = async (seasonPlanInput: SeasonPlanInput) => {
    if (seasonPlanInput.remove) {
      showAlert("Deleting Season Plan", "info", undefined, true);
    } else if (seasonPlanInput.id) {
      showAlert("Updating Season Plan", "info", undefined, true);
    } else {
      showAlert("Creating new Season Plan", "info", undefined, true);
    }

    try {
      const result = await saveSeasonPlanMutation({
        variables: {
          data: seasonPlanInput,
        },
      });

      if (result.data.saveSeasonPlan === null && seasonPlanInput.remove) {
        showAlert("Season Plan deleted!", "success", 5000);
      } else if (seasonPlanInput.id) {
        showAlert("Season Plan updated!", "success", 5000);
      } else {
        showAlert("Season Plan created!", "success", 5000);
      }
    } catch {
      // Error handling is done in the useEffect
    }
  };

  return (
    <Box id="season-plan">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <SeasonPlans
            seasonPlans={seasonPlanData?.getAllSeasonPlans}
            allTrainingGroups={recurringTrainingEventsData?.getAllRecurringTrainingEvents}
            allSkillSets={skillSetData?.getAllSkillSets}
            allPlayers={playerData?.getAllPlayers}
            onSaveSeasonPlan={handleSaveSeasonPlan}
            showAlert={showAlert}
            currentBreakPoint={currentBreakPoint}
            isLoading={isLoading}
          />
        </Box>
      )}
    </Box>
  );
};
