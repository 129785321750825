import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { useAlert } from "../../../common/components/AlertProvider";
import { GET_ALL_PLAYERS } from "../../../common/graphql/get-all-players.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { SAVE_PLAYER } from "../graphql/save-player.mutation";
import { SAVE_RECURRING_TRAINING_EVENT } from "../../../common/graphql/save-recurring-training-event.mutation";
import { SAVE_NOTE } from "../graphql/save-note.mutation";
import { SAVE_EVALUATION } from "../graphql/save-evaluation.mutation";
import { handleError } from "../../../common/utils";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Players } from "../components/Players";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";
import type { NoteInput } from "../../../types";
import { GET_ALL_RECURRING_TRAINING_EVENT_METADATAS } from "../../../common/graphql/get-all-recurring-training-event-metadatas.query";
import { GET_ALL_RECURRING_TRAINING_EVENTS } from "../../Settings/graphql/get-all-recurring-training-events.query";
import { GET_ALL_EVALUATION_CRITERIA } from "../../SkillSets/graphql/get-all-evaluation-criteria.query";

export type PlayerInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type RecurringTrainingEventInput = {
  /** Weekday for event, 1 = monday */
  recurrenceWeekday: number;
  /** Start time for event, e.g. "09.00" */
  recurrenceStartTime: string;
  /** End time for event, e.g. "10.30" */
  recurrenceEndTime: string;
  /** Unique identifier */
  id?: string;
  /** Array of coach IDs */
  coachIds?: string[];
  /** Array of Player IDs */
  playerIds?: string[];
  /** Court ID */
  courtId?: string;
  /** Indicates if the event should be removed */
  remove?: boolean;
  /** When the first possible date is for recurring event */
  recurrenceStartDate?: string;
  /** When the last possible date is for recurring event */
  recurrenceEndDate?: string;
  /** Training group name */
  name: string;
};

export type SkillEvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Skill ID */
  skillId: string;
  /** Rating */
  rating: number;
};

export type SkillSetEvaluationInput = {
  id?: string;
  skillSetId: string;
  skillEvaluations: SkillEvaluationInput[];
  comment?: string;
};

export type EvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Player ID */
  playerId: string;
  /** Array of skill set evaluations */
  skillSetEvaluations: SkillSetEvaluationInput[];
  /** Indicates if the evaluation should be removed */
  remove?: boolean;
};

export const PlayerContainer: React.FC = () => {
  const { showAlert, hideAlert } = useAlert();

  // Queries
  const { data: playerData, loading: playerDataLoading, error: playerDataError } = useQuery(GET_ALL_PLAYERS);
  const { data: coachData, loading: coachDataLoading, error: coachDataError } = useQuery(GET_ALL_COACHES);
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_USER_ORGANIZATION);
  const {
    data: evaluationCriteriaData,
    loading: evaluationCriteriaLoading,
    error: evaluationCriteriaError,
  } = useQuery(GET_ALL_EVALUATION_CRITERIA);

  // Mutations
  const [savePlayerMutation, { loading: savePlayerLoading, error: savePlayerError }] = useMutation(SAVE_PLAYER, {
    refetchQueries: [
      { query: GET_ALL_PLAYERS },
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS }, // to trigger update in Calendar
      { query: GET_ALL_RECURRING_TRAINING_EVENTS }, // to trigger update in Settings
    ],
    awaitRefetchQueries: true,
  });

  const [
    saveRecurringTrainingEventMutation,
    { loading: saveRecurringTrainingEventLoading, error: saveRecurringTrainingEventError },
  ] = useMutation(SAVE_RECURRING_TRAINING_EVENT, {
    refetchQueries: [
      { query: GET_ALL_PLAYERS },
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS }, // to trigger update in calendar for events
      { query: GET_ALL_RECURRING_TRAINING_EVENTS }, // to trigger update in Settings
    ],
    awaitRefetchQueries: true,
  });

  const [saveNoteMutation, { loading: saveNoteLoading, error: saveNoteError }] = useMutation(SAVE_NOTE, {
    refetchQueries: [{ query: GET_ALL_PLAYERS }],
    awaitRefetchQueries: true,
  });

  const [saveEvaluationMutation, { loading: saveEvaluationLoading, error: saveEvaluationError }] = useMutation(
    SAVE_EVALUATION,
    {
      refetchQueries: [{ query: GET_ALL_PLAYERS }],
      awaitRefetchQueries: true,
    },
  );

  // Loading and error states
  const isLoading =
    playerDataLoading || coachDataLoading || skillSetDataLoading || organizationLoading || evaluationCriteriaLoading;

  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      playerDataError ||
      coachDataError ||
      skillSetDataError ||
      organizationError ||
      savePlayerError ||
      saveRecurringTrainingEventError ||
      saveNoteError ||
      saveEvaluationError ||
      evaluationCriteriaError
    ) {
      const errors = [
        playerDataError,
        coachDataError,
        skillSetDataError,
        organizationError,
        savePlayerError,
        saveRecurringTrainingEventError,
        saveNoteError,
        saveEvaluationError,
        evaluationCriteriaError,
      ].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [
    playerDataError,
    coachDataError,
    skillSetDataError,
    organizationError,
    savePlayerError,
    saveRecurringTrainingEventError,
    saveNoteError,
    saveEvaluationError,
    evaluationCriteriaError,
  ]);

  // Handlers for saving entities
  const handleSavePlayer = async (playerInput: PlayerInput) => {
    try {
      if (playerInput.remove) {
        showAlert("Removing Player", "info", undefined, true);
      } else if (playerInput.id) {
        showAlert("Updating Player", "info", undefined, true);
      } else {
        showAlert("Creating new Player", "info", undefined, true);
      }

      await savePlayerMutation({ variables: { data: playerInput } });

      if (playerInput.remove) {
        showAlert("Player removed!", "success", 5000);
      } else {
        if (playerInput.id) {
          showAlert("Player updated!", "success", 5000);
        } else {
          showAlert("Player created!", "success", 5000);
        }
      }
    } catch (error) {
      // Error handling done via useEffect
    }
  };

  const handleSaveRecurringTrainingEvent = async (eventInput: RecurringTrainingEventInput) => {
    try {
      if (eventInput.remove) {
        showAlert("Removing player from training group", "info", undefined, true);
      } else {
        showAlert("Updating player training groups", "info", undefined, true);
      }

      const result = await saveRecurringTrainingEventMutation({ variables: { data: eventInput } });

      if (result.data.saveRecurringTrainingEvent && eventInput.remove) {
        // some player(s) left in the recurring event
        showAlert("Player removed from the training group!", "success", 5000);
      } else if (result.data.saveRecurringTrainingEvent === null && eventInput.remove) {
        // no players left in the recurring event
        showAlert("Deleted training group!", "success", 5000);
      } else if (result.data.saveRecurringTrainingEvent) {
        showAlert("Updated player training groups!", "success", 5000);
      }
    } catch (error) {
      // Error handling done via useEffect
    }
  };

  const handleSaveNote = async (noteInput: NoteInput) => {
    try {
      showAlert("Saving note", "info", undefined, true);

      await saveNoteMutation({ variables: { data: noteInput } });

      showAlert("Note saved!", "success", 5000);
    } catch (error) {
      // Error handling done via useEffect
    }
  };

  const handleSaveEvaluation = async (evaluationInput: EvaluationInput) => {
    try {
      showAlert("Updating Evaluation", "info", undefined, true);

      await saveEvaluationMutation({ variables: { data: evaluationInput } });

      showAlert("Evaluation saved!", "success", 5000);
    } catch (error) {
      // Error handling done via useEffect
    }
  };

  return (
    <Box id="players-container">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <Players
            players={playerData?.getAllPlayers || []}
            coaches={coachData?.getAllCoaches || []}
            skillSets={skillSetData?.getAllSkillSets || []}
            seasonStart={organizationData?.getUserOrganization?.seasonStart || ""}
            seasonEnd={organizationData?.getUserOrganization?.seasonEnd || ""}
            evaluationCriteria={evaluationCriteriaData?.getAllEvaluationCriteria || []}
            onSavePlayer={handleSavePlayer}
            onSaveRecurringTrainingEvent={handleSaveRecurringTrainingEvent}
            onSaveNote={handleSaveNote}
            onSaveEvaluation={handleSaveEvaluation}
            isLoading={isLoading}
            isEvaluationLoading={saveEvaluationLoading}
            isNoteLoading={saveNoteLoading}
            isRecurringTrainingEventLoading={saveRecurringTrainingEventLoading}
            isPlayerLoading={savePlayerLoading}
          />
        </Box>
      )}
    </Box>
  );
};
