import { gql } from "@apollo/client";

export const GET_INVITE_CODE = gql`
  query GetInviteCode($inviteCode: String!) {
    getInviteCode(inviteCode: $inviteCode) {
      inviteCode
      used
    }
  }
`;
