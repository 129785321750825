import { gql } from "@apollo/client";

export const GET_ALL_ATHLETES_FOR_SEASON_PLAN = gql`
  query GetAllAthletesForSeasonPlan {
    getAllAthletes {
      id
      name
    }
  }
`;
