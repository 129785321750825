import React, { useState } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Drill, SkillSet } from "../../../../types";
import { DrillLibrary } from "../../../../common/components/DrillLibrary";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { DrillBuilder } from "./components/DrillBuilder";
import { filterDrills } from "../../../../common/utils";
import type { DrillInput } from "../../containers/TrainingLibraryContainer";

type DrillsProps = {
  allDrills: Drill[];
  allSkillSets: SkillSet[];
  saveDrillLoading: boolean;
  saveDrill: (drillInput: DrillInput) => Promise<void>;
};

export const Drills: React.FC<DrillsProps> = (props) => {
  const [selectedDrill, setSelectedDrill] = useState<Drill | null>(null);
  const [isDrillBuilderOpen, setIsDrillBuilderOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>([]);
  const allUniqueTags = Array.from(new Set(props.allDrills.flatMap((drill) => (drill.tags ? drill.tags : []))));

  /**
   * Select a drill
   *
   * @param {Drill} drill - The drill to select
   */
  const handleSelectDrill = (drill: Drill) => {
    setSelectedDrill(drill);
    setIsDrillBuilderOpen(true);
  };

  const handleDrillBuilderClose = () => {
    setSelectedDrill(null);
    setIsDrillBuilderOpen(false);
  };

  /**
   * Change the search query
   *
   * @param {string} newSearchQuery - The new search query
   * @param {string} newSearchTags - The new search tags
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  const filteredDrills = filterDrills(props.allDrills, searchQuery, searchTags, searchSkillSets);

  return (
    <Box data-testid="drill-manager">
      <Stack direction="row" padding="6" spacing="4" width="100%" justifyContent="center">
        <Text fontSize="x-large">Drills ({filteredDrills.length})</Text>
        <CommonIconButton height="36px" onClick={() => setIsDrillBuilderOpen(true)} dataTestId="add-drill" />
      </Stack>
      <DrillLibrary
        allSkillSets={props.allSkillSets}
        drills={filteredDrills}
        searchSkillSets={searchSkillSets}
        searchQuery={searchQuery}
        searchTags={searchTags}
        scrollbarHeight="70svh"
        displayMetadata={true}
        isLoading={props.saveDrillLoading}
        onSelectDrill={handleSelectDrill}
        onSearchChange={handleSearchChange}
      />
      {isDrillBuilderOpen && (
        <DrillBuilder
          allSkillSets={props.allSkillSets}
          allTags={allUniqueTags}
          selectedDrill={selectedDrill}
          open={isDrillBuilderOpen}
          onSave={props.saveDrill}
          onClose={handleDrillBuilderClose}
        />
      )}
    </Box>
  );
};
