import React from "react";
import { FormControl, FormLabel, Stack, Switch, Text } from "@chakra-ui/react";
import { commonStyles } from "../../utils/styles";

/** Props for the SwitchWithLabel component. */
export type SwitchWithLabelProps = {
  /** Whether the switch is checked or not. */
  checked: boolean;
  /** The label to display next to the switch. */
  label: string;
  /** Function to be called when the switch is toggled. */
  onChange: () => void;
  /** The width of the component, defaults to "fit-content". */
  width?: string;
  /** Data test id for the form control. */
  dataTestId?: string;
};

/**
 * A component that renders a switch with a label. The switch's label, checked state, and onChange handler are
 * controlled via props.
 */
export const SwitchWithLabel: React.FC<SwitchWithLabelProps> = (props) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    props.onChange();
  };

  return (
    <FormControl
      display="flex"
      width={props.width || "fit-content"}
      height="42px"
      onClick={handleClick}
      {...commonStyles(!!props.checked)}
      data-testid={props.dataTestId || "form-control"}
    >
      <FormLabel margin={0}>
        <Stack
          direction="row"
          spacing="2"
          padding="8px"
          display="flex"
          alignItems="center"
          cursor="pointer"
          data-testid="stack"
        >
          <Switch
            isChecked={props.checked}
            _checked={{
              ".chakra-switch__track": {
                background: "orange.400",
              },
            }}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Text>{props.label}</Text>
          </Stack>
        </Stack>
      </FormLabel>
    </FormControl>
  );
};
