import React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { LoadingOverlay } from "../LoadingOverlay";
import { DeletionInfoEntityType, DeletionNode } from "../../../types";
import { getMessages, renderDeletionMessage, renderNoDeletionMessage } from "./components/utils";

type DeletionInfoProps = {
  entityName: string;
  entitiesToBeRemoved: DeletionNode[];
  loading: boolean;
  entityType: DeletionInfoEntityType;
};

export const DeletionInfo: React.FC<DeletionInfoProps> = (props) => {
  const messages = getMessages(props.entityType, props.entityName, props.entitiesToBeRemoved);
  let entitiesToBeRemoved = props.entitiesToBeRemoved;
  let extraEntitesToBeRemoved: number | null = null;

  switch (props.entityType) {
    case DeletionInfoEntityType.Venue:
      entitiesToBeRemoved = props.entitiesToBeRemoved.filter((entity) => entity.data?.type !== "SingleTrainingEvent");
      extraEntitesToBeRemoved = props.entitiesToBeRemoved.filter(
        (entity) => entity.data?.type === "SingleTrainingEvent",
      ).length;
      break;
    case DeletionInfoEntityType.Center:
      entitiesToBeRemoved = recursivelyFilterEntities(props.entitiesToBeRemoved);

      function recursivelyFilterEntities(entities: DeletionNode[]): DeletionNode[] {
        return entities
          .filter((entity) => entity.data?.type !== "SingleTrainingEvent")
          .map((entity) => ({
            ...entity,
            children: entity.children ? recursivelyFilterEntities(entity.children) : [],
          }));
      }

      extraEntitesToBeRemoved = props.entitiesToBeRemoved
        .flatMap((entity) => entity.children)
        ?.filter((child) => child?.data?.type === "SingleTrainingEvent").length;
  }

  return (
    <Box position="relative">
      <LoadingOverlay display={props.loading} />

      {props.loading && <Text>{messages.loadingMessage}</Text>}

      {!props.loading && entitiesToBeRemoved.length > 0 && (
        <Stack direction="column" spacing="4">
          {renderDeletionMessage(props.entityType, props.entityName, messages)}

          {messages.showEntityList && (
            <Stack direction="column" spacing="1">
              {entitiesToBeRemoved.map((entity, index) => (
                <Box
                  key={index}
                  padding={4}
                  border="1px solid"
                  borderColor="blackAlpha.300"
                  borderRadius="md"
                  backgroundColor="red.100"
                >
                  <Text>
                    {entity.data.name} ({entity.children.length} {messages.childEntityName})
                  </Text>
                </Box>
              ))}
            </Stack>
          )}

          <Text>Please confirm the action. This cannot be undone.</Text>
        </Stack>
      )}

      {!props.loading &&
        entitiesToBeRemoved.length === 0 &&
        renderNoDeletionMessage(props.entityType, props.entityName, extraEntitesToBeRemoved)}
    </Box>
  );
};
