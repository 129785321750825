import { FormControl, Input, InputGroup } from "@chakra-ui/react";
import React from "react";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils";

/** Props for the CommonInput component. */
export type CommonInputProps = {
  /** The current value of the input field. */
  value: string;
  /** Placeholder text for the input field. */
  placeholder: string;
  /** Callback function to handle changes in the input field. */
  onChange: (value: string) => void;
  /** Whether the input field is disabled. */
  disabled?: boolean;
  /** Whether the input field is required. */
  required?: boolean;
  /** The data-testid attribute to be added to the input field. */
  dataTestId?: string;
  /** The type of the input field. */
  type?: string;
};

/** A common input component that supports different customization options. */
export const CommonInput: React.FC<CommonInputProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <InputGroup>
      <FormControl id="common-input">
        <CommonFormLabel display={!!props.value}>{props.placeholder}</CommonFormLabel>
        <Input
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          required={props.required}
          height="42px"
          opacity={props.disabled ? 0.6 : 1}
          isDisabled={props.disabled}
          type={props.type}
          {...commonStyles(!!props.value)}
          data-testid={props.dataTestId}
        />
      </FormControl>
    </InputGroup>
  );
};
