import React, { useState } from "react";
import { Box, Text, Stack, Tag, Tooltip, TagLabel } from "@chakra-ui/react";
import { Player, type Note } from "../../../../types";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonTextArea } from "../../../../common/components/CommonTextArea";
import { cardStyles, extractDateFromUTC, extractTimeFromUTC, tagStyles } from "../../../../common/utils";
import { extractFormattedTime } from "../../../../common/utils";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { format } from "date-fns";

type NotesProps = {
  player: Player | null;
  onSave: (newNote: string) => void;
  /** Whether the component is loading */
  isLoading?: boolean;
};

type DisplayNote = Note & {
  tags?: {
    label: string;
    tooltip: React.ReactNode;
  }[];
};

export const Notes: React.FC<NotesProps> = (props) => {
  const [newNote, setNewNote] = useState("");

  const handleSaveNote = () => {
    if (newNote.trim() !== "") {
      props.onSave(newNote);
      setNewNote("");
    }
  };

  const displayNotes: DisplayNote[] =
    props.player?.notes?.map((note) => {
      const tags = [];

      if (note.isGroupNote) {
        tags.push({
          label: "Group",
          tooltip: (
            <Text fontSize="medium" color="whiteAlpha.900">
              Note relates to entire training group
            </Text>
          ), // TODO: group information, once that is implemented (recurring training event)
        });
      }

      // TODO: if training event is deleted, this information is lost
      // maybe then tag to indicate that related event is deleted? needs new field to NoteEntity
      if (!!note.recurringTrainingEventMetadata || !!note.singleTrainingEvent) {
        const trainingEvent = note.recurringTrainingEventMetadata || note.singleTrainingEvent;
        const trainingEventDate = extractDateFromUTC(trainingEvent?.startDateTime || "", ".", true);
        const trainingEventStartTime = extractTimeFromUTC(trainingEvent?.startDateTime || "");
        const trainingEventEndTime = extractTimeFromUTC(trainingEvent?.endDateTime || "");

        const tooltip = (
          <Stack direction="column" spacing="2" padding="1" borderRadius="md">
            <Text fontSize="medium" color="whiteAlpha.900">
              Note relates to a training event:
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Time: {`${trainingEventDate}  (${trainingEventStartTime}-${trainingEventEndTime})`}
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Place: {trainingEvent?.court?.name || ""}
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Coach: {trainingEvent?.coaches?.map((coach) => coach.name).join(", ") || "Not assigned"}
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Players: {trainingEvent?.players?.map((player) => player.name).join(", ") || ""}
            </Text>
          </Stack>
        );

        tags.push({
          label: "Training",
          tooltip: tooltip,
        });
      }

      return {
        ...note,
        tags: tags.length > 0 ? tags : undefined,
      };
    }) || [];

  return (
    <Box
      position="relative"
      width="100%"
      minHeight="400px"
      maxHeight="75svh"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />
      <Text padding="6" fontSize="large" color="blackAlpha.900">
        Notes ({props.player?.notes?.length || 0})
      </Text>
      <CommonScrollbar
        height="auto"
        overflow="auto"
        invisibleBorderWidth="0px 8px 0px 0px"
        paddingRightWhenOverflowing="2"
        paddingRightWhenNotOverflowing="6"
        paddingLeft="6"
        paddingBottom="6"
        marginBottom="6"
        flex={1}
      >
        {displayNotes.length > 0 ? (
          <Stack direction="column" spacing="6" align="stretch">
            {displayNotes.map((note, index) => (
              <Box key={index} padding="4" {...cardStyles({ disableClick: true })} data-testid="note-card">
                <Stack direction="column" spacing="2">
                  {note.tags && (
                    <Stack direction="row" spacing="2">
                      {note.tags.map((tag, index) => (
                        <Tag key={index} height="fit-content" {...tagStyles()}>
                          <Tooltip hasArrow label={tag.tooltip} aria-label={`${tag.label} tag`} placement="top">
                            <TagLabel>{tag.label}</TagLabel>
                          </Tooltip>
                        </Tag>
                      ))}
                    </Stack>
                  )}
                  <Text fontWeight="medium" whiteSpace="pre-wrap" marginBottom="3" color="blackAlpha.900">
                    {note.content}
                  </Text>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Text fontSize="xs" color="blackAlpha.600">
                    {note.createdBy?.username}
                  </Text>
                  <Stack direction="row" spacing="4">
                    <Text fontSize="xs" color="blackAlpha.600">
                      {format(extractDateFromUTC(note.createdAt || ""), "dd.MM.yyyy")}
                    </Text>
                    <Text fontSize="xs" color="blackAlpha.600">
                      {extractFormattedTime(note.createdAt || "")}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Stack>
        ) : (
          <Text color="blackAlpha.600">No notes</Text>
        )}
      </CommonScrollbar>
      <Box paddingX="6">
        <CommonTextArea
          placeholder="Add a note"
          value={newNote}
          onChange={(value) => setNewNote(value)}
          minHeight="80px"
          disableRezise={true}
          dataTestId="add-manual-note"
        />
      </Box>
      <Box padding="6" width="100%" textAlign="end">
        <CommonButton
          variantType="solidPrimary"
          onClick={handleSaveNote}
          dataTestId="save-manual-note"
          disabled={newNote.trim() === ""}
        >
          Save Note
        </CommonButton>
      </Box>
    </Box>
  );
};
