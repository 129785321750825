import { gql } from "@apollo/client";

export const GET_TRAINING_GROUP_EVENT = gql`
  query GetTrainingGroupEvent($id: String!) {
    getTrainingGroupEvent(id: $id) {
      id
      startDateTime
      endDateTime
      coaches {
        id
        name
      }
      venue {
        id
        name
      }
      athletes {
        id
        name
        seasonPlans {
          id
          skillSets {
            id
            skillSet {
              id
              name
            }
            skills {
              id
              name
            }
          }
        }
      }
      trainingGroup {
        id
        name
        seasonPlans {
          id
          skillSets {
            id
            skillSet {
              id
              name
            }
            skills {
              id
              name
            }
          }
        }
        trainingSeason {
          id
          status
          name
        }
      }
      notes {
        id
        isGroupNote
        content
        athletes {
          id
        }
      }
      drills {
        id
        name
        implementation
        goal
        tags
      }
      session {
        id
        name
        goal
        totalDuration
        tags
        sessionDrills {
          id
          duration
          order
          drill {
            id
            name
            implementation
            goal
            tags
          }
        }
      }
    }
  }
`;
