import React, { useState } from "react";
import { Box, Text, Table, Thead, Tbody, Tr, Td, TableContainer, Tooltip, Stack } from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  Coach,
  Venue,
  TrainingGroup,
  Athlete,
  type TrainingSeason,
  TrainingSeasonStatus,
  type DeletionNode,
  DeletionInfoEntityType,
  type Center,
} from "../../../../types";
import { FormModal } from "../../../../common/components/FormModal";
import type { TrainingGroupInput } from "../../../Athletes/containers/AthleteContainer";
import { useAlert } from "../../../../common/components/AlertProvider";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { TrainingGroupEditor } from "../../../../common/components/TrainingGroupEditor";
import { TrainingGroupsFilterOptions } from "./components/TrainingGroupsFilterOptions";
import { DeletionInfo } from "../../../../common/components/DeletionInfo";
import { weekdayOptions } from "../../../../common/utils/dataProcessing";

type TrainingGroupsProps = {
  events: TrainingGroup[];
  coaches: Coach[];
  centers: Center[];
  athletes: Athlete[];
  trainingSeasons: TrainingSeason[];
  onSave: (eventToSave: TrainingGroupInput) => Promise<any>;
  isLoading: boolean;
  entitiesToBeRemoved: DeletionNode[];
  entitiesToBeRemovedLoading: boolean;
  getEntitiesToBeRemoved: (id: string) => Promise<void>;
};

export const TrainingGroups: React.FC<TrainingGroupsProps> = (props) => {
  const [selectedEvent, setSelectedEvent] = useState<TrainingGroup | null>(null);
  const [initialEvent, setInitialEvent] = useState<TrainingGroup | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof TrainingGroup;
    direction: "ascending" | "descending";
  }>({ key: "trainingSeason", direction: "descending" });
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const { showAlert } = useAlert();

  const [selectedTrainingSeasons, setSelectedTrainingSeasons] = useState<TrainingSeason[]>([]);
  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
  const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);
  const [selectedWeekdays, setSelectedWeekdays] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const venues = props.centers.flatMap((center: Center) => center.venues.map((venue) => ({ ...venue, center }))) || [];
  const isMultipleCenters = props.centers.length > 1;

  const columns = [
    { label: "Training Season", key: "trainingSeason" as keyof TrainingGroup, width: "15%" },
    { label: "Group", key: "name" as keyof TrainingGroup, width: "15%" },
    {
      label: "Weekday",
      key: "recurrenceWeekday" as keyof TrainingGroup,
      width: "10%",
    },
    {
      label: "Start",
      key: "recurrenceStartTime" as keyof TrainingGroup,
      width: "10%",
    },
    { label: "End", key: "recurrenceEndTime" as keyof TrainingGroup, width: "10%" },
    { label: "Venue", key: "venue" as keyof TrainingGroup, width: "10%" },
    { label: "Coaches", key: "coaches" as keyof TrainingGroup, width: "10%" },
    { label: "Athletes", key: "athletes" as keyof TrainingGroup, width: "20%" },
  ];

  const sortableColumns: (keyof TrainingGroup)[] = [
    "trainingSeason",
    "name",
    "recurrenceWeekday",
    "recurrenceStartTime",
    "recurrenceEndTime",
    "venue",
  ];

  const handleSort = (key: keyof TrainingGroup) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "ascending" ? "descending" : "ascending",
      });
    } else {
      setSortConfig({ key, direction: "ascending" });
    }
  };

  const sortedEvents = React.useMemo(() => {
    let filteredEvents = [...props.events];

    // Filter by selected training seasons
    if (selectedTrainingSeasons.length > 0) {
      const selectedSeasonIds = selectedTrainingSeasons.map((season) => season.id);
      filteredEvents = filteredEvents.filter((event) => selectedSeasonIds.includes(event.trainingSeason.id));
    }

    // Filter by selected coaches
    if (selectedCoaches.length > 0) {
      const selectedCoachIds = selectedCoaches.map((coach) => coach.id);
      filteredEvents = filteredEvents.filter((event) =>
        event.coaches?.some((coach) => selectedCoachIds.includes(coach.id)),
      );
    }

    // Filter by selected athletes
    if (selectedAthletes.length > 0) {
      const selectedAthleteIds = selectedAthletes.map((athlete) => athlete.id);
      filteredEvents = filteredEvents.filter((event) =>
        event.athletes?.some((athlete) => selectedAthleteIds.includes(athlete.id)),
      );
    }

    // Filter by selected venues
    if (selectedVenues.length > 0) {
      const selectedVenueIds = selectedVenues.map((venue) => venue.id);
      filteredEvents = filteredEvents.filter((event) => selectedVenueIds.includes(event.venue.id));
    }

    // Filter by selected weekdays
    if (selectedWeekdays.length > 0) {
      filteredEvents = filteredEvents.filter((event) => selectedWeekdays.includes(event.recurrenceWeekday));
    }

    // Filter by search query
    if (searchQuery.trim() !== "") {
      const query = searchQuery.toLowerCase();
      filteredEvents = filteredEvents.filter(
        (event) =>
          event.name.toLowerCase().includes(query) ||
          weekdayOptions
            .map((weekday) => weekday.label)
            [event.recurrenceWeekday].toLowerCase()
            .includes(query),
      );
    }

    // Now proceed with sorting
    let sortableEvents = [...filteredEvents];
    if (sortConfig !== null) {
      sortableEvents.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "venue") {
          aValue = a.venue.name;
          bValue = b.venue.name;
        }
        if (sortConfig.key === "recurrenceWeekday") {
          aValue = (a.recurrenceWeekday + 6) % 7;
          bValue = (b.recurrenceWeekday + 6) % 7;
        }

        if (sortConfig.key === "trainingSeason") {
          aValue = a.trainingSeason.name || "";
          bValue = b.trainingSeason.name || "";
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sortableEvents;
  }, [
    props.events,
    selectedTrainingSeasons,
    selectedCoaches,
    selectedAthletes,
    selectedVenues,
    selectedWeekdays,
    searchQuery,
    sortConfig,
  ]);

  const handleRowClick = (event: TrainingGroup) => {
    setIsCreatingNewGroup(false);
    setSelectedEvent(event);
    setInitialEvent(event);
    setIsOpen(true);
  };

  const handleSave = async (remove: boolean) => {
    setIsOpen(false);
    if (selectedEvent) {
      const eventToSave: TrainingGroupInput = {
        id: selectedEvent.id,
        name: selectedEvent.name || "unnamed",
        recurrenceWeekday: selectedEvent.recurrenceWeekday,
        recurrenceStartTime: selectedEvent.recurrenceStartTime,
        recurrenceEndTime: selectedEvent.recurrenceEndTime,
        venueId: selectedEvent.venue.id,
        coachIds: selectedEvent.coaches?.map((coach) => coach.id || "") || [],
        athleteIds: selectedEvent.athletes?.map((athlete) => athlete.id || "") || [],
        remove: remove,
        trainingSeasonId: selectedEvent.trainingSeason.id || "",
      };

      if (eventToSave.remove) {
        showAlert("Removing Training Group", "info", undefined, true);
      } else if (eventToSave.id) {
        showAlert("Updating Training Group", "info", undefined, true);
      } else {
        showAlert("Creating new Training Group", "info", undefined, true);
      }

      const result = await props.onSave(eventToSave);

      if (result) {
        if (eventToSave.remove) {
          showAlert("Training Group removed!", "success", 5000);
        } else {
          if (eventToSave.id) {
            showAlert("Training Group updated!", "success", 5000);
          } else {
            showAlert("Training Group created!", "success", 5000);
          }
        }
      }
    }
  };

  const handleCreateNewGroup = () => {
    if (props.trainingSeasons.length === 0) {
      showAlert("No Training Seasons available", "warning");
      return;
    } else if (venues.length === 0) {
      showAlert("No Venues available", "warning");
      return;
    }
    setIsCreatingNewGroup(true);
    setSelectedEvent({
      name: "",
      recurrenceWeekday: 1,
      recurrenceStartTime: "17:00",
      recurrenceEndTime: "18:30",
      venue: venues[0],
      coaches: [],
      athletes: [],
      trainingSeason:
        props.trainingSeasons.find((season) => season.status === TrainingSeasonStatus.Active) ||
        props.trainingSeasons[0],
    });
    setIsOpen(true);
  };

  /**
   * Generates a list of reasons why the submit button is disabled.
   *
   * @returns An object containing the submit disabled reasons if any condition is not met, otherwise undefined.
   */
  const getSubmitDisabledReasons = (): string | undefined => {
    const reasons: string[] = [];

    if (!selectedEvent?.name || selectedEvent?.name.trim() === "") {
      reasons.push("Provide a Training Group name");
    }
    if (
      selectedEvent?.recurrenceWeekday === undefined ||
      selectedEvent?.recurrenceWeekday < 0 ||
      selectedEvent?.recurrenceWeekday > 6
    ) {
      reasons.push("Choose a weekday");
    }
    if (!selectedEvent?.recurrenceStartTime || selectedEvent?.recurrenceStartTime === "24:00") {
      reasons.push("Set a start time");
    }
    if (!selectedEvent?.recurrenceEndTime || selectedEvent?.recurrenceEndTime === "24:00") {
      reasons.push("Set an end time");
    }
    if (!selectedEvent?.venue) {
      reasons.push("Select a venue");
    }
    if (!selectedEvent?.trainingSeason) {
      reasons.push("Select a Training Season");
    }

    const identicalTrainingGroup = props.events.find((event) => {
      // Skip the current event if editing an existing one
      if (selectedEvent?.id && event.id === selectedEvent.id) {
        return false;
      }

      return (
        event.recurrenceWeekday === selectedEvent?.recurrenceWeekday &&
        event.recurrenceStartTime === selectedEvent.recurrenceStartTime &&
        event.recurrenceEndTime === selectedEvent.recurrenceEndTime &&
        event.venue.id === selectedEvent.venue.id &&
        event.trainingSeason.id === selectedEvent.trainingSeason.id
      );
    });

    if (identicalTrainingGroup) {
      reasons.push(
        `Training Group with the same time, venue, and Training Season exists already (${identicalTrainingGroup.name})`,
      );
    }

    if (JSON.stringify(selectedEvent) === JSON.stringify(initialEvent)) {
      reasons.push("There are no changes to save");
    }

    return reasons.length > 0
      ? `To enable saving, please address the following:\n- ${reasons.join("\n- ")}`
      : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const featurePurpose =
    "Training Groups are organized teams of athletes linked to a Training Season. Add Training Groups to manage weekly training events";

  return (
    <Box position="relative" data-testid="organization-training-groups">
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" padding="6" spacing="4" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Training Groups ({props.events.length})</Text>
        </Tooltip>
        <CommonIconButton
          height="36px"
          onClick={handleCreateNewGroup}
          disabled={props.trainingSeasons.length === 0 || venues.length === 0}
          tooltip={
            props.trainingSeasons.length === 0
              ? "No Training Seasons available, create one first before a new Training Group"
              : venues.length === 0
                ? "No Venues available, create one first before a new Training Group"
                : undefined
          }
          dataTestId="add-training-group"
        />
      </Stack>

      <TrainingGroupsFilterOptions
        allTrainingSeasons={props.trainingSeasons}
        selectedTrainingSeasons={selectedTrainingSeasons}
        setSelectedTrainingSeasons={setSelectedTrainingSeasons}
        allCoaches={props.coaches}
        selectedCoaches={selectedCoaches}
        setSelectedCoaches={setSelectedCoaches}
        allAthletes={props.athletes}
        selectedAthletes={selectedAthletes}
        setSelectedAthletes={setSelectedAthletes}
        allVenues={venues}
        selectedVenues={selectedVenues}
        setSelectedVenues={setSelectedVenues}
        selectedWeekdays={selectedWeekdays}
        setSelectedWeekdays={setSelectedWeekdays}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />

      <TableContainer overflow="hidden" paddingBottom="6" paddingX="6">
        <Table size="lg" variant="unstyled" layout="fixed">
          <Thead>
            <Tr>
              {columns.map((column) => {
                const isSortable = sortableColumns.includes(column.key);
                const isSorted = sortConfig.key === column.key;
                return (
                  <Text
                    key={column.key as string}
                    as="th"
                    width={column.width}
                    fontSize="md"
                    textTransform="uppercase"
                    paddingY="4"
                    paddingX="8"
                    onClick={() => {
                      if (isSortable) {
                        handleSort(column.key);
                      }
                    }}
                    transition="all 0.3s ease"
                    _hover={
                      isSortable
                        ? {
                            color: "orange.400",
                            cursor: "pointer",
                          }
                        : undefined
                    }
                  >
                    <Box display="flex" alignItems="center" color={isSorted ? "orange.400" : "inherit"}>
                      {column.label}
                      {isSortable && (
                        <Box marginLeft="1" display="flex" alignItems="center" color="inherit">
                          {isSorted ? (
                            sortConfig.direction === "ascending" ? (
                              <TriangleUpIcon color="orange.400" opacity={1} />
                            ) : (
                              <TriangleDownIcon color="orange.400" opacity={1} />
                            )
                          ) : (
                            <TriangleDownIcon color="inherit" opacity={0.3} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Text>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {sortedEvents.map((event) => {
              const venueName = isMultipleCenters
                ? `${event.venue.name} (${venues.find((venue) => venue.id === event.venue.id)?.center?.name})`
                : event.venue.name;

              return (
                <Tr
                  key={event.id}
                  onClick={() => handleRowClick(event)}
                  cursor="pointer"
                  borderTop="1px solid"
                  borderColor="blackAlpha.50"
                  borderRadius="md"
                  color={
                    event.trainingSeason.status === TrainingSeasonStatus.Completed
                      ? "blackAlpha.300"
                      : event.trainingSeason.status === TrainingSeasonStatus.Upcoming
                        ? "blackAlpha.600"
                        : undefined
                  }
                  transition="all 0.3s ease"
                  _hover={{
                    color: "orange.400",
                    borderColor: "transparent",
                    boxShadow: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C", // lg blackAlpha.500
                  }}
                >
                  <Td width={columns[0].width} overflow="hidden" textOverflow="ellipsis">
                    <Tooltip placement="top" label={event.trainingSeason.name || ""}>
                      {event.trainingSeason.name || ""}
                    </Tooltip>
                  </Td>
                  <Td width={columns[1].width} overflow="hidden" textOverflow="ellipsis">
                    <Tooltip placement="top" label={event.name}>
                      {event.name}
                    </Tooltip>
                  </Td>
                  <Td width={columns[2].width} overflow="hidden" textOverflow="ellipsis">
                    {weekdayOptions[(event.recurrenceWeekday + 6) % 7].label}
                  </Td>
                  <Td width={columns[3].width} overflow="hidden" textOverflow="ellipsis">
                    {event.recurrenceStartTime}
                  </Td>
                  <Td width={columns[4].width} overflow="hidden" textOverflow="ellipsis">
                    {event.recurrenceEndTime}
                  </Td>
                  <Td width={columns[5].width} overflow="hidden" textOverflow="ellipsis">
                    <Tooltip placement="top" label={venueName}>
                      <span>{venueName}</span>
                    </Tooltip>
                  </Td>
                  <Td width={columns[6].width} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    <Tooltip
                      placement="top"
                      label={
                        event.coaches && event.coaches?.length > 0 ? (
                          <Stack direction="column" spacing="0">
                            {event.coaches?.map((coach, index) => (
                              <Text key={index} color="white">
                                - {coach.name}
                              </Text>
                            ))}
                          </Stack>
                        ) : undefined
                      }
                    >
                      {event.coaches && event.coaches.length > 0 ? (
                        <span>{event.coaches.map((coach) => coach.name).join(", ")}</span>
                      ) : (
                        <Text color="blackAlpha.600">No coaches</Text>
                      )}
                    </Tooltip>
                  </Td>
                  <Td width={columns[7].width} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    <Tooltip
                      placement="top"
                      label={
                        event.athletes && event.athletes?.length > 0 ? (
                          <Stack direction="column" spacing="0">
                            {event.athletes?.map((athlete, index) => (
                              <Text key={index} color="white">
                                - {athlete.name}
                              </Text>
                            ))}
                          </Stack>
                        ) : undefined
                      }
                    >
                      {event.athletes && event.athletes.length > 0 ? (
                        <span>{event.athletes.map((athlete) => athlete.name).join(", ")}</span>
                      ) : (
                        <Text color="blackAlpha.600">No athletes</Text>
                      )}
                    </Tooltip>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {sortedEvents.length === 0 && (
        <Text
          fontSize="large"
          paddingY="6"
          textColor="blackAlpha.600"
          textAlign="center"
          data-testid="no-training-groups"
        >
          {props.events.length === 0
            ? featurePurpose
            : "No Training Groups match the applied filters. Clear filters to view all Training Groups."}
        </Text>
      )}

      <FormModal
        open={isOpen}
        title={isCreatingNewGroup ? "Create new Training Group" : "Edit Training Group"}
        submitButtonText={isCreatingNewGroup ? "Create" : "Save"}
        handleSubmit={() => handleSave(false)}
        onClose={() => setIsOpen(false)}
        handleRemove={() => handleSave(true)}
        removeDisabled={!selectedEvent?.id}
        removeDisabledReason="Group is not created"
        confirmationDialogTitle="Delete Training Group"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
        confirmationDialogMessage={
          <DeletionInfo
            entityName={initialEvent?.name || ""}
            entitiesToBeRemoved={props.entitiesToBeRemoved}
            entityType={DeletionInfoEntityType.TrainingGroup}
            loading={props.entitiesToBeRemovedLoading}
          />
        }
        onConfirmationDialogOpen={async () => await props.getEntitiesToBeRemoved(selectedEvent?.id || "")}
        isConfirmingDeletion={true}
      >
        {selectedEvent && (
          <TrainingGroupEditor
            allCoaches={props.coaches}
            allVenues={venues}
            allAthletes={props.athletes}
            allTrainingSeasons={props.trainingSeasons}
            trainingGroup={selectedEvent}
            displayAthletes={true}
            onChange={(editedEvent) => setSelectedEvent(editedEvent)}
            initialTrainingGroup={initialEvent || undefined}
          />
        )}
      </FormModal>
    </Box>
  );
};
