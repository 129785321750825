import React, { useEffect, useRef, useState } from "react";
import { Repeat, TimesOneMobiledata } from "@mui/icons-material";

import { AthleteInput } from "../AthleteInput";
import { CoachSelect } from "../../../../../../../common/components/CoachSelect";

import { FormModal } from "../../../../../../../common/components/FormModal";
import { Coach, Venue, Drill, Athlete, type Note, type Session, type SkillSet } from "../../../../../../../types";
import { VenueSelect } from "../../../../../../../common/components/VenueSelect";
import { DrillLibrary } from "../../../../../../../common/components/DrillLibrary";
import { DrillCard } from "../../../../../../../common/components/DrillCard";
import {
  formatToUtcDayStartString,
  updateUTCTime,
  extractUtcTime,
  convertToLocalDateTime,
} from "../../../../../../../common/utils/dateAndTime";
import { filterDrills, filterSessions } from "../../../../../../../common/utils/dataProcessing";
import { AthleteTab } from "./components/AthleteTab";
import { Box, Grid, GridItem, Icon, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";

import { TimePicker } from "../../../../../../../common/components/TimePicker";
import { CommonDayPicker } from "../../../../../../../common/components/CommonDayPicker";
import type { TrainingEventDetails, TrainingEventUpdateInput } from "../../../../../types";
import { CommonScrollbar } from "../../../../../../../common/components/CommonScrollbar";
import { SessionLibrary } from "../../../../../../../common/components/SessionLibrary";
import { SessionCard } from "../../../../../../../common/components/SessionCard";
import { SeasonPlanCard } from "../../../../../../../common/components/SeasonPlanCard";
import { NotesInput } from "../NotesInput";

type ViewEventModalProps = {
  /** Callback function for saving changes to the event */
  onSave: (trainingEventType: TrainingEventDetails["trainingEventType"], dataToSave: TrainingEventUpdateInput) => void;
  /** Callback function for closing the modal */
  onClose: () => void;
  /** Whether the modal is open or not */
  open: boolean;
  /** The event to display and edit */
  event: TrainingEventDetails;
  allCoaches: Coach[];
  allVenues: Venue[];
  allAthletes: Athlete[];
  allDrills: Drill[];
  allSessions: Session[];
  allSkillSets: SkillSet[];
  isMobile: boolean;
};

/** A modal component for viewing and editing an event. */
export const ViewEventModal: React.FC<ViewEventModalProps> = (props) => {
  const initialEventRef = useRef<TrainingEventDetails>(props.event);
  const [selectedDate, setSelectedDate] = useState(props.event.date || new Date().toISOString());
  const [selectedStartTime, setSelectedStartTime] = useState(props.event.start);
  const [selectedEndTime, setSelectedEndTime] = useState(props.event.end);
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>(props.event.athletes);
  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>(props.event.coach.map((coach) => coach));
  const [selectedVenue, setSelectedVenue] = useState<Venue>(props.event.venue);
  const [groupNote, setGroupNote] = useState(props.event.groupNote);
  const [athleteNotes, setAthleteNotes] = useState(props.event.athleteNotes);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>(
    props.event.seasonPlans?.flatMap((seasonPlan) => seasonPlan.skillSets.map((spss) => spss.skillSet) || []) || [],
  );
  const [selectedDrills, setSelectedDrills] = useState<Drill[]>(props.event.drills || []);
  const [selectedSession, setSelectedSession] = useState(props.event.session || null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const allSessionTags = Array.from(
    new Set(props.allSessions.flatMap((session) => (session.tags ? session.tags : []))),
  );

  /**
   * Updates the state variable 'isSubmitDisabled' based on the changes in the event data. If there are any changes, the
   * submit button is enabled. Otherwise, it is disabled.
   */
  const checkForChanges = () => {
    const initialEvent = initialEventRef.current;
    const dateChanged = selectedDate !== initialEvent.date;
    const startTimeChanged = selectedStartTime !== initialEvent.start;
    const endTimeChanged = selectedEndTime !== initialEvent.end;
    const athletesChanged =
      selectedAthletes.length !== initialEvent.athletes.length ||
      selectedAthletes.some((athlete, index) => athlete.id !== initialEvent.athletes[index]?.id);
    const coachesChanged =
      selectedCoaches.length !== initialEvent.coach.length ||
      selectedCoaches.some((coach, index) => coach.id !== initialEvent.coach[index]?.id);
    const venueChanged = selectedVenue.id !== initialEvent.venue.id;
    const groupNoteChanged = (groupNote?.content || "") !== (initialEvent.groupNote?.content || "");
    const createAthleteNoteMap = (notes: Note[]) => {
      const map: { [key: string]: string } = {};
      notes.forEach((note) => {
        if (note.content && note.athletes) {
          note.athletes.forEach((athlete) => {
            map[athlete.id || ""] = note.content?.trim() || "";
          });
        }
      });
      return map;
    };
    const currentAthleteNotesMap = createAthleteNoteMap(athleteNotes);
    const initialAthleteNotesMap = createAthleteNoteMap(initialEvent.athleteNotes);
    const athleteNotesChanged = selectedAthletes.some((athlete) => {
      const currentNoteContent = currentAthleteNotesMap[athlete.id || ""];
      const initialNoteContent = initialAthleteNotesMap[athlete.id || ""];
      return currentNoteContent !== initialNoteContent;
    });
    const drillsChanged =
      selectedDrills.length !== (initialEvent.drills?.length || 0) ||
      selectedDrills.some((drill, index) => drill.id !== initialEvent.drills?.[index]?.id);
    const sessionChanged = selectedSession?.id !== initialEvent.session?.id;

    const anyChanges =
      dateChanged ||
      startTimeChanged ||
      endTimeChanged ||
      athletesChanged ||
      coachesChanged ||
      venueChanged ||
      groupNoteChanged ||
      athleteNotesChanged ||
      drillsChanged ||
      sessionChanged;
    setIsSubmitDisabled(!anyChanges);
  };

  useEffect(() => {
    checkForChanges();
  }, [
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedAthletes,
    selectedCoaches,
    selectedVenue,
    groupNote,
    athleteNotes,
    selectedDrills,
    selectedSession,
  ]);

  /** Handles the save action by creating an TrainingEventUpdateInput object and passing it to the onSave callback. */
  const saveTrainingEvent = (remove: boolean) => {
    const trainingEventUpdateInput: TrainingEventUpdateInput = {
      id: props.event.id,
      coachIds: selectedCoaches.map((coach) => coach.id || ""),
      athleteIds: selectedAthletes.map((athlete) => athlete.id || ""),
      notes: [
        {
          id: groupNote?.id,
          isGroupNote: true,
          content: groupNote?.content || "",
          athleteIds: selectedAthletes.map((athlete) => athlete.id || ""),
          remove: !groupNote?.content || groupNote?.content?.trim() === "",
        },
        ...athleteNotes.map((note) => ({
          id: note.id,
          content: note.content || "",
          athleteIds: note.athletes?.map((athlete) => athlete.id || "") || [],
          remove: !note.content || note.content.trim() === "",
        })),
      ],
      venueId: selectedVenue.id || "",
      startDateTime: selectedStartTime,
      endDateTime: selectedEndTime,
      drillIds: selectedDrills.map((drill) => drill.id || ""),
      sessionId: selectedSession?.id,
      remove: remove,
    };
    props.onSave(props.event.trainingEventType, trainingEventUpdateInput);
  };

  /**
   * Handles the selection of a drill. If the selected drill is already selected, it deselects it. Otherwise, it adds
   * the drill to the selected drills.
   *
   * @param drill - The selected drill.
   */
  const handleSelectDrill = (drill: Drill) => {
    if (selectedDrills.find((d) => d.id === drill.id)) {
      setSelectedDrills(selectedDrills.filter((d) => d.id !== drill.id));
    } else {
      setSelectedDrills([...selectedDrills, drill]);
    }
    setSelectedSession(null);
  };

  const handleSelectSession = (session: Session) => {
    if (selectedSession && selectedSession.id === session.id) {
      setSelectedSession(null);
    } else {
      setSelectedSession(session);
    }
    setSelectedDrills([]);
  };

  /**
   * Handles the changes in search query and search tags. Updates the state variables 'searchQuery' and 'searchTags'.
   *
   * @param newSearchQuery - The new search query.
   * @param newSearchTags - The new search tags.
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  /**
   * Handles the selection of athletes. Updates the state variable 'selectedAthletes'.
   *
   * @param athletes - An array of selected athletes.
   */
  const handleSelectAthletes = (athletes: Athlete[]) => {
    setSelectedAthletes(athletes);
  };

  /**
   * Handles the change in the current tab. Updates the state variable 'tabIndex'.
   *
   * @param newValue - The new value of the tab index.
   */
  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  /**
   * Handles the change in an athlete note
   *
   * @param athleteId - The athlete ID of note to update
   * @param newNote - The new value of the note
   */
  const handleAthleteNoteUpdate = (athleteId: string | undefined, newNote: string) => {
    const noteToUpdate = athleteNotes.find((note) => note.athletes?.find((athlete) => athlete.id === athleteId));
    if (!noteToUpdate) {
      // create note for athlete
      const createdNote: Note = {
        content: newNote,
        athletes: selectedAthletes.filter((athlete) => athlete.id === athleteId),
      };
      // add created note to existing notes
      setAthleteNotes((prev) => [...prev, createdNote]);
    } else {
      // update existing note
      const updatedNote: Note = { ...noteToUpdate, content: newNote };
      setAthleteNotes((prev) =>
        prev.map((note) => (note.athletes?.find((athlete) => athlete.id === athleteId) ? updatedNote : note)),
      );
    }
  };

  const handleGroupNoteUpdate = (newNote: string) => {
    setGroupNote((prev) => ({ ...prev, content: newNote }));
  };

  /** Handles the removal action, by saving training event with "remove": true */
  const handleRemove = () => {
    saveTrainingEvent(true);
  };

  /** Handles the save action, by saving training event with "remove": false */
  const handleSave = () => {
    saveTrainingEvent(false);
  };

  const handleDateChange = (date?: Date) => {
    if (!date) {
      // date is cleared
      return;
    }
    const utcDate = updateUTCTime(formatToUtcDayStartString(date), extractUtcTime(selectedDate));
    setSelectedDate(utcDate);

    // Update the selectedStartTime and selectedEndTime with the new date but keep the original time
    const updatedStartTime = updateUTCTime(utcDate, extractUtcTime(selectedStartTime));
    const updatedEndTime = updateUTCTime(utcDate, extractUtcTime(selectedEndTime));

    setSelectedStartTime(updatedStartTime);
    setSelectedEndTime(updatedEndTime);
  };

  return (
    <FormModal
      fixedHeight
      open={props.open}
      handleSubmit={handleSave}
      handleRemove={handleRemove}
      confirmationDialogTitle="Delete training event"
      confirmationDialogMessage={
        props.event.trainingEventType === "weekly"
          ? "You are about to delete a single occurrence of this Training Group. To delete all training events of the Training Group, please proceed from the 'Management' page."
          : undefined
      }
      onClose={props.onClose}
      submitButtonText="Save"
      submitDisabled={isSubmitDisabled}
      submitButtonHoverText={isSubmitDisabled ? "No changes to save" : undefined}
      tabs={["General", ...props.event.athletes.map((p) => p.name), "Training Structure"]}
      handleTabChange={handleTabChange}
      tabIndex={tabIndex}
      handlePaddingXManually={true}
    >
      {/* First tab */}
      <Box data-testid="view-event-modal-first-tab" height="100%" paddingX={{ mobile: "6", laptop: "0" }}>
        <CommonScrollbar
          height="100%"
          overflow="auto"
          invisibleBorderWidth="0px 8px 0px 0px"
          paddingRight="2"
          paddingLeft="6"
        >
          {props.event.trainingEventType === "weekly" && props.event.trainingGroup && (
            <Text
              width="100%"
              textAlign="center"
              fontSize="lg"
              justifySelf="center"
              marginBottom="4"
              color="blackAlpha.600"
            >
              {props.event.trainingGroup.trainingSeason.name}
            </Text>
          )}

          <Stack
            direction={{ mobile: "column", laptop: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing="2"
            marginBottom={{ mobile: "6", laptop: "3" }}
            textAlign="center"
          >
            <Text fontSize="lg" alignContent="center">
              {props.event.trainingEventType === "weekly"
                ? `${props.event.name} - Weekly Training`
                : "One-time Training"}
            </Text>
            {props.event.trainingEventType === "weekly" ? (
              <Icon as={Repeat} alignSelf={{ mobile: "center", laptop: "end" }} boxSize="6" color="blackAlpha.800" />
            ) : (
              <Icon
                as={TimesOneMobiledata}
                alignSelf={{ mobile: "center", laptop: "end" }}
                boxSize="8"
                color="blackAlpha.800"
              />
            )}
          </Stack>
          <Grid
            templateColumns="repeat(6, 1fr)"
            templateRows="auto"
            gap={{ mobile: "3", laptop: "4" }}
            marginBottom={{ mobile: "6", laptop: "8" }}
            height="auto"
          >
            <GridItem colSpan={{ mobile: 6, laptop: 2 }}>
              <CommonDayPicker
                disabled={props.event.trainingEventType === "weekly"}
                initialDate={props.event.date ? convertToLocalDateTime(props.event.date) : undefined}
                onDateChange={(date) => handleDateChange(date)}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }} data-testid="select-start">
              <TimePicker
                disabled={props.event.trainingEventType === "weekly"}
                label="Start"
                selectedTime={extractUtcTime(selectedStartTime)}
                setSelectedTime={(value) => {
                  if (typeof value === "string") {
                    setSelectedStartTime(updateUTCTime(selectedDate, value));
                  }
                }}
                maxTime={extractUtcTime(selectedEndTime)}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }} data-testid="select-end">
              <TimePicker
                disabled={props.event.trainingEventType === "weekly"}
                label="End"
                selectedTime={extractUtcTime(selectedEndTime)}
                setSelectedTime={(value) => {
                  if (typeof value === "string") {
                    setSelectedEndTime(updateUTCTime(selectedDate, value));
                  }
                }}
                minTime={extractUtcTime(selectedStartTime)}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <VenueSelect
                venues={props.allVenues}
                selectedVenue={selectedVenue}
                setSelectedVenue={(venueId) => {
                  const newVenue = props.allVenues.find((c) => c.id === venueId);
                  if (newVenue) {
                    setSelectedVenue(newVenue);
                  }
                }}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <CoachSelect
                availableCoaches={props.allCoaches}
                selectedCoaches={selectedCoaches}
                setSelectedCoach={(coachArray) => setSelectedCoaches(coachArray)}
              />
            </GridItem>
            <GridItem colSpan={6}>
              <AthleteInput
                athletes={props.allAthletes}
                selectedAthletes={selectedAthletes}
                handleAthleteSelection={handleSelectAthletes}
              />
            </GridItem>
          </Grid>

          <Stack direction="column" spacing={{ mobile: "6", laptop: "8" }}>
            <Box>
              <Text fontSize="lg" marginBottom="2">
                Training Notes
              </Text>
              <NotesInput
                notes={groupNote?.content || ""}
                label="Add notes for the entire group"
                setNotes={handleGroupNoteUpdate}
                minRows={4}
              />
            </Box>

            {props.event.trainingEventType === "weekly" && (
              <Box>
                <Text fontSize="lg" marginBottom="2">
                  Training Targets
                </Text>
                <Stack direction="column" spacing="2">
                  {props.event.seasonPlans?.length ? (
                    props.event.seasonPlans.map((seasonPlan) => (
                      <SeasonPlanCard key={seasonPlan.id} skillSets={seasonPlan.skillSets} disableHover={true} />
                    ))
                  ) : (
                    <SeasonPlanCard skillSets={undefined} disableHover={true} />
                  )}
                </Stack>
              </Box>
            )}

            <Box>
              <Text fontSize="lg" marginBottom="2">
                Training Structure
              </Text>
              {selectedDrills.length > 0 && (
                <Stack direction="column" spacing={{ mobile: "3", laptop: "6" }}>
                  {selectedDrills.map((drill, index) => (
                    <DrillCard
                      key={index}
                      drill={drill}
                      selected={true}
                      disableHover={true}
                      isMobile={props.isMobile}
                    />
                  ))}
                </Stack>
              )}
              {selectedSession && (
                <SessionCard
                  session={selectedSession}
                  readOnly={true}
                  selected={true}
                  disableHover={true}
                  isMobile={props.isMobile}
                />
              )}
              {selectedDrills.length === 0 && !selectedSession && (
                <Text textColor="blackAlpha.600">
                  This training is still unplanned! You can create a plan from the "Training Structure" tab.
                </Text>
              )}
            </Box>
          </Stack>
        </CommonScrollbar>
      </Box>
      {/* Athlete tabs */}
      {...props.event.athletes.map((athlete) => (
        <AthleteTab
          key={athlete.id}
          athlete={athlete}
          trainingNote={
            athleteNotes.find((a) => a.athletes?.find((noteAthlete) => noteAthlete.id === athlete.id))?.content || ""
          }
          onNoteChange={(newNote) => handleAthleteNoteUpdate(athlete.id, newNote)}
        />
      ))}
      {/* Training structure tab */}
      <Box
        display="flex"
        height="100%"
        overflow={{ laptop: "hidden" }}
        id="training-plan-tab"
        paddingX={{ mobile: "6", laptop: "0" }}
      >
        <Tabs
          defaultIndex={selectedSession ? 1 : 0}
          variant="solid-rounded"
          size="md"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <TabList justifyContent="center">
            <Grid templateColumns="repeat(2, 1fr)" columnGap="8">
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _selected={{ backgroundColor: "orange.400", color: "white", border: "1px solid transparent" }}
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Drills
                </Tab>
              </GridItem>
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _selected={{ backgroundColor: "orange.400", color: "white", border: "1px solid transparent" }}
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Sessions
                </Tab>
              </GridItem>
            </Grid>
          </TabList>

          {/* FIXME: switching tab panels doesn't work well with CommonScrollbar paddingRightWhenOverflowing & 
          paddingRightWhenNotOverflowing attributes, overflowing triggers only after interacting with the elements. 
          Maybe refactor out of tab panels and unite with TrainingGroupAdder group selection */}

          <TabPanels
            overflow={{ laptop: "hidden" }}
            paddingTop="4" /* paddingTop instead of sibling's marginBottom due to the CommonFormLabel cut-off */
          >
            <TabPanel padding="0" height="100%">
              <DrillLibrary
                allSkillSets={props.allSkillSets}
                drills={filterDrills(props.allDrills, searchQuery, searchTags, searchSkillSets)}
                selectedDrills={selectedDrills}
                onSelectDrill={handleSelectDrill}
                onSearchChange={handleSearchChange}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                showDrillCardCheckbox
                scrollbarHeight="100%"
                isMobile={props.isMobile}
              />
            </TabPanel>
            <TabPanel padding="0" height="100%">
              <SessionLibrary
                allSkillSets={props.allSkillSets}
                allTags={allSessionTags}
                onSearchChange={handleSearchChange}
                onSelectSession={handleSelectSession}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                sessions={filterSessions(props.allSessions, searchQuery, searchTags, searchSkillSets)}
                selectedSession={selectedSession || undefined}
                showSessionCardCheckbox={true}
                isMobile={props.isMobile}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </FormModal>
  );
};
