import { gql } from "@apollo/client";

export const GET_GENERIC_EVALUATION_CRITERIA = gql`
  query GetGenericEvaluationCriteria {
    getGenericEvaluationCriteria {
      id
      fromRatingLevel
      toRatingLevel
      definition
      requirements
    }
  }
`;
