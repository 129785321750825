/**
 * Generates an array of available time options.
 *
 * @returns {string[]} An array of available time options.
 */
export const generateTimeOptions = (): string[] => {
  const options = [];
  for (let index = 8; index <= 22; index++) {
    index !== 22
      ? options.push(`${index.toString().padStart(2, "0")}:00`, `${index.toString().padStart(2, "0")}:30`)
      : options.push(`${index.toString().padStart(2, "0")}:00`);
  }
  return options;
};

/**
 * Converts a Date object to a time string in the format "HH:MM".
 *
 * @param date - The Date object to convert
 * @returns {string} The formatted time string
 */
export const dateToTimeString = (date: Date): string => {
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

/**
 * Converts a time string in the format "HH:MM" to a Date object.
 *
 * @param value - The time string to convert
 * @param current - The current Date object
 * @returns {Date} The updated Date object with the new time
 */
export const timeStringToDate = (value: string, current: Date): Date => {
  const [hours, minutes] = value.split(":").map(Number);
  return new Date(current.setUTCHours(hours, minutes));
};
