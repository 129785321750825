import type FullCalendar from "@fullcalendar/react";

/**
 * Converts a given input to a UTC string.
 *
 * @example
 *   toUTCString("12:30"); // Expected: An ISO string representation of today's date at 12:30 UTC.
 *   toUTCString(new Date()); // Expected: An ISO string representation of the given Date object.
 *
 * @param input - A string in the format 'HH:mm' or a Date object.
 * @returns A string representation of the date in the ISO format.
 * @throws {Error} Throws an error if the input type is neither a string nor a Date object.
 */
export const toUTCString = (input: string | Date): string => {
  let date: Date;

  if (typeof input === "string") {
    // Create a new Date object with today's date and the time provided in the input
    const [hours, minutes] = input.split(":").map(Number);
    date = new Date();
    date.setUTCHours(hours, minutes, 0, 0);
  } else if (input instanceof Date) {
    date = input;
  } else {
    throw new Error("Invalid input type");
  }

  return date.toISOString();
};

/**
 * Updates the UTC time (hours and minutes) of an ISO date string while keeping the day and timezone intact.
 *
 * @param {string} isoDate - The ISO date string in UTC to update.
 * @param {string} time - The time string in "HH:mm" format to set.
 * @returns {string} - The updated ISO date string with the new time in UTC.
 * @throws {Error} - If the provided ISO date string is not in UTC format.
 */
export const updateUTCTime = (isoDate: string, time: string): string => {
  if (!isoDate.endsWith("Z") || isoDate.length !== 24) {
    throw new Error("The provided ISO date string is not in UTC format.");
  }

  const date = new Date(isoDate);
  const [hours, minutes] = time.split(":").map(Number);

  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);

  return date.toISOString();
};

/**
 * Formats a given Date object into a UTC ISO string with time set to 00:00:00.000Z.
 *
 * @param date - The Date object to format.
 * @returns The formatted UTC date string (YYYY-MM-DDT00:00:00.000Z).
 */
export const formatToUtcDayStartString = (date?: Date): string => {
  if (!date) {
    throw new Error("No date provided");
  }
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so +1 is needed
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}T00:00:00.000Z`;
};

/**
 * Extracts the time part from a datetime string in the local format.
 *
 * @param dateString - The date string to extract the time from.
 * @returns The time part of the datetime string in the format 'HH:MM'.
 */
export const extractLocalTime = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

/**
 * Extracts the time part from a datetime string in the UTC format.
 *
 * @param dateTimeString - The datetime string to extract the time from.
 * @returns The time part of the datetime string in the format 'HH:MM'.
 */
export const extractUtcTime = (dateTimeString: string): string => {
  if (!dateTimeString.endsWith("Z") || dateTimeString.length !== 24) {
    throw new Error(`The provided ISO date string ${dateTimeString} is not in UTC format.`);
  }
  return dateTimeString.substring(11, 16);
};

/**
 * Extracts the date part from a datetime string in the UTC format.
 *
 * @param dateTimeString - The datetime string to extract the date from.
 * @param separator - Optional separator to replace the default '-' in the date format.
 * @param dayoToYear - If true, returns the date in the format 'DD-MM-YYYY' instead of 'YYYY-MM-DD'.
 * @returns The date part of the datetime string in the format 'YYYY-MM-DD' or 'DD-MM-YYYY' based on the dayoToYear
 *   flag.
 * @throws Error if the provided datetime string is not in a valid UTC format.
 */
export const extractDateFromUTC = (dateTimeString: string, separator?: string, dayoToYear?: boolean): string => {
  if (!dateTimeString.endsWith("Z") || dateTimeString.length !== 24) {
    throw new Error(`The provided ISO date string ${dateTimeString} is not in UTC format.`);
  }

  let date = dateTimeString.substring(0, 10); // Extracts YYYY-MM-DD

  if (dayoToYear) {
    // Rearranges the date to DD-MM-YYYY
    const [year, month, day] = date.split("-");
    date = `${day}-${month}-${year}`;
  }

  if (separator) {
    return date.replaceAll("-", separator);
  } else {
    return date;
  }
};

/**
 * Converts a UTC date to a "local" date while preserving year, month, and day. Sets the hours and minutes from the
 * original UTC string without timezone adjustments.
 *
 * @param utcDateString - The UTC date string to convert
 * @returns A new Date object with the adjusted local time
 */
export const convertToLocalDateTime = (utcDateString: string): Date => {
  const utcDate = new Date(utcDateString);

  // Create a new date preserving the year, month, and day
  const localDate = new Date(Date.UTC(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate()));

  // Parse and set hours and minutes from the original UTC date
  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  localDate.setHours(hours, minutes);

  return localDate;
};

/**
 * Adjusts the given date based on the view type and direction, and updates calendar references accordingly.
 *
 * @param prevDate - The previous date to be adjusted.
 * @param forward - A boolean indicating whether to move forward or backward.
 * @param activeView - The current active view type ('timeGridDay', 'timeGridWeek', 'dayGridMonth').
 * @param calendarRefs - A reference to an array of FullCalendar instances.
 * @returns The new adjusted date.
 */
export const getNewDate = (
  prevDate: Date,
  forward: boolean,
  activeView: string,
  calendarRefs: React.MutableRefObject<(FullCalendar | null)[]>,
): Date => {
  const newDate = new Date(prevDate);
  const direction = forward ? 1 : -1;

  switch (activeView) {
    case "timeGridDay":
      newDate.setDate(newDate.getDate() + direction);
      break;

    case "timeGridWeek":
      newDate.setDate(newDate.getDate() + 7 * direction);
      break;

    case "dayGridMonth":
      newDate.setMonth(newDate.getMonth() + direction);
      break;

    default:
      throw new Error(`Unknown view type: ${activeView}`);
  }

  calendarRefs.current.forEach((calendarRef) => {
    calendarRef?.getApi().gotoDate(newDate);
  });

  return newDate;
};
