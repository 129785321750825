import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import type {
  Evaluation as EvaluationType,
  SkillSet,
  SkillSetEvaluation,
  SkillEvaluation,
  EvaluationCriteria as EvaluationCriteriaType,
  Organization,
} from "../../../../types";
import { SkillSetCard } from "../../../../common/components/SkillSetCard";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar/CommonScrollbar";
import { CommonButton } from "../../../../common/components/CommonButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { EvaluationCriteria } from "../../../SkillSets/components/EvaluationCriteria";
import { cardStyles } from "../../../../common/utils/styles";

type EvaluationProps = {
  evaluation: EvaluationType;
  allSkillSets: SkillSet[];
  allCriteria: EvaluationCriteriaType[];
  disabled: boolean;
  onSaveEvaluation: (evaluation: EvaluationType) => void;
  onEvaluationChange: (evaluation: EvaluationType) => void;
  getSkillSetEvaluationCriteria: (skillSetId: string) => Promise<void>;
  skillSetEvaluationCriteriaData: { [key: string]: EvaluationCriteriaType[] };
  skillSetEvaluationCriteriaLoading: { [key: string]: boolean };
  organization?: Organization;
  /** Whether the component is loading */
  isLoading?: boolean;
};

/**
 * The Evaluation component allows users to evaluate a athlete by rating skills within each skill set. It handles the
 * state of the evaluation and communicates changes back to the parent component.
 */
export const Evaluation: React.FC<EvaluationProps> = (props) => {
  const [evaluation, setEvaluation] = useState<EvaluationType>(props.evaluation || { skillSetEvaluations: [] });
  const [isEditingDisabled, setIsEditingDisabled] = useState(true);

  useEffect(() => {
    props.onEvaluationChange(evaluation);
  }, [evaluation]);

  // Create a mapping of skillSetId to its evaluations and comments
  const evaluationValues: {
    [skillSetId: string]: {
      rating?: number;
      comment?: string;
      skillEvaluations: { [skillId: string]: { rating: number; comment?: string } };
    };
  } = {};

  evaluation.skillSetEvaluations.forEach((skillSetEvaluation) => {
    const skillEvaluations: { [skillId: string]: { rating: number; comment?: string } } = {};
    skillSetEvaluation.skillEvaluations.forEach((skillEvaluation) => {
      skillEvaluations[skillEvaluation.skill.id || ""] = {
        rating: skillEvaluation.rating,
        comment: skillEvaluation.comment,
      };
    });
    evaluationValues[skillSetEvaluation.skillSet.id || ""] = {
      rating: skillSetEvaluation.rating,
      comment: skillSetEvaluation.comment,
      skillEvaluations,
    };
  });

  const handleSkillRatingChange = (skillSetId: string, skillId: string, rating: number) => {
    setEvaluation((prev) => {
      const skillSetEvaluations = prev.skillSetEvaluations || [];
      const skillSetIndex = skillSetEvaluations.findIndex((sse) => sse.skillSet.id === skillSetId);

      let updatedSkillSetEvaluations: SkillSetEvaluation[];

      if (skillSetIndex > -1) {
        const skillEvaluations = skillSetEvaluations[skillSetIndex].skillEvaluations || [];
        const skillEvaluationIndex = skillEvaluations.findIndex((se) => se.skill.id === skillId);

        let updatedSkillEvaluations: SkillEvaluation[];

        if (skillEvaluationIndex > -1) {
          // Update existing skill evaluation
          updatedSkillEvaluations = skillEvaluations.map((se, index) =>
            index === skillEvaluationIndex ? { ...se, rating } : se,
          );
        } else {
          // Add new skill evaluation
          updatedSkillEvaluations = [...skillEvaluations, { skill: { id: skillId, name: "" }, rating }];
        }

        const updatedSkillSetEvaluation = {
          ...skillSetEvaluations[skillSetIndex],
          skillEvaluations: updatedSkillEvaluations,
        };

        updatedSkillSetEvaluations = skillSetEvaluations.map((sse, index) =>
          index === skillSetIndex ? updatedSkillSetEvaluation : sse,
        );
      } else {
        // Add new skill set evaluation
        const newSkillSetEvaluation: SkillSetEvaluation = {
          skillSet: { id: skillSetId, name: "", skills: [] },
          skillEvaluations: [{ skill: { id: skillId, name: "" }, rating }],
        };
        updatedSkillSetEvaluations = [...skillSetEvaluations, newSkillSetEvaluation];
      }

      return {
        ...prev,
        skillSetEvaluations: updatedSkillSetEvaluations,
      };
    });
  };

  const handleSkillSetCommentChange = (skillSetId: string, comment: string) => {
    setEvaluation((prev) => {
      const skillSetEvaluations = prev.skillSetEvaluations || [];
      const skillSetIndex = skillSetEvaluations.findIndex((sse) => sse.skillSet.id === skillSetId);

      let updatedSkillSetEvaluations: SkillSetEvaluation[];

      if (skillSetIndex > -1) {
        const updatedSkillSetEvaluation = {
          ...skillSetEvaluations[skillSetIndex],
          comment,
        };

        updatedSkillSetEvaluations = skillSetEvaluations.map((sse, index) =>
          index === skillSetIndex ? updatedSkillSetEvaluation : sse,
        );
      } else {
        // Add new skill set evaluation
        const newSkillSetEvaluation: SkillSetEvaluation = {
          skillSet: { id: skillSetId, name: "", skills: [] },
          comment,
          skillEvaluations: [],
        };
        updatedSkillSetEvaluations = [...skillSetEvaluations, newSkillSetEvaluation];
      }

      return {
        ...prev,
        skillSetEvaluations: updatedSkillSetEvaluations,
      };
    });
  };

  const handleSaveEvaluation = () => {
    if (!evaluation) {
      return;
    }
    props.onSaveEvaluation(evaluation);
    setIsEditingDisabled(true);
  };

  const handleSecondaryButtonClick = () => {
    if (isEditingDisabled) {
      setIsEditingDisabled(false);
    } else {
      setEvaluation(props.evaluation || { skillSetEvaluations: [] });
      setIsEditingDisabled(true);
    }
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="400px" maxHeight="75svh" position="relative">
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />
      <Stack direction="row" padding="6" spacing="4" justifyContent="space-between">
        <Text fontSize="xl">Evaluation</Text>
        <Box position="relative">
          <Popover closeOnBlur={false} placement="bottom-end">
            <PopoverTrigger>
              <CommonButton
                variantType="outlineSecondary"
                disabled={props.allCriteria.length === 0}
                tooltip={
                  props.allCriteria.length === 0
                    ? "No generic criteria available for rating levels. Add Evaluation Criteria in the 'Skill Sets' section to standardize evaluations"
                    : undefined
                }
              >
                Generic Evaluation Criteria
              </CommonButton>
            </PopoverTrigger>
            <PopoverContent paddingY="4" width="fit-content" maxWidth="xl" {...cardStyles({ selected: true })}>
              <PopoverArrow backgroundColor="orange.400" />
              <PopoverBody>
                <EvaluationCriteria readonly={true} allCriteria={props.allCriteria} isLoading={false} />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      </Stack>

      {props.allSkillSets.length === 0 ? (
        <Text paddingLeft="6" textColor="blackAlpha.600" flex={1}>
          No Skill Sets defined for athlete evaluation. Create Skill Sets in the 'Skill Sets' section to start
          evaluating performance
        </Text>
      ) : (
        <CommonScrollbar
          height="100%"
          overflow="scroll"
          flex={1}
          invisibleBorderWidth="0px 8px 0px 0px"
          paddingRight="0"
          paddingLeft="6"
          paddingBottom="6"
        >
          <SimpleGrid minChildWidth="80" gap="4" justifyItems="center">
            {props.allSkillSets.map((skillSet) => (
              <Box key={skillSet.id} maxHeight="inherit" overflow="visible">
                <SkillSetCard
                  skillSet={skillSet}
                  evaluationMode={true}
                  isDisabled={isEditingDisabled}
                  isLoading={false}
                  evaluationValues={evaluationValues[skillSet.id || ""] || { skillEvaluations: {} }}
                  onSkillEvaluationChange={handleSkillRatingChange}
                  onSkillSetCommentChange={handleSkillSetCommentChange}
                  getEvaluationCriteria={props.getSkillSetEvaluationCriteria}
                  evaluationCriteriaData={props.skillSetEvaluationCriteriaData[skillSet.id || ""] || []}
                  evaluationCriteriaLoading={props.skillSetEvaluationCriteriaLoading[skillSet.id || ""] || false}
                  organization={props.organization}
                />
              </Box>
            ))}
          </SimpleGrid>
        </CommonScrollbar>
      )}

      <Stack direction="row" padding="6" spacing="4" justifyContent="end">
        <CommonButton
          variantType="outlineSecondary"
          onClick={handleSecondaryButtonClick}
          disabled={props.disabled || props.allSkillSets.length === 0}
        >
          {isEditingDisabled ? "Edit" : "Cancel"}
        </CommonButton>
        <CommonButton
          variantType="solidPrimary"
          onClick={handleSaveEvaluation}
          disabled={props.disabled || isEditingDisabled}
        >
          Save
        </CommonButton>
      </Stack>
    </Box>
  );
};
