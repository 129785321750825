import { gql } from "@apollo/client";

export const GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_SEASON_PLAN = gql`
  query GetAllRecurringTrainingEventMetadatasForSeasonPlan {
    getAllRecurringTrainingEventMetadatas {
      id
      players {
        id
        seasonPlans {
          id
          startDateTime
          endDateTime
          isOrganizationWide
          skillSets {
            id
            skillSet {
              id
              name
            }
            skills {
              id
              name
            }
          }
        }
      }
      recurringTrainingEvent {
        id
        seasonPlans {
          id
          startDateTime
          endDateTime
          isOrganizationWide
          skillSets {
            id
            skillSet {
              id
              name
            }
            skills {
              id
              name
            }
          }
        }
      }
    }
  }
`;
