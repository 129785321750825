import { gql } from "@apollo/client";

export const GET_ALL_CENTERS = gql`
  query GetAllCenters {
    getAllCenters {
      id
      name
      venues {
        id
        name
      }
    }
  }
`;
