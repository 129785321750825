import { useState } from "react";
import type { Center, Coach, Venue } from "../../../types";
import type { FullCalendarEvent, TrainingEventDetails } from "../types";

export const useCalendarState = () => {
  const [activeView, setActiveView] = useState("timeGridWeek");
  const [eventsToDisplay, setEventsToDisplay] = useState<FullCalendarEvent[]>([]);
  const [fullCalendarKey, setFullCalendarKey] = useState(1);
  const [dayViewDate, setDayViewDate] = useState(new Date());
  const [dayViewSelectedVenue, setDayViewSelectedVenue] = useState<Venue | null>(null);

  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isDraggableTodoOpen, setIsDraggableTodoOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [showOnlyFreeSpots, setShowOnlyFreeSpots] = useState(false);
  const [showOnlyTrainingGroups, setShowOnlyTrainingGroups] = useState(false);
  const [showOnlySingleTrainingEvents, setShowOnlySingleTrainingEvents] = useState(false);

  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
  const [selectedDateTime, setSelectedDateTime] = useState<string | undefined>(undefined);
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<string | null>(null);
  const [selectedEventInfo, setSelectedEventInfo] = useState<TrainingEventDetails>({
    id: "",
    name: "",
    date: new Date().toISOString(),
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    venue: { name: "" },
    coach: [],
    athletes: [],
    athleteNotes: [],
    trainingEventType: "single",
    isLoading: false,
  });
  const [selectedCenter, setSelectedCenter] = useState<Center | null>(null);

  return {
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyTrainingGroups,
    showOnlySingleTrainingEvents,
    isAddEventModalOpen,
    isDraggableTodoOpen,
    selectedDateTime,
    selectedStartTime,
    selectedEndTime,
    isViewModalOpen,
    selectedEventInfo,
    eventsToDisplay,
    fullCalendarKey,
    activeView,
    dayViewDate,
    dayViewSelectedVenue,
    selectedCenter,
    setSelectedCoaches,
    setShowOnlyFreeSpots,
    setShowOnlyTrainingGroups,
    setShowOnlySingleTrainingEvents,
    setIsAddEventModalOpen,
    setIsDraggableTodoOpen,
    setSelectedDateTime,
    setSelectedStartTime,
    setSelectedEndTime,
    setIsViewModalOpen,
    setSelectedEventInfo,
    setFullCalendarKey,
    setActiveView,
    setDayViewDate,
    setDayViewSelectedVenue,
    setEventsToDisplay,
    setSelectedCenter,
  };
};
