import React, { useEffect, useRef, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { CalendarHeader, CalendarBody, CalendarModals } from "./components";
import { useCalendarState } from "../hooks";
import {
  createRecurringTrainingEvents,
  createSingleTrainingEvents,
  filterEvents,
  handleDateSelect,
  handleEventClick,
  saveNewSingleTrainingEvent,
  saveTrainingEventUpdate,
} from "../utils";
import type { FullCalendarEvent, TrainingEventUpdateInput } from "../types";
import type { Club, Court } from "../../../types";
import type FullCalendar from "@fullcalendar/react";
import type { AlertContextType } from "../../../common/components/AlertProvider";
import type { MutationFunction } from "@apollo/client";
import { getNewDate } from "../../../common/utils";

export type CalendarProps = {
  globalSelectedClub: Club | null;
  currentBreakPoint: string;
  recurringTrainingEventMetadata: any;
  drillData: any;
  sessionData: any;
  playerData: any;
  coachData: any;
  singleTrainingEventData: any;
  skillSetsData: any;
  showAlert: AlertContextType["showAlert"];
  saveSingleTrainingEvent: MutationFunction;
  updateRecurringTrainingEventMetadata: MutationFunction;
};

export const Calendar: React.FC<CalendarProps> = (props) => {
  const calendarRefs = useRef<(FullCalendar | null)[]>([]);
  const unitedEventsRef = useRef<FullCalendarEvent[] | null>(null);

  // State management using custom hooks
  const {
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyRecurringTrainingEvents,
    showOnlySingleTrainingEvents,
    isAddEventModalOpen,
    isDraggableTodoOpen,
    selectedDateTime,
    selectedStartTime,
    selectedEndTime,
    isViewModalOpen,
    selectedEventInfo,
    eventsToDisplay,
    fullCalendarKey,
    activeView,
    dayViewDate,
    dayViewSelectedCourt,
    setSelectedCoaches,
    setShowOnlyFreeSpots,
    setShowOnlyRecurringTrainingEvents,
    setShowOnlySingleTrainingEvents,
    setIsAddEventModalOpen,
    setIsDraggableTodoOpen,
    setSelectedDateTime,
    setSelectedStartTime,
    setSelectedEndTime,
    setIsViewModalOpen,
    setSelectedEventInfo,
    setFullCalendarKey,
    setActiveView,
    setDayViewDate,
    setDayViewSelectedCourt,
    setEventsToDisplay,
  } = useCalendarState();

  const recurringTrainingEvents = useMemo(() => {
    if (
      props.recurringTrainingEventMetadata &&
      props.recurringTrainingEventMetadata.getAllRecurringTrainingEventMetadatas &&
      props.playerData?.getAllPlayers?.length > 0
    ) {
      return createRecurringTrainingEvents(props.recurringTrainingEventMetadata.getAllRecurringTrainingEventMetadatas);
    }
  }, [props.recurringTrainingEventMetadata, props.playerData.getAllPlayers]);

  const singleTrainingEvents = useMemo(() => {
    if (props.singleTrainingEventData?.getAllSingleTrainingEvents?.length > 0) {
      return createSingleTrainingEvents(props.singleTrainingEventData.getAllSingleTrainingEvents);
    }
  }, [props.singleTrainingEventData.getAllSingleTrainingEvents, props.recurringTrainingEventMetadata]);

  useEffect(() => {
    if (recurringTrainingEvents || singleTrainingEvents) {
      const allEvents = [...(recurringTrainingEvents || []), ...(singleTrainingEvents || [])];
      unitedEventsRef.current = allEvents;
    }
  }, [recurringTrainingEvents, singleTrainingEvents]);

  useEffect(() => {
    const events = filterEvents(
      unitedEventsRef,
      selectedCoaches,
      showOnlyFreeSpots,
      showOnlyRecurringTrainingEvents,
      showOnlySingleTrainingEvents,
    );
    if (events) {
      setEventsToDisplay(events);
    }
  }, [
    recurringTrainingEvents,
    singleTrainingEvents,
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyRecurringTrainingEvents,
    showOnlySingleTrainingEvents,
  ]);

  // Event handlers
  const handleAddEventModalClose = () => {
    setSelectedDateTime(undefined);
    setSelectedStartTime(null);
    setIsAddEventModalOpen(false);
    calendarRefs.current.forEach((calendarRef) => {
      calendarRef?.getApi().unselect();
    });
  };

  const handleViewEventModalClose = () => {
    setIsViewModalOpen(false);
  };

  const setEventLoadingState = (trainingEventId: string, loadingState: boolean) => {
    const event = eventsToDisplay.find((event) => event.id === trainingEventId);
    if (event) {
      event.isLoading = loadingState;
      setFullCalendarKey((prevKey) => prevKey + 1);
    }
  };

  const handleSaveNewSingleTrainingEvent = async (singleTrainingEventInput: TrainingEventUpdateInput) => {
    props.showAlert("Creating new training", "info", undefined, true);
    setFullCalendarKey((prevKey) => prevKey + 1);

    await saveNewSingleTrainingEvent(
      singleTrainingEventInput,
      setIsAddEventModalOpen,
      props.saveSingleTrainingEvent,
      props.showAlert,
    );
  };

  const handleUpdateEvent = async (
    trainingEventType: FullCalendarEvent["trainingEventType"],
    updatedData: TrainingEventUpdateInput,
  ) => {
    if (updatedData.remove) {
      props.showAlert("Deleting training", "info", undefined, true);
    } else {
      props.showAlert("Updating training", "info", undefined, true);
    }
    setEventLoadingState(updatedData.id || "", true);

    await saveTrainingEventUpdate(
      trainingEventType,
      updatedData,
      setIsViewModalOpen,
      props.updateRecurringTrainingEventMetadata,
      props.saveSingleTrainingEvent,
      setIsAddEventModalOpen,
      props.showAlert,
    );

    setEventLoadingState(updatedData.id || "", false);
  };

  const handleCalendarEventClick = (info: any) => {
    handleEventClick(info, setSelectedEventInfo, setIsViewModalOpen);
  };

  const handleCalendarDateSelect = (args: any, court: Court | null) => {
    setDayViewSelectedCourt(court);
    handleDateSelect(args, setSelectedDateTime, setSelectedStartTime, setSelectedEndTime, setIsAddEventModalOpen);
  };

  return (
    <Box>
      <CalendarHeader
        allCoaches={props.coachData?.getAllCoaches || []}
        isDraggableTodoOpen={isDraggableTodoOpen}
        selectedCoaches={selectedCoaches}
        setIsDraggableTodoOpen={setIsDraggableTodoOpen}
        setSelectedCoaches={setSelectedCoaches}
        setShowOnlyFreeSpots={setShowOnlyFreeSpots}
        setShowOnlyRecurringTrainingEvents={setShowOnlyRecurringTrainingEvents}
        setShowOnlySingleTrainingEvents={setShowOnlySingleTrainingEvents}
        showOnlyFreeSpots={showOnlyFreeSpots}
        showOnlyRecurringTrainingEvents={showOnlyRecurringTrainingEvents}
        showOnlySingleTrainingEvents={showOnlySingleTrainingEvents}
        activeView={activeView}
        calendarRefs={calendarRefs}
        dayViewDate={dayViewDate}
        getNewDate={getNewDate}
        globalSelectedClub={!!props.globalSelectedClub}
        setActiveView={setActiveView}
        setDayViewDate={setDayViewDate}
        setFullCalendarKey={setFullCalendarKey}
        isMobile={props.currentBreakPoint === "mobile"}
      />
      <CalendarBody
        activeView={props.currentBreakPoint === "mobile" ? "timeGridDay" : activeView}
        dayViewDate={dayViewDate}
        eventsToDisplay={eventsToDisplay}
        allCourts={props.globalSelectedClub?.courts || []}
        globalSelectedClub={props.globalSelectedClub}
        calendarRefs={calendarRefs}
        handleEventClick={handleCalendarEventClick}
        dateSelectHandler={handleCalendarDateSelect}
        fullCalendarKey={fullCalendarKey}
        showAlert={props.showAlert}
        isMobile={props.currentBreakPoint === "mobile"}
      />
      <CalendarModals
        isViewModalOpen={isViewModalOpen}
        saveTrainingEventUpdate={handleUpdateEvent}
        selectedEventInfo={selectedEventInfo}
        isAddEventModalOpen={isAddEventModalOpen}
        saveNewSingleTrainingEvent={handleSaveNewSingleTrainingEvent}
        selectedDateTime={selectedDateTime}
        selectedStartTime={selectedStartTime}
        selectedEndTime={selectedEndTime}
        dayViewSelectedCourt={dayViewSelectedCourt}
        allCoaches={props.coachData?.getAllCoaches || []}
        allCourts={props.globalSelectedClub?.courts || []}
        allPlayers={props.playerData?.getAllPlayers || []}
        allDrills={props.drillData?.getAllDrills || []}
        allSessions={props.sessionData?.getAllSessions || []}
        allSkillSets={props.skillSetsData?.getAllSkillSets || []}
        handleAddEventModalClose={handleAddEventModalClose}
        handleViewEventModalClose={handleViewEventModalClose}
        isMobile={props.currentBreakPoint === "mobile"}
      />
    </Box>
  );
};
