import React from "react";
import { CommonTextArea } from "../../../../../../common/components/CommonTextArea";

type NotesInputProps = {
  /** Callback function for updating the notes value */
  setNotes: (value: string) => void;
  /** Current notes value */
  notes: string;
  /** Label of the component */
  label: string;
  /** When set to true, the component is read-only */
  disabled?: boolean;
  /** Minimum number of rows to display */
  minRows?: number;
};

/** NotesInput component allows the user to input notes for an event */
export const NotesInput: React.FC<NotesInputProps> = (props) => {
  return (
    <CommonTextArea
      placeholder={props.label}
      value={props.notes}
      onChange={(e) => props.setNotes(e)}
      disabled={props.disabled}
    />
  );
};
