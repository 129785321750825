import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
  Stack,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CREATE_ACCOUNT_WITH_INVITE } from "../../graphql/create-account-with-invite.mutation";
import { useAlert } from "../../../../common/components/AlertProvider";
import { useAsyncMutation } from "../../../../common/hooks";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { validateAndCheckChanges } from "../../../../common/utils/dataProcessing";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { cardStyles } from "../../../../common/utils/styles";
import { Step_0_Account } from "./components/Step_0_Account";
import { Step_1_Organization } from "./components/Step_1_OrganIzation";
import { Step_2_Team } from "./components/Step_2_Team";
import { Step_3_Review } from "./components/Step_3_Review";
import { slideInFromLeft, slideInFromRight, slideOutToLeft, slideOutToRight, validationLogic } from "./utils";
import type { Invite } from "../../../../types";

type CreateAccountCenterInput = {
  name: string;
  venues: string[];
};

type CreateAccountInput = {
  email: string;
  password: string;
  organizationName: string;
  centers?: CreateAccountCenterInput[];
  coachNames?: string[];
  athleteNames?: string[];
};

type CreateAccountProps = {
  invite: Invite;
  setIsAuthenticated: (value: boolean) => void;
};

export const CreateAccount: React.FC<CreateAccountProps> = (props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const steps = [
    { title: "Setup", description: "Create your login credentials" },
    { title: "Organization", description: "Add your organization details" },
    { title: "Team", description: "Add coaches and athletes" },
    { title: "Get Started", description: "Review and start using Striveon" },
  ];
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: steps.length });
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const { execute: createAccountWithInviteMutation, loading: createAccountWithInviteLoading } = useAsyncMutation(
    CREATE_ACCOUNT_WITH_INVITE,
    {
      onCustomError(error) {
        if (error?.message === "Cognito signUp failed: User already exists") {
          return "User with this email already exists";
        }
        return undefined;
      },
    },
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [centers, setCenters] = useState<{ name: string; venues: string[] }[]>([{ name: "", venues: [""] }]);
  const [coachNames, setCoachNames] = useState<string[]>([]);
  const [athleteNames, setAthleteNames] = useState<string[]>([]);
  const [changeStepAnimation, setChangeStepAnimation] = useState("");

  const animationTimeInMs = 600;
  const animationDurations = `${animationTimeInMs / 1000}s ease`;

  useEffect(() => {
    headerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [activeStep]);

  const handleStepChange = (direction: "next" | "prev") => {
    if (direction === "next") {
      // slide out current content with opacity fading
      setChangeStepAnimation(`${slideOutToLeft} ${animationDurations}`);
      // bring new content visible while previous content is sliding out half-way
      setTimeout(() => setChangeStepAnimation(`${slideInFromRight} ${animationDurations}`), animationTimeInMs / 2);
    } else if (direction === "prev") {
      setChangeStepAnimation(`${slideOutToRight} ${animationDurations}`);
      setTimeout(() => setChangeStepAnimation(`${slideInFromLeft} ${animationDurations}`), animationTimeInMs / 2);
    }
  };

  /** Attempts to submit the form at the final step. If not at final step, moves to next step. */
  const handleNext = async () => {
    if (activeStep < steps.length - 1) {
      handleStepChange("next");

      return setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, animationTimeInMs / 2);
    }

    showAlert("Creating Account", "info", undefined, true);

    const input: CreateAccountInput = {
      email,
      organizationName,
      password,
      centers,
      athleteNames,
      coachNames,
    };

    const result = await createAccountWithInviteMutation({
      inviteCode: props.invite.inviteCode,
      data: input,
    });

    if (result?.createAccountWithInvite) {
      localStorage.setItem("token", result.createAccountWithInvite);
      props.setIsAuthenticated(true);
      showAlert("Account created, welcome to Striveon!", "success", 5000);
      navigate("/");
    }
  };

  /** Moves back to the previous step if possible. */
  const handleBack = () => {
    if (activeStep > 0) {
      handleStepChange("prev");

      return setTimeout(() => {
        setActiveStep(activeStep - 1);
      }, animationTimeInMs / 2);
    }
  };

  const { submitDisabledReasons } = validateAndCheckChanges(
    { email, password, organizationName, centers, coachNames, athleteNames },
    () => validationLogic(activeStep, { email, password, organizationName, centers, coachNames, athleteNames }),
  );

  return (
    <Stack
      position="relative"
      direction="column"
      spacing={{ mobile: "4", laptop: "8" }}
      margin={{ mobile: undefined, laptop: "auto" }}
      maxWidth="1200px"
      height={{ mobile: "auto", laptop: "100%" }}
      maxHeight={{ mobile: undefined, laptop: "1000px" }}
      overflow={{ mobile: undefined, laptop: "hidden" }}
      flex={1}
    >
      <LoadingOverlay display={createAccountWithInviteLoading} spinnerSize="xl" spinnerTopPosition="50%" />

      {/* Scroll target for mobile, under the actual header */}
      <Box position="absolute" top="-56px" ref={headerRef} />

      {/* Header */}
      <Stack direction="column" spacing={{ mobile: "2", laptop: "4" }}>
        <Text fontSize="x-large" align="center" display={{ mobile: "none", laptop: "block" }}>
          Create Your Striveon Account
        </Text>
        <Stack direction="column" textAlign="center" display={{ mobile: "block", laptop: "none" }}>
          <Text fontSize="x-large">
            Step {activeStep + 1} - {steps[activeStep]?.title}
          </Text>
          <Text textColor="blackAlpha.600">{steps[activeStep]?.description}</Text>
        </Stack>
        <Stepper size="md" index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
              </StepIndicator>
              <Box display={{ mobile: "none", laptop: "block" }}>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Stack>

      {/* Step content */}
      <Box
        width="100%"
        maxWidth="600px"
        height="100%"
        alignSelf="center"
        overflow={{ mobile: undefined, laptop: "hidden" }}
        flex={{ mobile: undefined, laptop: 1 }}
        animation={{ mobile: undefined, laptop: changeStepAnimation }}
      >
        <CommonScrollbar
          key={activeStep}
          height="100%"
          overflow="auto"
          invisibleBorderWidth="0px 0px 0px 8px"
          paddingLeft="3"
          paddingRight="2"
        >
          {activeStep === 0 && (
            <Step_0_Account
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              loading={createAccountWithInviteLoading}
            />
          )}

          {activeStep === 1 && (
            <Step_1_Organization
              organizationName={organizationName}
              setOrganizationName={setOrganizationName}
              centers={centers}
              setCenters={setCenters}
              loading={createAccountWithInviteLoading}
            />
          )}

          {activeStep === 2 && (
            <Step_2_Team
              coachNames={coachNames}
              setCoachNames={setCoachNames}
              athleteNames={athleteNames}
              setAthleteNames={setAthleteNames}
              loading={createAccountWithInviteLoading}
            />
          )}

          {activeStep === 3 && (
            <Step_3_Review
              email={email}
              password={password}
              organizationName={organizationName}
              centers={centers}
              coachNames={coachNames}
              athleteNames={athleteNames}
              cardStyles={cardStyles}
            />
          )}
        </CommonScrollbar>
      </Box>

      {/* Footer/buttons */}
      <Box
        paddingLeft={{ mobile: "0", laptop: "3" }}
        paddingRight={{ mobile: "0", laptop: "6" }}
        width="100%"
        maxWidth="600px"
        alignSelf="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing="4">
          <CommonButton
            variantType="outlineSecondary"
            onClick={handleBack}
            disabled={activeStep === 0 || createAccountWithInviteLoading}
          >
            Back
          </CommonButton>
          <CommonButton
            fullWidth
            variantType="solidPrimary"
            onClick={handleNext}
            disabled={!!submitDisabledReasons || createAccountWithInviteLoading}
            tooltip={submitDisabledReasons}
          >
            {activeStep === steps.length - 1 ? "Create Account" : "Continue"}
          </CommonButton>
        </Stack>
      </Box>
    </Stack>
  );
};
