import { gql } from "@apollo/client";

export const GET_ALL_PLAYERS_FOR_SEASON_PLAN = gql`
  query GetAllPlayersForSeasonPlan {
    getAllPlayers {
      id
      name
    }
  }
`;
