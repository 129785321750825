import { gql } from "@apollo/client";

export const GET_MISSING_TRAINING_GROUP_EVENTS = gql`
  query GetMissingTrainingGroupEvents($id: String!) {
    getMissingTrainingGroupEvents(id: $id) {
      startDateTime
      athletes {
        id
        name
      }
      venue {
        id
        name
      }
      coaches {
        id
        name
      }
      trainingGroup {
        id
        name
        trainingSeason {
          id
          name
          status
        }
      }
    }
  }
`;
