import React, { useState } from "react";
import {
  TrainingSeasonStatus,
  type Coach,
  type Venue,
  type TrainingGroup,
  type TrainingSeason,
} from "../../../../../types";
import { Box, Stack } from "@chakra-ui/react";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { CommonSelect } from "../../../../../common/components/CommonSelect";
import { TrainingGroupEditor } from "../../../../../common/components/TrainingGroupEditor";

type TrainingGroupAdderProps = {
  nonParticipatedTrainingGroups: TrainingGroup[];
  allTrainingGroups: TrainingGroup[];
  allCoaches: Coach[];
  allVenues: Venue[];
  allTrainingSeasons: TrainingSeason[];
  onSave: (eventToSave: TrainingGroup) => void;
  onCancel: () => void;
  loading?: boolean;
};

/** Component for adding the athlete to a new training group */
export const TrainingGroupAdder: React.FC<TrainingGroupAdderProps> = (props) => {
  const [isSelectingExistingGroup, setIsSelectingExistingGroup] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [newEvent, setNewEvent] = useState<TrainingGroup | null>(null);

  const handleSelectExistingGroup = (groupId: string) => {
    setSelectedGroupId(groupId);
  };

  const handleCreateNewGroup = () => {
    setNewEvent({
      id: undefined,
      name: "",
      recurrenceWeekday: 1,
      recurrenceStartTime: "17:00",
      recurrenceEndTime: "18:30",
      venue: props.allVenues[0],
      coaches: [],
      athletes: [],
      trainingSeason:
        props.allTrainingSeasons.find((season) => season.status === TrainingSeasonStatus.Active) ||
        props.allTrainingSeasons[0],
    });
  };

  const handleSave = () => {
    if (isSelectingExistingGroup && selectedGroupId) {
      const selectedEvent = props.nonParticipatedTrainingGroups.find((event) => event.id === selectedGroupId);
      if (selectedEvent) {
        props.onSave(selectedEvent);
      }
    } else if (newEvent) {
      props.onSave(newEvent);
    }
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const reasons: string[] = [];

    if (!newEvent?.name || newEvent?.name.trim() === "") {
      reasons.push("Provide a Training Group name");
    }

    const identicalTrainingGroup = props.allTrainingGroups.find((event) => {
      return (
        event.recurrenceWeekday === newEvent?.recurrenceWeekday &&
        event.recurrenceStartTime === newEvent?.recurrenceStartTime &&
        event.recurrenceEndTime === newEvent?.recurrenceEndTime &&
        event.venue.id === newEvent?.venue.id &&
        event.trainingSeason.id === newEvent?.trainingSeason.id
      );
    });

    if (identicalTrainingGroup) {
      reasons.push(
        `Training Group with the same time, venue, and Training Season exists already (${identicalTrainingGroup.name})`,
      );
    }

    return reasons.length > 0
      ? `To enable saving, please address the following:\n- ${reasons.join("\n- ")}`
      : undefined;
  };
  const submitDisabledReasons = getSubmitDisabledReasons();

  return (
    <Stack direction="column" spacing="4">
      <Stack direction="row" spacing="6" alignItems="center" justify="center">
        <CommonButton
          variantType={isSelectingExistingGroup ? "solidPrimary" : "outlineSecondary"}
          onClick={() => {
            setIsSelectingExistingGroup(true);
            setNewEvent(null);
          }}
        >
          Select existing group
        </CommonButton>
        <CommonButton
          variantType={!isSelectingExistingGroup ? "solidPrimary" : "outlineSecondary"}
          onClick={() => {
            setIsSelectingExistingGroup(false);
            setSelectedGroupId(null);
            handleCreateNewGroup();
          }}
          dataTestId="create-new-group"
        >
          Create new group
        </CommonButton>
      </Stack>

      {isSelectingExistingGroup ? (
        <CommonSelect
          placeholder={props.nonParticipatedTrainingGroups.length === 0 ? "No groups available" : "Group"}
          value={selectedGroupId || undefined}
          options={props.nonParticipatedTrainingGroups.map((event) => ({
            value: event.id || "",
            label: event.name || "",
            disabled: event.trainingSeason.status === TrainingSeasonStatus.Completed,
          }))}
          disabledOptionsToLast={true}
          onChange={(e) => handleSelectExistingGroup(e.target.value)}
          data-testid="select-existing-group"
        />
      ) : (
        newEvent && (
          <Box data-testid="new-training-group">
            <TrainingGroupEditor
              trainingGroup={newEvent}
              allCoaches={props.allCoaches}
              allVenues={props.allVenues}
              allTrainingSeasons={props.allTrainingSeasons}
              onChange={(editedEvent) => setNewEvent(editedEvent)}
              displayAthletes={false}
            />
          </Box>
        )
      )}

      <Stack direction="row" spacing="4" justifyContent="end">
        <CommonButton variantType="outlineSecondary" onClick={props.onCancel} dataTestId="cancel-new-training-group">
          Cancel
        </CommonButton>
        <CommonButton
          variantType="solidPrimary"
          onClick={handleSave}
          disabled={isSelectingExistingGroup ? !selectedGroupId : !newEvent || !!submitDisabledReasons}
          tooltip={isSelectingExistingGroup && !selectedGroupId ? "Group must be selected" : submitDisabledReasons}
          dataTestId="save-new-training-group"
        >
          Save
        </CommonButton>
      </Stack>
    </Stack>
  );
};
