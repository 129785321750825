import { gql } from "@apollo/client";

export const SAVE_SESSION = gql`
  mutation SaveSession($data: SessionInput!) {
    saveSession(data: $data) {
      goal
      id
      name
      tags
      totalDuration
      sessionDrills {
        id
        duration
        order
        drill {
          id
          goal
          implementation
          name
          tags
        }
      }
    }
  }
`;
