import React, { useState } from "react";
import { Box, Divider, Stack, Text } from "@chakra-ui/react";
import { Organization } from "../../../types";
import { OrganizationUsers } from "./components/OrganizationUsers";
import { Autocomplete } from "../../../common/components/AutoComplete";
import { CommonButton } from "../../../common/components/CommonButton";
import { FormModal } from "../../../common/components/FormModal";
import { CommonInput } from "../../../common/components/CommonInput";

type AdminProps = {
  allOrganizations: Organization[];
  saveOrganization: (organizationInput: any) => Promise<void>;
  registerNewUser: (userData: any) => Promise<void>;
};

/** Admin handles UI logic and displays organizations and their users. */
export const Admin: React.FC<AdminProps> = (props) => {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState("");

  const handleOrganizationChange = (newOrganization: Organization) => {
    setSelectedOrganization(newOrganization);
  };

  const handleCreateNewOrganization = async () => {
    if (!newOrganizationName.trim()) {
      alert("Organization name cannot be empty.");
      return;
    }
    await props.saveOrganization({ name: newOrganizationName });
    setNewOrganizationName("");
    setIsDialogOpen(false);
  };

  const handleRegisterNewUser = (username: string, password: string) => {
    if (!selectedOrganization) return;
    props.registerNewUser({
      username,
      password,
      organizationId: selectedOrganization.id,
    });
  };

  return (
    <Box>
      <Stack direction="column" spacing="4">
        <Text fontSize="x-large" textAlign="center">
          {selectedOrganization?.name || "Select organization"}
        </Text>
        <Stack direction="row" justifyContent="space-between" spacing="6" alignItems="center">
          <Autocomplete
            allOptions={props.allOrganizations}
            handleOptionChange={handleOrganizationChange}
            getOptionLabel={(organization) => organization.name}
          />
          <CommonButton variantType="outlineSecondary" onClick={() => setIsDialogOpen(true)}>
            Create new Organization
          </CommonButton>
        </Stack>
        {selectedOrganization && (
          <>
            <Divider />
            <OrganizationUsers
              key={selectedOrganization.id}
              organizationUsers={selectedOrganization.users || []}
              onUserAndPasswordChange={handleRegisterNewUser}
            />
          </>
        )}
      </Stack>
      <FormModal
        open={isDialogOpen}
        title="Create new Organization"
        submitButtonText="Create"
        handleSubmit={handleCreateNewOrganization}
        onClose={() => setIsDialogOpen(false)}
        submitDisabled={!newOrganizationName.trim()}
      >
        <CommonInput
          placeholder="Organization name"
          value={newOrganizationName}
          onChange={(value) => setNewOrganizationName(value)}
        />
      </FormModal>
    </Box>
  );
};
