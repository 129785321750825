import { useState } from "react";
import type { Coach, Court } from "../../../types";
import type { FullCalendarEvent } from "../types";

export const useCalendarState = () => {
  const [activeView, setActiveView] = useState("timeGridWeek");
  const [eventsToDisplay, setEventsToDisplay] = useState<FullCalendarEvent[]>([]);
  const [fullCalendarKey, setFullCalendarKey] = useState(1);
  const [dayViewDate, setDayViewDate] = useState(() => {
    const initialDate = new Date();
    const currentDayOfWeek = initialDate.getDay();
    if (currentDayOfWeek === 0) {
      initialDate.setDate(initialDate.getDate() + 1);
    } else if (currentDayOfWeek === 6) {
      initialDate.setDate(initialDate.getDate() + 2);
    }
    return initialDate;
  });
  const [dayViewSelectedCourt, setDayViewSelectedCourt] = useState<Court | null>(null);

  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isDraggableTodoOpen, setIsDraggableTodoOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [showOnlyFreeSpots, setShowOnlyFreeSpots] = useState(false);
  const [showOnlyRecurringTrainingEvents, setShowOnlyRecurringTrainingEvents] = useState(false);
  const [showOnlySingleTrainingEvents, setShowOnlySingleTrainingEvents] = useState(false);

  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
  const [selectedDateTime, setSelectedDateTime] = useState<string | undefined>(undefined);
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<string | null>(null);
  const [selectedEventInfo, setSelectedEventInfo] = useState<FullCalendarEvent>({
    id: "",
    date: undefined,
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    court: { name: "" },
    coach: [],
    players: [],
    playerNotes: [],
    trainingEventType: "single",
    isLoading: false,
    title: "",
    color: "",
  });

  return {
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyRecurringTrainingEvents,
    showOnlySingleTrainingEvents,
    isAddEventModalOpen,
    isDraggableTodoOpen,
    selectedDateTime,
    selectedStartTime,
    selectedEndTime,
    isViewModalOpen,
    selectedEventInfo,
    eventsToDisplay,
    fullCalendarKey,
    activeView,
    dayViewDate,
    dayViewSelectedCourt,
    setSelectedCoaches,
    setShowOnlyFreeSpots,
    setShowOnlyRecurringTrainingEvents,
    setShowOnlySingleTrainingEvents,
    setIsAddEventModalOpen,
    setIsDraggableTodoOpen,
    setSelectedDateTime,
    setSelectedStartTime,
    setSelectedEndTime,
    setIsViewModalOpen,
    setSelectedEventInfo,
    setFullCalendarKey,
    setActiveView,
    setDayViewDate,
    setDayViewSelectedCourt,
    setEventsToDisplay,
  };
};
