import { gql } from "@apollo/client";

export const GET_ALL_SESSIONS = gql`
  query GetAllSessions {
    getAllSessions {
      id
      name
      goal
      totalDuration
      tags
      createdAt
      updatedAt
      sessionDrills {
        id
        duration
        order
        drill {
          id
          goal
          implementation
          name
          tags
        }
      }
      createdBy {
        username
      }
      skillSets {
        id
        name
      }
    }
  }
`;
