import { gql } from "@apollo/client";

export const GET_SKILL_SET_EVALUATION_CRITERIA = gql`
  query GetSkillSetEvaluationCriteria($skillSetId: ID!) {
    getSkillSetEvaluationCriteria(skillSetId: $skillSetId) {
      id
      fromRatingLevel
      toRatingLevel
      definition
      requirements
      skillSet {
        id
      }
    }
  }
`;
