import React, { useState } from "react";
import {
  Box,
  Image,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  Divider,
  Stack,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Logout } from "@mui/icons-material";
import { NavigationLink } from "./components";
import Striveon from "../../assets/StriveonCroppedPadding.png";
import { CommonIconButton } from "../../common/components/CommonIconButton";
import { links, type HeaderProps } from "./Header";
import { AlertComponent } from "../../common/components/AlertProvider/components/AlertComponent";
import { useLocation } from "react-router-dom";

export const MobileHeader: React.FC<HeaderProps> = (props) => {
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  console.log({ location });

  const headerTitle =
    location.pathname === "/create-account" || location.pathname === "/create-account/"
      ? "Create Your Striveon Account"
      : "Striveon";

  return (
    <Box
      position="fixed"
      top="0"
      width="100%"
      bg="teal.800"
      paddingY="2"
      paddingX="4"
      zIndex="10"
      display={{ mobile: "flex", laptop: "none" }}
      alignItems="center"
      justifyContent="space-between"
      height="56px"
    >
      <Logo title={headerTitle} />
      {props.isAuthenticated && (
        <CommonIconButton
          backgroundColor="transparent"
          borderColor="whiteAlpha.700"
          icon={isOpen ? <CloseIcon color="orange.400" /> : <HamburgerIcon color="whiteAlpha.700" />}
          onClick={isOpen ? onClose : onOpen}
          ariaLabel="Toggle Navigation"
        />
      )}
      <Drawer placement="top" onClose={onClose} isOpen={isOpen} autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent bg="teal.800" color="whiteAlpha.900" height="100dvh" overflow="auto">
          <DrawerHeader marginBottom="12" paddingBottom="0">
            <Logo title={headerTitle} />
            <DrawerCloseButton color="whiteAlpha.700" size="lg" padding="6" />
          </DrawerHeader>

          {isAlertOpen && (
            <AlertComponent
              severity="info"
              message="To access all features, use Striveon on a laptop or larger screen"
              hideAlert={() => setIsAlertOpen(false)}
            />
          )}

          <DrawerBody
            maxHeight="100dvh"
            overflow="auto"
            justifyContent="space-between"
            display="flex"
            flexDirection="column"
            paddingTop="0"
            paddingBottom="6"
          >
            <Stack flex={1} direction="column" spacing="2" align="start" divider={<Divider />} marginBottom="8">
              {links.map(
                (link) =>
                  (!link.restricted || (link.restricted && props.isAdmin)) && (
                    <NavigationLink
                      key={link.to}
                      to={link.to}
                      icon={link.icon}
                      primaryText={link.primaryText}
                      onClick={onClose}
                      isDisabledOnMobile={link.isDisabledOnMobile}
                    />
                  ),
              )}
            </Stack>
            <CommonIconButton
              ariaLabel="logout"
              icon={<Logout sx={{ height: "40px" }} />}
              color="whiteAlpha.900"
              onClick={() => {
                props.setIsAuthenticated(false);
                localStorage.clear();
                onClose();
              }}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

const Logo: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Box display="flex" alignItems="center">
      <Image src={Striveon} width="32px" height="32px" borderRadius="8px" />
      <Text fontSize="xl" fontWeight="bold" marginLeft="2" color="whiteAlpha.900">
        {title}
      </Text>
    </Box>
  );
};
