import React from "react";
import { Venue } from "../../../types";
import { CommonSelect } from "../CommonSelect";

/** Props for the disabled state of the VenueSelect component */
type DisabledProps = {
  /** When set to true, the component is read-only */
  disabled: true;
};

/** Props for the enabled state of the VenueSelect component */
type EnabledProps = {
  /** Callback function for updating the selected venue */
  setSelectedVenue: (venueId: string) => void;
  /** When set to true, the component is read-only */
  disabled?: false;
};

/** Props for the VenueSelect component */
export type VenueSelectProps = (DisabledProps | EnabledProps) & {
  /** Selected venue value */
  selectedVenue: Venue;
  /** Array of available venues */
  venues: Venue[];
};

/**
 * VenueSelect component allows the user to select a specific venue from a list of available venues. It supports both
 * read-only and editable states.
 *
 * @param props - The props for the VenueSelect component.
 * @returns The VenueSelect component.
 */
export const VenueSelect: React.FC<VenueSelectProps> = (props) => {
  const isMultipleCenters = new Set(props.venues.map((venue) => venue.center?.id)).size > 1;

  /**
   * Handles the change of selected venue. If the component is not disabled and the setSelectedVenue callback is
   * defined, it calls setSelectedVenue with the new value.
   *
   * @param event - The event object
   */
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.disabled && "setSelectedVenue" in props) {
      props.setSelectedVenue(event.target.value);
    }
  };

  let options = props.venues.map((venue) => ({
    value: venue.id || "",
    label: isMultipleCenters && venue.center?.name ? `${venue.name} (${venue.center?.name})` : venue.name,
  }));
  if (props.venues.length === 0) {
    options = [{ value: "", label: "No venues defined" }, ...options];
  }

  return (
    <CommonSelect
      placeholder="Venue"
      value={props.selectedVenue?.id || ""}
      onChange={handleChange}
      isDisabled={props.disabled}
      options={options}
      data-testid="venue-select"
    />
  );
};
