import { useEffect, useRef } from "react";
import { useAlert } from "../components/AlertProvider";
import { handleError } from "./utils/handleErrors";

/**
 * A custom hook for handling errors from GraphQL queries. Displays error messages to the user and avoids showing
 * duplicate messages within the same render cycle.
 *
 * @param errors - An array of error objects from GraphQL queries.
 */
export const useHandleQueryErrors = (errors: any[]) => {
  const { showAlert } = useAlert();
  const previousErrorMessagesRef = useRef<string[]>([]);

  useEffect(() => {
    // Filter out falsy values (e.g., undefined or null errors)
    const filteredErrors = errors.filter(Boolean);

    // Process errors to get error messages
    const errorMessage = handleError(filteredErrors);

    if (errorMessage) {
      // Check if the error message is different from the previous one to avoid duplicate alerts
      if (!previousErrorMessagesRef.current.includes(errorMessage)) {
        showAlert(errorMessage, "error");
        previousErrorMessagesRef.current.push(errorMessage);
      }
    }
  }, [errors, showAlert]);
};
