import React, { useEffect, useState } from "react";
import { Coach, Court, type RecurringTrainingEvent } from "../../../../types";
import { Box, Stack, Text, Tooltip } from "@chakra-ui/react";
import { CommonButton } from "../../../../common/components/CommonButton";
import { DeleteIcon } from "@chakra-ui/icons";
import { useGlobalContext } from "../../../../common/components/GlobalProvider";
import { TrainingGroupAdder } from "./components/TrainingGroupAdder";
import { ConfirmationDialog } from "../../../../common/components/ConfirmationDialog";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { SingleTrainingGroup } from "./components/SingleTrainingGroup";

/** RecurringTrainingEvents props */
export type RecurringTrainingEventsProps = {
  /** Player name */
  playerName: string;
  /** Coaches to link to Recurring Training Event */
  coaches: Coach[];
  /** Courts to link to Recurring Training Event */
  courts: Court[];
  /** The player's events */
  recurringTrainingEvents: RecurringTrainingEvent[];
  /** All possible recurring training events */
  allTrainingEvents: RecurringTrainingEvent[];
  /** Function to handle event save */
  onSave: (recurringTrainingEventToSave: RecurringTrainingEvent) => void;
  /** Function to handle event remove */
  onRemove: (recurringTrainingEventToRemove: RecurringTrainingEvent) => void;
  /** Flag to indicate whether the component is loading */
  isLoading?: boolean;
  /** Flag to indicate whether season sates are missing */
  isSeasonDatesMissing: boolean;
};

// TODO: check cache with metadatas when adding player to existing group

/** Component for displaying and editing a player's recurring training events */
export const RecurringTrainingEvents: React.FC<RecurringTrainingEventsProps> = (props) => {
  const { globalSelectedClub } = useGlobalContext();
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
  const [recurringTrainingEventToRemove, setRecurringTrainingEventToRemove] = useState<RecurringTrainingEvent | null>(
    null,
  );

  useEffect(() => {
    if (isAddingNewGroup) {
      // scroll to bottom of the page
      window.scrollTo({ top: document.body.scrollHeight, behavior: "auto" });
    }
  }, [isAddingNewGroup]);

  /** Function to handle saving the recurring training event */
  const handleSaveGroup = (event: RecurringTrainingEvent) => {
    props.onSave(event);
    setIsAddingNewGroup(false);
  };

  /** Function to handle removing a group from the player's events */
  const handleRemoveGroup = (event: RecurringTrainingEvent) => {
    setRecurringTrainingEventToRemove(null);
    props.onRemove(event);
  };

  /** Function to handle adding a new group */
  const handleAddNewGroup = () => {
    setIsAddingNewGroup(true);
  };

  /** Function to handle canceling editing or adding */
  const handleCancel = () => {
    setIsAddingNewGroup(false);
  };

  return (
    <Box position="relative" data-testid="recurring-training-events">
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />

      <Tooltip label="Training Groups can be edited from the 'Settings' page" placement="auto-start">
        <Text fontSize="xl" marginBottom="4">
          Training groups ({props.recurringTrainingEvents.length})
        </Text>
      </Tooltip>

      {props.isSeasonDatesMissing ? (
        <Text textColor="blackAlpha.600">
          No season dates available for training groups, you can set them from the "Settings" page
        </Text>
      ) : (
        <Stack direction="column" spacing="8">
          {props.recurringTrainingEvents.length > 0 ? (
            props.recurringTrainingEvents.map((event, index) => (
              <Box key={JSON.stringify(event)} data-testid={`recurring-training-event-${index}`}>
                <Stack direction="row" spacing="4">
                  <SingleTrainingGroup event={event} />
                  <CommonButton
                    variantType="outlineSecondary"
                    aria-label="remove"
                    hoverColor="red.600"
                    onClick={() => setRecurringTrainingEventToRemove(event)}
                    dataTestId={`remove-recurring-training-event-${index}`}
                    tooltip={`Remove ${props.playerName} from training group ${event.name}`}
                  >
                    <DeleteIcon color="red.600" />
                  </CommonButton>
                </Stack>
              </Box>
            ))
          ) : (
            <Text textColor="blackAlpha.600">{`${props.playerName} doesn't belong to any training group`}</Text>
          )}

          {isAddingNewGroup ? (
            <TrainingGroupAdder
              allTrainingEvents={props.allTrainingEvents}
              allCoaches={props.coaches}
              allCourts={props.courts}
              onSave={handleSaveGroup}
              onCancel={handleCancel}
              loading={props.isLoading}
            />
          ) : (
            <Box width="fit-content" alignSelf="end">
              <CommonButton
                variantType="solidPrimary"
                onClick={handleAddNewGroup}
                disabled={!globalSelectedClub || globalSelectedClub?.courts.length === 0}
                tooltip={
                  !globalSelectedClub || globalSelectedClub?.courts.length === 0
                    ? "Court must be created first for selected club"
                    : undefined
                }
                dataTestId="add-recurring-training-event"
              >
                {`Add ${props.playerName} to new group`}
              </CommonButton>
            </Box>
          )}
        </Stack>
      )}

      <ConfirmationDialog
        isOpen={!!recurringTrainingEventToRemove}
        message={`Please confirm that you want to remove "${props.playerName}" from the training group "${recurringTrainingEventToRemove?.name}". This cannot be undone. If you want to remove the whole training group, you can do it from the "Settings" page.`}
        onCancel={() => setRecurringTrainingEventToRemove(null)}
        onClose={() => setRecurringTrainingEventToRemove(null)}
        onConfirm={() =>
          recurringTrainingEventToRemove ? handleRemoveGroup(recurringTrainingEventToRemove) : undefined
        }
        title="Remove from training group"
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
      />
    </Box>
  );
};
