/** Base type for entities with an optional id and removable flag */
export type BaseEntity = {
  /** Unique identifier */
  id?: string;
  /** Indicates if the entity should be removed */
  remove?: boolean;
  /** Autogenerated by graphql */
  __typename?: string;
};

/** Base type for entities with name */
type NamedEntity = BaseEntity & {
  /** Name of the entity */
  name: string;
};

/** Represents a note with content and creation date */
export type Note = BaseEntity & {
  /** Content of the note */
  content: string;
  /** Date when the note was created */
  createdAt?: string;
  /** User who created the note */
  createdBy?: OrganizationUser;
  isGroupNote?: boolean;
  players?: Player[];
  singleTrainingEvent?: SingleTrainingEvent;
  recurringTrainingEventMetadata?: RecurringTrainingEventMetadata;
};

export type NoteInput = {
  id?: string;
  content: string;
  playerIds: string[];
  isGroupNote?: boolean;
  remove?: boolean;
};

export enum LogType {
  ParticipationWithdrawn = "ParticipationWithdrawn",
  JoinedReplacementEvent = "JoinedReplacementEvent",
  EventCancelled = "EventCancelled",
}

/** Represents a log with description and creation date */
export type Log = BaseEntity & {
  /** Description of the log */
  description?: string;
  /** Date when the log was created */
  createdAt: string;
  recurringTrainingEventMetadata?: RecurringTrainingEventMetadata;
  player: Player;
  type: LogType;
};

export type SkillEvaluation = BaseEntity & {
  skill: Skill;
  rating: number;
  comment?: string;
};

export type SkillSetEvaluation = BaseEntity & {
  skillSet: SkillSet;
  skillEvaluations: SkillEvaluation[];
  rating?: number;
  comment?: string;
};

export type Evaluation = BaseEntity & {
  skillSetEvaluations: SkillSetEvaluation[];
};

/** Represents a player with associated training events and a progress framework */
export type Player = NamedEntity & {
  /** List of recurring training events associated with the player */
  recurringTrainingEvents?: RecurringTrainingEvent[];
  notes?: Note[];
  logs?: Log[];
  /** List of Season Plans associated with the player (atm used only with RecurringTrainingEventMetadata) */
  seasonPlans?: SeasonPlan[];
  evaluation?: Evaluation;
};

/** Represents a drill with specific details */
export type Drill = NamedEntity & {
  /** Goal of the drill */
  goal: string;
  /** How the drill is to be implemented */
  implementation: string;
  /** Tags associated with the drill */
  tags?: string[];
  /** Coaches associated with the drill */
  coaches?: Coach[];
  /** Date when the drill was created */
  createdAt?: string;
  /** Date when the drill was last updated */
  updatedAt?: string;
  /** User who created the drill */
  createdBy?: OrganizationUser;
  /** Skill Sets associated with the drill */
  skillSets?: SkillSet[];
};

/** Extended Drill entity, which is part of Session */
type SessionDrill = {
  /** ID of the SessionDrill */
  id: string;
  /** Related Drill */
  drill: Drill;
  /** Duration of the Drill (in minutes) on Session */
  duration: number;
};

/** Represents a training session including drills and total duration */
export type Session = NamedEntity & {
  /** The goal of the session */
  goal: string;
  /** The tags associated with the session */
  tags?: string[];
  /** The drills included in the session (with durations) */
  sessionDrills: SessionDrill[];
  /** The total duration of the session */
  totalDuration: number;
  /** Date when the drill was created */
  createdAt?: string;
  /** Date when the drill was last updated */
  updatedAt?: string;
  /** User who created the drill */
  createdBy?: OrganizationUser;
  /** Skill Sets associated with the session */
  skillSets?: SkillSet[];
};

/** Represents a coach with a unique identifier and name */
export type Coach = NamedEntity;

/** Represents a court with a unique identifier and name */
export type Court = NamedEntity & {
  /** The club where the court is located */
  club?: Club;
};

/** Represents the base properties for training events */
type BaseTrainingEvent = BaseEntity & {
  /** The court where the training event takes place */
  court: Court;
  /** Array of coaches associated with the training event */
  coaches?: Coach[];
  /** Array of players participating in the training event */
  players?: Player[];
};

/** Represents the base properties for metadata of training events */
type BaseTrainingEventMetadata = BaseTrainingEvent & {
  /** Start time of the training event, UTC, e.g. "2023-09-05T11:30:00.000Z" */
  startDateTime: string;
  /** End time of the training event, UTC, e.g. "2023-09-05T13:00:00.000Z" */
  endDateTime: string;
  /** Array of notes associated with the training event */
  notes?: Note[];
  /** Array of drills associated with the training event */
  drills?: Drill[];
  /** The session associated with the training event */
  session?: Session;
};

/** Represents a recurring training event in the database */
export type RecurringTrainingEvent = BaseTrainingEvent & {
  /** The weekday when the training event occurs, represented as a number (1 for Monday, 2 for Tuesday, etc.) */
  recurrenceWeekday: number;
  /** The start time of the training event, formatted as "HH:mm", e.g. "09:00" */
  recurrenceStartTime: string;
  /** The end time of the training event, formatted as "HH:mm", e.g. "11:30" */
  recurrenceEndTime: string;
  /** The name of the training event */
  name: string;
  /** The season plans associated with the training event */
  seasonPlans?: SeasonPlan[];
};

/** Represents the metadata for a recurring training event */
export type RecurringTrainingEventMetadata = BaseTrainingEventMetadata & {
  /** The details of the recurring training event */
  recurringTrainingEvent: RecurringTrainingEvent;
};

/** Represents a single training event in the database */
export type SingleTrainingEvent = BaseTrainingEventMetadata;

/** Represents an organization with a unique identifier, name, and users */
export type Organization = NamedEntity & {
  /** Array of users associated with the organization */
  users: OrganizationUser[];
};

/** Represents a user within an organization */
export type OrganizationUser = BaseEntity & {
  /** Username of the user */
  username: string;
};

/** Represents a club with a unique identifier, name, and courts */
export type Club = NamedEntity & {
  /** Array of courts associated with the club */
  courts: Court[];
};

export type MissingMetadata = BaseEntity & {
  recurringTrainingEventId: string;
  startDateTime: string;
  players: Player[];
  court: Court;
  coaches: Coach[];
};

export type Skill = NamedEntity;

export type SkillSet = NamedEntity & {
  skills: Skill[];
};

type SeasonPlanSkillSet = BaseEntity & {
  skillSet: SkillSet;
  skills: Skill[];
};

export type SeasonPlan = BaseEntity & {
  startDateTime: string;
  endDateTime: string;
  skillSets: SeasonPlanSkillSet[];
  recurringTrainingEvents?: RecurringTrainingEvent[];
  players?: Player[];
  isOrganizationWide?: boolean;
};

export type EvaluationCriteria = BaseEntity & {
  fromRatingLevel: number;
  toRatingLevel: number;
  definition: string;
  requirements: string;
};
