import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Box, useBreakpoint } from "@chakra-ui/react";
import {
  Header,
  CalendarContainer,
  TrainingLibraryContainer,
  PlayerContainer,
  SettingsContainer,
  Login,
  AdminContainer,
  SkillSetsContainer,
  SeasonPlanContainer,
} from "./features";
import { MobileHeader } from "./features/Header/MobileHeader";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const currentBreakPoint = useBreakpoint({ ssr: false });

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwt_decode(token) as {
        userId: string;
        role?: string;
      };
      if (decodedToken.userId) {
        setIsAuthenticated(true);
      }
      if (decodedToken.role === "admin") {
        setIsAdmin(true);
      }
    }
    setIsCheckingToken(false);
  }, []);

  return (
    <Router>
      <Box>
        {isCheckingToken || !isAuthenticated ? undefined : currentBreakPoint === "mobile" ? (
          <MobileHeader isAdmin={isAdmin} />
        ) : (
          <Header isAdmin={isAdmin} />
        )}
        <Box
          as="main"
          position="relative"
          top="56px"
          width="100%"
          minHeight="calc(100svh - 56px)"
          paddingTop={{ mobile: "6", laptop: "8" }}
          paddingX={{ mobile: "4", laptop: "16" }}
          paddingBottom={{ mobile: "6", laptop: "12" }}
          bgColor="white"
          overflow="hidden"
          data-testid="mainBox"
        >
          {!isCheckingToken && !isAuthenticated ? (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<CalendarContainer />} />
              <Route path="/season-plan" element={<SeasonPlanContainer />} />
              <Route path="/players" element={<PlayerContainer />} />
              <Route path="/skill-sets" element={<SkillSetsContainer />} />
              <Route path="/training-library" element={<TrainingLibraryContainer />} />
              <Route path="/settings" element={<SettingsContainer />} />
              {isAdmin && <Route path="/admin" element={<AdminContainer />} />}
            </Routes>
          )}
        </Box>
      </Box>
    </Router>
  );
};

export default App;
