import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { CommonDayPicker } from "../../../../../../common/components/CommonDayPicker";
import { toUTCString } from "../../../../../../common/utils";

interface StepDatesProps {
  startDate: string | undefined;
  endDate: string | undefined;
  setStartDate: (date: string | undefined) => void;
  setEndDate: (date: string | undefined) => void;
}

export const StepDates: React.FC<StepDatesProps> = ({ startDate, endDate, setStartDate, setEndDate }) => {
  /**
   * Converts a UTC date to a "local" date while preserving year, month, and day. Sets the hours and minutes from the
   * original UTC string without timezone adjustments.
   *
   * @param utcDateString - The UTC date string to convert
   * @returns A new Date object with the adjusted local time
   */
  const convertToLocalDateTime = (utcDateString: string): Date => {
    const utcDate = new Date(utcDateString);

    // Create a new date preserving the year, month, and day
    const localDate = new Date(Date.UTC(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate()));

    // Parse and set hours and minutes from the original UTC date
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    localDate.setHours(hours, minutes);

    return localDate;
  };

  return (
    <Grid templateColumns="(1fr 1fr)" columnGap="4" rowGap="8" paddingX="6">
      <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
        <CommonDayPicker
          initialDate={startDate ? convertToLocalDateTime(startDate) : undefined}
          onDateChange={(newDate) => setStartDate(newDate ? toUTCString(newDate) : undefined)}
          label="Start Date"
        />
      </GridItem>
      <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
        <CommonDayPicker
          initialDate={endDate ? convertToLocalDateTime(endDate) : undefined}
          onDateChange={(newDate) => setEndDate(newDate ? toUTCString(newDate) : undefined)}
          label="End Date"
        />
      </GridItem>
    </Grid>
  );
};
