import React, { useEffect, useState } from "react";
import { Coaches } from "../components/Coaches/Coaches";
import { Centers } from "../components/Centers/Centers";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { TrainingSeasons } from "../components/TrainingSeasons/TrainingSeasons";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_TRAINING_SEASONS } from "../graphql/get-all-training-seasons.query";
import { GET_MISSING_TRAINING_GROUP_EVENTS } from "../graphql/get-missing-training-group-events.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { SAVE_COACH } from "../graphql/save-coach.mutation";
import { SAVE_CENTER } from "../graphql/save-center.mutation";
import { SAVE_VENUE } from "../graphql/save-venue.mutation";
import { SAVE_TRAINING_SEASON } from "../graphql/save-training-season.mutation";
import { CREATE_MISSING_TRAINING_GROUP_EVENTS } from "../graphql/create-missing-training-group-events.mutation";
import { GET_CENTER } from "../graphql/get-center.query";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { CommonScrollbar } from "../../../common/components/CommonScrollbar";
import {
  DeletionInfoEntityType,
  type DeletionInfoInput,
  type OrganizationInput,
  type TrainingSeasonStatus,
} from "../../../types";
import { SAVE_TRAINING_GROUP } from "../../../common/graphql/save-training-group.mutation";
import { GET_ALL_TRAINING_GROUPS } from "../../../common/graphql/get-all-training-groups.query";
import { TrainingGroups } from "../components/TrainingGroups/TrainingGroups";
import type { TrainingGroupInput } from "../../Athletes/containers/AthleteContainer";
import { GET_ALL_TRAINING_GROUP_EVENTS } from "../../../common/graphql/get-all-training-group-events.query";
import { GET_ENTITIES_TO_REMOVE } from "../../../common/graphql/get-entities-to-remove.query";
import { GET_ALL_ATHLETES_FOR_MANAGEMENT } from "../graphql/get-all-athletes-for-management.query";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import { Organization } from "../components/Organization/Organization";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";
import { UPDATE_USER_ORGANIZATION_SETTINGS } from "../graphql/update-user-organization-settings.mutation";

type TrainingGroupCopyInput = {
  id: string;
  name: string;
};

export type TrainingSeasonInput = {
  id?: string;
  name: string;
  seasonStart: string;
  seasonEnd: string;
  status: TrainingSeasonStatus;
  trainingGroupsToCopy?: TrainingGroupCopyInput[];
  remove?: boolean;
};

export type CreateMissingTrainingGroupEventInput = {
  trainingSeasonId: string;
  trainingGroupId: string;
  startDateTime: string;
};

export type CoachInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type VenueInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type CenterInput = {
  id?: string;
  venues?: VenueInput[];
  name: string;
  remove?: boolean;
};

// TODO: change training event participation count (default 4/calendar)
// TODO: change evaluation scale (default 1-10)

export const ManagementContainer: React.FC = () => {
  // Queries
  const {
    data: allTrainingSeasonsData,
    loading: allTrainingSeasonsLoading,
    error: allTrainingSeasonsError,
  } = useQuery(GET_ALL_TRAINING_SEASONS);
  const [
    getMissingMTrainingGroupEvents,
    {
      data: missingTrainingGroupEventsData,
      loading: missingTrainingGroupEventsLoading,
      error: missingTrainingGroupEventsError,
    },
  ] = useLazyQuery(GET_MISSING_TRAINING_GROUP_EVENTS, { fetchPolicy: "network-only" });
  const { data: coachData, loading: coachLoading, error: coachError } = useQuery(GET_ALL_COACHES);
  const { data: centerData, loading: centerLoading, error: centerError } = useQuery(GET_ALL_CENTERS);
  const {
    data: trainingGroupsData,
    loading: trainingGroupsLoading,
    error: trainingGroupsError,
  } = useQuery(GET_ALL_TRAINING_GROUPS);
  const { data: athleteData, loading: athleteLoading, error: athleteError } = useQuery(GET_ALL_ATHLETES_FOR_MANAGEMENT);
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_USER_ORGANIZATION);
  const [
    getEntitiesToBeRemovedWithTrainingSeason,
    { data: entitiesToBeRemovedData, loading: entitiesToBeRemovedLoading, error: entitiesToBeRemovedError },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    // changes with saveTrainingGroupMutation doesn't update cache, and for now
    // it's too much hassle with training season ids to do refetchQuery for this
    fetchPolicy: "network-only",
  });
  const [
    getEntitiesToBeRemovedWithTrainingGroup,
    {
      data: entitiesToBeRemovedWithTrainingGroupData,
      loading: entitiesToBeRemovedWithTrainingGroupLoading,
      error: entitiesToBeRemovedWithTrainingGroupError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });
  const [
    getEntitiesToBeRemovedWithVenue,
    {
      data: entitiesToBeRemovedWithVenueData,
      loading: entitiesToBeRemovedWithVenueLoading,
      error: entitiesToBeRemovedWithVenueError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });
  const [
    getEntitiesToBeRemovedWithCenter,
    {
      data: entitiesToBeRemovedWithCenterData,
      loading: entitiesToBeRemovedWithCenterLoading,
      error: entitiesToBeRemovedWithCenterError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });

  // Mutations
  const { execute: saveCoachMutation, loading: saveCoachLoading } = useAsyncMutation(SAVE_COACH, {
    refetchQueries: [{ query: GET_ALL_COACHES }],
    awaitRefetchQueries: true,
  });
  const { execute: saveCenterMutation, loading: saveCenterLoading } = useAsyncMutation(SAVE_CENTER, {
    refetchQueries: [
      { query: GET_ALL_CENTERS },
      { query: GET_ALL_TRAINING_GROUPS },
      { query: GET_ALL_TRAINING_GROUP_EVENTS },
    ],
    awaitRefetchQueries: true,
  });
  const { execute: saveVenueMutation, loading: saveVenueLoading } = useAsyncMutation(SAVE_VENUE, {
    refetchQueries: [{ query: GET_ALL_TRAINING_GROUPS }, { query: GET_ALL_TRAINING_GROUP_EVENTS }],
  });
  const { execute: saveTrainingSeasonMutation, loading: saveTrainingSeasonLoading } = useAsyncMutation(
    SAVE_TRAINING_SEASON,
    {
      refetchQueries: [
        { query: GET_ALL_TRAINING_SEASONS },
        { query: GET_ALL_TRAINING_GROUPS },
        { query: GET_ALL_ATHLETES_FOR_MANAGEMENT },
        { query: GET_ALL_TRAINING_GROUP_EVENTS },
      ],
      awaitRefetchQueries: true,
    },
  );
  const { execute: createMissingTrainingGroupEventsMutation, loading: createTrainingGroupEventsLoading } =
    useAsyncMutation(CREATE_MISSING_TRAINING_GROUP_EVENTS, {
      refetchQueries: [
        { query: GET_ALL_TRAINING_GROUP_EVENTS }, // to trigger calendar events
      ],
      awaitRefetchQueries: true,
    });
  const [getCenter, { error: getCenterError, loading: getCenterLoading }] = useLazyQuery(GET_CENTER, {
    fetchPolicy: "network-only",
  });
  const { execute: saveTrainingGroupMutation, loading: saveTrainingGroupLoading } = useAsyncMutation(
    SAVE_TRAINING_GROUP,
    {
      refetchQueries: [
        { query: GET_ALL_TRAINING_GROUPS },
        { query: GET_ALL_ATHLETES_FOR_MANAGEMENT },
        { query: GET_ALL_TRAINING_GROUP_EVENTS },
      ],
      awaitRefetchQueries: true,
    },
  );
  const { execute: updateOrganizationSettingsMutation, loading: updateOrganizationLoading } = useAsyncMutation(
    UPDATE_USER_ORGANIZATION_SETTINGS,
    {
      refetchQueries: [{ query: GET_USER_ORGANIZATION }],
      awaitRefetchQueries: true,
    },
  );

  const { globalSetSelectedCenter } = useGlobalContext();
  const isLoading =
    allTrainingSeasonsLoading ||
    coachLoading ||
    centerLoading ||
    athleteLoading ||
    trainingGroupsLoading ||
    organizationLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    allTrainingSeasonsError,
    missingTrainingGroupEventsError,
    coachError,
    centerError,
    getCenterError,
    athleteError,
    trainingGroupsError,
    entitiesToBeRemovedError,
    entitiesToBeRemovedWithTrainingGroupError,
    entitiesToBeRemovedWithVenueError,
    entitiesToBeRemovedWithCenterError,
    organizationError,
  ]);

  const saveCoach = async (coachInput: CoachInput): Promise<void> => {
    return await saveCoachMutation({ data: coachInput });
  };

  const saveCenter = async (centerInput: CenterInput): Promise<void> => {
    const result = await saveCenterMutation({ data: centerInput });
    if (result) {
      if (result.data?.saveCenter?.id) {
        // center is created or updated
        const { data: centerData } = await getCenter({ variables: { id: result.data?.saveCenter?.id } });
        if (centerData) {
          globalSetSelectedCenter(centerData.getCenter);
        }
      } else {
        // center is removed
        globalSetSelectedCenter(null);
      }
    }

    return result;
  };

  const saveVenue = async (venueInput: VenueInput, centerId: string): Promise<void> => {
    const result = await saveVenueMutation({ data: venueInput, centerId });
    if (result) {
      const { data: centerData } = await getCenter({ variables: { id: centerId } });
      if (centerData) {
        globalSetSelectedCenter(centerData.getCenter);
      }
    }

    return result;
  };

  const saveTrainingSeason = async (trainingSeasonInput: TrainingSeasonInput): Promise<void> => {
    return await saveTrainingSeasonMutation({ data: trainingSeasonInput });
  };

  const createMissingTrainingGroupEvents = async (input: CreateMissingTrainingGroupEventInput[]): Promise<void> => {
    await createMissingTrainingGroupEventsMutation({
      data: input,
    });

    await getMissingMTrainingGroupEvents({ variables: { id: input[0].trainingSeasonId } });
  };

  const saveTrainingGroup = async (trainingGroupInput: TrainingGroupInput): Promise<void> => {
    return await saveTrainingGroupMutation({ data: trainingGroupInput });
  };

  const getTrainingSeasonRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.TrainingSeason,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithTrainingSeason({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const getTrainingGroupRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.TrainingGroup,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithTrainingGroup({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const getMissingTrainingGroupEvents = async (trainingSeasonId: string) => {
    await getMissingMTrainingGroupEvents({ variables: { id: trainingSeasonId } });
  };

  const getVenueRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.Venue,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithVenue({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const getCenterRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.Center,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithCenter({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const updateOrganizationSettings = async (organizationInput: OrganizationInput) => {
    return await updateOrganizationSettingsMutation({ data: organizationInput });
  };

  return (
    <Box>
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Grid
          animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}
          templateColumns="repeat(3, 1fr)"
          templateRows="max-content"
          gap="8"
        >
          <GridItem colSpan={3} height="min-content">
            <ComponentContainer handlePaddingManually={true}>
              <TrainingSeasons
                allTrainingGroups={trainingGroupsData?.getAllTrainingGroups || []}
                trainingSeasons={allTrainingSeasonsData?.getAllTrainingSeasons || []}
                missingTrainingGroupEvents={missingTrainingGroupEventsData?.getMissingTrainingGroupEvents || []}
                updateTrainingSeasonLoading={saveTrainingSeasonLoading}
                createTrainingGroupEventsLoading={createTrainingGroupEventsLoading}
                saveTrainingSeason={saveTrainingSeason}
                createMissingTrainingGroupEvents={createMissingTrainingGroupEvents}
                getEntitiesToBeRemoved={getTrainingSeasonRelatedEntities}
                entitiesToBeRemoved={entitiesToBeRemovedData?.getEntitiesToRemove || []}
                entitiesToBeRemovedLoading={entitiesToBeRemovedLoading}
                onGetMissingTrainingGroupEvents={getMissingTrainingGroupEvents}
                missingTrainingGroupEventsLoading={missingTrainingGroupEventsLoading}
              />
            </ComponentContainer>
          </GridItem>

          <GridItem colSpan={1}>
            <ComponentContainer handlePaddingManually>
              <Coaches
                allCoaches={coachData?.getAllCoaches || []}
                saveCoach={saveCoach}
                saveCoachLoading={saveCoachLoading}
              />
            </ComponentContainer>
          </GridItem>

          <GridItem colSpan={2}>
            <Centers
              allCenters={centerData?.getAllCenters || []}
              saveCenter={saveCenter}
              saveCenterLoading={saveCenterLoading}
              saveVenue={saveVenue}
              saveVenueLoading={saveVenueLoading || getCenterLoading}
              entitiesToBeRemovedWithVenue={entitiesToBeRemovedWithVenueData?.getEntitiesToRemove || []}
              entitiesToBeRemovedWithVenueLoading={entitiesToBeRemovedWithVenueLoading}
              getEntitiesToBeRemovedWithVenue={getVenueRelatedEntities}
              entitiesToBeRemovedWithCenter={entitiesToBeRemovedWithCenterData?.getEntitiesToRemove || []}
              entitiesToBeRemovedWithCenterLoading={entitiesToBeRemovedWithCenterLoading}
              getEntitiesToBeRemovedWithCenter={getCenterRelatedEntities}
            />
          </GridItem>

          <GridItem colSpan={3}>
            <ComponentContainer handlePaddingManually={true}>
              <TrainingGroups
                coaches={coachData?.getAllCoaches || []}
                centers={centerData?.getAllCenters || []}
                events={trainingGroupsData?.getAllTrainingGroups || []}
                trainingSeasons={allTrainingSeasonsData?.getAllTrainingSeasons || []}
                athletes={athleteData?.getAllAthletes || []}
                onSave={saveTrainingGroup}
                isLoading={saveTrainingGroupLoading}
                getEntitiesToBeRemoved={getTrainingGroupRelatedEntities}
                entitiesToBeRemoved={entitiesToBeRemovedWithTrainingGroupData?.getEntitiesToRemove || []}
                entitiesToBeRemovedLoading={entitiesToBeRemovedWithTrainingGroupLoading}
              />
            </ComponentContainer>
          </GridItem>

          <GridItem colSpan={3}>
            <ComponentContainer>
              <Organization
                organization={organizationData?.getUserOrganization || null}
                updateUserOrganizationSettings={updateOrganizationSettings}
                updateOrganizationLoading={updateOrganizationLoading}
              />
            </ComponentContainer>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};
