import React from "react";
import { MultiTagSelectMenu } from "../../../../../../common/components/MultiTagSelectMenu";
import type { Athlete } from "../../../../../../types";

type AthleteInputProps = {
  /** Callback function for athlete selection */
  handleAthleteSelection: (value: Athlete[]) => void;
  /** Array of athlete names */
  athletes: Athlete[];
  /** Array of the selected athletes */
  selectedAthletes: Athlete[];
};

/** AthleteInput component allows the user to search for existing athletes by name, or create a new one from the spot. */
export const AthleteInput: React.FC<AthleteInputProps> = (props) => {
  return (
    <MultiTagSelectMenu
      options={props.athletes}
      selectedTags={props.selectedAthletes}
      handleTagSelection={props.handleAthleteSelection}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      label="Athletes"
      disableActive={true}
    />
  );
};
