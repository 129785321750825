import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useAlert } from "../../../common/components/AlertProvider";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import { Box, useBreakpoint } from "@chakra-ui/react";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Calendar } from "../components/Calendar";
import { GET_ALL_RECURRING_TRAINING_EVENT_METADATAS } from "../graphql/get-all-recurring-training-event-metadatas.query";
import { GET_ALL_DRILLS } from "../../../common/graphql/get-all-drills.query";
import { GET_ALL_SESSIONS } from "../../../common/graphql/get-all-sessions.query";
import { GET_ALL_PLAYERS_FOR_CALENDAR } from "../graphql/get-all-players-for-calendar.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { GET_ALL_SINGLE_TRAINING_EVENTS } from "../graphql/get-all-single-training-events.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { SAVE_SINGLE_TRAINING_EVENT } from "../graphql/save-single-training-event.mutation";
import { UPDATE_RECURRING_TRAINING_EVENT_METADATA } from "../graphql/update-recurring-training-event-metadata.mutation";
import { GET_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "../../../common/graphql/get-missing-recurring-trainingevent-metadatas.query";
import { handleError } from "../../../common/utils";

export const CalendarContainer: React.FC = () => {
  const { globalSelectedClub } = useGlobalContext();
  const currentBreakPoint = useBreakpoint({ ssr: false });
  const { showAlert } = useAlert();

  // GraphQL queries
  const {
    data: recurringTrainingEventMetadata,
    loading: recurringTrainingEventMetadataLoading,
    error: recurringTrainingEventMetadataError,
  } = useQuery(GET_ALL_RECURRING_TRAINING_EVENT_METADATAS);
  const { data: drillData, loading: drillLoading, error: drillError } = useQuery(GET_ALL_DRILLS);
  const { data: sessionData, loading: sessionLoading, error: sessionError } = useQuery(GET_ALL_SESSIONS);
  const { data: playerData, loading: playerLoading, error: playerError } = useQuery(GET_ALL_PLAYERS_FOR_CALENDAR);
  const { data: coachData, loading: coachLoading, error: coachError } = useQuery(GET_ALL_COACHES);
  const {
    data: singleTrainingEventData,
    loading: singleTrainingEventLoading,
    error: singleTrainingEventError,
  } = useQuery(GET_ALL_SINGLE_TRAINING_EVENTS);
  const { data: skillSetsData, loading: skillSetsLoading, error: skillSetsError } = useQuery(GET_ALL_SKILL_SETS);

  // GraphQL mutations
  const [saveSingleTrainingEvent] = useMutation(SAVE_SINGLE_TRAINING_EVENT, {
    refetchQueries: [
      { query: GET_ALL_SINGLE_TRAINING_EVENTS },
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS },
      { query: GET_ALL_PLAYERS_FOR_CALENDAR },
    ],
    awaitRefetchQueries: true,
  });
  const [updateRecurringTrainingEventMetadata] = useMutation(UPDATE_RECURRING_TRAINING_EVENT_METADATA, {
    refetchQueries: [
      { query: GET_ALL_SINGLE_TRAINING_EVENTS },
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS },
      { query: GET_ALL_PLAYERS_FOR_CALENDAR },
      { query: GET_MISSING_RECURRING_TRAININGEVENT_METADATAS },
    ],
    awaitRefetchQueries: true,
  });

  const isLoading =
    recurringTrainingEventMetadataLoading ||
    drillLoading ||
    sessionLoading ||
    playerLoading ||
    coachLoading ||
    singleTrainingEventLoading ||
    skillSetsLoading;
  const [showContent, setShowContent] = useState(!isLoading);
  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      drillError ||
      sessionError ||
      playerError ||
      coachError ||
      singleTrainingEventError ||
      recurringTrainingEventMetadataError ||
      skillSetsError
    ) {
      const errors = [
        drillError,
        sessionError,
        playerError,
        coachError,
        singleTrainingEventError,
        recurringTrainingEventMetadataError,
        skillSetsError,
      ].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
  }, [
    drillError,
    sessionError,
    playerError,
    coachError,
    singleTrainingEventError,
    recurringTrainingEventMetadataError,
    skillSetsError,
    showAlert,
  ]);

  return (
    <Box id="calendar">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}>
          <Calendar
            globalSelectedClub={globalSelectedClub}
            currentBreakPoint={currentBreakPoint}
            showAlert={showAlert}
            recurringTrainingEventMetadata={recurringTrainingEventMetadata}
            drillData={drillData}
            sessionData={sessionData}
            playerData={playerData}
            coachData={coachData}
            singleTrainingEventData={singleTrainingEventData}
            skillSetsData={skillSetsData}
            saveSingleTrainingEvent={saveSingleTrainingEvent}
            updateRecurringTrainingEventMetadata={updateRecurringTrainingEventMetadata}
          />
        </Box>
      )}
    </Box>
  );
};
