import React from "react";
import { Text, Stack, Grid, GridItem, Tooltip } from "@chakra-ui/react";
import { CommonCheckbox } from "../../../../../../../common/components/CommonCheckbox";
import { cardStyles, commonStyles } from "../../../../../../../common/utils/styles";

/** Represents the common properties shared by all nodes in the tree. */
type BaseNode = {
  id: string;
  label: string;
  checked: boolean;
  level: number;
};

/** Represents a single athlete node in the tree. */
type AthleteNode = BaseNode & {
  parent: GroupNode;
};

/** Represents a group node which contains athlete nodes. */
export type GroupNode = BaseNode & {
  parent?: RootNode;
  children: AthleteNode[];
};

/** Represents the root node, which contains group nodes. */
export type RootNode = BaseNode & {
  children: GroupNode[];
};

/** Represents any node in the tree, which could be a RootNode, GroupNode, or AthleteNode. */
export type TreeNodeData = RootNode | GroupNode | AthleteNode;

/** Props for the TreeNode component, which includes the node to render and a function to handle check state changes. */
export type TreeNodeProps = {
  node: TreeNodeData;
  onCheck: (node: TreeNodeData, checked: boolean) => void;
};

/** TreeNode component renders nodes in a hierarchical structure, allowing for any nodes to be toggled. */
export const TreeNode: React.FC<TreeNodeProps> = ({ node, onCheck }) => {
  /**
   * Toggles the checked state of the given node.
   *
   * @param node - The node whose checked state needs to be toggled.
   * @returns The new checked state of the node.
   */
  const toggleCheckedState = (node: TreeNodeData) => !node.checked;

  /**
   * Handles the check toggle action and updates the parent with the new checked state.
   *
   * @param event - The click event triggering the toggle.
   * @param groupOrAthleteNode - Optional specific node (group or athlete) to toggle; defaults to the root node.
   */
  const handleCheckToggle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    groupOrAthleteNode?: TreeNodeData,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    const targetNode = groupOrAthleteNode || node;
    const newCheckedState = toggleCheckedState(targetNode);

    onCheck(targetNode, newCheckedState);
  };

  /**
   * Renders a single node with appropriate styles and click handlers.
   *
   * @param currentNode - The node to render.
   * @returns A JSX element rendering the node.
   */
  const renderNode = (currentNode: TreeNodeData, siblingCount: number) => (
    <Stack
      key={currentNode.id}
      direction="row"
      spacing="2"
      onClick={(event) => handleCheckToggle(event, currentNode)}
      width="100%"
      justifyContent="center"
      padding="2"
      cursor="pointer"
      {...commonStyles(currentNode.checked)}
    >
      <CommonCheckbox checked={currentNode.checked} large={currentNode.level === 0} />
      <Tooltip label={currentNode.label} aria-label={currentNode.label} placement="top">
        <Text
          fontSize={currentNode.level === 0 ? "large" : "medium"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {currentNode.label}
        </Text>
      </Tooltip>
    </Stack>
  );

  /**
   * Renders the children nodes recursively.
   *
   * @param currentNode - The parent node containing children.
   * @returns A JSX element rendering the children.
   */
  const renderChildren = (currentNode: TreeNodeData) => {
    if ("children" in currentNode && currentNode.children.length > 0) {
      const isRootLevel = currentNode.level === 0;
      const columns = currentNode.children.length > 4 ? 4 : currentNode.children.length;

      return (
        <Grid
          templateColumns={`repeat(${isRootLevel ? "2" : columns}, minmax(0, 1fr))`}
          width="100%"
          gap={isRootLevel ? "12" : "2"}
          marginTop={!isRootLevel ? "4" : undefined}
        >
          {currentNode.children.map((childNode) => (
            <GridItem
              key={childNode.id}
              colSpan={1}
              {...(currentNode.level === 0 && {
                padding: "4",
                ...cardStyles({ selected: childNode.checked, disableClick: true }),
              })}
            >
              {renderNode(childNode, isRootLevel ? 0 : currentNode.children.length)}
              {renderChildren(childNode)}
            </GridItem>
          ))}
        </Grid>
      );
    }
    return null;
  };

  if (node.level === 0 && "children" in node && node.children.length > 0) {
    return (
      <Stack
        direction="column"
        align="center"
        spacing="8"
        overflow="auto"
        paddingX="2"
        paddingBottom="6"
        id="root-level"
      >
        {renderNode(node, 0)}
        {renderChildren(node)}
      </Stack>
    );
  }

  return null;
};
