import React, { useEffect, useState } from "react";
import { FormModal } from "../../../../../common/components/FormModal";
import { type GroupNode, type RootNode, type TreeNodeData } from "./components/components/TreeNode";
import {
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
  useSteps,
  StepIcon,
} from "@chakra-ui/react";
import type { TrainingGroup, SeasonPlan, SkillSet } from "../../../../../types";
import type { SeasonPlanInput } from "../../SeasonPlans";
import type { SkillSetDisplayCardProps } from "./components/components/components/SkillSetDisplayCard";
import type { FullCalendarSeasonPlanEvent } from "../../../types";
import { StepDates } from "./components/StepDates";
import { StepTrainingGroups } from "./components/StepTrainingGroups";
import { StepSkillSets } from "./components/StepSkillSets";
import { StepReview } from "./components/StepReview";

/** SeasonPlanModal properties */
export type SeasonPlanModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (seasonPlanData: SeasonPlanInput) => void;
  allSkillSets: SkillSet[];
  allTrainingGroups: TrainingGroup[];
  fullCalendarEvent?: FullCalendarSeasonPlanEvent | null;
  initialStartDate?: SeasonPlan["startDateTime"];
  initialEndDate?: SeasonPlan["endDateTime"];
};

export const SeasonPlanModal: React.FC<SeasonPlanModalProps> = (props) => {
  const existingSeasonPlanSkillSets = props.fullCalendarEvent?.seasonPlan.skillSets || [];
  const initiallySelected = existingSeasonPlanSkillSets.map((seasonPlanSkillSet) => ({
    ...seasonPlanSkillSet,
    skillSet: { ...seasonPlanSkillSet.skillSet, selected: true },
    skills: seasonPlanSkillSet.skills.map((skill) => ({ ...skill, selected: true })),
  }));
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: 4 });
  const [startDate, setStartDate] = useState(
    props.fullCalendarEvent?.seasonPlan.startDateTime
      ? props.fullCalendarEvent.seasonPlan.startDateTime
      : props.initialStartDate,
  );
  const [endDate, setEndDate] = useState(
    props.fullCalendarEvent?.seasonPlan.endDateTime
      ? props.fullCalendarEvent.seasonPlan.endDateTime
      : props.initialEndDate,
  );
  const [allSkillSets, setAllSkillSets] = useState<SkillSetDisplayCardProps["skillSet"][]>(
    props.allSkillSets.map((skillSet) => {
      const initiallySelectedSkillSet = initiallySelected.find(
        (selectedSet) => selectedSet.skillSet.id === skillSet.id,
      );
      return {
        ...skillSet,
        selected: !!initiallySelectedSkillSet,
        skills: skillSet.skills.map((skill) => {
          const initiallySelectedSkill = initiallySelectedSkillSet?.skills.find(
            (selectedSkill) => selectedSkill.id === skill.id,
          );
          return {
            ...skill,
            selected: !!initiallySelectedSkill,
          };
        }),
      };
    }),
  );
  const [treeData, setTreeData] = useState(prepareTreeData(props.allTrainingGroups));
  const [submitButtonText, setSubmitButtonText] = useState("Next");

  useEffect(() => {
    if (activeStep < 3) {
      setSubmitButtonText("Next");
    } else {
      setSubmitButtonText("Save");
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  /**
   * Prepares the tree data structure for displaying training groups and athletes.
   *
   * @param allTrainingGroups - Array of training groups from props
   * @returns The root node of the tree data structure
   */
  function prepareTreeData(allTrainingGroups: TrainingGroup[]): TreeNodeData {
    const rootNode: RootNode = {
      id: "organization",
      label: "Everyone",
      checked: props.fullCalendarEvent?.seasonPlan.isOrganizationWide || false,
      level: 0,
      children: [],
    };

    rootNode.children = allTrainingGroups.map((trainingGroup) => {
      const groupNode: GroupNode = {
        id: trainingGroup.id || "",
        label: trainingGroup.name || "Unnamed (rename from 'Athletes' page)",
        parent: rootNode,
        level: 1,
        checked:
          props.fullCalendarEvent?.seasonPlan.isOrganizationWide ||
          props.fullCalendarEvent?.seasonPlan.trainingGroups?.map((event) => event.id).includes(trainingGroup.id) ||
          false,
        children:
          trainingGroup.athletes?.map((athlete) => ({
            id: athlete.id || "",
            label: athlete.name,
            parent: {} as GroupNode,
            level: 2,
            checked:
              props.fullCalendarEvent?.seasonPlan.isOrganizationWide ||
              props.fullCalendarEvent?.seasonPlan.trainingGroups?.map((event) => event.id).includes(trainingGroup.id) ||
              props.fullCalendarEvent?.seasonPlan.athletes?.map((p) => p.id).includes(athlete.id) ||
              false,
          })) || [],
      };

      groupNode.children.forEach((athlete) => {
        athlete.parent = groupNode;
      });

      return groupNode;
    });

    return rootNode;
  }

  const handleSave = (remove: boolean) => {
    const selectedSkillSetIds = allSkillSets.filter((set) => set.selected).map((set) => set.id || "");
    const selectedSkillIds = allSkillSets.flatMap(
      (set) => set.skills?.filter((skill) => skill.selected).map((skill) => skill.id || "") || [],
    );

    // Collect the selected trainingGroupIds
    const selectedTrainingGroupIds = (treeData as RootNode).children
      .filter((group) => group.checked)
      .map((group) => group.id);

    // Collect the athleteIds, but exclude those whose parent training event is already selected
    const selectedAthleteIds = (treeData as RootNode).children
      .filter((group) => !group.checked) // Exclude groups that are fully checked (i.e., their eventId is already selected)
      .flatMap((group) => group.children.filter((athlete) => athlete.checked).map((athlete) => athlete.id));

    const seasonPlanData: SeasonPlanInput = remove
      ? {
          remove: true,
          id: props.fullCalendarEvent?.id || "",
          endDateTime: props.fullCalendarEvent?.seasonPlan.endDateTime || "",
          startDateTime: props.fullCalendarEvent?.seasonPlan.startDateTime || "",
          skillSetSelections:
            props.fullCalendarEvent?.seasonPlan.skillSets.map((skillSet) => ({
              skillSetId: skillSet.skillSet.id || "",
              selectedSkillIds: skillSet.skills.map((skill) => skill.id || ""),
            })) || [],
          athleteIds: props.fullCalendarEvent?.seasonPlan.athletes?.map((athlete) => athlete.id || ""),
          trainingGroupIds: props.fullCalendarEvent?.seasonPlan.trainingGroups?.map((event) => event.id || ""),
        }
      : {
          id: props.fullCalendarEvent?.id,
          startDateTime: startDate || "",
          endDateTime: endDate || "",
          skillSetSelections: selectedSkillSetIds.map((skillSetId) => ({
            skillSetId: skillSetId,
            selectedSkillIds: selectedSkillIds.filter((skillId) =>
              allSkillSets.find((skillSet) => skillSet.id === skillSetId)?.skills.find((skill) => skill.id === skillId),
            ),
          })),
          trainingGroupIds: selectedTrainingGroupIds, // Save selected event IDs
          athleteIds: selectedAthleteIds, // Save athlete IDs only if their event is not selected
          isOrganizationWide: (treeData as RootNode).checked,
        };

    props.onSave(seasonPlanData);
  };

  const handleSelectSkillSet = (selectedSkillSet: SkillSetDisplayCardProps["skillSet"]) => {
    setAllSkillSets((prevSets) =>
      prevSets.map((set) =>
        set.id === selectedSkillSet.id
          ? {
              ...set,
              selected: !set.selected,
              skills: set.skills.map((skill) => ({ ...skill, selected: !set.selected })),
            }
          : set,
      ),
    );
  };

  const handleSelectSkill = (selectedSkill: SkillSetDisplayCardProps["skillSet"]["skills"][0]) => {
    setAllSkillSets((prevSkillSets) =>
      prevSkillSets.map((skillSet) => {
        if (skillSet.skills.some((skill) => skill.id === selectedSkill.id)) {
          const updatedSkills = skillSet.skills.map((skill) =>
            skill.id === selectedSkill.id ? { ...skill, selected: !skill.selected } : skill,
          );

          const isAnySkillSelected = updatedSkills.some((skill) => skill.selected);

          return {
            ...skillSet,
            skills: updatedSkills,
            selected: isAnySkillSelected || skillSet.selected,
          };
        }
        return skillSet;
      }),
    );
  };

  /**
   * Recursive function to update the checked status of nodes. If the node is a parent, it updates all its children as
   * well. If it's a child, it updates its parent based on the state of all siblings.
   *
   * @param currentNode - The current node being processed
   * @param targetNodeId - The id of the target node
   * @param checked - The new checked state
   * @returns The updated node with the new checked state
   */
  const toggleNodeChecked = (currentNode: TreeNodeData, targetNodeId: string, checked: boolean): TreeNodeData => {
    if (currentNode.id === targetNodeId) {
      return updateNodeWithCheckStatus(currentNode, checked);
    }

    if ("children" in currentNode) {
      const updatedChildren = currentNode.children.map((child) => toggleNodeChecked(child, targetNodeId, checked));
      return {
        ...currentNode,
        children: updatedChildren as GroupNode["children"],
      };
    }

    return currentNode;
  };

  /**
   * Updates the checked state of a node and, if it's a parent, applies the checked state to all its children.
   *
   * @param node - The node to update
   * @param checked - The new checked state
   * @returns The updated node with the applied checked state
   */
  const updateNodeWithCheckStatus = (node: TreeNodeData, checked: boolean): TreeNodeData => {
    if ("children" in node) {
      const updatedChildren = node.children.map((child) => updateNodeWithCheckStatus(child, checked));

      return {
        ...node,
        checked,
        children: updatedChildren as GroupNode["children"],
      };
    }

    return {
      ...node,
      checked,
    };
  };

  const getSubmitStateForStep = (): { submitDisabled: boolean; submitButtonHoverText: string | undefined } => {
    switch (activeStep) {
      case 0:
        const isDateStepDisabled = !startDate || !endDate;
        return {
          submitDisabled: isDateStepDisabled,
          submitButtonHoverText: isDateStepDisabled
            ? "Next step is available once start and end dates are selected"
            : undefined,
        };

      case 1:
        const isGroupStepDisabled = (treeData as RootNode).children.every((group) =>
          group.children.every((athlete) => !athlete.checked),
        );
        return {
          submitDisabled: isGroupStepDisabled,
          submitButtonHoverText: isGroupStepDisabled
            ? "Next step is available once target group is selected"
            : undefined,
        };

      case 2:
        const isSkillSetStepDisabled = allSkillSets?.every(
          (set) => !set.selected && set.skills?.every((skill) => !skill.selected),
        );
        return {
          submitDisabled: isSkillSetStepDisabled,
          submitButtonHoverText: isSkillSetStepDisabled
            ? "Next step is available once training target is selected"
            : undefined,
        };

      case 3:
        return {
          submitDisabled: false, // Assuming review step doesn't have any restrictions
          submitButtonHoverText: undefined,
        };

      default:
        return { submitDisabled: true, submitButtonHoverText: undefined };
    }
  };

  const steps = [
    { title: "Dates", description: "Define dates" },
    { title: "Training groups", description: "Assign training groups" },
    { title: "Skill Sets", description: "Select Skill Sets" },
    { title: "Review", description: "Review & confirm plan" },
  ];

  return (
    <FormModal
      fixedHeight={true}
      open={props.isOpen}
      onClose={props.onClose}
      handleSubmit={activeStep < 3 ? handleNext : () => handleSave(false)}
      submitDisabled={getSubmitStateForStep().submitDisabled}
      submitButtonHoverText={getSubmitStateForStep().submitButtonHoverText}
      handleRemove={props.fullCalendarEvent ? () => handleSave(true) : undefined}
      submitButtonText={submitButtonText}
      secondaryButtonText={activeStep > 0 ? "Back" : undefined}
      title={props.fullCalendarEvent ? "Edit Season Plan" : "Create Season Plan"}
      handleSecondaryClick={activeStep > 0 ? handleBack : undefined}
      handlePaddingXManually={true}
    >
      <Stepper size="md" index={activeStep} paddingX="6">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
            </StepIndicator>
            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Box
        height="100%"
        overflow="hidden"
        paddingTop="6" /* paddingTop instead of sibling's marginBottom due to the CommonFormLabel cut-off */
      >
        {activeStep === 0 && (
          <StepDates startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        )}

        {activeStep === 1 && (
          <StepTrainingGroups
            treeData={treeData}
            onCheck={(currentNode, targetNodeId, checked) =>
              setTreeData(toggleNodeChecked(currentNode, targetNodeId, checked))
            }
          />
        )}

        {activeStep === 2 && (
          <StepSkillSets
            allSkillSets={allSkillSets}
            handleSelectSkillSet={handleSelectSkillSet}
            handleSelectSkill={handleSelectSkill}
          />
        )}

        {activeStep === 3 && (
          <StepReview
            startDate={startDate}
            endDate={endDate}
            treeData={treeData as RootNode}
            allSkillSets={allSkillSets}
          />
        )}
      </Box>
    </FormModal>
  );
};
