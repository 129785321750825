import { Box, SimpleGrid, Stack, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SkillSetCard, type SkillSetCardProps } from "../../../common/components/SkillSetCard/SkillSetCard";
import { CommonIconButton } from "../../../common/components/CommonIconButton";
import type { SkillSet, DeletionNode, EvaluationCriteria, Organization } from "../../../types";

type SkillSetsProps = {
  skillSets: SkillSetCardProps["skillSet"][];
  onSave: (skillSetToSave: SkillSet) => Promise<void>;
  entitiesToBeRemovedWithSkillSet?: DeletionNode[];
  entitiesToBeRemovedWithSkill?: DeletionNode[];
  getEntitiesToBeRemovedWithSkillSet?: (id: string) => Promise<void>;
  getEntitiesToBeRemovedWithSkill?: (id: string) => Promise<void>;
  entitiesToBeRemovedLoading?: boolean;
  getSkillSetEvaluationCriteria: (skillSetId: string) => Promise<void>;
  skillSetEvaluationCriteriaData: { [key: string]: EvaluationCriteria[] };
  skillSetEvaluationCriteriaLoading: { [key: string]: boolean };
  onSaveEvaluationCriteria: (criteriaToSave: EvaluationCriteria) => Promise<void>;
  organization?: Organization;
};

export const SkillSets: React.FC<SkillSetsProps> = (props) => {
  const [skillSets, setSkillSets] = useState(
    props.skillSets.map((skillSet) => ({ ...skillSet, loading: false, active: false })),
  );
  const [isCreatingNewSkillset, setIsCreatingNewSkillset] = useState(false);

  useEffect(() => {
    setSkillSets(props.skillSets.map((skillSet) => ({ ...skillSet, loading: false, active: false })));
  }, [props.skillSets]);

  const handleSave = async (skillSetToSave: SkillSet) => {
    setSkillSets((prevSkillSets) =>
      prevSkillSets.map((skillSet) => (skillSet.id === skillSetToSave.id ? { ...skillSet, loading: true } : skillSet)),
    );

    await props.onSave(skillSetToSave);

    setSkillSets((prevSkillSets) =>
      prevSkillSets.map((skillSet) => (skillSet.id === skillSetToSave.id ? { ...skillSet, loading: false } : skillSet)),
    );
    setIsCreatingNewSkillset(false);
  };

  const handleAddNewSkillSet = () => {
    setIsCreatingNewSkillset(true);
    setSkillSets((prevSkillSets) => [
      ...prevSkillSets,
      {
        name: "",
        skills: [],
        active: true,
        loading: false,
      },
    ]);
  };

  const handleAddSkill = (index: number) => {
    setSkillSets((prevSkillSets) =>
      prevSkillSets.map((skillSet, i) =>
        i === index
          ? {
              ...skillSet,
              active: true,
              skills: [...(skillSet.skills || []), { name: "" }],
            }
          : skillSet,
      ),
    );
  };

  const handleResetSkillSet = (skillSetId: string | null) => {
    setSkillSets((prevSkillSets) =>
      prevSkillSets
        .map((skillSet) =>
          skillSet.id === skillSetId
            ? {
                ...skillSet,
                skills: skillSet.skills?.filter((skill) => skill.name),
              }
            : skillSet,
        )
        .filter((skillSet) => skillSet.name),
    );
    setIsCreatingNewSkillset(false);
  };

  const featurePurpose =
    "Skill Sets are categories that group related Skills for coaching and evaluation. Create a Skill Set to start tracking and developing athlete performance";

  return (
    <Box>
      <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Skill Sets ({props.skillSets.length})</Text>
        </Tooltip>
        <CommonIconButton
          height="36px"
          aria-label="Add Skill Set"
          onClick={handleAddNewSkillSet}
          disabled={isCreatingNewSkillset}
          tooltip={isCreatingNewSkillset ? "Another skill set is being created" : undefined}
          dataTestId="add-skill-set"
        />
      </Stack>
      {skillSets.length === 0 ? (
        <Text fontSize="large" textAlign="center" color="blackAlpha.600">
          {featurePurpose}
        </Text>
      ) : (
        <SimpleGrid minChildWidth="80" spacing="8">
          {skillSets.map((skillSet, index) => (
            <SkillSetCard
              key={skillSet.id || index}
              skillSet={skillSet}
              onAdd={() => handleAddSkill(index)}
              onSave={(updatedSkillSetOrSkill) => handleSave(updatedSkillSetOrSkill)}
              entitiesToBeRemovedWithSkillSet={props.entitiesToBeRemovedWithSkillSet}
              entitiesToBeRemovedWithSkill={props.entitiesToBeRemovedWithSkill}
              getEntitiesToBeRemovedWithSkillSet={props.getEntitiesToBeRemovedWithSkillSet}
              getEntitiesToBeRemovedWithSkill={props.getEntitiesToBeRemovedWithSkill}
              entitiesToBeRemovedLoading={props.entitiesToBeRemovedLoading}
              resetSkillSet={(skillSetId) => handleResetSkillSet(skillSetId)}
              isLoading={skillSet.loading}
              isActive={skillSet.active}
              dataTestId={skillSet.id ? undefined : "skill-set-card-new"}
              getEvaluationCriteria={props.getSkillSetEvaluationCriteria}
              evaluationCriteriaData={props.skillSetEvaluationCriteriaData[skillSet.id || ""] || []}
              evaluationCriteriaLoading={props.skillSetEvaluationCriteriaLoading[skillSet.id || ""] || false}
              onSaveEvaluationCriteria={props.onSaveEvaluationCriteria}
              organization={props.organization}
            />
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};
