import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_ORGANIZATIONS } from "../graphql/get-all-organizations.query";
import { SAVE_ORGANIZATION } from "../graphql/save-organization.mutation";
import { REGISTER } from "../graphql/register.mutation";
import { useAlert } from "../../../common/components/AlertProvider";
import { handleError } from "../../../common/utils";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Box } from "@chakra-ui/react";
import { Admin } from "../components/Admin";

/** AdminContainer handles the communication with the server and passes necessary props to AdminComponent. */
export const AdminContainer: React.FC = () => {
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_ALL_ORGANIZATIONS);

  const [saveOrganizationMutation, { error: saveOrganizationError, loading: saveOrganizationLoading }] = useMutation(
    SAVE_ORGANIZATION,
    {
      refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
      awaitRefetchQueries: true,
    },
  );

  const [registerNewUserMutation, { error: registerNewUserError, loading: registerNewUserLoading }] = useMutation(
    REGISTER,
    {
      refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
      awaitRefetchQueries: true,
    },
  );

  const { showAlert, hideAlert } = useAlert();
  const isLoading = organizationLoading || saveOrganizationLoading || registerNewUserLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setShowContent(true), 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const errors = [organizationError, saveOrganizationError, registerNewUserError].filter(Boolean);
    if (errors.length) {
      showAlert(handleError(errors), "error");
    }
    return () => hideAlert();
  }, [organizationError, saveOrganizationError, registerNewUserError]);

  const saveOrganization = async (organizationInput: any) => {
    try {
      if (organizationInput.id) {
        showAlert("Updating Organization", "info", undefined, true);
      } else {
        showAlert("Creating new Organization", "info", undefined, true);
      }

      await saveOrganizationMutation({ variables: { data: organizationInput } });

      if (organizationInput.id) {
        showAlert("Organization updated!", "success", 5000);
      } else {
        showAlert("Organization created!", "success", 5000);
      }
    } catch {
      // Handle silently; errors managed in useEffect
    }
  };

  const registerNewUser = async (userData: any) => {
    try {
      showAlert("Registering new user", "info", undefined, true);
      await registerNewUserMutation({ variables: { ...userData } });
      showAlert("User registered successfully!", "success", 5000);
    } catch {
      // Handle silently; errors managed in useEffect
    }
  };

  return (
    <Box>
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}>
          <Admin
            allOrganizations={organizationData?.getAllOrganizations || []}
            saveOrganization={saveOrganization}
            registerNewUser={registerNewUser}
          />
        </Box>
      )}
    </Box>
  );
};
