import { gql } from "@apollo/client";

export const GET_ALL_RECURRING_TRAINING_EVENT_METADATAS = gql`
  query GetAllRecurringTrainingEventMetadatas {
    getAllRecurringTrainingEventMetadatas {
      id
      players {
        id
      }
    }
  }
`;
