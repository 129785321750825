import React, { useState, useEffect, useMemo } from "react";
import { Box, CircularProgress, CircularProgressLabel, Stack, Text, Tooltip, keyframes } from "@chakra-ui/react";
import { Autocomplete } from "../../../common/components/AutoComplete";
import { FormModal } from "../../../common/components/FormModal";
import { CommonButton } from "../../../common/components/CommonButton";
import { CommonInput } from "../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../common/components/LoadingOverlay";
import { Evaluation } from "./Evaluation/Evaluation";
import { Notes } from "./Notes/Notes";
import { Logs } from "./Logs/Logs";
import { RecurringTrainingEvents } from "./RecurringTrainingEvents/RecurringTrainingEvents";
import type {
  Player,
  RecurringTrainingEvent,
  Evaluation as EvaluationType,
  NoteInput,
  SkillSet,
  Coach,
  EvaluationCriteria,
} from "../../../types";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import type {
  EvaluationInput,
  PlayerInput,
  RecurringTrainingEventInput,
  SkillEvaluationInput,
  SkillSetEvaluationInput,
} from "../containers/PlayerContainer";
import { ComponentContainer } from "../../../common/components/ComponentContainer";

type PlayersProps = {
  players: Player[];
  coaches: Coach[];
  skillSets: SkillSet[];
  seasonStart: string;
  seasonEnd: string;
  evaluationCriteria: EvaluationCriteria[];
  onSavePlayer: (playerInput: PlayerInput) => Promise<void>;
  onSaveRecurringTrainingEvent: (eventInput: RecurringTrainingEventInput) => Promise<void>;
  onSaveNote: (noteInput: NoteInput) => Promise<void>;
  onSaveEvaluation: (evaluationInput: EvaluationInput) => Promise<void>;
  isLoading: boolean;
  isEvaluationLoading: boolean;
  isNoteLoading: boolean;
  isRecurringTrainingEventLoading: boolean;
  isPlayerLoading: boolean;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
`;

export const Players: React.FC<PlayersProps> = (props) => {
  const { globalSelectedClub } = useGlobalContext();
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [clearAutocomplete, setClearAutocomplete] = useState(false);
  const [changePlayerAnimation, setChangePlayerAnimation] = useState("");
  const [isHeaderDisabled, setIsHeaderDisabled] = useState(false);
  const [playerRating, setPlayerRating] = useState<number>(0);

  useEffect(() => {
    setSelectedPlayer(
      props.players.find((player) => player.id === selectedPlayer?.id || player.name === selectedPlayer?.name) || null,
    );
  }, [props.players]);

  const handlePlayerChange = (newPlayer: Player) => {
    setIsHeaderDisabled(true);
    setClearAutocomplete(true);
    setChangePlayerAnimation(`${fadeOut} 0.5s ease`);
    setTimeout(() => {
      setChangePlayerAnimation(`${fadeIn} 0.5s ease`);
      setSelectedPlayer(newPlayer);
      setIsHeaderDisabled(false);
    }, 400);
  };

  const handleAddPlayer = () => {
    setSelectedPlayer({
      id: undefined,
      name: "New Player",
      recurringTrainingEvents: [],
    });
    setIsDialogOpen(true);
  };

  const handleSavePlayer = async (remove: boolean) => {
    if (!selectedPlayer) {
      return;
    }

    const playerInput: PlayerInput = {
      name: selectedPlayer.name,
      id: selectedPlayer.id,
      remove,
    };

    await props.onSavePlayer(playerInput);

    setIsDialogOpen(false);
  };

  const handleSaveRecurringTrainingEvent = async (eventToSave: RecurringTrainingEvent) => {
    const existingPlayerIds = eventToSave.players?.map((player) => player.id || "") || [];
    const playerIdsToSave = eventToSave.remove
      ? existingPlayerIds.filter((playerId) => playerId !== selectedPlayer?.id || "")
      : [...existingPlayerIds, selectedPlayer?.id || ""];

    const dataToSave: RecurringTrainingEventInput = {
      name: eventToSave.name,
      id: eventToSave.id,
      recurrenceEndTime: eventToSave.recurrenceEndTime,
      recurrenceStartTime: eventToSave.recurrenceStartTime,
      recurrenceWeekday: eventToSave.recurrenceWeekday,
      coachIds: eventToSave.coaches?.map((coach) => coach.id || "") || [],
      courtId: eventToSave.court?.id,
      playerIds: playerIdsToSave,
      // always false under players, if whole training group should be removed, it needs to be done through settings
      remove: false,
    };
    await props.onSaveRecurringTrainingEvent(dataToSave);
  };

  const handleSaveNote = async (note: string) => {
    if (!selectedPlayer) return;
    const noteToSave: NoteInput = {
      content: note,
      playerIds: [selectedPlayer.id || ""],
    };
    await props.onSaveNote(noteToSave);
  };

  const handleSaveEvaluation = async (evaluation: EvaluationType) => {
    if (!selectedPlayer) {
      return;
    }

    // Map over each SkillSetEvaluation to create SkillSetEvaluationInput
    const skillSetEvaluationsInput: SkillSetEvaluationInput[] = evaluation.skillSetEvaluations.map(
      (skillSetEvaluation) => {
        // Map over each SkillEvaluation to create SkillEvaluationInput
        const skillEvaluationsInput: SkillEvaluationInput[] = skillSetEvaluation.skillEvaluations.map(
          (skillEvaluation) => {
            return {
              id: skillEvaluation.id,
              skillId: skillEvaluation.skill.id || "",
              rating: skillEvaluation.rating,
            };
          },
        );

        // Create SkillSetEvaluationInput
        const skillSetEvaluationInput: SkillSetEvaluationInput = {
          id: skillSetEvaluation.id,
          skillSetId: skillSetEvaluation.skillSet.id || "",
          skillEvaluations: skillEvaluationsInput,
          comment: skillSetEvaluation.comment,
        };

        return skillSetEvaluationInput;
      },
    );

    const evaluationInput: EvaluationInput = {
      id: evaluation.id,
      playerId: selectedPlayer.id || "",
      skillSetEvaluations: skillSetEvaluationsInput,
      remove: evaluation.remove,
    };

    await props.onSaveEvaluation(evaluationInput);
  };

  const handleCloseModal = () => {
    if (!selectedPlayer?.id) {
      setSelectedPlayer(null);
    }
    // Reset player to the one that was selected before opening the modal
    setSelectedPlayer(
      props.players.find(
        (player: Player) => player.id === selectedPlayer?.id || player.name === selectedPlayer?.name,
      ) || null,
    );
    setIsDialogOpen(false);
  };

  const handlePlayerNameChange = (name: string) => {
    if (selectedPlayer) {
      setSelectedPlayer({
        ...selectedPlayer,
        name,
      });
    }
  };

  const handleSave = () => {
    setIsDialogOpen(false);
    handleSavePlayer(false);
  };

  const handleRemove = () => {
    setIsDialogOpen(false);
    handleSavePlayer(true);
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    if (props.players.map((player) => player.name.toLowerCase()).includes(selectedPlayer?.name.toLowerCase() || "")) {
      return `Player "${selectedPlayer?.name}" already exists`;
    } else if (selectedPlayer?.name.trim() === "") {
      return "Player name cannot be empty";
    }
    return undefined;
  };

  /** Calculate player rating based on evaluation. */
  const handleEvaluationChange = (evaluation: EvaluationType) => {
    // TODO: improve this by saving rating to db
    if (!evaluation || !evaluation.skillSetEvaluations) {
      setPlayerRating(0);
      return;
    }

    const skillSetEvaluations = evaluation.skillSetEvaluations;
    const skillSetRatings = skillSetEvaluations
      .filter((skillSetEvaluation) => skillSetEvaluation.skillEvaluations.length > 0)
      .map((skillSetEvaluation) => {
        const skillRatings = skillSetEvaluation.skillEvaluations.map((skillEvaluation) => skillEvaluation.rating);
        const skillSetRating = skillRatings.reduce((acc, current) => acc + current, 0) / skillRatings.length;
        return skillSetRating;
      });

    const overallRating = skillSetRatings.reduce((acc, current) => acc + current, 0) / skillSetRatings.length || 0;
    setPlayerRating(overallRating);
  };

  const submitDisabledReasons = useMemo(() => getSubmitDisabledReasons(), [props.players, selectedPlayer?.name]);

  const allUniqueRecurringTrainingEvents = props.players
    .flatMap((player) => player.recurringTrainingEvents || [])
    .reduce((acc, currentEvent) => {
      if (!acc.some((event) => event.id === currentEvent.id)) {
        acc.push(currentEvent);
      }
      return acc;
    }, [] as RecurringTrainingEvent[]);

  const filteredUniqueRecurringTrainingEvents = allUniqueRecurringTrainingEvents.filter(
    (event) => !selectedPlayer?.recurringTrainingEvents?.some((playerEvent) => playerEvent.id === event.id),
  );

  return (
    <Box id="players" data-testid="players">
      <LoadingOverlay display={props.isPlayerLoading} spinnerSize="xl" spinnerTopPosition="60px" />

      {/* Header */}
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" marginBottom="12">
        <Stack direction="row" spacing="4" flex={1}>
          <Autocomplete
            initialOption={selectedPlayer}
            allOptions={props.players}
            getOptionLabel={(player: Player) => player.name}
            handleOptionChange={handlePlayerChange}
            clearInput={clearAutocomplete}
            dataTestId="player-autocomplete"
          />
        </Stack>
        <Text fontSize="x-large" flex={1} textAlign="center" animation={changePlayerAnimation}>
          {selectedPlayer?.name || "Select player"}
        </Text>
        <Stack direction="row" spacing="12" flex={1} justifyContent="end">
          <CommonButton
            variantType="outlineSecondary"
            onClick={handleAddPlayer}
            disabled={isHeaderDisabled}
            dataTestId="add-player"
          >
            New player
          </CommonButton>
          <CommonButton
            variantType="outlineSecondary"
            disabled={!selectedPlayer || isHeaderDisabled}
            onClick={() => setIsDialogOpen(true)}
            dataTestId="edit-player"
          >
            Edit
          </CommonButton>
        </Stack>
      </Stack>

      {/* Player content */}
      {selectedPlayer && (
        <Stack animation={changePlayerAnimation} direction="column" spacing="8">
          {/* Player overall rating */}
          <Box
            // lift overall rating with negative margin to reduce whitespace
            marginTop="-8"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <Tooltip label="Player overall rating is the average of Skill Set ratings">
              <CircularProgress
                value={(playerRating / 10) * 100}
                color="orange.400"
                size="55px"
                capIsRound={true}
                trackColor="gray.200"
              >
                <CircularProgressLabel fontSize="large" cursor="default">
                  {playerRating > 0 ? playerRating.toFixed(1) : "-"}
                </CircularProgressLabel>
              </CircularProgress>
            </Tooltip>
          </Box>

          <ComponentContainer handlePaddingManually={true}>
            <Evaluation
              key={selectedPlayer.evaluation?.id || selectedPlayer.id}
              player={selectedPlayer}
              allSkillSets={props.skillSets}
              allCriteria={props.evaluationCriteria}
              disabled={false}
              onSaveEvaluation={handleSaveEvaluation}
              onEvaluationChange={handleEvaluationChange}
              isLoading={props.isEvaluationLoading}
            />
          </ComponentContainer>

          <Stack direction="row" spacing="8" width="100%">
            <ComponentContainer handlePaddingManually={true}>
              <Notes player={selectedPlayer} onSave={handleSaveNote} isLoading={props.isNoteLoading} />
            </ComponentContainer>
            <ComponentContainer handlePaddingManually={true}>
              <Logs logs={selectedPlayer.logs || []} isLoading={props.isRecurringTrainingEventLoading} />
            </ComponentContainer>
          </Stack>

          <ComponentContainer>
            <RecurringTrainingEvents
              playerName={selectedPlayer?.name || ""}
              coaches={props.coaches}
              courts={globalSelectedClub?.courts || []}
              allTrainingEvents={filteredUniqueRecurringTrainingEvents}
              recurringTrainingEvents={selectedPlayer?.recurringTrainingEvents?.map((event) => ({ ...event })) || []}
              onSave={handleSaveRecurringTrainingEvent}
              onRemove={(eventToRemove) => handleSaveRecurringTrainingEvent({ ...eventToRemove, remove: true })}
              isLoading={props.isRecurringTrainingEventLoading}
              isSeasonDatesMissing={!props.seasonStart || !props.seasonEnd}
            />
          </ComponentContainer>

          <FormModal
            handleSubmit={handleSave}
            open={isDialogOpen}
            submitButtonText={!selectedPlayer?.id ? "Create" : "Confirm"}
            title={!selectedPlayer?.id ? "Create new player" : `Edit ${selectedPlayer.name}`}
            handleRemove={handleRemove}
            removeDisabled={!selectedPlayer?.id}
            removeDisabledReason={!selectedPlayer?.id ? "Player isn't saved yet" : undefined}
            onClose={handleCloseModal}
            submitDisabled={!!submitDisabledReasons}
            submitButtonHoverText={submitDisabledReasons}
          >
            <Box data-testid="player-modal">
              <CommonInput
                placeholder="Player name"
                value={selectedPlayer?.name || ""}
                onChange={handlePlayerNameChange}
                dataTestId="player-name"
              />
            </Box>
          </FormModal>
        </Stack>
      )}
    </Box>
  );
};
