import React from "react";
import { Box } from "@chakra-ui/react";

type ComponentContainerProps = {
  /** The child elements to be rendered within the container */
  children: React.ReactNode;
  /**
   * Optional flag to indicate if padding should be handled manually. Paddings/margins should still total to 24px for
   * consistency
   */
  handlePaddingManually?: boolean;
};

export const ComponentContainer: React.FC<ComponentContainerProps> = (props) => {
  return (
    <Box
      height="100%"
      width="100%"
      padding={props.handlePaddingManually ? "0" : "6"}
      border="1px solid"
      borderColor="blackAlpha.300"
      borderRadius="md"
      boxShadow="sm"
      transition="all 0.3s ease"
      _hover={{
        boxShadow: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #00000029", // lg blackAlpha.300
      }}
    >
      {props.children}
    </Box>
  );
};
