import React, { useState } from "react";
import { Box, Button, Icon, Stack, Switch, Text, Tooltip, type StackProps } from "@chakra-ui/react";
import { CommonIconButton } from "../../../../../common/components/CommonIconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type Step_3_ReviewProps = {
  email: string;
  password: string;
  organizationName: string;
  centers: { name: string; venues: string[] }[];
  coachNames: string[];
  athleteNames: string[];
  cardStyles: (props: { disableClick: boolean; selected: boolean }) => Record<string, any>;
};

/** Step_3_Review renders the UI for the final step (Review & finalize) */
export const Step_3_Review: React.FC<Step_3_ReviewProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const keyValueStyle: StackProps = {
    direction: "row",
    spacing: "6",
    padding: "4",
    justifyContent: "space-between",
    fontSize: "large",
  };
  return (
    <Stack direction="column" spacing="4" paddingBottom="6">
      <Text fontSize="large">Review your details and click 'Create Account' to start using Striveon</Text>
      <Tooltip label={props.email}>
        <Stack {...keyValueStyle} {...props.cardStyles({ disableClick: true, selected: true })}>
          <Text textColor="orange.400">Email</Text>
          <Text noOfLines={1} wordBreak="break-all">
            {props.email}
          </Text>
        </Stack>
      </Tooltip>

      <Stack {...keyValueStyle} {...props.cardStyles({ disableClick: true, selected: true })}>
        <Text textColor="orange.400">Password</Text>
        <Stack direction="row" alignItems="center">
          <Text noOfLines={1} wordBreak="break-all">
            {isPasswordVisible ? props.password : "•".repeat(props.password.length)}
          </Text>
          <Button variant="unstyled" size="sm" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {isPasswordVisible ? "Hide" : "Show"}
          </Button>
        </Stack>
      </Stack>

      <Tooltip label={props.organizationName}>
        <Stack {...keyValueStyle} {...props.cardStyles({ disableClick: true, selected: true })}>
          <Text textColor="orange.400">Organization</Text>
          <Text noOfLines={1} wordBreak="break-all">
            {props.organizationName}
          </Text>
        </Stack>
      </Tooltip>

      {props.centers.map((center, index) => (
        <Tooltip key={index} label={center.venues.join(", ")}>
          <Stack {...keyValueStyle} {...props.cardStyles({ disableClick: true, selected: true })}>
            <Stack direction="column" spacing="1">
              <Text textColor="orange.400">Center</Text>
              <Text textColor="orange.400">Venues</Text>
            </Stack>
            <Stack direction="column" spacing="1" alignItems="end">
              <Text noOfLines={1} wordBreak="break-all">
                {center.name}
              </Text>
              <Text noOfLines={1} wordBreak="break-all">
                {center.venues.join(", ")}
              </Text>
            </Stack>
          </Stack>
        </Tooltip>
      ))}

      {props.coachNames.length > 0 && (
        <Tooltip label={props.coachNames.join(", ")}>
          <Stack {...keyValueStyle} {...props.cardStyles({ disableClick: true, selected: true })}>
            <Text textColor="orange.400">Coaches</Text>
            <Text noOfLines={1} wordBreak="break-all">
              {props.coachNames.join(", ")}
            </Text>
          </Stack>
        </Tooltip>
      )}

      {props.athleteNames.length > 0 && (
        <Tooltip label={props.athleteNames.join(", ")}>
          <Stack {...keyValueStyle} {...props.cardStyles({ disableClick: true, selected: true })}>
            <Text textColor="orange.400">Athletes</Text>
            <Text noOfLines={1} wordBreak="break-all">
              {props.athleteNames.join(", ")}
            </Text>
          </Stack>
        </Tooltip>
      )}

      {/* <Text color="blackAlpha.600">Check the information and click 'Create Account' to finish</Text> */}
    </Stack>
  );
};
