import React from "react";
import { Box, Table, TableContainer, Tbody, Td, Text, Thead, Tr, Tooltip } from "@chakra-ui/react";
import type { OrganizationUsageStatistics } from "../../types";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

type UsageStatsProps = {
  usageStats: OrganizationUsageStatistics[];
};

/** Column definition for UsageStats table */
type ColumnDefinition = {
  label: string;
  key: keyof OrganizationUsageStatistics;
  width?: string;
};

/** UsageStats component displays usage statistics for organizations */
export const UsageStats: React.FC<UsageStatsProps> = ({ usageStats }) => {
  const columns: ColumnDefinition[] = [
    { label: "Organization", key: "organizationName", width: "200px" },
    { label: "Coaches", key: "coachesCount", width: "200px" },
    { label: "Athletes", key: "athletesCount", width: "200px" },
    { label: "Centers", key: "centersCount", width: "200px" },
    { label: "Venues", key: "venuesCount", width: "200px" },
    { label: "Seasons", key: "trainingSeasonsCount", width: "200px" },
    { label: "Groups", key: "trainingGroupsCount", width: "200px" },
    { label: "Group Events", key: "trainingGroupEventsCount", width: "200px" },
    { label: "Single Events", key: "singleTrainingEventsCount", width: "200px" },
    { label: "Drills", key: "drillsCount", width: "200px" },
    { label: "Sessions", key: "sessionsCount", width: "200px" },
    { label: "Season Plans", key: "seasonPlansCount", width: "200px" },
    { label: "Skill Sets", key: "skillSetsCount", width: "200px" },
    { label: "Skills", key: "skillsCount", width: "200px" },
    { label: "Evaluations", key: "evaluationsCount", width: "200px" },
    { label: "Notes", key: "notesCount", width: "200px" },
  ];

  /** Sort configuration state */
  const [sortConfig, setSortConfig] = React.useState<{
    key: keyof OrganizationUsageStatistics;
    direction: "ascending" | "descending";
  } | null>(null);

  /**
   * Handle column header click to sort table by the specified key
   *
   * @param key The key of the column to sort by
   */
  const handleSort = (key: keyof OrganizationUsageStatistics) => {
    if (sortConfig && sortConfig.key === key) {
      // Toggle direction
      setSortConfig({
        key,
        direction: sortConfig.direction === "ascending" ? "descending" : "ascending",
      });
    } else {
      // Default to ascending
      setSortConfig({ key, direction: "ascending" });
    }
  };

  /** Sort usageStats based on sortConfig */
  const sortedUsageStats = React.useMemo(() => {
    let sortable = [...usageStats];

    if (sortConfig) {
      sortable.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        // Determine if values are numbers or strings
        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        } else if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        // If different types or unknown, do not change order
        return 0;
      });
    }

    return sortable;
  }, [usageStats, sortConfig]);

  /**
   * Returns a text color if value is zero. Otherwise undefined.
   *
   * @param value Number to check
   */
  const getTextColorIfZero = (value: number): string | undefined => (value === 0 ? "blackAlpha.600" : undefined);

  return (
    <Box position="relative" data-testid="usage-stats">
      <Box paddingBottom="6" display="flex" justifyContent="center">
        <Text fontSize="x-large" fontWeight="bold">
          Organization Usage Statistics ({usageStats.length})
        </Text>
      </Box>

      <TableContainer overflowX="auto" overflowY="hidden">
        <Table size="lg" variant="unstyled" layout="fixed">
          <Thead>
            <Tr>
              {columns.map((col) => {
                const isSorted = sortConfig?.key === col.key;
                return (
                  <Text
                    key={col.key}
                    as="th"
                    width={col.width}
                    fontSize="md"
                    textTransform="uppercase"
                    paddingY="4"
                    paddingX="8"
                    transition="all 0.3s ease"
                    borderBottom="1px solid"
                    borderColor="blackAlpha.200"
                    textAlign="left"
                    onClick={() => handleSort(col.key)}
                    _hover={{
                      color: "orange.400",
                      cursor: "pointer",
                    }}
                  >
                    <Box display="inline-flex" alignItems="center" gap="1">
                      {col.label}
                      {isSorted ? (
                        sortConfig.direction === "ascending" ? (
                          <TriangleUpIcon color="orange.400" opacity={1} />
                        ) : (
                          <TriangleDownIcon color="orange.400" opacity={1} />
                        )
                      ) : (
                        <TriangleDownIcon color="inherit" opacity={0.3} />
                      )}
                    </Box>
                  </Text>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {sortedUsageStats.map((stat) => {
              return (
                <Tr
                  key={stat.organizationName}
                  borderTop="1px solid"
                  borderColor="blackAlpha.50"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "orange.400",
                    borderColor: "transparent",
                    boxShadow: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C",
                  }}
                >
                  <Td width={columns[0].width} overflow="hidden" textOverflow="ellipsis">
                    <Tooltip label={stat.organizationName}>
                      <span>{stat.organizationName}</span>
                    </Tooltip>
                  </Td>
                  <Td width={columns[1].width} color={getTextColorIfZero(stat.coachesCount)}>
                    {stat.coachesCount}
                  </Td>
                  <Td width={columns[2].width} color={getTextColorIfZero(stat.athletesCount)}>
                    {stat.athletesCount}
                  </Td>
                  <Td width={columns[3].width} color={getTextColorIfZero(stat.centersCount)}>
                    {stat.centersCount}
                  </Td>
                  <Td width={columns[4].width} color={getTextColorIfZero(stat.venuesCount)}>
                    {stat.venuesCount}
                  </Td>
                  <Td width={columns[5].width} color={getTextColorIfZero(stat.trainingSeasonsCount)}>
                    {stat.trainingSeasonsCount}
                  </Td>
                  <Td width={columns[6].width} color={getTextColorIfZero(stat.trainingGroupsCount)}>
                    {stat.trainingGroupsCount}
                  </Td>
                  <Td width={columns[7].width} color={getTextColorIfZero(stat.trainingGroupEventsCount)}>
                    {stat.trainingGroupEventsCount}
                  </Td>
                  <Td width={columns[8].width} color={getTextColorIfZero(stat.singleTrainingEventsCount)}>
                    {stat.singleTrainingEventsCount}
                  </Td>
                  <Td width={columns[9].width} color={getTextColorIfZero(stat.drillsCount)}>
                    {stat.drillsCount}
                  </Td>
                  <Td width={columns[10].width} color={getTextColorIfZero(stat.sessionsCount)}>
                    {stat.sessionsCount}
                  </Td>
                  <Td width={columns[11].width} color={getTextColorIfZero(stat.seasonPlansCount)}>
                    {stat.seasonPlansCount}
                  </Td>
                  <Td width={columns[12].width} color={getTextColorIfZero(stat.skillSetsCount)}>
                    {stat.skillSetsCount}
                  </Td>
                  <Td width={columns[13].width} color={getTextColorIfZero(stat.skillsCount)}>
                    {stat.skillsCount}
                  </Td>
                  <Td width={columns[14].width} color={getTextColorIfZero(stat.evaluationsCount)}>
                    {stat.evaluationsCount}
                  </Td>
                  <Td width={columns[15].width} color={getTextColorIfZero(stat.notesCount)}>
                    {stat.notesCount}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {usageStats.length === 0 && (
        <Text fontSize="large" paddingY="6" textColor="blackAlpha.600" textAlign="center" data-testid="no-usage-stats">
          No usage statistics available.
        </Text>
      )}
    </Box>
  );
};
