import React from "react";
import { Box } from "@chakra-ui/react";
import { componentContainerShadowStyles } from "../../utils/styles";

type ComponentContainerProps = {
  /** The child elements to be rendered within the container */
  children: React.ReactNode;
  /**
   * Optional flag to indicate if padding should be handled manually. Paddings/margins should still total to 24px for
   * consistency
   */
  handlePaddingManually?: boolean;
};

export const ComponentContainer: React.FC<ComponentContainerProps> = (props) => {
  return (
    <Box
      height="100%"
      width="100%"
      padding={props.handlePaddingManually ? "0" : "6"}
      border="1px solid"
      borderColor="blackAlpha.300"
      {...componentContainerShadowStyles()}
    >
      {props.children}
    </Box>
  );
};
