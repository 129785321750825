import React from "react";

import { cardStyles } from "../../../../../common/utils/styles";
import { extractLocalTime, extractDateFromUTC, extractUtcTime } from "../../../../../common/utils/dateAndTime";
import { format, getISOWeek } from "date-fns";
import { Box, Grid, Stack, Text } from "@chakra-ui/react";
import { Log, LogType } from "../../../../../types";

type LogItemProps = {
  log: Log;
};

export const LogItem: React.FC<LogItemProps> = (props) => {
  const formatWeekday = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "EEEE");
  };

  const formatWeekNumber = (dateString: string) => {
    const date = new Date(dateString);
    return getISOWeek(date);
  };

  const formatLogType = (logType: LogType) => {
    switch (logType) {
      case LogType.ParticipationWithdrawn:
        return "Withdrawn from a weekly training";
      case LogType.JoinedReplacementEvent:
        return "Participated in an extra weekly training";
      case LogType.EventCancelled:
        return "Weekly training cancelled";
      case LogType.ParticipatedInSingleTrainingEvent:
        return "Participated in a one-time training";
      default:
        return "Unknown";
    }
  };

  return (
    <Box padding="4" {...cardStyles({ disableClick: true })}>
      <Box fontWeight="medium" whiteSpace="pre-wrap" marginBottom="3">
        <Box as="dl">
          <Grid templateColumns="max-content 1fr" columnGap="4">
            <Text as="dt" color="blackAlpha.600">
              Event
            </Text>
            <Text as="dd" marginBottom="2">
              {formatLogType(props.log.type)}
            </Text>
            <Text as="dt" color="blackAlpha.600">
              Training date
            </Text>
            <Text as="dd">
              {`${formatWeekday(props.log.eventStartDateTime || "")}, ${format(extractDateFromUTC(props.log.eventStartDateTime || ""), "dd.MM.yyyy")} `}
              <Text as="span" color="blackAlpha.600">
                (week {formatWeekNumber(props.log.eventStartDateTime || "")})
              </Text>
            </Text>
            <Text as="dt" color="blackAlpha.600">
              Training time
            </Text>
            <Text as="dd">
              {extractUtcTime(props.log.eventStartDateTime || "")} - {extractUtcTime(props.log.eventEndDateTime || "")}
            </Text>
            <Text as="dt" color="blackAlpha.600">
              Training venue
            </Text>
            <Text as="dd">
              {props.log.eventVenue}{" "}
              <Text as="span" color="blackAlpha.600">
                ({props.log.eventCenter})
              </Text>
            </Text>
            <Text as="dt" color="blackAlpha.600">
              Training group
            </Text>
            <Text
              as="dd"
              color={props.log.type === LogType.ParticipatedInSingleTrainingEvent ? "blackAlpha.600" : undefined}
            >
              {props.log.eventTrainingGroupName}
            </Text>
          </Grid>
        </Box>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Text fontSize="xs" color="blackAlpha.600">
          Automated message
        </Text>
        <Stack direction="row" spacing="4">
          <Text fontSize="xs" color="blackAlpha.600">
            {format(extractDateFromUTC(props.log.createdAt), "dd.MM.yyyy")}
          </Text>
          <Text fontSize="xs" color="blackAlpha.600">
            {extractLocalTime(props.log.createdAt)}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
