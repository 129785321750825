import React, { useEffect, useState } from "react";
import { Coaches } from "../components/Coaches/Coaches";
import { Clubs } from "../components/Clubs/Clubs";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Season } from "../components/Season/Season";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";
import { GET_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "../../../common/graphql/get-missing-recurring-trainingevent-metadatas.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { useAlert } from "../../../common/components/AlertProvider";
import { handleError } from "../../../common/utils";
import { GET_ALL_CLUBS } from "../../../common/graphql/get-all-clubs.query";
import { SAVE_COACH } from "../graphql/save-coach.mutation";
import { SAVE_CLUB } from "../graphql/save-club.mutation";
import { SAVE_COURT } from "../graphql/save-court.mutation";
import { UPDATE_USER_ORGANIZATION } from "../graphql/update-user-organization.mutation";
import { CREATE_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "../graphql/create-missing-recurring-trainingevent-metadatas.mutation";
import { GET_ALL_PLAYERS } from "../../../common/graphql/get-all-players.query";
import { GET_CLUB } from "../graphql/get-club.query";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { CommonScrollbar } from "../../../common/components/CommonScrollbar";
import type { Club } from "../../../types";
import { SAVE_RECURRING_TRAINING_EVENT } from "../../../common/graphql/save-recurring-training-event.mutation";
import { GET_ALL_RECURRING_TRAINING_EVENTS } from "../graphql/get-all-recurring-training-events.query";
import { TrainingGroups } from "../components/TrainingGroups/TrainingGroups";
import type { RecurringTrainingEventInput } from "../../Players/containers/PlayerContainer";
import { GET_ALL_RECURRING_TRAINING_EVENT_METADATAS } from "../../../common/graphql/get-all-recurring-training-event-metadatas.query";

export type OrganizationInput = {
  id?: string;
  name?: string;
  seasonStart?: string;
  seasonEnd?: string;
};

export type CoachInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type CourtInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type ClubInput = {
  id?: string;
  courts?: CourtInput[];
  name: string;
  remove?: boolean;
};

export const SettingsContainer: React.FC = () => {
  // Queries
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_USER_ORGANIZATION);
  const {
    data: missingMetadataData,
    loading: missingMetadataLoading,
    error: missingMetadataError,
  } = useQuery(GET_MISSING_RECURRING_TRAININGEVENT_METADATAS);
  const { data: coachData, loading: coachLoading, error: coachError } = useQuery(GET_ALL_COACHES);
  const { data: clubData, loading: clubLoading, error: clubError } = useQuery(GET_ALL_CLUBS);
  const {
    data: recurringTrainingEventsData,
    loading: recurringTrainingEventsLoading,
    error: recurringTrainingEventsError,
  } = useQuery(GET_ALL_RECURRING_TRAINING_EVENTS);
  const { data: playerData, loading: playerLoading, error: playerError } = useQuery(GET_ALL_PLAYERS);

  // Mutations
  const [saveCoachMutation, { error: saveCoachError, loading: saveCoachLoading }] = useMutation(SAVE_COACH, {
    refetchQueries: [{ query: GET_ALL_COACHES }],
    awaitRefetchQueries: true,
  });
  const [saveClubMutation, { error: saveClubError, loading: saveClubLoading }] = useMutation(SAVE_CLUB, {
    refetchQueries: [{ query: GET_ALL_CLUBS }],
    awaitRefetchQueries: true,
  });
  const [saveCourtMutation, { error: saveCourtError, loading: saveCourtLoading }] = useMutation(SAVE_COURT);
  const [updateOrganizationMutation, { error: updateOrganizationError, loading: updateOrganizationLoading }] =
    useMutation(UPDATE_USER_ORGANIZATION, {
      refetchQueries: [{ query: GET_USER_ORGANIZATION }, { query: GET_MISSING_RECURRING_TRAININGEVENT_METADATAS }],
      awaitRefetchQueries: true,
    });
  const [createMissingMetadatasMutation, { error: createMetadatasError, loading: createMetadatasLoading }] =
    useMutation(CREATE_MISSING_RECURRING_TRAININGEVENT_METADATAS, {
      refetchQueries: [
        { query: GET_MISSING_RECURRING_TRAININGEVENT_METADATAS },
        { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS },
        { query: GET_ALL_PLAYERS },
      ],
      awaitRefetchQueries: true,
    });
  const [getClub, { error: getClubError, loading: getClubLoading }] = useLazyQuery(GET_CLUB, {
    fetchPolicy: "network-only",
  });
  const [
    saveRecurringTrainingEventMutation,
    { error: saveRecurringTrainingEventError, loading: saveRecurringTrainingEventLoading },
  ] = useMutation(SAVE_RECURRING_TRAINING_EVENT, {
    refetchQueries: [
      { query: GET_ALL_RECURRING_TRAINING_EVENTS }, // to trigger update in this component
      { query: GET_ALL_PLAYERS }, // to trigger update in players (training groups)
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS }, // to trigger update in calendar for events
    ],
    awaitRefetchQueries: true,
  });

  const { globalSetSelectedClub } = useGlobalContext();
  const { showAlert, hideAlert } = useAlert();
  const isLoading =
    organizationLoading ||
    missingMetadataLoading ||
    coachLoading ||
    clubLoading ||
    playerLoading ||
    recurringTrainingEventsLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const errors = [
      organizationError,
      missingMetadataError,
      coachError,
      clubError,
      saveCoachError,
      saveClubError,
      saveCourtError,
      updateOrganizationError,
      createMetadatasError,
      getClubError,
      playerError,
      recurringTrainingEventsError,
      saveRecurringTrainingEventError,
    ].filter(Boolean);
    if (errors.length) {
      showAlert(handleError(errors), "error");
    }
    return () => {
      hideAlert();
    };
  }, [
    organizationError,
    missingMetadataError,
    coachError,
    clubError,
    saveCoachError,
    saveClubError,
    saveCourtError,
    updateOrganizationError,
    createMetadatasError,
    getClubError,
    playerError,
    recurringTrainingEventsError,
    saveRecurringTrainingEventError,
  ]);

  const saveCoach = async (coachInput: CoachInput): Promise<void> => {
    try {
      await saveCoachMutation({ variables: { data: coachInput } });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const saveClub = async (clunInput: ClubInput): Promise<void> => {
    try {
      await saveClubMutation({ variables: { data: clunInput } });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const saveCourt = async (courtInput: CourtInput, clubId: string): Promise<void> => {
    try {
      await saveCourtMutation({ variables: { data: courtInput, clubId } });
      const { data: clubData } = await getClub({ variables: { id: clubId } });
      if (clubData) {
        globalSetSelectedClub(clubData.getClub);
      }
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const updateOrganization = async (organizationInput: OrganizationInput): Promise<void> => {
    try {
      await updateOrganizationMutation({ variables: { data: organizationInput } });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const createMissingMetadatas = async (): Promise<void> => {
    try {
      await createMissingMetadatasMutation();
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const saveRecurringTrainingEvent = async (
    recurringTrainingEventInput: RecurringTrainingEventInput,
  ): Promise<void> => {
    try {
      await saveRecurringTrainingEventMutation({ variables: { data: recurringTrainingEventInput } });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  return (
    <Box>
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Grid
          animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}
          templateColumns="repeat(3, 1fr)"
          templateRows="max-content"
          gap="8"
        >
          <GridItem colSpan={3} height="min-content">
            <ComponentContainer>
              <Season
                seasonStart={organizationData?.getUserOrganization?.seasonStart}
                seasonEnd={organizationData?.getUserOrganization?.seasonEnd}
                missingMetadata={missingMetadataData?.getMissingRecurringTrainingEventMetadatas}
                updateOrganizationLoading={updateOrganizationLoading}
                createMetadatasLoading={createMetadatasLoading}
                updateOrganization={updateOrganization}
                createMissingMetadatas={createMissingMetadatas}
              />
            </ComponentContainer>
          </GridItem>
          <GridItem colSpan={1}>
            <ComponentContainer>
              <CommonScrollbar height="50svh" overflow="auto">
                <Coaches
                  allCoaches={coachData?.getAllCoaches || []}
                  saveCoach={saveCoach}
                  saveCoachLoading={saveCoachLoading}
                />
              </CommonScrollbar>
            </ComponentContainer>
          </GridItem>
          <GridItem colSpan={2}>
            <Clubs
              allClubs={clubData?.getAllClubs || []}
              saveClub={saveClub}
              saveClubLoading={saveClubLoading}
              saveCourt={saveCourt}
              saveCourtLoading={saveCourtLoading || getClubLoading}
            />
          </GridItem>
          <GridItem colSpan={3}>
            <ComponentContainer handlePaddingManually={true}>
              <TrainingGroups
                coaches={coachData?.getAllCoaches || []}
                courts={clubData?.getAllClubs?.flatMap((club: Club) => club.courts) || []}
                events={recurringTrainingEventsData?.getAllRecurringTrainingEvents || []}
                players={playerData?.getAllPlayers || []}
                onSave={saveRecurringTrainingEvent}
                isLoading={saveRecurringTrainingEventLoading}
              />
            </ComponentContainer>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};
