import { gql } from "@apollo/client";

export const GET_ALL_PLAYERS_FOR_CALENDAR = gql`
  query GetAllPlayersForCalendar {
    getAllPlayers {
      id
      name
    }
  }
`;
