import React from "react";
import { Box, Grid, GridItem, Divider, Stack, Text } from "@chakra-ui/react";
import { SkillSetDisplayCard, type SelectableSkill, type SelectableSkillSet } from "./components/SkillSetDisplayCard";
import { CommonInput } from "../../../../../../../common/components/CommonInput";
import { CommonScrollbar } from "../../../../../../../common/components/CommonScrollbar";

/** Props for the SkillSetLibrary component */
type SkillSetLibraryProps = {
  /** Callback function for selecting a skill set */
  onSelectSkillSet: (skillSet: SelectableSkillSet) => void;
  /** Callback function for selecting a skill */
  onSelectSkill: (skill: SelectableSkill) => void;
  /** Callback function for changing search */
  onSearchChange: (searchQuery: string) => void;
  /** Array of skill sets */
  skillSets: SelectableSkillSet[];
  /** Current search query */
  searchQuery: string;
  /** Whether the user is using a mobile device */
  isMobile: boolean;
  /** Display skill set card checkbox on click */
  showSkillSetCardCheckbox?: boolean;
};

/** SkillSetLibrary component */
export const SkillSetLibrary: React.FC<SkillSetLibraryProps> = (props) => {
  return (
    <Box data-testid="skill-set-library" height="100%" display="flex" flexDirection="column">
      <Grid templateColumns="repeat(2, 1fr)" paddingX="6" columnGap="4" rowGap="6" marginBottom="6">
        <GridItem colSpan={2}>
          <CommonInput
            placeholder="Filter Skill Sets by name or Skill name"
            value={props.searchQuery}
            onChange={(value) => props.onSearchChange(value)}
            dataTestId="search-skill-sets"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
      </Grid>
      <CommonScrollbar
        overflow="auto"
        isMobile={props.isMobile}
        invisibleBorderWidth="0px 8px 0px 0px"
        paddingRightWhenOverflowing="2"
        paddingRightWhenNotOverflowing="6"
        paddingLeft="6"
        paddingBottom="6"
      >
        <Stack direction="column" spacing="6">
          {props.skillSets.length === 0 ? (
            <Text fontSize="large" textAlign="center">
              No skill sets
            </Text>
          ) : (
            props.skillSets.map((skillSet, index) => (
              <SkillSetDisplayCard
                key={index}
                skillSet={skillSet}
                onSelectSkillSet={props.onSelectSkillSet}
                onSelectSkill={props.onSelectSkill}
                showCheckbox={props.showSkillSetCardCheckbox}
                isMobile={props.isMobile}
                dataTestId={`skill-set-card-${index}`}
              />
            ))
          )}
        </Stack>
      </CommonScrollbar>
    </Box>
  );
};
