import { extendTheme } from "@chakra-ui/react";
import "./app.css";

const breakpoints = {
  mobile: "0rem",
  laptop: "80rem",
  base: null,
  sm: null,
  md: null,
  lg: null,
  xl: null,
  "2xl": null,
};

const customStepper = {
  baseStyle: {
    indicator: {
      '&[data-status="active"]': {
        borderColor: "orange.400",
        color: "white",
      },
      '&[data-status="complete"]': {
        backgroundColor: "orange.400",
        color: "white",
      },
    },
    separator: {
      '&[data-status="complete"]': {
        backgroundColor: "orange.400",
      },
    },
    icon: {
      color: "white",
    },
  },
};

export const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      body: {
        fontFamily: "Lato",
      },
      "p, a, div, button, input, header, textarea": {
        color: "blackAlpha.800",
      },
      _placeholder: {
        color: "inherit !important",
        opacity: "0.6 !important",
      },
    },
  },
  components: {
    Stepper: customStepper,
  },
});
