import React, { createContext, useContext, useEffect, useState } from "react";
import { Center } from "../../../types";
import { sortNames } from "../../utils/dataProcessing";

/** Type definition for the global context state */
type GlobalContextType = {
  /** The selected center in calendar, stored in local storage to persist the information */
  globalSelectedCenter: Center | null;
  /** Function to store the local storage information of selected center in calendar */
  globalSetSelectedCenter: React.Dispatch<React.SetStateAction<Center | null>>;
  /** All centers in the system, stored in local storage to persist the information */
  globalSetAllCenters?: React.Dispatch<React.SetStateAction<Center[] | null>>;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

/** GlobalProvider component that provides global state for centers. */
export const GlobalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [globalAllCenters, globalSetAllCenters] = useState<Center[] | null>(null);
  const [globalSelectedCenter, globalSetSelectedCenter] = useState<Center | null>(null);

  /** Load centers from localStorage on mount. */
  useEffect(() => {
    const storedAllCenters = localStorage.getItem("allCenters");
    const storedCenter = localStorage.getItem("selectedCenter");
    if (storedCenter) {
      globalSetSelectedCenter(JSON.parse(storedCenter));
    }
    if (storedAllCenters) {
      globalSetAllCenters(JSON.parse(storedAllCenters));
    }
  }, []);

  /** Store the selected center in localStorage whenever it changes. */
  useEffect(() => {
    if (globalSelectedCenter) {
      const sortedVenues = [...globalSelectedCenter.venues].sort((a, b) => sortNames(a.name, b.name));
      const sortedCenter = { ...globalSelectedCenter, venues: sortedVenues };
      localStorage.setItem("selectedCenter", JSON.stringify(sortedCenter));
    }
  }, [globalSelectedCenter]);

  /** Store all centers in localStorage whenever they change. */
  useEffect(() => {
    if (globalAllCenters?.length && globalAllCenters.length > 0) {
      const sortedCenters = globalAllCenters.map((center) => {
        const sortedVenues = [...center.venues].sort((a, b) => sortNames(a.name, b.name));
        return { ...center, venues: sortedVenues };
      });
      localStorage.setItem("allCenters", JSON.stringify(sortedCenters));
    } else {
      localStorage.setItem("allCenters", JSON.stringify(globalAllCenters));
    }
  }, [globalAllCenters]);

  return (
    <GlobalContext.Provider
      value={{
        globalSelectedCenter,
        globalSetSelectedCenter,
        globalSetAllCenters,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

/**
 * Custom hook to use the global context.
 *
 * @returns The global context.
 * @throws Will throw an error if used outside of a GlobalProvider.
 */
export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
