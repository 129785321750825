import React, { createContext, useContext, useState } from "react";
import { AlertComponent } from "./components/AlertComponent";
import { createPortal } from "react-dom";

/** Type definition for the context value used in the AlertContext. */
export type AlertContextType = {
  /** Function to show the alert with the specified message, severity, and optional auto-hide duration. */
  showAlert: (
    message: string,
    severity: "success" | "info" | "warning" | "error",
    autoHideDuration?: number,
    isIndeterminate?: boolean,
  ) => void;
  /** Function to hide the alert. */
  hideAlert: () => void;
};

/** Create a context for the alert system. */
const AlertContext = createContext<AlertContextType | undefined>(undefined);

/** Custom hook to use the alert context. Throws an error if used outside of an AlertProvider. */
export const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

/** Props for the AlertProvider component. */
type AlertProviderProps = {
  /** Child components to be wrapped by the AlertProvider. */
  children: React.ReactNode;
};

/** Provider component to manage alert state and provide context to its children. */
export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [message, setMessage] = useState("-");
  const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error">("info");
  const [open, setOpen] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState<number | undefined>(undefined);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  /**
   * Show an alert with the specified message and severity.
   *
   * @param message - The message to display in the alert.
   * @param severity - The severity level of the alert.
   * @param autoHideDuration - Optional duration in milliseconds after which the alert should automatically hide.
   */
  const showAlert = (
    message: string,
    severity: "success" | "info" | "warning" | "error",
    autoHideDuration?: number,
    isIndeterminate?: boolean,
  ) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
    setAutoHideDuration(autoHideDuration);
    setIsIndeterminate(isIndeterminate || false);
  };

  /** Hide the alert and reset the message. */
  const hideAlert = () => {
    setMessage("-");
    setOpen(false);
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {open &&
        createPortal(
          <AlertComponent
            message={message}
            severity={severity}
            hideAlert={hideAlert}
            autoHideDuration={autoHideDuration}
            isIndeterminate={isIndeterminate}
          />,
          document.body,
        )}
    </AlertContext.Provider>
  );
};
