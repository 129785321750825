import React, { useState, useEffect } from "react";
import { Box, CircularProgress, keyframes } from "@chakra-ui/react";

/** Keyframes for the fade-in animation of the loading container */
export const loadingContainerFadeIn = keyframes`
  from { opacity: 0; transform: translateY(-50px); }
  to { opacity: 1; transform: translateY(0); }
`;

/** Keyframes for the fade-out animation of the loading container */
export const loadingContainerFadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(50px); }
`;

/** Props for the LoadingContainer component */
export type LoadingContainerProps = {
  /** Whether the loading container should be displayed */
  display: boolean;
};

/** LoadingContainer component that shows a loading spinner with fade-in and fade-out animations. */
export const LoadingContainer: React.FC<LoadingContainerProps> = (props) => {
  const [shouldRender, setShouldRender] = useState(props.display);

  useEffect(() => {
    if (!props.display) {
      // Delay the removal of the loading container to allow the fade-out animation to play
      const timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timeoutId);
    } else {
      setShouldRender(true);
    }
  }, [props.display]);

  const animation = props.display ? `${loadingContainerFadeIn} 0.3s` : `${loadingContainerFadeOut} 0.5s`;

  return (
    shouldRender && (
      <Box
        display="flex"
        width="100%"
        height="calc(100svh - 136px)"
        justifyContent="center"
        alignItems="center"
        borderRadius="md"
        bgColor="blackAlpha.50"
        animation={animation}
        data-testid="loading-container"
      >
        <CircularProgress isIndeterminate capIsRound color="orange.400" size="88px" thickness="8px" />
      </Box>
    )
  );
};
