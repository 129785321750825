import React, { useState } from "react";
import { FmdBadTwoTone, TimesOneMobiledata, Repeat } from "@mui/icons-material";
import { EventContentArg } from "fullcalendar";
import { Box, Grid, GridItem, Icon, Stack, Text, Tooltip } from "@chakra-ui/react";
import { type Coach, type Organization } from "../../../../../../types";
import { extractUtcTime } from "../../../../../../common/utils/dateAndTime";
import { LoadingOverlay } from "../../../../../../common/components/LoadingOverlay";
import type { FullCalendarEventExtendedProps } from "../../../../types";

type CommonEventContentProps = {
  args: EventContentArg;
  organization?: Organization;
};

/** Renders the content of an event in the calendar. */
export const CommonEventContent = (props: CommonEventContentProps) => {
  const [openTooltip, setOpenTooltip] = useState<string | null>(null);

  const startHour = extractUtcTime(new Date(props.args.event.start || "").toISOString());
  const endHour = extractUtcTime(new Date(props.args.event.end || "").toISOString());
  const durationInMinutes =
    ((props.args.event.end?.valueOf() || 0) - (props.args.event.start?.valueOf() || 0)) / 1000 / 60;

  const extendedProps = props.args.event.extendedProps as FullCalendarEventExtendedProps;
  const name = extendedProps.eventName;
  const isLoading = extendedProps.eventIsLoading;
  const trainingEventType = extendedProps.eventType;

  const expectedAthletesPerTrainingEvent = props.organization?.expectedAthletesPerTrainingEvent || 4;
  const freeSpots = extendedProps.eventAthletes
    ? expectedAthletesPerTrainingEvent - extendedProps.eventAthletes.length
    : undefined;

  const handleTooltipOpen = (id: string) => {
    setOpenTooltip(id);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  return (
    <Box height="100%">
      <LoadingOverlay display={isLoading} />

      <Tooltip
        isOpen={openTooltip === "main"}
        onOpen={() => handleTooltipOpen("main")}
        onClose={handleTooltipClose}
        label={
          <Stack direction="column" spacing="1">
            <Text color="white">{`Training Group: ${name || "No group name"}`}</Text>
            <Text color="white">{`Time: ${startHour} - ${endHour}`}</Text>
            <Text color="white">{`Venue: ${extendedProps.eventVenue?.name || "No venue"}`}</Text>
            <Text color="white">{`Coach: ${extendedProps.eventCoaches?.map((coach: Coach) => coach.name).join(", ") || "No coach"}`}</Text>
            <Text color="white">{`Athletes: ${extendedProps.eventAthletes?.map((athlete: any) => athlete.name).join(", ") || "No athletes"}`}</Text>
          </Stack>
        }
        hasArrow
        placement="top"
      >
        <Grid
          templateColumns={{ mobile: "1fr auto", laptop: "auto 1fr auto" }}
          width="100%"
          templateRows={durationInMinutes >= 90 ? "auto 1fr" : "auto"}
          alignContent="start"
          columnGap="4"
          rowGap="2"
          padding="2"
          height="100%"
          overflow="hidden"
          shadow="md"
          cursor="pointer"
          borderRadius="md"
          border="1px solid"
          borderColor="whiteAlpha.700"
          backgroundColor="teal.800"
          transition="all 0.3s ease"
          _hover={{
            shadow: "base",
            backgroundColor: "teal.700",
            borderColor: "teal.700",
          }}
          data-testid="common-event-content"
        >
          {/* Always display group name first */}
          <GridItem
            colSpan={{ mobile: 1, laptop: 2 }}
            alignSelf={durationInMinutes < 60 ? "center" : undefined}
            overflow="hidden"
          >
            <Text
              fontSize="medium"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              color="white"
              overflow="hidden"
              textAlign="left"
            >
              {name || "No group name"}
            </Text>
          </GridItem>

          {/* Display icons on third column if event is 60 minutes or over */}
          {durationInMinutes >= 60 && (
            <GridItem colSpan={1} overflow="hidden">
              <Stack direction="row" spacing="1">
                {freeSpots !== undefined && freeSpots > 0 && (
                  <Tooltip
                    isOpen={openTooltip === "freeSpots"}
                    onOpen={() => handleTooltipOpen("freeSpots")}
                    onClose={handleTooltipClose}
                    label={`${freeSpots} free spot${freeSpots >= 2 ? "s" : ""}`}
                    hasArrow
                    placement="top"
                  >
                    <Icon as={FmdBadTwoTone} color="orange.400" />
                  </Tooltip>
                )}
                {trainingEventType === "weekly" ? (
                  <Tooltip
                    isOpen={openTooltip === "weekly"}
                    onOpen={() => handleTooltipOpen("weekly")}
                    onClose={handleTooltipClose}
                    label="Weekly training"
                    hasArrow
                    placement="top"
                  >
                    <Icon as={Repeat} color="orange.400" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    isOpen={openTooltip === "single"}
                    onOpen={() => handleTooltipOpen("single")}
                    onClose={handleTooltipClose}
                    label="One-time training"
                    hasArrow
                    placement="top"
                  >
                    <Icon as={TimesOneMobiledata} color="orange.400" />
                  </Tooltip>
                )}
              </Stack>
            </GridItem>
          )}

          {/* Display venue name on second row if event is 90 minutes or over */}
          {durationInMinutes >= 90 && (
            <GridItem colSpan={1} overflow="hidden">
              <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="white">
                {extendedProps.eventVenue?.name || "No venue"}
              </Text>
            </GridItem>
          )}

          {/* Display coach names on second row if event is 90 minutes or over, unless mobile */}
          {durationInMinutes >= 90 && (
            <GridItem colSpan={2} display={{ mobile: "none", laptop: "block" }} overflow="hidden">
              <Text
                fontSize="medium"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                color="white"
                textAlign="left"
              >
                {extendedProps.eventCoaches?.map((coach: Coach) => coach.name).join(", ") || "No coach"}
              </Text>
            </GridItem>
          )}
        </Grid>
      </Tooltip>
    </Box>
  );
};
