import type FullCalendar from "@fullcalendar/react";
import { Session, type Drill, type SkillSet } from "../types";

/**
 * Filters the provided sessions based on a search query and tags.
 *
 * The session matches the search query if the query is included in the session's name, goal, or any of the drills'
 * names. The comparison is case insensitive.
 *
 * The session matches the tags if it has any of the provided tags. The comparison is case insensitive.
 *
 * A session is included in the results if it matches both the search query and the tags.
 *
 * @param {Session[]} sessions - The sessions to filter.
 * @param {string} searchQuery - The search query.
 * @param {string[]} searchTags - The tags to filter by.
 * @param {SkillSet[]} searchSkillSets - The skill sets to filter by.
 * @returns {Session[]} The filtered sessions.
 */
export const filterSessions = (
  sessions: Session[],
  searchQuery: string,
  searchTags: string[],
  searchSkillSets: SkillSet[],
) => {
  return sessions.filter((session) => {
    // Check if the session matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      session.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      session.goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      session.sessionDrills.some((sessionDrill) =>
        sessionDrill.drill.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );

    // Check if the session matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => session.tags?.includes(searchTag));
    // Check if the session matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      session.skillSets?.some((sessionSkillSet) => sessionSkillSet.id === searchSkillSet.id),
    );

    // Return the session if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });
};

/**
 * Filters the provided drills based on a search query, tags, and skill sets.
 *
 * The drill matches the search query if the query is included in the drill's name, goal, or implementation. The
 * comparison is case insensitive.
 *
 * The drill matches the tags if it has any of the provided tags. The comparison is case insensitive.
 *
 * The drill matches the skill sets if it contains any of the provided skill sets.
 *
 * A drill is included in the results if it matches the search query, tags, and skill sets.
 *
 * @param {Drill[]} drills - The drills to filter.
 * @param {string} searchQuery - The search query.
 * @param {string[]} searchTags - The tags to filter by.
 * @param {SkillSet[]} searchSkillSets - The skill sets to filter by.
 * @returns {Drill[]} The filtered drills.
 */
export const filterDrills = (
  drills: Drill[],
  searchQuery: string,
  searchTags: string[],
  searchSkillSets: SkillSet[],
): Drill[] => {
  return drills.filter((drill) => {
    // Check if the drill matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      drill.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.goal?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.implementation?.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if the drill matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => drill.tags?.includes(searchTag));

    // Check if the drill matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      drill.skillSets?.some((drillSkillSet) => drillSkillSet.id === searchSkillSet.id),
    );

    // Return the drill if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });
};

/**
 * Converts a given input to a UTC string.
 *
 * @example
 *   toUTCString("12:30"); // Expected: An ISO string representation of today's date at 12:30 UTC.
 *   toUTCString(new Date()); // Expected: An ISO string representation of the given Date object.
 *
 * @param input - A string in the format 'HH:mm' or a Date object.
 * @returns A string representation of the date in the ISO format.
 * @throws {Error} Throws an error if the input type is neither a string nor a Date object.
 */
export const toUTCString = (input: string | Date): string => {
  let date: Date;

  if (typeof input === "string") {
    // Create a new Date object with today's date and the time provided in the input
    const [hours, minutes] = input.split(":").map(Number);
    date = new Date();
    date.setUTCHours(hours, minutes, 0, 0);
  } else if (input instanceof Date) {
    date = input;
  } else {
    throw new Error("Invalid input type");
  }

  return date.toISOString();
};

type GraphQLErrorType = {
  networkError?: any;
  graphQLErrors?: { message: string; path: string[] }[];
};

const handleGraphQLError = (error: GraphQLErrorType): string[] => {
  const errorMessages: string[] = [];

  if (error.networkError) {
    const errors = error.networkError.result?.errors || [];
    const hasBadUserInput = errors.some((errorItem: any) => errorItem.extensions?.code === "BAD_USER_INPUT");
    if (hasBadUserInput) {
      const regex = /Field "(.+)" of required type "(.+)" was not provided/;
      errors.forEach((errorItem: any) => {
        if (errorItem.extensions?.code === "BAD_USER_INPUT") {
          const message = errorItem.message;
          const match = message.match(regex);

          if (match && match.length > 1) {
            const missingField = match[1];
            errorMessages.push(`Invalid data, please check the input and try again. Missing field: "${missingField}"`);
          } else {
            errorMessages.push("Invalid data, please check the input and try again");
          }
        }
      });
    } else {
      errorMessages.push("Network connection error");
    }
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    error.graphQLErrors.forEach((gqlError) => {
      errorMessages.push("GraphQL error: " + gqlError.message + " | path:" + gqlError.path[0]);
    });
  }

  return errorMessages;
};

const handleGeneralError = (error: any): string => {
  return error?.message || "An unexpected error occurred";
};

export const handleError = (errors: (GraphQLErrorType | any)[]): string => {
  let allErrorMessages: string[] = [];

  errors.forEach((error) => {
    console.log({ error });
    if (error?.graphQLErrors || error?.networkError) {
      allErrorMessages = [...allErrorMessages, ...handleGraphQLError(error)];
    } else {
      allErrorMessages.push(handleGeneralError(error));
    }
  });

  return "Error(s) occurred:\n" + allErrorMessages.map((e) => `- ${e}`).join("\n");
};

/**
 * Sorts two strings by combining lexical and numerical sorting.
 *
 * @remarks
 *   This function breaks down the input strings into numeric and non-numeric segments. It goes through each segment of
 *   both strings and compares them either lexically or numerically, depending on the type of the segment.
 * @example
 *   ```typescript
 *   const arr = ['Court 10', 'Court 2', 'Court 1'];
 *   arr.sort(sortNames);
 *   console.log(arr); // Output: ["Court 1", "Court 2", "Court 10"]
 *   ```;
 *
 * @param a - The first string to sort.
 * @param b - The second string to sort.
 * @returns Returns a sorting value. Negative means `a` comes before `b`. Positive means `b` comes before `a`. Zero
 *   means they are equivalent.
 */
export const sortNames = (a: string, b: string): number => {
  const regex = /(\d+|\D+)/g;
  const aFragments = a.match(regex);
  const bFragments = b.match(regex);

  if (!aFragments || !bFragments) return 0;

  for (let i = 0; i < Math.min(aFragments.length, bFragments.length); i++) {
    if (isNaN(Number(aFragments[i])) || isNaN(Number(bFragments[i]))) {
      const lexicalOrder = aFragments[i].localeCompare(bFragments[i]);
      if (lexicalOrder !== 0) return lexicalOrder;
    } else {
      const numericOrder = Number(aFragments[i]) - Number(bFragments[i]);
      if (numericOrder !== 0) return numericOrder;
    }
  }
  return aFragments.length - bFragments.length;
};

/**
 * Updates the UTC time (hours and minutes) of an ISO date string while keeping the day and timezone intact.
 *
 * @param {string} isoDate - The ISO date string in UTC to update.
 * @param {string} time - The time string in "HH:mm" format to set.
 * @returns {string} - The updated ISO date string with the new time in UTC.
 * @throws {Error} - If the provided ISO date string is not in UTC format.
 */
export const updateUTCTime = (isoDate: string, time: string): string => {
  if (!isoDate.endsWith("Z") || isoDate.length !== 24) {
    throw new Error("The provided ISO date string is not in UTC format.");
  }

  const date = new Date(isoDate);
  const [hours, minutes] = time.split(":").map(Number);

  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);

  return date.toISOString();
};

/**
 * Formats a given Date object into a UTC ISO string with time set to 00:00:00.000Z.
 *
 * @param date - The Date object to format.
 * @returns The formatted UTC date string (YYYY-MM-DDT00:00:00.000Z).
 */
export const formatDateToUTC = (date?: Date): string => {
  if (!date) {
    throw new Error("No date provided");
  }
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so +1 is needed
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}T00:00:00.000Z`;
};

/**
 * Extracts local hours and minutes from a date string and ensures they are formatted as two digits.
 *
 * @param dateString - The date string to extract the time from.
 * @returns A string in 'HH:MM' format.
 */
export const extractFormattedTime = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

/**
 * Extracts the date part from a datetime string in the UTC format.
 *
 * @param dateTimeString - The datetime string to extract the date from.
 * @param separator - Optional separator to replace the default '-' in the date format.
 * @param dayoToYear - If true, returns the date in the format 'DD-MM-YYYY' instead of 'YYYY-MM-DD'.
 * @returns The date part of the datetime string in the format 'YYYY-MM-DD' or 'DD-MM-YYYY' based on the dayoToYear
 *   flag.
 * @throws Error if the provided datetime string is not in a valid UTC format.
 */
export const extractDateFromUTC = (dateTimeString: string, separator?: string, dayoToYear?: boolean): string => {
  if (!dateTimeString.endsWith("Z") || dateTimeString.length !== 24) {
    throw new Error(`The provided ISO date string ${dateTimeString} is not in UTC format.`);
  }

  let date = dateTimeString.substring(0, 10); // Extracts YYYY-MM-DD

  if (dayoToYear) {
    // Rearranges the date to DD-MM-YYYY
    const [year, month, day] = date.split("-");
    date = `${day}-${month}-${year}`;
  }

  if (separator) {
    return date.replaceAll("-", separator);
  } else {
    return date;
  }
};

/**
 * Extracts the time part from a datetime string in the UTC format.
 *
 * @param dateTimeString - The datetime string to extract the time from.
 * @returns The time part of the datetime string in the format 'HH:MM'.
 */
export const extractTimeFromUTC = (dateTimeString: string): string => {
  if (!dateTimeString.endsWith("Z") || dateTimeString.length !== 24) {
    throw new Error(`The provided ISO date string ${dateTimeString} is not in UTC format.`);
  }
  return dateTimeString.substring(11, 16);
};

/**
 * Adjusts the given date based on the view type and direction, and updates calendar references accordingly.
 *
 * @param prevDate - The previous date to be adjusted.
 * @param forward - A boolean indicating whether to move forward or backward.
 * @param activeView - The current active view type ('timeGridDay', 'timeGridWeek', 'dayGridMonth').
 * @param calendarRefs - A reference to an array of FullCalendar instances.
 * @returns The new adjusted date.
 */
export const getNewDate = (
  prevDate: Date,
  forward: boolean,
  activeView: string,
  calendarRefs: React.MutableRefObject<(FullCalendar | null)[]>,
): Date => {
  const newDate = new Date(prevDate);
  const direction = forward ? 1 : -1;

  switch (activeView) {
    case "timeGridDay":
      newDate.setDate(newDate.getDate() + direction);
      const dayOfWeek = newDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        // Skip weekends
        newDate.setDate(newDate.getDate() + 2 * direction);
      }
      break;

    case "timeGridWeek":
      newDate.setDate(newDate.getDate() + 7 * direction);
      break;

    case "dayGridMonth":
      newDate.setMonth(newDate.getMonth() + direction);
      break;

    default:
      throw new Error(`Unknown view type: ${activeView}`);
  }

  calendarRefs.current.forEach((calendarRef) => {
    calendarRef?.getApi().gotoDate(newDate);
  });

  return newDate;
};

/**
 * Returns a set of common styles for various common components.
 *
 * @param hasValue - A boolean that determines if the component has a value and different style should be applied.
 * @returns An object containing common styles
 */
export const commonStyles = (hasValue: boolean) => ({
  border: "1px solid",
  borderRadius: "md",
  boxShadow: hasValue ? "none" : "sm",
  borderColor: hasValue ? "orange.400" : "blackAlpha.300",
  transition: "all 0.3s ease",
  _hover: { borderColor: "orange.500", boxShadow: "none" },
  _focusWithin: { borderColor: "orange.500", boxShadow: "none" },
});

/** Returns a set of common styles for tags. */
export const tagStyles = () => ({
  size: "md",
  borderRadius: "full",
  variant: "solid",
  backgroundColor: "orange.400",
  transition: "all 0.3s ease",
  _hover: { backgroundColor: "orange.500" },
});

/** Returns a set of common styles for cards */
export const cardStyles = ({
  disableClick,
  disableHover,
  selected,
}: {
  disableClick?: boolean;
  disableHover?: boolean;
  selected?: boolean;
}) => ({
  border: "1px solid",
  borderColor: {
    mobile: selected ? "orange.400" : "blackAlpha.300",
    laptop: selected ? "orange.400" : "blackAlpha.300",
  },
  borderRadius: "md",
  transition: "all 0.3s ease",
  _hover: disableHover
    ? undefined
    : {
        borderColor: { mobile: undefined, laptop: selected ? "orange.400" : "blackAlpha.300" },
        boxShadow: { mobile: undefined, laptop: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C" }, // lg blackAlpha.500
        cursor: { mobile: undefined, laptop: disableClick ? "default" : "pointer" },
      },
  _focusWithin: disableHover
    ? undefined
    : {
        borderColor: { mobile: undefined, laptop: selected ? "orange.400" : "blackAlpha.300" },
        boxShadow: { mobile: undefined, laptop: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C" }, // lg blackAlpha.500
        cursor: { mobile: undefined, laptop: disableClick ? "default" : "pointer" },
      },
});
