import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_ORGANIZATIONS } from "../graphql/get-all-organizations.query";
import { SAVE_ORGANIZATION } from "../graphql/save-organization.mutation";
import { REGISTER } from "../graphql/register.mutation";
import { useAlert } from "../../../common/components/AlertProvider";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Box } from "@chakra-ui/react";
import { Admin } from "../components/Admin";
import { GET_ORGANIZATION_USAGE_STATS } from "../graphql/get-organization-usage-stats.query";
import { ADMIN_CREATE_INVITE } from "../graphql/admin-create-invite.mutation";
import { ADMIN_GET_ALL_INVITES } from "../graphql/admin-get-all-invites.query";

/** AdminContainer handles the communication with the server and passes necessary props to AdminComponent. */
export const AdminContainer: React.FC = () => {
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_ALL_ORGANIZATIONS);

  const {
    data: organizationUsageStatsData,
    loading: organizationUsageStatsLoading,
    error: organizationUsageStatsError,
  } = useQuery(GET_ORGANIZATION_USAGE_STATS);

  const { data: inviteData, loading: inviteLoading, error: inviteError } = useQuery(ADMIN_GET_ALL_INVITES);

  const { execute: saveOrganizationMutation, loading: saveOrganizationLoading } = useAsyncMutation(SAVE_ORGANIZATION, {
    refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
    awaitRefetchQueries: true,
  });

  const { execute: registerNewUserMutation, loading: registerNewUserLoading } = useAsyncMutation(REGISTER, {
    refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
    awaitRefetchQueries: true,
  });

  const { execute: createInviteMutation, loading: createInviteLoading } = useAsyncMutation(ADMIN_CREATE_INVITE, {
    refetchQueries: [{ query: ADMIN_GET_ALL_INVITES }],
    awaitRefetchQueries: true,
  });

  const { showAlert } = useAlert();
  const isLoading =
    organizationLoading ||
    saveOrganizationLoading ||
    registerNewUserLoading ||
    organizationUsageStatsLoading ||
    inviteLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setShowContent(true), 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([organizationError, organizationUsageStatsError, inviteError]);

  const saveOrganization = async (organizationInput: any) => {
    if (organizationInput.id) {
      showAlert("Updating Organization", "info", undefined, true);
    } else {
      showAlert("Creating new Organization", "info", undefined, true);
    }

    const result = await saveOrganizationMutation({ data: organizationInput });

    if (result) {
      if (organizationInput.id) {
        showAlert("Organization updated!", "success", 5000);
      } else {
        showAlert("Organization created!", "success", 5000);
      }
    }
  };

  const registerNewUser = async (userData: any) => {
    showAlert("Registering new user", "info", undefined, true);

    const result = await registerNewUserMutation({ ...userData });
    if (result) {
      showAlert("User registered successfully!", "success", 5000);
    }
  };

  const createInvite = async (receiver?: string) => {
    showAlert("Creating invite...", "info", undefined, true);

    const result = await createInviteMutation({ data: { receiver } });

    if (result) {
      showAlert(`Invite created successfully! Link: ${result.adminCreateInvite}`, "success");
    }
  };

  return (
    <Box>
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}>
          <Admin
            allOrganizations={organizationData?.getAllOrganizations || []}
            allUsageStats={organizationUsageStatsData?.getOrganizationUsageStats || []}
            allInvites={inviteData?.adminGetAllInvites || []}
            saveOrganization={saveOrganization}
            registerNewUser={registerNewUser}
            createInvite={createInvite}
            createInviteLoading={createInviteLoading}
          />
        </Box>
      )}
    </Box>
  );
};
