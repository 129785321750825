import React, { useEffect, useRef, useState } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { OverflowIndicator } from "./components/OverflowIndicator";

type CommonScrollbarProps = BoxProps & {
  /** Child elements to display inside the scrollable container */
  children: React.ReactNode;
  /** Color of the scrollbar border, defaults to white */
  scrollbarBorderColor?: string;
  /** Indicates if the user is using a mobile device */
  isMobile?: boolean;
  /** Customize the invisible border of thumb, instead of default left 8px */
  invisibleBorderWidth?: string;
  /**
   * PaddingRight when content is overflowing. Prop "paddingRightWhenNotOverflowing" needs to be provided as well to
   * have effect with this
   */
  paddingRightWhenOverflowing?: BoxProps["paddingRight"];
  /**
   * PaddingRight when content is not overflowing. Prop "paddingRightWhenOverflowing" needs to be provided as well to
   * have effect with this
   */
  paddingRightWhenNotOverflowing?: BoxProps["paddingRight"];
};

/**
 * CustomScrollbar component adds a stylized scrollbar to its children, and displays OverflowIndicator on hover. It
 * extends BoxProps, allowing Chakra UI Box styling properties.
 */
export const CommonScrollbar: React.FC<CommonScrollbarProps> = ({
  children,
  scrollbarBorderColor,
  isMobile,
  invisibleBorderWidth,
  paddingRightWhenOverflowing,
  paddingRightWhenNotOverflowing,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    /* FIXME: improve this, as it flickers because it will be done after render.
    If nothing else, hide it with animation like on player page */
    const { current } = containerRef;
    if (current) {
      const hasOverflow = current.scrollHeight > current.clientHeight;
      setIsOverflowing(hasOverflow);
    }
  }, [children]);

  return isMobile ? (
    children
  ) : (
    <Box
      ref={containerRef}
      paddingRight={
        (paddingRightWhenOverflowing &&
          paddingRightWhenNotOverflowing &&
          (isOverflowing ? paddingRightWhenOverflowing : paddingRightWhenNotOverflowing)) ||
        undefined
      }
      _hover={{
        ".overflowIcon": {
          opacity: 0.25,
        },
      }}
      css={{
        "&::-webkit-scrollbar": {
          width: "16px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#00000029", // blackAlpha.300
          borderStyle: "solid",
          borderWidth: invisibleBorderWidth || "0px 0px 0px 8px",
          borderColor: scrollbarBorderColor || "white",
          backgroundClip: "padding-box",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#0000007A", // blackAlpha.600
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "inherit",
        },
      }}
      {...props}
    >
      {children}
      <OverflowIndicator containerRef={containerRef} />
    </Box>
  );
};
