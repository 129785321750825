import { gql } from "@apollo/client";

export const GET_ATHLETE_NOTES = gql`
  query GetAthleteNotes($id: String!) {
    getAthleteNotes(id: $id) {
      id
      content
      createdAt
      isGroupNote
      createdBy {
        id
        username
      }
      athletes {
        id
        name
      }
      trainingEventDetails {
        eventId
        eventStartDateTime
        eventEndDateTime
        eventCenter
        eventVenue
        eventTrainingGroupName
        eventCoaches
      }
    }
  }
`;
