import React from "react";
import { Stack, Box, Text, Divider } from "@chakra-ui/react";
import { MultiTagSelectMenu } from "../../../../../common/components/MultiTagSelectMenu";
import { SwitchWithLabel } from "../../../../../common/components/SwitchWithLabel/SwitchWithLabel";
import { CalendarViewControls, type CalendarViewControlsProps } from "./components/CalendarViewControls";

/** Props for the CalendarHeader component. */
type CalendarHeaderProps = CalendarViewControlsProps & {
  /** All available coaches. */
  allCoaches: Array<{ name: string }>;
  /** Selected coaches. */
  selectedCoaches: Array<{ name: string }>;
  /** Filter to show only free spots. */
  showOnlyFreeSpots: boolean;
  /** Filter to show only recurring training events. */
  showOnlyRecurringTrainingEvents: boolean;
  /** Filter to show only single training events. */
  showOnlySingleTrainingEvents: boolean;
  /** State to indicate if the draggable To-do is open. */
  isDraggableTodoOpen: boolean;
  /** Function to set selected coaches. */
  setSelectedCoaches: (coaches: Array<{ name: string }>) => void;
  /** Function to toggle showing only free spots. */
  setShowOnlyFreeSpots: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only recurring training events. */
  setShowOnlyRecurringTrainingEvents: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only single training events. */
  setShowOnlySingleTrainingEvents: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle the draggable To-do. */
  setIsDraggableTodoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
};

/** CalendarHeader component to control and filter calendar view settings. */
export const CalendarHeader: React.FC<CalendarHeaderProps> = (props) => {
  return (
    <Box id="calendar-header" data-testid="calendar-header">
      <Stack
        direction={{ mobile: "column", laptop: "row" }}
        spacing={{ mobile: "3", laptop: "4" }}
        justifyContent="center"
        alignItems="center"
        data-testid="calendar-filter-controls"
      >
        <MultiTagSelectMenu
          selectedTags={props.selectedCoaches}
          handleTagSelection={props.setSelectedCoaches}
          options={props.allCoaches}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          label="Filter by coach"
          width={props.isMobile ? "100%" : "80"}
          dataTestId="coach-filter"
        />
        <SwitchWithLabel
          checked={props.showOnlyFreeSpots}
          label="Free spots only"
          onChange={() => props.setShowOnlyFreeSpots((prev) => !prev)}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="free-spots-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlyRecurringTrainingEvents}
          label="Recurring trainings only"
          onChange={() => {
            props.setShowOnlyRecurringTrainingEvents((prev) => !prev);
            if (props.showOnlySingleTrainingEvents) {
              props.setShowOnlySingleTrainingEvents(false);
            }
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="recurring-events-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlySingleTrainingEvents}
          label="Single trainings only"
          onChange={() => {
            props.setShowOnlySingleTrainingEvents((prev) => !prev);
            if (props.showOnlyRecurringTrainingEvents) {
              props.setShowOnlyRecurringTrainingEvents(false);
            }
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="single-events-switch"
        />
      </Stack>
      {!props.globalSelectedClub && (
        <Text textAlign="center" marginTop="12" fontSize="large" data-testid="no-club-message">
          Select an active club from Settings to view the Calendar
        </Text>
      )}
      <Divider marginY="6" />
      <CalendarViewControls
        activeView={props.isMobile ? "timeGridDay" : props.activeView}
        calendarRefs={props.calendarRefs}
        dayViewDate={props.dayViewDate}
        getNewDate={props.getNewDate}
        globalSelectedClub={!!props.globalSelectedClub}
        setActiveView={props.setActiveView}
        setDayViewDate={props.setDayViewDate}
        setFullCalendarKey={props.setFullCalendarKey}
        isMobile={props.isMobile}
        data-testid="calendar-view-controls"
      />
    </Box>
  );
};
