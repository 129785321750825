// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.isHovering-multi-week-event {
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.06) !important; /* base shadow */
  background-color: #285e61 !important; /* teal.700 */
  border-color: #285e61 !important; /* teal.700 */
}

.isHovering-single-week-event {
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.06) !important; /* base shadow */
  background-color: #ed8936 !important; /* orange.400 */
  border-color: #ed8936 !important; /* orange.400 */
}
`, "",{"version":3,"sources":["webpack://./src/features/SeasonPlans/components/components/SeasonPlanCalendar/components/seasonPlanEventContent.css"],"names":[],"mappings":"AAAA;EACE;;8CAE4C,EAAE,gBAAgB;EAC9D,oCAAoC,EAAE,aAAa;EACnD,gCAAgC,EAAE,aAAa;AACjD;;AAEA;EACE;;8CAE4C,EAAE,gBAAgB;EAC9D,oCAAoC,EAAE,eAAe;EACrD,gCAAgC,EAAE,eAAe;AACnD","sourcesContent":[".isHovering-multi-week-event {\n  box-shadow:\n    0px 1px 3px rgba(0, 0, 0, 0.1),\n    0px 1px 2px rgba(0, 0, 0, 0.06) !important; /* base shadow */\n  background-color: #285e61 !important; /* teal.700 */\n  border-color: #285e61 !important; /* teal.700 */\n}\n\n.isHovering-single-week-event {\n  box-shadow:\n    0px 1px 3px rgba(0, 0, 0, 0.1),\n    0px 1px 2px rgba(0, 0, 0, 0.06) !important; /* base shadow */\n  background-color: #ed8936 !important; /* orange.400 */\n  border-color: #ed8936 !important; /* orange.400 */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
