import React, { useState } from "react";
import type { Coach, Court, RecurringTrainingEvent } from "../../../../../types";
import { Box, Stack } from "@chakra-ui/react";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { CommonSelect } from "../../../../../common/components/CommonSelect";
import { TrainingGroupEditor } from "../../../../../common/components/TrainingGroupEditor";

type TrainingGroupAdderProps = {
  allTrainingEvents: RecurringTrainingEvent[];
  allCoaches: Coach[];
  allCourts: Court[];
  onSave: (eventToSave: RecurringTrainingEvent) => void;
  onCancel: () => void;
  loading?: boolean;
};

/** Component for adding the player to a new training group */
export const TrainingGroupAdder: React.FC<TrainingGroupAdderProps> = (props) => {
  const [isSelectingExistingGroup, setIsSelectingExistingGroup] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [newEvent, setNewEvent] = useState<RecurringTrainingEvent | null>(null);

  const handleSelectExistingGroup = (groupId: string) => {
    setSelectedGroupId(groupId);
  };

  const handleCreateNewGroup = () => {
    setNewEvent({
      id: undefined,
      name: "",
      recurrenceWeekday: 1,
      recurrenceStartTime: "09:00",
      recurrenceEndTime: "10:30",
      court: props.allCourts[0],
      coaches: [],
      players: [],
    });
  };

  const handleSave = () => {
    if (isSelectingExistingGroup && selectedGroupId) {
      const selectedEvent = props.allTrainingEvents.find((event) => event.id === selectedGroupId);
      if (selectedEvent) {
        props.onSave(selectedEvent);
      }
    } else if (newEvent) {
      props.onSave(newEvent);
    }
  };

  return (
    <Stack direction="column" spacing="4">
      <Stack direction="row" spacing="6" alignItems="center" justify="center">
        {/* TODO: maybe unite with ViewEventModal Tab-switcher, instead of buttons */}
        <CommonButton
          variantType={isSelectingExistingGroup ? "solidPrimary" : "outlineSecondary"}
          onClick={() => {
            setIsSelectingExistingGroup(true);
            setNewEvent(null);
          }}
        >
          Select existing group
        </CommonButton>
        <CommonButton
          variantType={!isSelectingExistingGroup ? "solidPrimary" : "outlineSecondary"}
          onClick={() => {
            setIsSelectingExistingGroup(false);
            setSelectedGroupId(null);
            handleCreateNewGroup();
          }}
          dataTestId="create-new-group"
        >
          Create new group
        </CommonButton>
      </Stack>

      {isSelectingExistingGroup ? (
        <CommonSelect
          placeholder={props.allTrainingEvents.length === 0 ? "No groups available" : "Group"}
          value={selectedGroupId || undefined}
          options={props.allTrainingEvents.map((event) => ({
            value: event.id || "",
            label: event.name || "",
          }))}
          onChange={(e) => handleSelectExistingGroup(e.target.value)}
          data-testid="select-existing-group"
        />
      ) : (
        newEvent && (
          <Box data-testid="new-recurring-training-event">
            <TrainingGroupEditor
              trainingGroup={newEvent}
              allCoaches={props.allCoaches}
              allCourts={props.allCourts}
              onChange={(editedEvent) => setNewEvent(editedEvent)}
              displayPlayers={false}
            />
          </Box>
        )
      )}

      <Stack direction="row" spacing="4" justifyContent="end">
        <CommonButton
          variantType="outlineSecondary"
          onClick={props.onCancel}
          dataTestId="cancel-new-recurring-training-event"
        >
          Cancel
        </CommonButton>
        <CommonButton
          variantType="solidPrimary"
          onClick={handleSave}
          disabled={isSelectingExistingGroup ? !selectedGroupId : !newEvent || newEvent.name?.trim() === ""}
          tooltip={
            isSelectingExistingGroup && !selectedGroupId
              ? "Group must be selected"
              : newEvent?.name?.trim() === ""
                ? "Group name cannot be empty"
                : ""
          }
          dataTestId="save-new-recurring-training-event"
        >
          Save
        </CommonButton>
      </Stack>
    </Stack>
  );
};
