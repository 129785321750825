import { Alert, AlertIcon, AlertDescription, CloseButton, Box, Progress, Stack, Text } from "@chakra-ui/react";
import React, { useState, useEffect, useMemo } from "react";

/** Props for the AlertComponent component. */
export type AlertComponentProps = {
  /** The message to be displayed in the alert. */
  message: string;
  /** The severity level of the alert. */
  severity: "success" | "info" | "warning" | "error";
  /** Function to hide the alert. */
  hideAlert: () => void;
  /** Duration in milliseconds before the alert auto-hides. */
  autoHideDuration?: number;
  /** Flag to indicate if the progress bar should be indeterminate. */
  isIndeterminate?: boolean;
};

/**
 * Get the color scheme based on the severity of the alert.
 *
 * @param severity - The severity level of the alert.
 * @returns The color scheme corresponding to the severity.
 */
const getColorScheme = (severity: string): string | undefined => {
  switch (severity) {
    case "success":
      return "green";
    case "info":
      return "gray";
    case "warning":
      return "orange";
    case "error":
      return "red";
    default:
      return undefined;
  }
};

/** AlertComponent to display alert messages with different severity levels. */
export const AlertComponent: React.FC<AlertComponentProps> = (props) => {
  const colorScheme = useMemo(() => getColorScheme(props.severity), [props.severity]);
  const [remainingTime, setRemainingTime] = useState(props.autoHideDuration || 0);
  const progressValue = props.autoHideDuration ? (remainingTime / props.autoHideDuration) * 100 : 0;

  useEffect(() => {
    setRemainingTime(props.autoHideDuration || 0);
  }, [props.autoHideDuration, props.message]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (props.autoHideDuration) {
      intervalId = setInterval(() => {
        setRemainingTime((prev) => {
          if (prev <= 10) {
            clearInterval(intervalId);
            props.hideAlert();
            return 0;
          }
          return prev - 10;
        });
      }, 10);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props.autoHideDuration, props.hideAlert, props.message]);

  /**
   * Handle the close button click event.
   *
   * @param event - The mouse event.
   */
  const handleClose = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.stopPropagation();
    props.hideAlert();
  };

  return (
    <Alert
      status={props.severity || "success"}
      colorScheme={colorScheme}
      variant="subtle"
      position="fixed"
      top={{ mobile: undefined, laptop: "90svh" }}
      bottom={{ mobile: "0", laptop: "auto" }}
      zIndex="overlay"
      width={{ mobile: "100%", laptop: "lg" }}
      borderRadius={{ mobile: undefined, laptop: "md" }}
      left={{ laptop: "65%" }}
      alignItems="stretch"
      padding="4"
      data-testid="alert"
    >
      <Stack direction="column" spacing="0" width="100%">
        <Box width="100%" display="flex">
          <AlertIcon alignSelf="center" />
          <AlertDescription flex={1} alignContent="center" whiteSpace="pre-wrap">
            {props.message}
          </AlertDescription>
          <Box height="inherit" borderLeft="1px solid" borderColor="blackAlpha.200" marginX="2" />
          <Box onClick={handleClose} alignContent="center" cursor="pointer">
            <CloseButton alignSelf="center" onClick={handleClose} data-testid="alert-close-button" />
          </Box>
        </Box>
        {(props.autoHideDuration || props.isIndeterminate) && (
          <Box width="calc(100% + 32px)" marginBottom="-16px" marginX="-16px" paddingTop="2">
            <Progress
              value={progressValue}
              size="xs"
              background={
                (props.severity === "success" && "green.100") ||
                (props.severity === "info" && "gray.100") ||
                (props.severity === "warning" && "orange.100") ||
                (props.severity === "error" && "red.100") ||
                undefined
              }
              colorScheme={colorScheme}
              isIndeterminate={props.isIndeterminate}
            />
          </Box>
        )}
      </Stack>
    </Alert>
  );
};
