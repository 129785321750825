import React, { useEffect, useState } from "react";
import { Coach, Venue, type TrainingGroup, type TrainingSeason } from "../../../../types";
import { Box, Stack, Text, Tooltip } from "@chakra-ui/react";
import { CommonButton } from "../../../../common/components/CommonButton";
import { DeleteIcon } from "@chakra-ui/icons";
import { TrainingGroupAdder } from "./components/TrainingGroupAdder";
import { ConfirmationDialog } from "../../../../common/components/ConfirmationDialog";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { SingleTrainingGroup } from "./components/SingleTrainingGroup";

/** TrainingGroups props */
export type TrainingGroupsProps = {
  /** Athlete name */
  athleteName: string;
  /** Coaches to link to Training Group */
  coaches: Coach[];
  /** Venues to link to Training Group */
  venues: Venue[];
  /** The athlete's events */
  trainingGroups: TrainingGroup[];
  /** Training groups where athlete is not included */
  nonParticipatedTrainingGroups: TrainingGroup[];
  /** All possible training groups */
  allTrainingGroups: TrainingGroup[];
  /** All possible Training Seasons */
  allTrainingSeasons: TrainingSeason[];
  /** Function to handle event save */
  onSave: (trainingGroupToSave: TrainingGroup) => void;
  /** Function to handle event remove */
  onRemove: (trainingGroupToRemove: TrainingGroup) => void;
  /** Flag to indicate whether the component is loading */
  isLoading?: boolean;
};

/** Component for displaying and editing a athlete's Training Groups */
export const TrainingGroups: React.FC<TrainingGroupsProps> = (props) => {
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
  const [trainingGroupToRemove, setTrainingGroupToRemove] = useState<TrainingGroup | null>(null);

  useEffect(() => {
    if (isAddingNewGroup) {
      // scroll to bottom of the page
      window.scrollTo({ top: document.body.scrollHeight, behavior: "auto" });
    }
  }, [isAddingNewGroup]);

  /** Function to handle saving the Training Group */
  const handleSaveGroup = (event: TrainingGroup) => {
    props.onSave(event);
    setIsAddingNewGroup(false);
  };

  /** Function to handle removing a group from the athlete's events */
  const handleRemoveGroup = (event: TrainingGroup) => {
    setTrainingGroupToRemove(null);
    props.onRemove(event);
  };

  /** Function to handle adding a new group */
  const handleAddNewGroup = () => {
    setIsAddingNewGroup(true);
  };

  /** Function to handle canceling editing or adding */
  const handleCancel = () => {
    setIsAddingNewGroup(false);
  };

  return (
    <Box position="relative" data-testid="training-groups">
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />

      <Tooltip
        label="Training Groups organize athletes into manageable teams for weekly training schedules and event planning"
        placement="auto-start"
      >
        <Text fontSize="xl" marginBottom="4">
          Training Groups ({props.trainingGroups.length})
        </Text>
      </Tooltip>

      {props.allTrainingSeasons.length === 0 ? (
        <Text textColor="blackAlpha.600">
          Training Groups require a Training Season to be assigned. Create a Training Season in the 'Management' section
          to enable group scheduling
        </Text>
      ) : (
        <Stack direction="column" spacing="8">
          {props.trainingGroups.length > 0 ? (
            props.trainingGroups
              .slice()
              .sort((a, b) => {
                const dayA = a.recurrenceWeekday === 0 ? 7 : a.recurrenceWeekday;
                const dayB = b.recurrenceWeekday === 0 ? 7 : b.recurrenceWeekday;
                return dayA - dayB || a.recurrenceStartTime.localeCompare(b.recurrenceStartTime);
              })
              .map((event, index) => (
                <Box key={JSON.stringify(event)} data-testid={`training-group-${index}`}>
                  <Stack direction="row" spacing="4">
                    <SingleTrainingGroup event={event} allVenues={props.venues} />
                    <CommonButton
                      variantType="outlineSecondary"
                      aria-label="remove"
                      hoverColor="red.600"
                      onClick={() => setTrainingGroupToRemove(event)}
                      dataTestId={`remove-training-group-${index}`}
                      tooltip={`Remove ${props.athleteName} from training group ${event.name}`}
                    >
                      <DeleteIcon color="red.600" />
                    </CommonButton>
                  </Stack>
                </Box>
              ))
          ) : (
            <Text textColor="blackAlpha.600">{`${props.athleteName} is not assigned to any Training Group. Add ${props.athleteName} to a group to schedule weekly training events`}</Text>
          )}

          {isAddingNewGroup ? (
            <TrainingGroupAdder
              nonParticipatedTrainingGroups={props.nonParticipatedTrainingGroups}
              allTrainingGroups={props.allTrainingGroups}
              allCoaches={props.coaches}
              allVenues={props.venues}
              allTrainingSeasons={props.allTrainingSeasons}
              onSave={handleSaveGroup}
              onCancel={handleCancel}
              loading={props.isLoading}
            />
          ) : (
            <Box width="fit-content" alignSelf="end">
              <CommonButton
                variantType="solidPrimary"
                onClick={handleAddNewGroup}
                disabled={props.venues.length === 0}
                tooltip={
                  props.venues.length === 0
                    ? "No venues available to create a new group, venues can be added from the 'Management' page"
                    : undefined
                }
                dataTestId="add-training-group"
              >
                {`Add ${props.athleteName} to group`}
              </CommonButton>
            </Box>
          )}
        </Stack>
      )}

      <ConfirmationDialog
        isOpen={!!trainingGroupToRemove}
        message={
          <Text>
            Please confirm that you want to remove
            <Text as="span" color="red">{` ${props.athleteName} `}</Text>
            from the training group
            <Text as="span" color="red">{` ${trainingGroupToRemove?.name}`}</Text>. This cannot be undone.
          </Text>
        }
        onCancel={() => setTrainingGroupToRemove(null)}
        onClose={() => setTrainingGroupToRemove(null)}
        onConfirm={() => (trainingGroupToRemove ? handleRemoveGroup(trainingGroupToRemove) : undefined)}
        title="Remove from training group"
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
      />
    </Box>
  );
};
