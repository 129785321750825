import React, { useRef, useState } from "react";
import {
  Box,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
} from "@chakra-ui/react";
import { FormModal } from "../../../common/components/FormModal";
import { CommonIconButton } from "../../../common/components/CommonIconButton";
import { CommonButton } from "../../../common/components/CommonButton";
import { CommonNumberInput } from "../../../common/components/CommonNumberInput";
import { CommonInput } from "../../../common/components/CommonInput";
import { CommonTextArea } from "../../../common/components/CommonTextArea";
import type { EvaluationCriteria as EvaluationCriteriaType } from "../../../types";

/** Props for the EvaluationCriteria component. */
type EvaluationCriteriaProps = {
  allCriteria: EvaluationCriteriaType[];
  onSave?: (criteriaToSave: EvaluationCriteriaType) => Promise<void>;
  isLoading: boolean;
  readonly?: boolean;
  dataTestId?: string;
};

/** Component for managing evaluation criteria, which define requirements for rating levels. */
export const EvaluationCriteria: React.FC<EvaluationCriteriaProps> = (props) => {
  const [currentCriteria, setCurrentCriteria] = useState<EvaluationCriteriaType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const accordionRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const [fromRatingLevel, setFromRatingLevel] = useState<number>(1);
  const [toRatingLevel, setToRatingLevel] = useState<number>(1);
  const [criteriaDefinition, setCriteriaDefinition] = useState("");
  const [criteriaRequirements, setCriteriaRequirements] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  /** Calculates available levels considering existing criteria and the current one if editing */
  const getAvailableLevels = () => {
    const allLevels = Array.from({ length: 10 }, (_, i) => i + 1);

    // Used levels excluding the current criterion (if editing)
    const usedLevels = props.allCriteria
      .filter((criteria) => !(isEditing && currentCriteria && criteria.id === currentCriteria.id))
      .flatMap((criteria) => {
        const levels = [];
        for (let i = criteria.fromRatingLevel; i <= criteria.toRatingLevel; i++) {
          levels.push(i);
        }
        return levels;
      });

    // Available levels including levels of the current criterion (if editing)
    let availableLevels: number[];
    if (isEditing && currentCriteria) {
      const currentLevels: number[] = [];
      for (let i = currentCriteria.fromRatingLevel; i <= currentCriteria.toRatingLevel; i++) {
        currentLevels.push(i);
      }
      availableLevels = allLevels.filter((level) => !usedLevels.includes(level) || currentLevels.includes(level));
    } else {
      availableLevels = allLevels.filter((level) => !usedLevels.includes(level));
    }

    return availableLevels.sort((a, b) => a - b);
  };

  /** Determines if all rating levels are defined */
  const areAllLevelsDefined = () => {
    if (props.readonly) {
      return false;
    }

    const availableLevels = getAvailableLevels();
    return availableLevels.length === 0;
  };

  const allLevelsUsed = areAllLevelsDefined();

  /** Sets the default rating levels to the smallest possible unused values. */
  const setDefaultRatingLevels = () => {
    if (props.readonly) {
      return;
    }

    const availableLevels = getAvailableLevels();

    if (availableLevels.length > 0) {
      const smallestAvailableLevel = availableLevels[0];
      setFromRatingLevel(smallestAvailableLevel);
      setToRatingLevel(smallestAvailableLevel);
    } else {
      // If all levels are used, default to 1
      setFromRatingLevel(1);
      setToRatingLevel(1);
    }
  };

  /** Opens the modal to add a new evaluation criteria. */
  const handleAdd = () => {
    if (allLevelsUsed || props.readonly) {
      // Cannot add more criteria
      return;
    }
    setCurrentCriteria(null);
    setIsEditing(false);
    setDefaultRatingLevels();
    setCriteriaDefinition("");
    setCriteriaRequirements("");
    setIsModalOpen(true);
  };

  /**
   * Opens the modal to edit an existing evaluation criteria.
   *
   * @param criteria - The criteria to edit.
   */
  const handleEdit = (criteria: EvaluationCriteriaType) => {
    if (props.readonly) {
      return;
    }

    setCurrentCriteria(criteria);
    setIsEditing(true);
    setFormFields(criteria);
    setIsModalOpen(true);
  };

  /**
   * Sets the form fields based on the given criteria.
   *
   * @param criteria - The criteria whose values will populate the form fields.
   */
  const setFormFields = (criteria: EvaluationCriteriaType) => {
    if (props.readonly) {
      return;
    }

    setFromRatingLevel(criteria.fromRatingLevel);
    setToRatingLevel(criteria.toRatingLevel);
    setCriteriaDefinition(criteria.definition);
    setCriteriaRequirements(criteria.requirements);
  };

  /** Handles the submission of the form, adding or updating an evaluation criteria. */
  const handleFormSubmit = async () => {
    if (props.readonly) {
      return;
    }

    setIsSaving(true);
    if (isEditing && currentCriteria) {
      const updatedCriteria: EvaluationCriteriaType = {
        ...currentCriteria,
        fromRatingLevel,
        toRatingLevel,
        definition: criteriaDefinition,
        requirements: criteriaRequirements,
      };
      setIsModalOpen(false);
      await props.onSave?.(updatedCriteria);
    } else {
      const newCriteria: EvaluationCriteriaType = {
        fromRatingLevel,
        toRatingLevel,
        definition: criteriaDefinition,
        requirements: criteriaRequirements,
      };
      setIsModalOpen(false);
      await props.onSave?.(newCriteria);
    }
    setIsSaving(false);
  };

  /**
   * Determines the reasons why the form submit button should be disabled.
   *
   * @returns A string containing the reasons, or undefined if no reasons.
   */
  const getSubmitDisabledReasons = (): string | undefined => {
    if (props.readonly) {
      return;
    }

    const reasons: string[] = [];

    if (fromRatingLevel < 1 || fromRatingLevel > 10 || toRatingLevel < 1 || toRatingLevel > 10) {
      reasons.push("Rating levels are between 1 and 10");
    }
    if (fromRatingLevel > toRatingLevel) {
      reasons.push("'Rating level start' is less than or equal to 'Rating level end'");
    }
    const overlapping = props.allCriteria.some((criteria) => {
      if (isEditing && currentCriteria && criteria.id === currentCriteria.id) {
        return false;
      }
      return fromRatingLevel <= criteria.toRatingLevel && toRatingLevel >= criteria.fromRatingLevel;
    });

    if (overlapping) {
      reasons.push("Rating levels does not overlap with existing criteria");
    }

    if (!criteriaDefinition.trim()) {
      reasons.push("Rating has a definition");
    }

    if (!criteriaRequirements.trim()) {
      reasons.push("Criteria is set");
    }

    if (allLevelsUsed && !isEditing) {
      reasons.push("There is a rating level without definition");
    }

    return reasons.length > 0 ? `Saving is enabled once\n- ${reasons.join("\n- ")}` : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  /**
   * Removes an evaluation criteria from the list.
   *
   * @param id - The identifier of the criteria to remove.
   */
  const handleRemove = async (id: string) => {
    if (props.readonly) {
      return;
    }
    setIsSaving(true);
    setIsModalOpen(false);
    await props.onSave?.({
      id: id,
      definition: currentCriteria?.definition || "",
      fromRatingLevel: currentCriteria?.fromRatingLevel || 0,
      requirements: currentCriteria?.requirements || "",
      toRatingLevel: currentCriteria?.toRatingLevel || 0,
      remove: true,
    });
    setIsSaving(false);
  };

  // Compute min and max values for the number inputs
  const availableLevels = getAvailableLevels();
  const minAvailableLevel = Math.min(...availableLevels);
  const maxAvailableLevel = Math.max(...availableLevels);

  return (
    <Box data-testid={props.dataTestId || "evaluation-criteria-card"}>
      {!props.readonly && (
        <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
          <Text fontSize="x-large">Evaluation Criteria ({props.allCriteria.length})</Text>
          <CommonIconButton
            height="36px"
            aria-label="Add Evaluation Criteria"
            onClick={handleAdd}
            disabled={isSaving || props.isLoading || allLevelsUsed}
            tooltip={
              allLevelsUsed
                ? "All rating levels 1-10 are already defined. Modify or delete existing criteria to add new ones"
                : undefined
            }
          />
        </Stack>
      )}
      {props.allCriteria.length > 0 ? (
        <Accordion allowMultiple display="flex" flexDirection="column" gap="2">
          {props.allCriteria.map((criteria, index) => (
            <AccordionItem
              key={criteria.id || index}
              border="1px solid"
              borderRadius="md"
              boxShadow="sm"
              borderColor="blackAlpha.300"
              transition="all 0.3s ease"
              _hover={{ borderColor: "orange.500", boxShadow: "none" }}
            >
              <AccordionButton
                ref={(el) => (accordionRefs.current[index] = el)}
                paddingY={{ mobile: "4", laptop: "2" }}
                paddingX={{ mobile: "2", laptop: "4" }}
                width="100%"
                borderTopRadius="md"
                borderBottomRadius="md"
                transition="all 0.3s ease"
                _expanded={{ borderBottomRadius: "none", color: "orange.400" }}
                _hover={{ backgroundColor: "transparent" }}
              >
                <Grid
                  display={{ mobile: "flex", laptop: "grid" }}
                  flexDirection={{ mobile: "column", laptop: undefined }}
                  templateColumns="min-content 1fr"
                  columnGap="4"
                  width="100%"
                  color="inherit"
                >
                  <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                    {criteria.fromRatingLevel === criteria.toRatingLevel
                      ? `Rating level ${criteria.fromRatingLevel}`
                      : `Rating levels ${criteria.fromRatingLevel} - ${criteria.toRatingLevel}`}
                  </Text>
                  <Text as="dd" fontSize="medium" color="inherit" textAlign="start">
                    {criteria.definition}
                  </Text>
                </Grid>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                paddingY="4"
                paddingX={{ mobile: "2", laptop: "4" }}
                borderBottomRadius="md"
                cursor="pointer"
                onClick={() => {
                  const button = accordionRefs.current[index];
                  if (button) {
                    button.click();
                  }
                }}
              >
                <Grid
                  templateColumns={{ mobile: "1", laptop: "min-content 1fr" }}
                  columnGap="4"
                  rowGap="4"
                  alignItems="center"
                >
                  <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                    Criteria
                  </Text>
                  <Stack as="dd" direction="row" spacing="2" alignItems="center" justifyContent="space-between">
                    <Text flex={1}>{criteria.requirements}</Text>
                    {!props.readonly && (
                      <CommonButton
                        aria-label="Edit Evaluation Criteria"
                        variantType="outlineSecondary"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleEdit(criteria);
                        }}
                        disabled={isSaving || props.isLoading}
                      >
                        Edit
                      </CommonButton>
                    )}
                  </Stack>
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <Text color="blackAlpha.600">Add Evaluation Criteria to define the requirements for each rating level</Text>
      )}

      <FormModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        handleSubmit={handleFormSubmit}
        submitDisabled={!!submitDisabledReasons || isSaving || props.isLoading}
        submitButtonHoverText={submitDisabledReasons}
        handleRemove={() => handleRemove(currentCriteria?.id || "")}
        removeDisabled={!currentCriteria?.id}
        removeDisabledReason={!currentCriteria?.id ? "Evaluation Criteria isn't created yet" : undefined}
        submitButtonText={isEditing ? "Update" : "Create"}
        title={isEditing ? "Edit Evaluation Criteria" : "Define Evaluation Criteria"}
      >
        <Stack spacing="4">
          <Stack direction="row" spacing="4">
            <CommonNumberInput
              placeholder="Rating level start"
              min={minAvailableLevel}
              max={Math.min(toRatingLevel, maxAvailableLevel)}
              value={fromRatingLevel}
              onChange={(valueString) => setFromRatingLevel(Number(valueString))}
              disabled={isSaving || props.isLoading}
            />
            <CommonNumberInput
              placeholder="Rating level end"
              min={fromRatingLevel}
              max={maxAvailableLevel}
              value={toRatingLevel}
              onChange={(valueString) => setToRatingLevel(Number(valueString))}
              disabled={isSaving || props.isLoading}
            />
          </Stack>
          <CommonInput
            placeholder="Rating definition"
            value={criteriaDefinition}
            onChange={(value) => setCriteriaDefinition(value)}
            disabled={isSaving || props.isLoading}
          />
          <CommonTextArea
            placeholder="Criteria for achieving this rating"
            value={criteriaRequirements}
            onChange={(value) => setCriteriaRequirements(value)}
            disabled={isSaving || props.isLoading}
          />
        </Stack>
      </FormModal>
    </Box>
  );
};
