import { gql } from "@apollo/client";

export const GET_ALL_SEASON_PLANS = gql`
  query GetAllSeasonPlans {
    getAllSeasonPlans {
      id
      startDateTime
      endDateTime
      isOrganizationWide
      skillSets {
        id
        skillSet {
          id
          name
        }
        skills {
          id
          name
        }
      }
      recurringTrainingEvents {
        id
        name
      }
      players {
        id
        name
      }
    }
  }
`;
