import React from "react";
import { Stack, Box } from "@chakra-ui/react";
import { MultiTagSelectMenu } from "../../../../../../common/components/MultiTagSelectMenu";
import { SwitchWithLabel } from "../../../../../../common/components/SwitchWithLabel/SwitchWithLabel";

/** Props for the CalendarHeader component. */
type SeasonPlanCalendarHeaderProps = {
  /** All available athletes. */
  allAthletes: Array<{ name: string }>;
  /** Selected athletes. */
  selectedAthletes: Array<{ name: string }>;
  /** All available groups. */
  allGroups: Array<{ name: string }>;
  /** Selected groups. */
  selectedGroups: Array<{ name: string }>;
  /** Loading state indicator. */
  isLoading: boolean;
  /** Filter to show only organization wide season plans */
  showOnlyOrganizationWide: boolean;
  /** Filter to show only Training Group specific season plans */
  showOnlyTrainingGroups: boolean;
  /** Filter to show only individual athlete season plans */
  showOnlyAthletePlans: boolean;
  /** Function to set selected athletes. */
  setSelectedAthletes: (athletes: Array<{ name: string }>) => void;
  /** Function to set selected groups. */
  setSelectedGroups: (groups: Array<{ name: string }>) => void;
  /** Function to toggle showing only organization wide season plans */
  setShowOnlyOrganizationWide: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only Training Group specific season plans */
  setShowOnlyTrainingGroups: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to toggle showing only individual athlete season plans */
  setShowOnlyAthletePlans: React.Dispatch<React.SetStateAction<boolean>>;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
};

/** SeasonPlanCalendarHeader component to control and filter calendar view settings. */
export const SeasonPlanCalendarHeader: React.FC<SeasonPlanCalendarHeaderProps> = (props) => {
  return (
    <Box id="season-plan-calendar-header" data-testid="season-plan-calendar-header">
      <Stack
        direction={{ mobile: "column", laptop: "row" }}
        spacing={{ mobile: "2", laptop: "4" }}
        justifyContent="center"
        alignItems="center"
        data-testid="calendar-filter-controls"
      >
        <MultiTagSelectMenu
          selectedTags={props.selectedAthletes}
          handleTagSelection={(selectedValues) => {
            props.setSelectedAthletes(selectedValues);
            props.setShowOnlyOrganizationWide(false);
            props.setShowOnlyAthletePlans(false);
            props.setShowOnlyTrainingGroups(false);
            props.setSelectedGroups([]);
          }}
          options={props.allAthletes}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          label="Filter by athletes"
          width={props.isMobile ? "100%" : "80"}
          dataTestId="athlete-filter"
        />
        <MultiTagSelectMenu
          selectedTags={props.selectedGroups}
          handleTagSelection={(selectedValues) => {
            props.setSelectedGroups(selectedValues);
            props.setShowOnlyOrganizationWide(false);
            props.setShowOnlyAthletePlans(false);
            props.setShowOnlyTrainingGroups(false);
            props.setSelectedAthletes([]);
          }}
          options={props.allGroups}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          label="Filter by groups"
          width={props.isMobile ? "100%" : "80"}
          dataTestId="group-filter"
        />
        <SwitchWithLabel
          checked={props.showOnlyOrganizationWide}
          label="Everyone only"
          onChange={() => {
            props.setShowOnlyOrganizationWide((prev) => !prev);
            props.setShowOnlyAthletePlans(false);
            props.setShowOnlyTrainingGroups(false);
            props.setSelectedAthletes([]);
            props.setSelectedGroups([]);
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="free-spots-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlyTrainingGroups}
          label="Individual Groups only"
          onChange={() => {
            props.setShowOnlyTrainingGroups((prev) => !prev);
            props.setShowOnlyAthletePlans(false);
            props.setShowOnlyOrganizationWide(false);
            props.setSelectedAthletes([]);
            props.setSelectedGroups([]);
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="weekly-events-switch"
        />
        <SwitchWithLabel
          checked={props.showOnlyAthletePlans}
          label="Individual Athletes only"
          onChange={() => {
            props.setShowOnlyAthletePlans((prev) => !prev);
            props.setShowOnlyTrainingGroups(false);
            props.setShowOnlyOrganizationWide(false);
            props.setSelectedAthletes([]);
            props.setSelectedGroups([]);
          }}
          width={props.isMobile ? "100%" : undefined}
          dataTestId="single-events-switch"
        />
      </Stack>
    </Box>
  );
};
